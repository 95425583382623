import {
  React,
  _,
  memoizeOne,
  bind
} from "$Imports/Imports";

import {
  FormControl,
  InputLabel,
  Input,
  Select,
  SelectProps,
  MenuItem,
} from "$Imports/MaterialUIComponents";

import {
  joinClasses
} from "$Utilities/css";

import {
  CategoryItem,
} from "$Generated/api";

interface ISourceProviderSelectorProps extends SelectProps {
  formControlClassName?: string;
  htmlForName?: string;
  sourceAdapterData: CategoryItem[];
  onAdapterChange?: (e: React.ChangeEvent<{ name?: string | undefined; value: unknown; }>, child: React.ReactNode, adapterName: string) => void;
}

const styles: {
  adapterSelector: string;
} = require("./SourceProviderSelector.scss");

interface IParsedSourceJson {
  Id: string;
  FriendlyName?: string;
}

export class SourceProviderSelector extends React.PureComponent<ISourceProviderSelectorProps> {

  static defaultProps: Partial<ISourceProviderSelectorProps> = {
    htmlForName: "adapter",
    input: (<Input name="adapter-name" id="adapter" />),
    fullWidth: true
  };

  private _sortName(adapters: IParsedSourceJson[]): IParsedSourceJson[] {
    return _.sortBy(adapters, (a) => {
      return a.FriendlyName === undefined || a.FriendlyName === null ? null : a.FriendlyName.toLowerCase();
    });
  }

  private _findSelected(adapters: IParsedSourceJson[], searchValue: unknown): IParsedSourceJson | undefined {
    return _.find(adapters, (a) => {
      if (searchValue === undefined || searchValue === null) {
        return false;
      }

      return a.Id === searchValue || a.FriendlyName === searchValue;
    });
  }

  private _parsedSourceJson(adapters: CategoryItem[]): IParsedSourceJson[] {
    let convertedResults = _.map(adapters, (a) => {
      let parsed = null;
      try {
        parsed = JSON.parse(a.value !== undefined ? a.value : "{}");
        return parsed as IParsedSourceJson;
      } catch {
        return {} as IParsedSourceJson;
      }
    });

    convertedResults = _.filter(convertedResults, (c) => c !== undefined && c !== null);
    return convertedResults ? convertedResults : [];
  }

  private readonly _sortName_memoize = memoizeOne(this._sortName);
  private readonly _findSelected_memoize = memoizeOne(this._findSelected);
  private readonly _parsedSourceJson_memoize = memoizeOne(this._parsedSourceJson);

  @bind
  private _onChange(e: React.ChangeEvent<{ name?: string | undefined; value: unknown; }>, child: React.ReactNode): void {
    if (this.props.onChange) {
      this.props.onChange(e, child);
    }

    if (this.props.onAdapterChange) {
      const parsedValues = this._parsedSourceJson_memoize(this.props.sourceAdapterData);
      const adapter = this._findSelected(parsedValues, e.target.value);
      this.props.onAdapterChange(e, child, adapter ? adapter.FriendlyName ? adapter.FriendlyName : "" : "");
    }
  }

  render() {
    const { htmlForName, formControlClassName, sourceAdapterData, value, onChange, onAdapterChange, ...passThroughProps } = this.props;

    const parsedJson = this._parsedSourceJson_memoize(sourceAdapterData);
    const sortedValue = this._sortName_memoize(parsedJson);
    const selectedValue = this._findSelected_memoize(parsedJson, value);

    return (
      <FormControl
        className={joinClasses([styles.adapterSelector, formControlClassName])}
      >
        <InputLabel htmlFor="adapter">Adapter</InputLabel>
        <Select
          {...passThroughProps}
          value={selectedValue ? selectedValue.Id : ""}
          onChange={this._onChange}
        >
          <MenuItem value="">
            <em>All Adapters</em>
          </MenuItem>
          {_.map(sortedValue, (a, aidx) => (
            <MenuItem key={aidx} value={a.Id}>{a.FriendlyName}</MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  }
}
