import {
  React,
  _,
  bind
} from "$Imports/Imports";
import { Button } from "$Imports/MaterialUIComponents";

import { AdvanceTextField } from "$Components/Common";
import { ICredentialProps } from "$State/CredentialTypes";
import { OmniOneCredentials } from "$Generated/api";

interface IOmniOneBaseProps {
}
type IOmniOneProps = IOmniOneBaseProps & ICredentialProps<OmniOneCredentials>;

export class OmniOne extends React.PureComponent<IOmniOneProps> {

  @bind
  onChange(e: any) {
    if (e.target.name === "username") {
      this.props.onChange(this.props.providerKey, { username: e.target.value });
    }
    else if (e.target.name === "password") {
      this.props.onChange(this.props.providerKey, { password: e.target.value });
    }
  }

  render(): JSX.Element {
    const omniOne: OmniOneCredentials = this.props.data ? this.props.data : {} as OmniOneCredentials;
    return (
      <div>
        <AdvanceTextField
          label="User Name*"
          name="username"
          onChange={this.onChange}
          value={omniOne.username ? omniOne.username : ""}
          error={(omniOne.username === "")}
        /><br />
        <AdvanceTextField
          label="Password*"
          name="password"
          type="password"
          onChange={this.onChange}
          value={omniOne.password ? omniOne.password : ""}
          error={(omniOne.password === "")}
        /><br />
        <br />
        <Button className="float-right" disabled={true} color={"primary"}>VALIDATE CREDENTIALS</Button>{/* Disabled for as per LDM - 483 */}
      </div>
    );
  }
}