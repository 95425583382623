import {
  React,
  _,
  bind
} from "$Imports/Imports";

import {
  ITenantViewProps
} from '../TenantViewProps';

import {
  TenantViewFaultsService,
  ITenantViewFaultsServiceInjectedProps
} from "$State/job-views/JobDetailFaultsFreezerService";

import {
  JobDetailFaults
} from "./JobDetailFaults";

import {
  AjaxActionIndicator
} from "$Components/Common";

interface IJobDetailFaultsViewBaseProp {

}

type IJobDetailFaultsViewProp = IJobDetailFaultsViewBaseProp
& ITenantViewFaultsServiceInjectedProps
& ITenantViewProps;

class _JobDetailFaultsView extends React.Component<IJobDetailFaultsViewProp> {
  componentDidMount() {
    if (this.props.job !== null && this.props.job.id !== undefined) {
      this.props.jobDetailFaultsService.setFaultsJobId(this.props.job.id);
      this.props.jobDetailFaultsService.fetchFaults();
    }
  }

  render() {
    const state = this.props.jobDetailFaultsService.getState();
    const {
      jobDetailFaultsResults,
      jobDetailFaultsPager,
      jobDetailFaultsSearch
    } = state;

    return (
      <>
        <AjaxActionIndicator
          state={[jobDetailFaultsResults]}
        />
        <JobDetailFaults
          data={jobDetailFaultsResults.data?.data || []}
          pager={jobDetailFaultsPager}
          search={jobDetailFaultsSearch}
        />
      </>
    );
  }
}

export const JobDetailFaultsView = TenantViewFaultsService.inject(
  _JobDetailFaultsView
);