import {
  React,
  _,
  bind
} from "$Imports/Imports";
import { Button } from "$Imports/MaterialUIComponents";
import { AdvanceTextField } from "$Components/Common";
import { ICredentialProps } from "$State/CredentialTypes";
import { SFTPCredentials } from "$Generated/api";

interface ISFTPBaseProps {
}

type ISFTPProps = ISFTPBaseProps & ICredentialProps<SFTPCredentials>;

export class SFTP extends React.PureComponent<ISFTPProps>{


  @bind
  onChange(e: any) {
    if (e.target.name === "ftpAddress") {
      this.props.onChange(this.props.providerKey, { ftpAddress: e.target.value });
    }
    else if (e.target.name === "ftpUsername") {
      this.props.onChange(this.props.providerKey, { ftpUsername: e.target.value });
    }
    else if (e.target.name === "ftpPassword") {
      this.props.onChange(this.props.providerKey, { ftpPassword: e.target.value });
    }
  }

  render(): JSX.Element {
    const SFTPData: SFTPCredentials = this.props.data ? this.props.data : {} as SFTPCredentials;
    return (
      <div>
        <AdvanceTextField
          label="Address*"
          name="ftpAddress"
          autoComplete="off"
          value={SFTPData.ftpAddress}
          onChange={this.onChange}
          error={(SFTPData.ftpAddress === "")}
        /><br />
        <AdvanceTextField
          label="Username*"
          name="ftpUsername"
          value={SFTPData.ftpUsername}
          onChange={this.onChange}
          error={(SFTPData.ftpUsername === "")}
        /><br />
        <AdvanceTextField
          name="ftpPassword"
          autoComplete="new-password"
          type="password"
          label="Password"
          value={SFTPData.ftpPassword}
          onChange={this.onChange}
        /><br />

        <Button className="float-right" disabled={true} color={"primary"}>VALIDATE CREDENTIALS</Button>
      </div>
    );
  }
}