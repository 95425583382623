import {
  React,
  bind,
  numeral,
  _
} from "$Imports/Imports";

import {
  DataTable,
  DataTablePager,
  IDataTableColumn,
  DateFormatter,
  JsonPropertyDisplay
} from "$Components/Common";

import {
  TableCell,
  TableRow
} from "$Imports/MaterialUIComponents";

import {
  IPagerState,
  calcPagedState
} from "$State/PagerPagingState";

import {
  ISortState
} from "$State/SortState";

import {
  DrivingStatusEvent
} from "$Generated/api";

interface IJobDetailReadingsProps {
  data: DrivingStatusEvent[];
  pagerState: IPagerState;
  onPagerStateChange?: (pagerState: IPagerState) => void;
}

export class ReadingsGrid extends React.PureComponent<IJobDetailReadingsProps> {
  private readonly columns: IDataTableColumn<DrivingStatusEvent>[] = [
    {
      columnName: "vin",
      columnFieldData: (r) => r.vin ? r.vin : "",
      sortMethod: (r) => (r.vin ? r.vin : "").toLowerCase(),
      headerValue: "VIN"
    },
    {
      columnName: "latitude",
      columnFieldData: (r) => numeral(r.latitude).format("0.0000"),
      sortMethod: (r) => r.latitude,
      headerValue: "Latitude"
    },
    {
      columnName: "longitude",
      columnFieldData: (r) => numeral(r.longitude).format("0.0000"),
      sortMethod: (r) => r.longitude,
      headerValue: "Longitude"
    },
    // removed temporarily as part of LDM-1369; may be restored in the future
    // {
    //   columnName: "vehicle-id",
    //   columnFieldData: (r) => r.vehicleId ? r.vehicleId : "",
    //   sortMethod: (r) => r.vehicleId,
    //   headerValue: "Vehicle ID"
    // },
    {
      columnName: "data",
      columnFieldData: (r) => r.data ? (<JsonPropertyDisplay data={r.data} />) : "",
      headerValue: "Data",
    },
    {
      columnName: "datetime",
      columnFieldData: (r) => r.eventDateTime ? (<DateFormatter value={r.eventDateTime} />) : null,
      sortMethod: (r) => r.eventDateTime,
      headerValue: "Datetime"
    }
  ];

  @bind
  private _onPagerStateChanged(page: number, rowsPerPage: number) {
    if (this.props.onPagerStateChange) {
      this.props.onPagerStateChange({
        page,
        rowsPerPage,
      });
    }
  }

  @bind
  private _onChangePage() {

  }

  render() {
    const { data, pagerState } = this.props;
    const pageState = calcPagedState(pagerState);

    return (
      <DataTable
        columns={this.columns}
        data={data}
        skipRows={pageState.skip}
        takeRows={pageState.take}
        tableFooterComponent={(
          <TableRow>
            <DataTablePager
              onChangePage={this._onChangePage}
              count={data.length}
              page={pagerState.page}
              rowsPerPage={pagerState.rowsPerPage}
              onPagerStateChange={this._onPagerStateChanged}
            />
          </TableRow>
        )}
      />
    );
  }
}