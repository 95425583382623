import {
  FreezerService,
  IAjaxState,
  managedAjaxUtil,
  _,
  bind
} from "$Imports/Imports";

import {
  IPagerState
} from "./../PagerPagingState";

import {
  VehicleAssignmentViewModelIEnumerableResponseBase,
  TransportationApiFactory,
} from "$Generated/api";

import {
  ErrorService
} from "$State/ErrorFreezerService";

import {
  SitePubSubManager
} from "$Utilities/PubSubUtil";

const InjectedPropName = "tenantViewVehicleAssignment";

interface ITenantViewVehicleAssignmentFreezerState {
  vehicleAssignmentResults: IAjaxState<VehicleAssignmentViewModelIEnumerableResponseBase>;
  vehicleAssignmentPager: IPagerState;
  vehicleAssignmentSearch: string;
  jobId: string | null;
}

const defaultPagerState: IPagerState = {
  page: 0,
  rowsPerPage: 50,
};

class TenantViewVehicleAssignmentFreezerService extends FreezerService<ITenantViewVehicleAssignmentFreezerState, typeof InjectedPropName>  {
  constructor() {
    super({
      vehicleAssignmentResults: managedAjaxUtil.createInitialState(),
      vehicleAssignmentPager: defaultPagerState,
      vehicleAssignmentSearch: "",
      jobId: null
    }, InjectedPropName);

    SitePubSubManager.subscribe("application:login:before", this.clearResults);
    SitePubSubManager.subscribe("job-detail:job-id-change", this.clearResults);
  }

  public setVehicleAssignmentPagerState(pager: IPagerState) {
    this.freezer.get().vehicleAssignmentPager.set(pager);
  }

  public setVehicleAssignmentJobId(jobId: string) {
    this.freezer.get().set({
      jobId
    });
  }

  public async fetchVehicleAssignment(forceUpdate: boolean = false) {
    const results = this.freezer.get().vehicleAssignmentResults;
    const jobId = this.freezer.get().jobId;

    if (results.hasFetched && !forceUpdate) {
      return;
    }

    return managedAjaxUtil.fetchResults({
      ajaxStateProperty: "vehicleAssignmentResults",
      freezer: this.freezer,
      onExecute: (apiOptions, params, options) => {
        const transportationFactory = TransportationApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
        return transportationFactory.apiV1VehicleAssignmentsGet(params);
      },
      params: {
        jobId: jobId ? jobId : undefined,
      },
      onError: () => {
        ErrorService.pushErrorMessage("Failed to retrieve vehicle assignment data from the server.");
      }
    });
  }

  public setVehicleAssignmentSearch(search: string) {
    const currentPaging = this.freezer.get().vehicleAssignmentPager.toJS();

    this.freezer.get().set({
      vehicleAssignmentPager: {
        page: 0,
        rowsPerPage: currentPaging.rowsPerPage
      },
      vehicleAssignmentSearch: search,
    });
  }

  @bind
  private clearResults() {
    this.freezer.get().set({
      vehicleAssignmentResults: managedAjaxUtil.createInitialState(),
      vehicleAssignmentSearch: "",
      vehicleAssignmentPager: defaultPagerState,
    });
  }
}

export const TenantViewVehicleAssignmentService = new TenantViewVehicleAssignmentFreezerService();
export type ITenantViewVehicleAssignmentServiceInjectedProps = ReturnType<TenantViewVehicleAssignmentFreezerService["getPropsForInjection"]>;