import {
  React,
} from "$Imports/Imports";

import {
  Container
} from "./Container";

import {
  Paper,
} from "$Imports/MaterialUIComponents";

const styles: {
  paperStyle: string;
} = require("./AccountManagement.scss");
interface IAccountManagementBaseProps {

}

type IAccountManagementProps = IAccountManagementBaseProps;

export class AccountManagement extends React.Component<IAccountManagementProps> {
  render() {
    return (
      <Container>
        <div>
          <Paper
            className={styles.paperStyle}
          >
            Account Management Placeholder
          </Paper>
        </div>
      </Container>
    );
  }
}
