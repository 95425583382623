import {
  React,
  _,
} from "$Imports/Imports";

import {
  PubSubManager,
  dataType
} from "$Utilities/PubSubUtil";

interface IPageRefreshListenerProps<T = string, D = dataType> {
  subscribeToMessages: T | T[];

  pubSubManager: PubSubManager<T>;
  onMessageReceived: (data: D, message: T) => void;
}

interface IPageRefreshListenerState {
  tokens: string[];
}

export class PageRefreshListener<T = string, D = dataType> extends React.PureComponent<IPageRefreshListenerProps<T, D>, IPageRefreshListenerState> {
  state: IPageRefreshListenerState = {
    tokens: [],
  };

  componentDidMount() {
    this._updateSubscriptions(this.props.subscribeToMessages);
  }

  componentWillUnmount() {
    this._unsubscribe();
  }

  componentDidUpdate(prevProps: IPageRefreshListenerProps<T, D>, prevState: IPageRefreshListenerState) {

    if (this.props.pubSubManager === prevProps.pubSubManager) {
      // If the types are different updates the subscribed messages.
      if (typeof this.props.subscribeToMessages !== typeof prevProps.subscribeToMessages) {
        this._updateSubscriptions(this.props.subscribeToMessages);
      } else if (!_.isArray(this.props.subscribeToMessages)) {  // If the type is string, use standard string comparison to determine difference.
        if (prevProps.subscribeToMessages !== this.props.subscribeToMessages) {
          this._updateSubscriptions(this.props.subscribeToMessages);
        }
      } else {
        if (!_.isEqual(this.props.subscribeToMessages, prevProps.subscribeToMessages)) { // Check if the values within the array are different.
          this._updateSubscriptions(this.props.subscribeToMessages);
        }
      }
    } else {
      this._unsubscribe(prevProps.pubSubManager);
      this._updateSubscriptions(this.props.subscribeToMessages);
    }
  }

  private _updateSubscriptions(subscribeToMessages: T | T[], pubSubManager: PubSubManager<T> = this.props.pubSubManager) {
    this._unsubscribe(pubSubManager);

    let messages: T[] = [];

    if (!_.isArray(subscribeToMessages)) {
      messages.push(subscribeToMessages);
    } else if (subscribeToMessages) {
      messages = subscribeToMessages as T[];
    }

    this._subscribe(messages, pubSubManager);
  }

  private _subscribe(messages: T[], pubSubManager: PubSubManager<T> = this.props.pubSubManager) {
    if (messages) {
      const tokens = _.map(messages, (c) => pubSubManager.subscribe(c, (message, data) => this._onMessageReceived(data, message)));

      this.setState({
        tokens,
      });
    }
  }

  private _unsubscribe(pubSubManager: PubSubManager<T> = this.props.pubSubManager) {
    if (this.state.tokens && this.state.tokens.length !== 0) {
      _.forEach(this.state.tokens, (c) => pubSubManager.unsubscribe(c));
    }
  }

  private _onMessageReceived(data: D, message: T) {
    this.props.onMessageReceived(data, message);
  }

  render() {
    return (
      <div style={{ display: 'none' }}>
        Page Refresh Listener
      </div>
    );
  }
}