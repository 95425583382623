import {
  React,
  _
} from "$Imports/Imports";

import {
  ITenantViewProps
} from "./../TenantViewProps";

import {
  TenantViewErrorReportService,
  ITenantViewErrorReportServiceeInjectedProps
} from "$State/job-views/ErrorReportFreezerService";

import {
  AjaxActionIndicator
} from "$Components/Common/AjaxActionIndicator";

import {
  SubmissionsErrorReport
} from "./SubmissionErrorReport";

interface ISubmissionErrorReportViewBaseProps {

}

type ISubmissionErrorReportViewProps = ISubmissionErrorReportViewBaseProps
  & ITenantViewProps
  & ITenantViewErrorReportServiceeInjectedProps;

class _SubmissionErrorReportView extends React.Component<ISubmissionErrorReportViewProps> {
  componentDidMount() {
    if (this.props.job !== null && this.props.job.id !== undefined) {
      this.props.tenantViewErrorReport.setJobId(this.props.job.id, this.props.childJobs || []);
      this.props.tenantViewErrorReport.fetchSubmissionResults();
    }
  }

  render() {
    const {
      submissionResults,
      vehicleSubmissionPager,
      submissionReportJobId,
      vehicleSubmissionSearch,
      childJobs
    } = this.props.tenantViewErrorReport.getState();


    return (
      <>
        <AjaxActionIndicator
          state={[submissionResults]}
        />
        <SubmissionsErrorReport
          childJobs={childJobs}
          data={submissionResults.data || {}}
          pagerState={vehicleSubmissionPager}
          search={vehicleSubmissionSearch}
          selectedSubmissionJobId={submissionReportJobId}
        />
      </>
    );
  }
}

export const SubmissionErrorReportView = TenantViewErrorReportService.inject(
  _SubmissionErrorReportView
);