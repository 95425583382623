import {
    React,
    _
  } from "$Imports/Imports";
 
  import {
    MultipleTenantViewErrorReportService,
    IMultipleTenantViewErrorReportServiceeInjectedProps
  } from "$State/job-views/MultipleErrorReportFreezerService";
  
  import {
    AjaxActionIndicator
  } from "$Components/Common/AjaxActionIndicator";
  
  import {
    MultipleSubmissionsErrorReport
  } from "./MultipleSubmissionErrorReport";

import { ChildJobViewModel, JobSummaryViewModel } from "$Generated/api";
  
  interface IMultipleErrorReportViewBaseProps {
    job: JobSummaryViewModel[] | null;
    childJobs: ChildJobViewModel[] | null;
    forceUpdate: boolean;
    setForceUpdate: (update: boolean) => void;
  }
  
  type ISubmissionErrorReportViewProps = IMultipleErrorReportViewBaseProps
    & IMultipleTenantViewErrorReportServiceeInjectedProps;
  
  class _MultipleSubmissionErrorReportView extends React.Component<ISubmissionErrorReportViewProps> {

    state = {
        job: this.props.job,
    }

    componentDidMount() {
        this.initialize();
    }

    private async initialize(){
        if (this.props.forceUpdate && this.props.job !== null && this.props.job !== undefined) {
            const jobIds : string[] = [];
            //optional check for failed items speeds up this call:
            this.props.job.forEach(element => {if(
                element.jobId!==undefined && element.itemsFailed!==undefined && element.itemsFailed!==0
            || element.jobId!==undefined && element.itemsFailed===undefined){jobIds.push(element.jobId);
            };});
            this.props.multipleTenantViewErrorReport.setJobId(jobIds, this.props.childJobs || []);
             await this.props.multipleTenantViewErrorReport.fetchSubmissionResults(this.props.forceUpdate);
            this.props.setForceUpdate(false);
          }
    }
  
    componentDidUpdate(prevProps : IMultipleErrorReportViewBaseProps) {
        if (this.props.job !== prevProps.job) {
          this.initialize();
        }
    }
    render() {
      const {
        submissionResults,
        vehicleSubmissionPager,
        vehicleSubmissionSearch,
      } = this.props.multipleTenantViewErrorReport.getState();
      const {job, } = this.props;

      return (
        <>
          <AjaxActionIndicator
            state={[submissionResults]}
          />
          <MultipleSubmissionsErrorReport
            data={submissionResults.data || {}}
            pagerState={vehicleSubmissionPager}
            search={vehicleSubmissionSearch}
          />
        </>
      );
    }
  }
  
  export const MultipleSubmissionErrorReportView = MultipleTenantViewErrorReportService.inject(
    _MultipleSubmissionErrorReportView
  );