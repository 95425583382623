import {
  React
} from "$Imports/Imports";

import {
  Home,
  AddCircleOutline,
  DataUsage,
  Dashboard,
  DirectionsCar,
  People,
  Settings,
  Search
} from "$Imports/MaterialUIIcons";

import {
  SvgIconProps
} from "$Imports/MaterialUIComponents";

import {
  ISecurityContext
} from "$Providers/.";

import {
  UserRoles
} from "$Components/Common/UserRoles";

import * as dashboardView from "../dashboard-view";
import * as tenantView from "../tenant-view";
import * as jobsView from "../job-view";
import * as jobDetailView from "../job-detail-view";
import * as onBoardClient from "../onboard-client-view";
import * as vehicleSearch from "../vehicle-search-view";
import * as adminIntegration from "../admin-integration";
import * as accountManagement from "../account-management";
import * as genericReport from "../generic-report-view/LytxGenericReport";
import * as vehicleCount from "../vehicle-count-view";
import { securityContextHasRoles, securityContextLacksRole } from "$Providers/AuthenticationProvider";

interface IMatchProps<P> {
  match?: {
    params?: P;
  };
}

export interface INavigationItem {
  url: string;
  redirectUrls?: string[];
  label: string;
  externalLink: boolean;
  enabled: boolean | ((navItem: INavigationItem, securityContext: ISecurityContext) => boolean);
  hasAccess?: boolean | ((navItem: INavigationItem, securityContext: ISecurityContext) => boolean);
  childNavigation?: INavigationItem[];
  icon?: React.ComponentType<SvgIconProps>;
  component?: React.ComponentClass | React.FunctionComponent;
  title?: string;
}

export const mainNavigation: INavigationItem[] = [
  {
    redirectUrls: ["/"],
    url: "/dashboard",
    label: "Dashboard",
    externalLink: false,
    enabled: true,
    hasAccess: (navItem: INavigationItem, securityContext: ISecurityContext) => {
      return securityContextLacksRole(securityContext, UserRoles.ReportView);
    },
    component: dashboardView.Dashboard,
    icon: Home,
    title: "TTIP - Dashboard"
  },
  {
    redirectUrls: ["/"],
    url: "/tenants",
    label: "Tenants",
    externalLink: false,
    enabled: true,
    hasAccess: (navItem: INavigationItem, securityContext: ISecurityContext) => {
      return securityContextHasRoles(securityContext, [UserRoles.ReadTenant])
        && securityContextLacksRole(securityContext, UserRoles.ReportView);
    },
    component: tenantView.Tenant,
    icon: Dashboard,
    title: "TTIP - Tenants"
  },
  {
    url: "/jobs",
    label: "Jobs",
    externalLink: false,
    enabled: true,
    hasAccess: (navItem: INavigationItem, securityContext: ISecurityContext) => {
      return securityContextHasRoles(securityContext, [UserRoles.ReadJobsList, UserRoles.ReadJobsOverview])
        && securityContextLacksRole(securityContext, UserRoles.ReportView);
    },
    component: jobsView.Jobs,
    icon: DataUsage,
    title: "TTIP - Jobs",
    childNavigation: [
      {
        externalLink: false,
        url: "/jobs/:jobId",
        icon: DataUsage,
        label: "Job Details",
        title: "TTIP - Job Detail",
        enabled: true,
        component: (props: React.PropsWithChildren<IMatchProps<{ jobId: string }>>) => {
          const componentProps: { jobId: string } = props.match && props.match.params ? props.match.params : { jobId: "" };
          return React.createElement(jobDetailView.JobDetail, componentProps);
        }
      }
    ]
  },
  {
    url: "/onboard-client",
    label: "Onboard Client",
    externalLink: false,
    enabled: true,
    hasAccess: false,
    component: onBoardClient.OnBoardClient,
    title: "TTIP - Onboard Client",
    icon: AddCircleOutline,
  },
  {
    url: "/admin-integration",
    label: "Admin Integration",
    externalLink: false,
    enabled: true,
    hasAccess: false,
    component: adminIntegration.AdminIntegration,
    title: "TTIP - Admin Integration",
    icon: People,
  },
  {
    url: "/account-management",
    label: "Account Management",
    externalLink: false,
    enabled: true,
    hasAccess: false,
    component: accountManagement.AccountManagement,
    title: "TTIP - Account Management",
    icon: Settings,
  },
  {
    url: "/vehicle-search",
    label: "Vehicle Search",
    externalLink: false,
    enabled: true,
    hasAccess: (navItem: INavigationItem, securityContext: ISecurityContext) => {
      return (securityContext.IsAdminUser || securityContextHasRoles(securityContext, [UserRoles.PortalHoS]))
        && securityContextLacksRole(securityContext, UserRoles.ReportView);
    },
    component: vehicleSearch.VehicleSearch,
    title: "TTIP - Vehicle Search",
    icon: Search,
  },
  {
    redirectUrls: ["/"],
    url: "/generic-report",
    label: "Report",
    externalLink: false,
    enabled: true,
    hasAccess: (navItem: INavigationItem, securityContext: ISecurityContext) => {
      return securityContext.IsAdminUser || securityContextHasRoles(securityContext, [UserRoles.ReportView]);
    },
    component: genericReport.LytxGenericReport,
    title: "TTIP - Report",
    icon: Dashboard,
  },
  {
    url: "/vehicle-count",
    label: "Vehicle Count Report",
    externalLink: false,
    enabled: true,
    hasAccess: (navItem: INavigationItem, securityContext: ISecurityContext) => {
      return securityContext.IsAdminUser;
    },
    component: vehicleCount.VehicleCount,
    title: "TTIP - Vehicle Count Report",
    icon: DirectionsCar
  },
];
