import {
  moment,
  _
} from "$Imports/Imports";

import {
  TimeZoneService
} from "$State/TimeZoneFreezerService";

export type rangeType = "custom" | "today" | "yesterday" | "last-7-days" | "last-14-days" | "last-30-days";

export interface IDateRange {
  startDate: Date | moment.Moment | null;
  endDate: Date | moment.Moment | null;
}

export interface IISODateRangeString {
  startDate?: string;
  endDate?: string;
}

// The list of values to determine range type.
const checkTypes: rangeType[] = [
  "today",
  "yesterday",
  "last-7-days",
  "last-14-days",
  "last-30-days"
];

export function calcRangeType(dateRange?: IDateRange | null): rangeType {
  if (
    dateRange === null ||
    dateRange === undefined ||
    dateRange.startDate === null ||
    dateRange.endDate === null
  ) {
    return "custom";
  }

  // Return custom range by default.
  let result: rangeType = "custom";

  // Search for matching range.
  _.forEach(checkTypes, (rt) => {
    const range = calcDateRange(rt);

    if (
      range.endDate &&
      range.startDate &&
      dateRange.endDate !== null &&
      dateRange.startDate !== null &&
      moment(range.endDate).isSame(dateRange.endDate) &&
      moment(range.startDate).isSame(dateRange.startDate)
    ) {
      result = rt;
      return false;
    }
  });

  return result;
}

export function convertToISOString(dateRange: IDateRange): IISODateRangeString {

  // Time zones are problematic - because our dateRange is timezoneless. (DateRange picker uses local time.)
  const results: IISODateRangeString = {};
  const zone = TimeZoneService.getTimeZone();

  if (dateRange.endDate !== null && moment(dateRange.endDate).isValid()) {
    const endDateAsDate = moment(dateRange.endDate).toDate();
    const endStringWithoutZone = endDateAsDate.getFullYear() + "-" + ((endDateAsDate.getMonth() + 1)) + "-" + endDateAsDate.getDate() + "T23:59:59";
    const endZone = moment.tz(endDateAsDate.getFullYear() + "-" + ((endDateAsDate.getMonth() + 1)) + "-" + endDateAsDate.getDate() + " 23:59:59", "YYYY-MM-DD h:mm:ss", zone).format();

    if (zone !== "UTC") {
      results.endDate = endStringWithoutZone + "-" + endZone.slice(-5); // -5 to pull out timezone
    }
    else {
      results.endDate = endZone;
    }
  }

  if (dateRange.startDate !== null && moment(dateRange.startDate).isValid()) {
    const startDateAsDate = moment(dateRange.startDate).toDate();
    const startStringWithoutZone = startDateAsDate.getFullYear() + "-" + ((startDateAsDate.getMonth() + 1)) + "-" + startDateAsDate.getDate() + "T00:00:00";
    const startZone = moment.tz(startDateAsDate.getFullYear() + "-" + ((startDateAsDate.getMonth() + 1)) + "-" + startDateAsDate.getDate() + " 00:00:00", "YYYY-MM-DD h:mm:ss", zone).format();

    if (zone !== "UTC") {
      results.startDate = startStringWithoutZone + "-" + startZone.slice(-5); // -5 to pull out timezone
    }
    else {
      results.startDate = startZone;
    }
  }
  return results;
}

export function calcDateRange(range: rangeType): IDateRange {
  switch (range) {
    case "today":
      return {
        startDate: moment().startOf("day"),
        endDate: moment().endOf("day"),
      };

    case "yesterday": {
      const endOfRange = moment().endOf("day").subtract(1, "day");

      return {
        endDate: endOfRange,
        startDate: moment(endOfRange).startOf("day")
      };
    }

    case "last-7-days": {
      const endOfRange = moment().endOf("day");

      return {
        endDate: endOfRange,
        startDate: moment(endOfRange).subtract(6, "day").startOf("day"),
      };
    }

    case "last-14-days": {
      const endOfRange = moment().endOf("day");

      return {
        endDate: endOfRange,
        startDate: moment(endOfRange).subtract(13, "day").startOf("day"),
      };
    }

    case "last-30-days": {
      const endOfRange = moment().endOf("day");

      return {
        endDate: endOfRange,
        startDate: moment(endOfRange).subtract(29, "day").startOf("day"),
      };
    }

    case "custom":
    default:
      return {
        endDate: null,
        startDate: null
      };
  }
}
