import {
  React,
  moment,
  _
} from "$Imports/Imports";

import {
  timeZoneType
} from "./TimeZoneType";

import {
  TimeZoneConsumer
} from "$Providers/TimeZoneProvider";

interface IDateFormatterProps {
  value: Date | moment.Moment | null | undefined;
  timeZone?: timeZoneType;
  formatString?: string;
}

const defaultTimeZone: timeZoneType = "UTC";
const defaultFormatString: string = "MM/DD/YYYY h:mm A [(]z[)]";
const defaultMilitaryFormatString: string = "MM/DD/YYYY HH:mm [(]z[)]";

export class DateFormatter extends React.PureComponent<IDateFormatterProps> {

  public static formatDateString(value: Date | moment.Moment, timeZone: timeZoneType, formatString: string, isMilitaryTime: boolean): string {
    if (formatString === "") {
      if (isMilitaryTime) {
        formatString = defaultMilitaryFormatString
      } else {
        formatString = defaultFormatString;
      }
    } 
    return moment(value).tz(timeZone).format(formatString);
  }

  public static getDefaultFormatString(isMilitaryTime: boolean): string {
    return isMilitaryTime ? defaultMilitaryFormatString : defaultFormatString;
  }

  render() {
    const { value, timeZone } = this.props;

    return value !== null && value !== undefined ? (
      <TimeZoneConsumer>
        {(context) => {
          const formatString = DateFormatter.getDefaultFormatString(context!.isMilitaryTime);
          if (timeZone !== undefined) // Property was set
          {
            return moment(value).tz(timeZone).format(formatString);
          }
          else if (context!.timeZone !== undefined) {
            return moment(value).tz(context!.timeZone).format(formatString);
          }
          else {
            return moment(value).tz(defaultTimeZone).format(formatString);
          }
        }}
      </TimeZoneConsumer>
    ) : null;
  }
}