import {
  FreezerService,
  IAjaxState,
  managedAjaxUtil,
} from "$Imports/Imports";

import {
  LoginApiFactory,
  LoginViewModel,
  ResponseBase,
  UserApiFactory,
  UserInfoViewModel,
  } from "$Generated/api";

import { AuthenticationSettingsInstance} from "../utilities/Security";

import {
  SitePubSubManager
} from "$Utilities/PubSubUtil";

const InjectedPropName = "login";

interface ILoginState {
  formFields: LoginViewModel;
  isLoggedIn: boolean;
  attemptLoginResults: IAjaxState<ResponseBase>;
  userInformationResults: IAjaxState<UserInfoViewModel>;
  logoutResults: IAjaxState<ResponseBase>;
  hasError: boolean;
}

class LoginFreezerService extends FreezerService<ILoginState, typeof InjectedPropName> {
  constructor() {
    super({
      attemptLoginResults: managedAjaxUtil.createInitialState(),
      formFields: {
        password: "",
        username: "",
      },
      isLoggedIn: false,
      userInformationResults: managedAjaxUtil.createInitialState(),
      logoutResults: managedAjaxUtil.createInitialState(),
      hasError: false,
    }, InjectedPropName);

  }

  public async getUserInformation(forceUpdate: boolean = false): Promise<void> {

    if(this.freezer.get().userInformationResults.hasFetched && !forceUpdate) {
      return;
    }

    await managedAjaxUtil.fetchResults({
      ajaxStateProperty: "userInformationResults",
      freezer: this.freezer,
      onExecute: (apiOptions, params, options) => {
        const userApi = UserApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
        return userApi.apiV1UserGet();
      },
      onOk: (data: UserInfoViewModel) => {
        this.freezer.get().set({ isLoggedIn: true });
        AuthenticationSettingsInstance.setUserSecurityContext(data);
        return data;
      },
      onError: () => {
        this.freezer.get().set({ isLoggedIn: false });
      }
    });
  }

  public async attemptLogin(): Promise<void> {
    const userFields = this.freezer.get().formFields.toJS();

    await managedAjaxUtil.fetchResults({
      ajaxStateProperty: "attemptLoginResults",
      freezer: this.freezer,
      onExecute: (apiOptions, params, options) => {
        if (params === undefined) {
          throw new Error("Parameters is undefined");
        }

        const loginApi = LoginApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
        return loginApi.apiV1LoginPost(params);
      },
      params: {
        body: userFields,
      },
      onOk: (data: ResponseBase) => {
        if (data.success) {
          this.freezer.get().set({ hasError: false, formFields: { password: "", username: "" } });
          SitePubSubManager.publish("application:login", AuthenticationSettingsInstance);
        } else {
          this.freezer.get().set({ hasError: true });
        }

        return data;
      },
      onError: () => {
        this.freezer.get().set({ hasError: true });
        AuthenticationSettingsInstance.setUserSecurityContext(undefined);
      }
    });

    await this.getUserInformation();
  }

  public setFormValue(formValue: Partial<LoginViewModel>): void {
    this.freezer.get().formFields.set(formValue);
  }

  public async Logout(): Promise<void> {  

    if(!this.freezer.get().isLoggedIn) {
      return;
    }

    await managedAjaxUtil.fetchResults({
      ajaxStateProperty: "logoutResults",
      freezer: this.freezer,
      onExecute: (apiOptions, params, options) => {
        const loginApi = LoginApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
        return loginApi.apiV1LoginLogoutPost();
      },
      onOk: (data: ResponseBase) => {
        AuthenticationSettingsInstance.setUserSecurityContext(undefined);
        this.freezer.get().set({ 
          userInformationResults: {...managedAjaxUtil.createInitialState<UserInfoViewModel>(), state: "error" },
          isLoggedIn: false 
        });
    
        SitePubSubManager.publish("application:logout", null);

        return data;
      },
    });  
  }

  public resetForm() {
    this.freezer.get().set({
      formFields: {
        password: "",
        username: "",
      }
    });
  }
}

export const LoginService = new LoginFreezerService();
export type ILoginServiceInjectedProps = ReturnType<LoginFreezerService["getPropsForInjection"]>;
