import {
  React
} from "$Imports/Imports";

import {
  ITenantViewItem
} from "./tenant-views/index";

import {
  ITenantViewProps
} from "./tenant-views/TenantViewProps";

interface IJobDetailViewRenderProps extends ITenantViewProps {
  tenantItem: ITenantViewItem;
}

export class JobDetailViewRender extends React.PureComponent<IJobDetailViewRenderProps> {

  render() {
    const {
      childJobs,
      job,
      tenantItem
    } = this.props;

    return React.createElement(tenantItem.component, {
      childJobs,
      job
    } as ITenantViewProps);
  }
}