import {
  React,
} from "$Imports/Imports";
import { ThemeConsumer } from "$Providers/YaharaThemeProvider";

const styles: {
  mainContainer: string;
  mainApplicationBar: string;
  mainSideNav: string;
  mainContentPanel: string;
  mainInnerContentPanel: string;
  applicationTitle: string;
} = require("./MainLayout.scss");

interface IMainLayoutProps {
  applicationBar: JSX.Element;
  sideNavigation: JSX.Element;
}

export class MainLayout extends React.PureComponent<IMainLayoutProps> {
  render() {
    const { applicationBar, sideNavigation } = this.props;

    return (
      <div
        className={styles.mainContainer}
      >
        <div
          className={styles.mainApplicationBar}
        >
          {applicationBar}
        </div>
        <div
          className={styles.mainContentPanel}
        >
          <ThemeConsumer>
            {(context) =>
            {
            return (<div id='mainSideNav'
                className={styles.mainSideNav}
                style={{
                  backgroundColor: context.themeConfig.sideNavigationColor,
                }}
              >
              {sideNavigation}
              <div
                className={styles.applicationTitle}
              >
                TTIP
              </div>
              </div>);
            }
          }
          </ThemeConsumer>
          <div
            className={styles.mainInnerContentPanel}
          >
            {this.props.children}
          </div>
        </div>
      </div>
    )
  }
}
