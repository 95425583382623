import {
  React,
  _,
  bind
} from "$Imports/Imports";
import { Button } from "$Imports/MaterialUIComponents";
import { AdvanceTextField } from "$Components/Common";
import { ICredentialProps } from "$State/CredentialTypes";
import { ShawCredentials } from "$Generated/api";

interface IShawBaseProps {
}

type IShawProps = IShawBaseProps & ICredentialProps<ShawCredentials>;
export class Shaw extends React.PureComponent<IShawProps>{


  @bind
  onChange(e: any) {
    if (e.target.name === "username") {
      this.props.onChange(this.props.providerKey, { username: e.target.value });
    }
    else if (e.target.name === "password") {
      this.props.onChange(this.props.providerKey, { password: e.target.value });
    }
    else if (e.target.name === "companyId") {
      this.props.onChange(this.props.providerKey, { companyId: e.target.value });
    }
  }

  render(): JSX.Element {
    const shaw: ShawCredentials = this.props.data ? this.props.data : {} as ShawCredentials;
    return (
      <div>
        <AdvanceTextField
          label="Username*"
          name="username"
          autoComplete="off"
          value={shaw.username}
          onChange={this.onChange}
          error={(shaw.username === "")}
        /><br />
        <AdvanceTextField
          name="password"
          autoComplete="new-password"
          type="password"
          label="Password"
          value={shaw.password}
          onChange={this.onChange}
          error={(shaw.password === "")}
        /><br />
        <AdvanceTextField
          label="Company ID*"
          name="companyId"
          value={shaw.companyId}
          onChange={this.onChange}
          error={(shaw.companyId === "")}
        /><br />
        <Button className="float-right" disabled={true} color={"primary"}>VALIDATE CREDENTIALS</Button>
      </div>
    );
  }
}