import {
    React,
    _,
    bind
} from "$Imports/Imports";
import { Button } from "$Imports/MaterialUIComponents";
import { NetradyneCredentials } from "$Generated/api";
import { ICredentialProps } from "$State/CredentialTypes";
import { AdvanceTextField } from "$Components/Common";

interface INetradyneBaseProps {

}
type INetradyneProps = INetradyneBaseProps & ICredentialProps<NetradyneCredentials>;

export class Netradyne extends React.PureComponent<INetradyneProps> {

    @bind
    onChange(e: any): void {
        if(e.target.name === "ftpAddress")
        {
            this.props.onChange(this.props.providerKey, {ftpAddress: e.target.value});
        }
        else if(e.target.name === "basePath")
        {
            this.props.onChange(this.props.providerKey, {basePath: e.target.value});
        }
        else if(e.target.name === "ftpUsername")
        {
            this.props.onChange(this.props.providerKey, {ftpUsername: e.target.value});
        }
        else if(e.target.name === "ftpPassword")
        {
            this.props.onChange(this.props.providerKey, {ftpPassword: e.target.value});
        }
    }

    render(): JSX.Element {
        const netradyne: NetradyneCredentials = this.props.data ? this.props.data : {} as NetradyneCredentials;
        return (
            <div>
                <AdvanceTextField
                    label="FTP Address*"
                    name="ftpAddress"
                    onChange={this.onChange}
                    value={netradyne.ftpAddress ? netradyne.ftpAddress : ""}
                    error = {(netradyne.ftpAddress === "")}
                /><br />
                <AdvanceTextField
                    label="FTP Base Path*"
                    name="basePath"
                    onChange={this.onChange}
                    value={netradyne.basePath ? netradyne.basePath : ""}
                    error = {(netradyne.basePath === "")}
                /><br />
                <AdvanceTextField
                    label="FTP Username*"
                    name="ftpUsername"
                    onChange={this.onChange}
                    value={netradyne.ftpUsername ? netradyne.ftpUsername : ""}
                    error = {(netradyne.ftpUsername === "")}

                />
                <br />
                <AdvanceTextField
                    label="FTP Password*"
                    name="ftpPassword"
                    type="password"
                    autoComplete="new-password"
                    onChange={this.onChange}
                    value={netradyne.ftpPassword ? netradyne.ftpPassword : ""}
                    error = {(netradyne.ftpPassword === "")}
                /><br />
                <Button 
                  className="float-right"
                  disabled={true}
                  color="primary"
                >
                  VALIDATE CREDENTIALS
                </Button>{/* Disabled for as per LDM - 481 */}
            </div>
        );
    }

}