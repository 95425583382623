import { React, _, memoizeOne, bind } from "$Imports/Imports";
import { FormControl, InputLabel, Select, MenuItem, SelectProps, } from "$Imports/MaterialUIComponents";

const styles: {
  selectComponent: string;
} = require("./SimpleStringSelector.scss");

interface ISimpleStringSelectorProps extends SelectProps {
  sourceData: string[];
  onValueChange?: (
    e: React.ChangeEvent<{ name?: string | undefined; value: unknown; }>,
    child: React.ReactNode, group: string | undefined
  ) => void;
  errorState: boolean;
  lowercaseLabel: string;
  uppercaseLabel: string;
  selectClassName?: string;
}

export class SimpleStringSelector extends React.PureComponent<ISimpleStringSelectorProps> {
  private _findSelected(values: string[], searchValue: unknown): string | undefined {
    return _.find(values, (v) => {
      if (searchValue === undefined || searchValue === null) {
        return false;
      }
      return v === searchValue;
    });
  }

  private readonly _findSelected_memoize = memoizeOne(this._findSelected);

  @bind
  private _onChange(e: React.ChangeEvent<{ name?: string | undefined; value: unknown; }>, child: React.ReactNode): void {
    if (this.props.onChange) {
      this.props.onChange(e, child);
    }

    const { sourceData } = this.props;

    if (this.props.onValueChange) {
      const group = this._findSelected(sourceData, e.target.value);
      this.props.onValueChange(e, child, group);
    }
  }

  render(): React.ReactNode {
    const { sourceData, value, errorState, lowercaseLabel, uppercaseLabel, selectClassName, ...passThroughProps } = this.props;

    const selectedValue = this._findSelected_memoize(sourceData, value);

    return (
      <FormControl>
        <InputLabel
          htmlFor={lowercaseLabel}>
          {uppercaseLabel}
        </InputLabel>
        <Select
          className={styles.selectComponent}
          {...passThroughProps}
          value={selectedValue ? selectedValue : ""}
          onChange={this._onChange}
          error={errorState && !selectedValue}>
          <MenuItem value="">
            <em>No {uppercaseLabel} Selected</em>
          </MenuItem>
          {
            _.map(sourceData, (v, vidx) => (
              <MenuItem key={vidx} value={v}>
                {v}
              </MenuItem>
            ))
          }
        </Select>
      </FormControl>
    );
  }
}