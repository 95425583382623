export enum UserRoles {
	CreateAssocEntity = "create-assoc-entity",
	CreateJobs = "create-jobs",
	CreateOrgUnits = "create-org-units",
	CreateTenant = "create-tenant",
	CreateTenantSourceCreds = "create-tenant-source-creds",
	CreateTenantSubmissionCreds = "create-tenant-submission-creds",
	DeleteAssocEntity = "delete-assoc-entity",
	DeleteOrgUnits = "delete-org-units",
	DeleteTenant = "delete-tenant",
	DeleteTenantSourceCreds = "delete-tenant-source-creds",
	DeleteTenantSubmissionCreds = "delete-tenant-submission-creds",
	OfflineAccess = "offline_access",
	PortalClientUser = "portal-client-user",
	PortalHoS = "portal-hos",
	PortalOrganizationUser = "portal-organization-user",
	PortalSuperuser = "portal-superuser",
	ReadAdvTenantConfig = "read-adv-tenant-config",
	ReadAssocEntity = "read-assoc-entity",
	ReadJobsList = "read-jobs-list",
	ReadJobsOverview = "read-jobs-overview",
	ReadJobsSourceData = "read-jobs-source-data",
	ReadJobsSubmissionData = "read-jobs-submission-data",
	ReadOrgUnits = "read-org-units",
	ReadTenant = "read-tenant",
	ReadTenantManageExecution = "read-tenant-manage-execution",
	ReadTenantSourceCreds = "read-tenant-source-creds",
	ReadTenantSubmissionCreds = "read-tenant-submission-creds",
	ReportView = "report-view",
	UmaAuthorization = "uma_authorization",
	UpdateAdvTenantConfig = "update-adv-tenant-config",
	UpdateAssocEntity = "update-assoc-entity",
	UpdateOrgUnits = "update-org-units",
	UpdateTenant = "update-tenant",
	UpdateTenantManageExecution = "update-tenant-manage-execution",
	UpdateTenantSourceCreds = "update-tenant-source-creds",
	UpdateTenantSubmissionCreds = "update-tenant-submission-creds"
}