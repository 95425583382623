import {
  JobDetail
} from "./JobDetail";

import {
  Container
} from "./Container";

export {
  JobDetail,
  Container
}
