import {
  React,
  bind
} from "$Imports/Imports";

import {
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
} from "$Imports/MaterialUIComponents";

import {
  MoreVert,
  Settings,
  Autorenew,
  Edit,
  Close,
} from "$Imports/MaterialUIIcons";

import {
  TenantSummaryViewModel
} from "$Generated/api";

import {
    UserRoles,
    UserRoleLimitedControl
} from "$Components/Common";

export type menuType = "activate-tenant" | "edit-credentials" | "edit-contacts" | "edit-email-recipients" | "rename-tenant" | "deactivate-tenant" | "advance-settings" | "start-job";

interface ITenantMenuProps {
  data: TenantSummaryViewModel;
  onMenuItemClick?: (event: React.MouseEvent<HTMLLIElement, MouseEvent>, tenantSummaryViewModel: TenantSummaryViewModel, operation: menuType) => void;
}

interface ITenantMenuState {
  anchorEl: Element | null;
  isOpen: boolean;
}

export class TenantMenu extends React.PureComponent<ITenantMenuProps, ITenantMenuState> {

  state: ITenantMenuState = {
    anchorEl: null,
    isOpen: false,
  };

  @bind
  private _onEditCredentialClick(event: React.MouseEvent<HTMLLIElement, MouseEvent>): void {
    if (this.props.onMenuItemClick) {
      this.props.onMenuItemClick(event, this.props.data, "edit-credentials");
      this.setState({
        isOpen: false,
      });
    }
  }

  @bind
  private _onEditContactsClick(event: React.MouseEvent<HTMLLIElement, MouseEvent>): void {
    if (this.props.onMenuItemClick) {
      this.props.onMenuItemClick(event, this.props.data, "edit-contacts");
      this.setState({
        isOpen: false
      });
    }
  }

  @bind
  private _onEditEmailRecipientsClick(event: React.MouseEvent<HTMLLIElement, MouseEvent>): void {
    if (this.props.onMenuItemClick) {
      this.props.onMenuItemClick(event, this.props.data, "edit-email-recipients");
      this.setState({
        isOpen: false,
      });
    }
  }

  @bind
  private _onRenameClick(event: React.MouseEvent<HTMLLIElement, MouseEvent>): void {
    if (this.props.onMenuItemClick) {
      this.props.onMenuItemClick(event, this.props.data, "rename-tenant");
      this.setState({
        isOpen: false
      })
    }
  }

  @bind
  private _onActivateTenantClick(event: React.MouseEvent<HTMLLIElement, MouseEvent>): void {
    if (this.props.onMenuItemClick) {
      this.props.onMenuItemClick(event, this.props.data, "activate-tenant");
      this.setState({
        isOpen: false
      });
    }
  }

  @bind
  private _onDeactivateTenantClick(event: React.MouseEvent<HTMLLIElement, MouseEvent>): void {
    if (this.props.onMenuItemClick) {
      this.props.onMenuItemClick(event, this.props.data, "deactivate-tenant");
      this.setState({
        isOpen: false
      });
    }
  }

  @bind
  private _onAdvanceSettingsClick(event: React.MouseEvent<HTMLLIElement, MouseEvent>): void {
    if (this.props.onMenuItemClick) {
      this.props.onMenuItemClick(event, this.props.data, "advance-settings");
    }
  }

  @bind
  private _onStartJobClick(event: React.MouseEvent<HTMLLIElement, MouseEvent>): void {
    if (this.props.onMenuItemClick) {
      this.props.onMenuItemClick(event, this.props.data, "start-job");
      this.setState({
        isOpen: false,
      });
    }
  }

  @bind
  private _onClose(event: {}, reason: "backdropClick" | "escapeKeyDown") {
    this.setState({
      isOpen: false,
    });
  }

  @bind
  private _onClick(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    this.setState({
      anchorEl: event.currentTarget,
      isOpen: true
    });
  }

  render() {
    const { anchorEl, isOpen } = this.state;
    const { data } = this.props;

    return (
      <>
        <IconButton
          size="small"
          onClick={this._onClick}
        >
          <MoreVert />
        </IconButton>
        <Menu
          getContentAnchorEl={null}
          anchorEl={anchorEl}
          open={isOpen}
          onClose={this._onClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <UserRoleLimitedControl
            requiredUserRoles={
              [
                UserRoles.ReadTenantSourceCreds,
                UserRoles.ReadTenantSubmissionCreds,
                UserRoles.UpdateTenantSourceCreds,
                UserRoles.UpdateTenantSubmissionCreds
              ]
            }
          >
            <MenuItem
              onClick={this._onEditCredentialClick}
            >
              <ListItemIcon>
                <Edit />
              </ListItemIcon>
              <ListItemText primary="Edit Credentials" />
            </MenuItem>
          </UserRoleLimitedControl>
          {
            data.submissionProviderId === "35" &&
            <MenuItem onClick={this._onEditContactsClick}>
              <ListItemIcon>
                <Edit />
              </ListItemIcon>
              <ListItemText primary="Edit Contacts" />
            </MenuItem>
          }
          {
            data.submissionProviderId === "33" &&
            <MenuItem
              onClick={this._onEditEmailRecipientsClick}
            >
              <ListItemIcon>
                <Edit />
              </ListItemIcon>
              <ListItemText primary="Edit Email Recipients" />
            </MenuItem>
          }
          <UserRoleLimitedControl
            requiredUserRoles={
              [
                UserRoles.CreateTenant,
                UserRoles.UpdateTenant,
              ]
            }
          >
            <MenuItem onClick={this._onRenameClick}>
              <ListItemIcon>
                <Edit/>
              </ListItemIcon>
              <ListItemText primary="Rename Tenant"/>
            </MenuItem>
          </UserRoleLimitedControl>
          <UserRoleLimitedControl
            requiredUserRoles={
              [
                UserRoles.CreateTenant,
                UserRoles.UpdateTenant
              ]
            }
          >
            {data.active ?
              <MenuItem
                onClick={this._onDeactivateTenantClick}
              >
                <ListItemIcon>
                  <Close />
                </ListItemIcon>
                <ListItemText primary="Deactivate Tenant" />
              </MenuItem>
              :
              <MenuItem
              onClick={this._onActivateTenantClick}
              >
                <ListItemIcon>
                  <Close />
                </ListItemIcon>
                <ListItemText primary="Activate Tenant" />
              </MenuItem>
            }
          </UserRoleLimitedControl>
          <MenuItem
            onClick={this._onAdvanceSettingsClick}
            disabled={true}
          >
            <ListItemIcon>
              <Settings />
            </ListItemIcon>
            <ListItemText primary="Advance Settings" />
          </MenuItem>
          <UserRoleLimitedControl 
            requiredUserRoles={[
                UserRoles.CreateJobs,
            ]}
          >
            <MenuItem
              onClick={this._onStartJobClick}
              disabled={!(data.active === true && data.status === "Enabled")}
            >
              <ListItemIcon>
                <Autorenew />
              </ListItemIcon>
              <ListItemText primary="Start Job" />
            </MenuItem>
          </UserRoleLimitedControl>
        </Menu>
      </>
    );
  }
}
