import {
  FreezerService,
  IAjaxState,
  managedAjaxUtil,
  _
} from "$Imports/Imports";

import {
  DashboardApiFactory,
  AdapterSummaryModelIEnumerableResponseBase,
  DelinquentTenantViewModelIEnumerableResponseBase
} from "$Generated/api";

const InjectedPropName = "dashboardService";

interface IDashboardState {
  adapterSummaryResults: IAjaxState<AdapterSummaryModelIEnumerableResponseBase>,
  adapterFilter: string,
  delinquentTenantResults: IAjaxState<DelinquentTenantViewModelIEnumerableResponseBase>
}

class DashboardFreezerService extends FreezerService<IDashboardState, typeof InjectedPropName> {
  constructor() {
    super({
      adapterSummaryResults: managedAjaxUtil.createInitialState(),
      adapterFilter: "",
      delinquentTenantResults: managedAjaxUtil.createInitialState()
    }, InjectedPropName);
  }

  public async fetchAdapterSummary(forceUpdate: boolean = false) {
    const adapterSummaryState = this.freezer.get().adapterSummaryResults;

    if (adapterSummaryState.hasFetched && !forceUpdate) {
      return;
    }

    return managedAjaxUtil.fetchResults({
      ajaxStateProperty: "adapterSummaryResults",
      freezer: this.freezer,
      onExecute: (apiOption, parms, options) => {
        const dashboardApi = DashboardApiFactory(apiOption.wrappedFetch, apiOption.baseUrl);
        return dashboardApi.apiV1DashboardAdapterSummaryGet();
      }
    });
  }

  public async fetchDelinquentTenants(forceUpdate: boolean = false) {
    const delinquentTenantState = this.freezer.get().delinquentTenantResults;

    if (delinquentTenantState.hasFetched && !forceUpdate) {
      return;
    }

    return managedAjaxUtil.fetchResults({
      ajaxStateProperty: "delinquentTenantResults",
      freezer: this.freezer,
      onExecute: (apiOption, params, options) => {
        const dashboardApi = DashboardApiFactory(apiOption.wrappedFetch, apiOption.baseUrl);
        return dashboardApi.apiV1DashboardDelinquentTenantsGet();
      }
    })
  }

  public setAdapterSearch(search: string) {
    this.freezer.get().set({
      adapterFilter: search
    });
  }
}

export const DashboardService = new DashboardFreezerService();
export type IDashboardServiceInjectedProps = ReturnType<DashboardFreezerService["getPropsForInjection"]>;
