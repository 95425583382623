import { IThemeConfiguration } from "./ThemeConfiguration";

const defaultSideNavigationSelectedColor = "#89c402"; //Green selected color
const netradyneSideNavigationSelectedColor = "#FFFFFF"; 
const lytxSideNavigationSelectedColor = "#009CDE"; 
const smartdriveSideNavigationSelectedColor = "#3CB54A"; 

export const defaultTheme: IThemeConfiguration = {
  themeOptions: {
    palette: {
      primary: {
        main: "#0d417d",
      },
      secondary: {
        main: "#89c402"
      }
    }
  },
  sideNavigationColor: "#28343c",
  sideNavigationSelectedColor: defaultSideNavigationSelectedColor, //Access the color if the component does not have a selected property
  sideNavigationMenu: {
    root: {
      "color": "#ffffff",
      "&.Mui-selected": {
        color: defaultSideNavigationSelectedColor,
        backgroundColor: "#28343c"
      }
    },
  },
  userInformationPanel: {
    root: {
      color: "#FFFFFF",
    }
  },
  avatarColor: "#BDBDBD"
};

export const netradyneTheme: IThemeConfiguration = {
  themeOptions: {
    palette: {
      primary: {
        main: "#6F408A",
      }
    }
  },
  sideNavigationColor: "#4A5B66",
  sideNavigationSelectedColor: netradyneSideNavigationSelectedColor, //Access the color if the component does not have a selected property
  sideNavigationMenu: {
    root: {
      "color": "#ffffff",
      "&.Mui-selected": {
        color: netradyneSideNavigationSelectedColor,
        backgroundColor: "#4A5B66"
      }
    },
  },
  userInformationPanel: {
    root: {
      color: "#FFFFFF",
    }
  },
  avatarColor: "#8b979e"
};

export const lytxTheme: IThemeConfiguration = {
  themeOptions: {
    palette: {
      primary: {
        main: "#16135F",
      }
    }
  },
  sideNavigationColor: "#27343C",
  sideNavigationSelectedColor: lytxSideNavigationSelectedColor, //Access the color if the component does not have a selected property
  sideNavigationMenu: {
    root: {
      "color": "#ffffff",
      "&.Mui-selected": {
        color: lytxSideNavigationSelectedColor,
        backgroundColor: "#27343C"
      }
    },
  },
  userInformationPanel: {
    root: {
      color: "#FFFFFF",
    }
  },
  avatarColor: "#009CDE"
};

export const smartdriveTheme: IThemeConfiguration = {
  themeOptions: {
    palette: {
      primary: {
        main: "#0E9A47",
      }
    }
  },
  sideNavigationColor: "#221F1F",
  sideNavigationSelectedColor: smartdriveSideNavigationSelectedColor, //Access the color if the component does not have a selected property
  sideNavigationMenu: {
    root: {
      "color": "#ffffff",
      "&.Mui-selected": {
        color: smartdriveSideNavigationSelectedColor,
        backgroundColor: "#221F1F"
      }
    },
  },
  userInformationPanel: {
    root: {
      color: "#FFFFFF",
    }
  },
  avatarColor: "#3CB54A"
};