import { React } from "$Imports/Imports";
import { withTheme } from '@material-ui/core/styles';
import {
  Paper
} from "$Imports/MaterialUIComponents";

import { ThemedComponentProps } from "@material-ui/core/styles/withTheme";

const styles: {
  paper: string;
} = require("./PageHeader.scss");

interface IPageHeaderBaseProps {
  title: string
}

type IPageHeaderProps = IPageHeaderBaseProps & ThemedComponentProps;

class _PageHeader extends React.PureComponent<IPageHeaderProps> {
  render() {
    const { title } = this.props;
    
    return (
      <Paper className={styles.paper} style={{backgroundColor: this.props.theme?.palette.primary.main || '#0d417d', color: '#fff'}}>
        {title}
      </Paper>
    );
  }
}

export const PageHeader = withTheme(_PageHeader);