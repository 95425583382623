import {
  React,
  bind,
  _
} from "$Imports/Imports";

import {
  Dialog,
  Button,
  DialogContent,
  DialogContentText,
  MenuItem,
  Select,
  DialogActions,
  Checkbox,
  FormControlLabel
} from "$Imports/MaterialUIComponents";

import {
  MoreHoriz
} from "$Imports/MaterialUIIcons";

import {
  AdvanceTextField
} from "$Components/Common";

const styles: {
  wrapper: string;
  text: string;
  truncated: string;
} = require("./TruncatedTextView.scss");

interface ITruncatedTextViewProps {
  text: string;
  maxDisplayLength: number;
}

interface ITruncatedTextViewState {
  open: boolean;
}

export class TruncatedTextView extends React.Component<ITruncatedTextViewProps, ITruncatedTextViewState> {
  state = {
    open: false
  }

  @bind
  private _onOpen() {
    this.setState({ open: true });
  }

  @bind
  private _onClose() {
    this.setState({ open: false });
  }

  render() {
    const { text, maxDisplayLength } = this.props;

    const truncate = text.length > maxDisplayLength;
    const displayValue = truncate ? text.substring(0, maxDisplayLength) : text;

    return (
      <>
        <div
          onClick={this._onOpen}
          className={styles.wrapper}
        >
          <div
            className={truncate ? styles.truncated : styles.text}
          >
            {displayValue}
          </div>
          {truncate && <MoreHoriz />}
        </div>
      
        {truncate && <Dialog
          open={this.state.open}
          key={"dialog"}
        >
          <DialogContent>
            <div style={{maxWidth: '450px'}}>{text}</div>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={this._onClose}
              color="primary"
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>}
      </>
    );
  }
}