import {
  React,
  _,
  bind
} from "$Imports/Imports";
import { Button } from "$Imports/MaterialUIComponents";
import { AdvanceTextField } from "$Components/Common";
import { SamsaraCredentials } from "$Generated/api";
import { ICredentialProps } from "$State/CredentialTypes";
import { ValidateCredentialsResults, validateCredentials } from "$State/ValidateCredentials";

interface ISamsaraBaseProps {
}

type SamsaraValidateCredentials = SamsaraCredentials & ValidateCredentialsResults;

type ISamsaraProps = ISamsaraBaseProps & ICredentialProps<SamsaraValidateCredentials>;
export class Samsara extends React.PureComponent<ISamsaraProps>{


  @bind
  onChange(e: any): void {
    if (e.target.name === "url") {
      this.props.onChange(this.props.providerKey, { url: e.target.value });
    }
    else if (e.target.name === "accessToken") {
      this.props.onChange(this.props.providerKey, { accessToken: e.target.value });
    }
  }

  @bind
  async validate (e:any) {
    this.props.onChange(this.props.providerKey, { running: true });
    var promiseResults = validateCredentials(this.props.data, this.props.providerKey);
    var results = await promiseResults;
    this.props.onChange(this.props.providerKey, { success: results.success ? 'true' : 'false' });
    this.props.onChange(this.props.providerKey, { errorResult: results.errorMessage });
    this.props.onChange(this.props.providerKey, { running: false });
  }

  render(): JSX.Element {
    const samsara: SamsaraCredentials = this.props.data ? this.props.data : {} as SamsaraCredentials;
    return (
      <div>
        <AdvanceTextField
          label="URL*"
          name="url"
          onChange={this.onChange}
          value={samsara.url ? samsara.url : ''}
          error={(samsara.url === "")}

        /><br />
        <AdvanceTextField
          label="Access Token*"
          name="accessToken"
          onChange={this.onChange}
          value={samsara.accessToken ? samsara.accessToken : ''}
          error={(samsara.accessToken === "")}
        /><br />
        <Button disabled={this.props?.data?.running} onClick={this.validate} color={"primary"}>VALIDATE CREDENTIALS</Button>
        {this.props?.data?.success == 'none' || this.props?.data?.success === undefined ?
            <div />
            :
            this.props?.data?.success == 'false' ?
              <div style={{ color: "red", display: "block", marginLeft: "auto", marginRight: "auto" }}>{this.props?.data?.errorResult}</div>
              :
              <div style={{ color: "green", display: "block", marginLeft: "auto", marginRight: "auto", textAlign: "center" }}>Passed</div>}
      </div>
    );
  }
}
