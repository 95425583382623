import {
  FreezerService,
  IAjaxState,
  managedAjaxUtil,
  _,
  bind
} from "$Imports/Imports";

import {
  IPagerState
} from "./../PagerPagingState";

import {
  JobDetailApiFactory,
  DriveCamSubmissionResultViewModelResponseBase,
  ChildJobViewModel,
  StringResponseBase,
  StringStringDictionaryResponseBase
} from "$Generated/api";

import {
  SitePubSubManager
} from "$Utilities/PubSubUtil";

import {
  ErrorService
} from "$State/ErrorFreezerService";


const InjectedPropName = "samsaraRairErrorReport";

interface ISamsaraRairErrorReportFreezerState {
  jobId: string | null;
  rairErrorResults: IAjaxState<StringStringDictionaryResponseBase>
}

class SamsaraRairErrorReportFreezerService extends FreezerService<ISamsaraRairErrorReportFreezerState, typeof InjectedPropName> {
  constructor() {
    super({
      jobId: null,
      rairErrorResults: managedAjaxUtil.createInitialState()
    }, InjectedPropName);

    SitePubSubManager.subscribe("application:login:before", this.clearResults);
    SitePubSubManager.subscribe("job-detail:job-id-change", this.clearResults);
  }

  @bind
  private clearResults() {
    this.freezer.get().set({
      jobId: null,
      rairErrorResults: managedAjaxUtil.createInitialState()
     });
  }

  public setJobId(jobId: string): void {
    this.freezer.get().set({
      jobId
    });
  }

  public async fetchRairErrors(forceUpdate: boolean = false) {
    if (this.freezer.get().rairErrorResults.hasFetched
      && !forceUpdate) {
      return;
    }

    const jobId = this.freezer.get().jobId;

    return managedAjaxUtil.fetchResults({
      ajaxStateProperty: "rairErrorResults",
      freezer: this.freezer,
      onExecute: (apiOptions, params, options) => {
        const submissionFactory = JobDetailApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
        return submissionFactory.apiV1JobDetailSamsaraRairExportErrorsGet(params);
      },
      params: {
        jobId: jobId ? jobId : undefined
      },
      onError: () => {
        ErrorService.pushErrorMessage("Failed to retrieve rair error data from server");
      }
    })
  }

}

export const SamsaraRairErrorReportService = new SamsaraRairErrorReportFreezerService();
export type ISamsaraRairErrorReportFreezerServiceInjectedProps = ReturnType<SamsaraRairErrorReportFreezerService["getPropsForInjection"]>;