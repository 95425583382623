import Home from "@material-ui/icons/Home";
import AddCircleOutline from "@material-ui/icons/AddCircleOutline"
import DataUsage from "@material-ui/icons/DataUsage";
import Dashboard from "@material-ui/icons/Dashboard";
import People from "@material-ui/icons/People";
import Settings from "@material-ui/icons/Settings";
import Help from "@material-ui/icons/Help";
import CloudQueueOutlined from "@material-ui/icons/CloudQueueOutlined";
import Cloud from "@material-ui/icons/Cloud";
import Check from "@material-ui/icons/Check";
import Error from "@material-ui/icons/Error";
import Search from "@material-ui/icons/Search";
import Menu from "@material-ui/icons/Menu";
import MoreHoriz from "@material-ui/icons/MoreHoriz";
import MoreVert from "@material-ui/icons/MoreVert";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import Autorenew from "@material-ui/icons/Autorenew";
import Edit from "@material-ui/icons/Edit";
import Person from "@material-ui/icons/Person";
import ExitToApp from "@material-ui/icons/ExitToApp";
import Close from "@material-ui/icons/Close";
import Loop from "@material-ui/icons/Loop";
import HelpOutline from "@material-ui/icons/HelpOutline";
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import DateRangeIcon from '@material-ui/icons/DateRange';
import DirectionsCar from '@material-ui/icons/DirectionsCar';
import CloudDownload from '@material-ui/icons/CloudDownload';

export {
  DateRangeIcon,
  ArrowDownward,
  ArrowUpward,
  Home,
  AddCircleOutline,
  DataUsage,
  Dashboard,
  People,
  Settings,
  Help,
  HelpOutline,
  CloudQueueOutlined,
  Cloud,
  Check,
  Error,
  Search,
  Menu,
  MoreHoriz,
  MoreVert,
  Autorenew,
  Edit,
  Person,
  ExitToApp,
  Close,
  Loop,
  AccessTimeIcon,
  DirectionsCar,
  CloudDownload
};
