import {
  React,
  bind,
  _
} from "$Imports/Imports";

import {
  TablePagination,
  TablePaginationProps
} from "$Imports/MaterialUIComponents";

interface IDataTablePagerBaseProps {
  onPagerStateChange?: (page: number, rowsPerPage: number) => void;
}

type IDataTablePagerProps = TablePaginationProps & IDataTablePagerBaseProps;

export class DataTablePager extends React.PureComponent<IDataTablePagerProps> {
  static defaultProps = {
    rowsPerPageOptions: [25, 50, 100, 500, 1000],
  };

  @bind
  private _onChangePage(event: React.MouseEvent<HTMLButtonElement> | null, page: number) {
    const { rowsPerPage, onChangePage } = this.props;

    if (onChangePage) {
      onChangePage(event, page);
    }

    this._raisePagerStateChange(page, rowsPerPage);
  }

  @bind
  private _onChangeRowsPerPage(event: React.ChangeEvent<HTMLInputElement>) {
    const { page, onChangeRowsPerPage } = this.props;

    if (onChangeRowsPerPage) {
      onChangeRowsPerPage(event);
    }

    const numberPerPage = _.toInteger(event.target.value);

    this._raisePagerStateChange(page, numberPerPage);
  }

  private _raisePagerStateChange(page: number, rowsPerPage: number) {
    const { count } = this.props;

    if (this.props.onPagerStateChange) {
      // Adjust the page number if the number of items per page changes.
      const possiblePaged = Math.ceil(count / rowsPerPage) - 1;
      let raisedPageNumber = 0;
      if (possiblePaged >= 0) {
        raisedPageNumber = page > possiblePaged ? possiblePaged : page;
      }

      // Raise the values.
      this.props.onPagerStateChange(raisedPageNumber, rowsPerPage);
    }
  }

  render() {
    const { onPagerStateChange, onChangePage, onChangeRowsPerPage, ref, ...passThroughProps } = this.props;

    return (
      <TablePagination
        {...passThroughProps}
        onChangePage={this._onChangePage}
        onChangeRowsPerPage={this._onChangeRowsPerPage}
      />
    );
  }
}
