import { FreezerService, IAjaxState, managedAjaxUtil, _, } from "$Imports/Imports";
import { IDateRange, DateRangeCalculator, } from "$Components/Common";
import { ReportApiFactory, StringIEnumerableResponseBase, StringDaySummaryListDictionaryResponseBase, DateTimeDateTimeTupleResponseBase } from "$Generated/api";
import { SitePubSubManager } from "$Utilities/PubSubUtil";
import { ErrorService } from "./ErrorFreezerService";

const InjectPropName = "reportService";

export interface IReportSearchFilter {
  dateRange: IDateRange;
  group: string | undefined;
  vehicle: string | undefined;
}

interface ILytxGenericReportState {
  errorState: boolean;
  hasFetched: boolean;
  reportSearchFilter: IReportSearchFilter;
  groupsResult: IAjaxState<StringIEnumerableResponseBase>;
  vehiclesResult: IAjaxState<StringIEnumerableResponseBase>;
  dataResult: IAjaxState<StringDaySummaryListDictionaryResponseBase>;
  dataDateRangeResult: IAjaxState<DateTimeDateTimeTupleResponseBase>;
  tenantId: string;
}

class LytxGenericReportFreezerService extends FreezerService<ILytxGenericReportState, typeof InjectPropName> {
  constructor() {
    super({
      errorState: false,
      hasFetched: false,
      reportSearchFilter: {
        dateRange: DateRangeCalculator.calcDateRange("last-7-days"),
        group: undefined,
        vehicle: undefined
      },
      groupsResult: managedAjaxUtil.createInitialState(),
      vehiclesResult: managedAjaxUtil.createInitialState(),
      dataResult: managedAjaxUtil.createInitialState(),
      dataDateRangeResult: managedAjaxUtil.createInitialState(),
      tenantId: "LytxDataLakeExportTestDirectory"
    }, InjectPropName);

    SitePubSubManager.subscribe("application:login:before", this.clearResults);
  }

  private clearResults() {
    this.freezer.get().set({
      errorState: false,
      hasFetched: false,
      dataResult: managedAjaxUtil.createInitialState()
    });
  }

  public async fetchDataResult(forceUpdate: boolean = false) {
    const currentState = this.freezer.get();
    const currentFilter = currentState.reportSearchFilter.toJS();

    if (currentState.hasFetched && !forceUpdate) {
      return;
    }
    const dateRange = DateRangeCalculator.convertToISOString(currentFilter.dateRange);

    this.freezer.get().set({ errorState: false });

    return managedAjaxUtil.fetchResults({
      ajaxStateProperty: "dataResult",
      freezer: this.freezer,
      onExecute: (apiOptions, params, options) => {
        const factory = ReportApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
        return factory.apiV1GenericReportGetDaysInRangeGet(params);
      },
      params: {
        tenantId: currentState.tenantId,
        startDate: dateRange.startDate,
        endDate: dateRange.endDate,
        groupFullName: currentFilter.group,
        vehicleName: currentFilter.vehicle
      },
      onError: () => {
        ErrorService.pushErrorMessage("Failed to load test data from the server.");
      },
      onOk: () => {
        this.freezer.get().set({ hasFetched: true });
      }
    })
  }

  public setReportFilter(filter: Partial<IReportSearchFilter>, reload: boolean) {
    const currentFilter = this.freezer.get().reportSearchFilter.toJS();

    const assignedFilterValues = _.assign({}, currentFilter, filter);
    const assignedDates = DateRangeCalculator.convertToISOString(assignedFilterValues.dateRange);
    const currentDates = DateRangeCalculator.convertToISOString(currentFilter.dateRange);
    const assignedGroup = assignedFilterValues.group;
    const currentGroup = currentFilter.group;
    const assignedVehicle = assignedFilterValues.vehicle;
    const currentVehicle = currentFilter.vehicle;

    this.freezer.get().set({
      reportSearchFilter: assignedFilterValues,
    });

    if (assignedGroup !== currentGroup ||
      assignedDates.endDate !== currentDates.endDate ||
      assignedDates.startDate !== currentDates.startDate) {
      this.fetchVehicles(true);
    }

    this.clearResults();

    if (reload &&
      (assignedDates.endDate !== currentDates.endDate ||
        assignedDates.startDate !== currentDates.startDate ||
        assignedGroup !== currentGroup ||
        assignedVehicle !== currentVehicle)) {
      this.fetchDataResult(true);
    }
  }

  public async fetchGroups(forceUpdate: boolean = false) {
    const currentState = this.freezer.get();
    const currentGroupsState = currentState.groupsResult;

    if (currentGroupsState.hasFetched && !forceUpdate) {
      return;
    }

    return managedAjaxUtil.fetchResults({
      ajaxStateProperty: "groupsResult",
      freezer: this.freezer,
      onExecute: (apiOptions, params, options) => {
        const factory = ReportApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
        return factory.apiV1GenericReportGetGroupsGet(params);
      },
      params: {
        tenantId: currentState.tenantId
      },
      onError: () => {
        ErrorService.pushErrorMessage("Failed to load group data from the server.");
      },
    });
  }

  public async fetchVehicles(forceUpdate: boolean = false) {
    const currentState = this.freezer.get();

    if (currentState.vehiclesResult.hasFetched && !forceUpdate) {
      return;
    }
    const currentFilter = currentState.reportSearchFilter.toJS();
    const dateRange = DateRangeCalculator.convertToISOString(currentFilter.dateRange);

    return managedAjaxUtil.fetchResults({
      ajaxStateProperty: "vehiclesResult",
      freezer: this.freezer,
      onExecute: (apiOptions, params, options) => {
        const factory = ReportApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
        return factory.apiV1GenericReportGetVehiclesGet(params)
      },
      params: {
        tenantId: currentState.tenantId,
        groupFullName: currentState.reportSearchFilter.group,
        startDate: dateRange.startDate,
        endDate: dateRange.endDate
      },
      onError: () => {
        ErrorService.pushErrorMessage("Failed to load vehicle data from the server.");
      },
      onOk: (vehiclesResult : StringIEnumerableResponseBase) => {
        const state = this.freezer.get();
        const currentFilter = state.reportSearchFilter.toJS();
        const defaultArray : string[] = [];
        const vehiclesData = vehiclesResult.data ? vehiclesResult.data : defaultArray;
        if (currentFilter.vehicle && vehiclesData.indexOf(currentFilter.vehicle) < 0) {
          this.freezer.get().set({ reportSearchFilter: { 
            dateRange: currentFilter.dateRange, 
            group: currentFilter.group, 
            vehicle : undefined }});
        }
      }
    });
  }

  public async fetchDataDateRange(forceUpdate: boolean = false) {
    const currentState = this.freezer.get();

    if (currentState.dataDateRangeResult.hasFetched && !forceUpdate) {
      return;
    }

    return managedAjaxUtil.fetchResults({
      ajaxStateProperty: "dataDateRangeResult",
      freezer: this.freezer,
      onExecute: (apiOptions, params, options) => {
        const factory = ReportApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
        return factory.apiV1GenericReportGetDataDateRangeGet(params);
      },
      params: {
        tenantId: currentState.tenantId
      },
      onError: () => {
        ErrorService.pushErrorMessage("Failed to load data date range from the server.");
      }
    });
  }
}

export const LytxGenericReportService = new LytxGenericReportFreezerService();
export type ILytxGenericReportServiceInjectedProps = ReturnType<LytxGenericReportFreezerService["getPropsForInjection"]>;
