import {
  React,
  _,
  bind
} from "$Imports/Imports";
import { Button } from "$Imports/MaterialUIComponents";

import { AdvanceTextField } from "$Components/Common";
import { ICredentialProps } from "$State/CredentialTypes";
import { PedigreeCredentials } from "$Generated/api";

interface IPedigreeBaseProps { }

type IPedigreeProps = IPedigreeBaseProps & ICredentialProps<PedigreeCredentials>;

export class Pedigree extends React.PureComponent<IPedigreeProps> {
  @bind
  onChange(e: any) {
    if (e.target.name === "username") {
      this.props.onChange(this.props.providerKey, { username: e.target.value });
    }
    else if (e.target.name === "password") {
      this.props.onChange(this.props.providerKey, { password: e.target.value });
    }
  }

  render(): JSX.Element {
    const pedigree: PedigreeCredentials = this.props.data ? this.props.data : {} as PedigreeCredentials;
    return (
      <div>
        <AdvanceTextField
          label="User Name*"
          name="username"
          onChange={this.onChange}
          value={pedigree.username ? pedigree.username : ""}
          error={(pedigree.username === "")}
        />
        <br />
        <AdvanceTextField
          label="Password*"
          name="password"
          type="password"
          onChange={this.onChange}
          value={pedigree.password ? pedigree.password : ""}
          error={(pedigree.password === "")}
        />
        <br />
        <br />
        <Button className="float-right" disabled={true} color={"primary"}>VALIDATE CREDENTIALS</Button>{/* Disabled for as per LDM - 483 */}
      </div>
    );
  }
}