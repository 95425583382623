import { FetchAPI } from "../generated/api";
import { IThemeConfiguration } from "../themes/ThemeConfiguration";
import { defaultTheme } from "../themes";




const date: Date = new Date();
const year: number = date.getFullYear();

export const APPCONFIG: {
  brand: string;
  appName: string;
  year: number;
  productLink: string;
  AutoCloseMobileNav: boolean;
  apiURL: string;
  baseUrl: string;
  fetch: (input: RequestInfo, init?: RequestInit) => Promise<Response>;
  color: {
    primary: string;
    success: string;
    info: string;
    infoAlt: string;
    warning: string;
    danger: string;
    text: string;
    gray: string;
  };
  settings: {
    layoutBoxed: boolean;
    navCollapsed: boolean;
    navBehind: boolean;
    fixedHeader: boolean;
    sidebarWidth: "small" | "middle" | "large";
    colorOption: string;
    theme: string;
    advancedActionsModalOpen: boolean;
    themeConfig: IThemeConfiguration;
  };
  dataFormats: {
    datetime: string;
    date: string;
    time: string;
  }
} = {
  brand: "Yahara Software",
  appName: "Integration Portal",
  year,
  productLink: "https://yaharasoftware.com/",
  AutoCloseMobileNav: true,                         // true, false. Automatically close sidenav on route change (Mobile only)
  apiURL: "http://localhost:49647/api/v1/",
  baseUrl: "http://localhost:49647",
  fetch,
  color: {
    primary: "#00BCD4",
    success: "#8BC34A",
    info: "#66BB6A",
    infoAlt: "#7E57C2",
    warning: "#FFCA28",
    danger: "#F44336",
    text: "#3D4051",
    gray: "#EDF0F1"
  },
  settings: {
    layoutBoxed: false,                             // true, false
    navCollapsed: false,                            // true, false
    navBehind: false,                               // true, false
    fixedHeader: true,                              // true, false
    sidebarWidth: "middle",                         // small, middle, large
    colorOption: "12",                              // string: 11,12,13,14,15,16; 21,22,23,24,25,26; 31,32,33,34,35,36
    theme: "light",
    advancedActionsModalOpen: false,                                // light, gray, dark
    themeConfig: defaultTheme,
  },
  dataFormats: {
    datetime: "YY-MM-DD HH:mm:ss",
    date: "YY-MM-DD",
    time: "HH:mm:ss"
  }
};
