import {
  React,
  _,
  bind
} from "$Imports/Imports";

import {
  ITenantViewProps
} from '../TenantViewProps';

import {
  ITenantViewVehicleAssignmentServiceInjectedProps,
  TenantViewVehicleAssignmentService
} from "$State/job-views/VehicleAssignmentFreezerService";

import {
  VehicleAssignment
} from "./VehicleAssignment";

import {
  AjaxActionIndicator
} from "$Components/Common"

interface IVehicleAssignmentViewBaseProp {

}

type IVehicleAssignmentViewProp = IVehicleAssignmentViewBaseProp
& ITenantViewVehicleAssignmentServiceInjectedProps
& ITenantViewProps;

class _VehicleAssignmentView extends React.Component<IVehicleAssignmentViewProp> {
  componentDidMount() {
    if (this.props.job !== null && this.props.job.id !== undefined) {
      this.props.tenantViewVehicleAssignment.setVehicleAssignmentJobId(this.props.job.id);
      this.props.tenantViewVehicleAssignment.fetchVehicleAssignment();
    }
  }

  render() {
    const state = this.props.tenantViewVehicleAssignment.getState();
    const {
      vehicleAssignmentResults,
      vehicleAssignmentPager,
      vehicleAssignmentSearch
    } = state;

    return (
      <>
        <AjaxActionIndicator
          state={[vehicleAssignmentResults]}
        />
        <VehicleAssignment
          data={vehicleAssignmentResults.data?.data || []}
          pagerState={vehicleAssignmentPager}
          search={vehicleAssignmentSearch}
        />
      </>
    );
  }
}

export const VehicleAssignmentView = TenantViewVehicleAssignmentService.inject(
  _VehicleAssignmentView
);