import {
  React,
  bind
} from "$Imports/Imports";

import {
  TableCell,
  Tooltip,
  TableSortLabel,
  TableCellProps
} from "$Imports/MaterialUIComponents";

import { directionType } from "./DirectionType";

interface IDataGridHeaderSortColumnProps extends TableCellProps {
  direction?: directionType;
  propertyName?: string;
  label?: string;
  sortLabelClassName?: string;
  sortTooltipLabel?: string;
  onSortClick?: (event: React.MouseEvent<HTMLElement>, propertyName: string | undefined, direction: directionType) => void;
}

export class DataGridHeaderSortColumn extends React.PureComponent<IDataGridHeaderSortColumnProps> {

  static defaultProps: Partial<IDataGridHeaderSortColumnProps> = {
    sortTooltipLabel: "Sort",
    direction: null,
  };

  @bind
  private _onClick(event: React.MouseEvent<HTMLElement>) {
    if (this.props.onSortClick) {
      this.props.onSortClick(event,
        this.props.propertyName,
        this.props.direction === undefined ? null : this.props.direction
      );
    }
  }

  render() {
    const { direction, label, sortLabelClassName, sortTooltipLabel, propertyName, onSortClick, ...passThroughProps } = this.props;

    return (
      <TableCell
        {...passThroughProps}
      >
        <Tooltip
          title={sortTooltipLabel === undefined ? "" : sortTooltipLabel}
          enterDelay={300}
        >
          <TableSortLabel
            className={sortLabelClassName}
            direction={direction || "desc"}
            active={direction !== null}
            onClick={this._onClick}
          >
            {label}
          </TableSortLabel>
        </Tooltip>
      </TableCell>
    );
  }
}
