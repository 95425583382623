import {
  React,
  bind,
} from "$Imports/Imports";

import {
  AppBar,
  Toolbar
} from "$Imports/MaterialUIComponents";

import {
  UserInformation
} from "./UserInformation";

const styles: {
  toolbar: string;
  applicationTitleContainer: string;
  applicationTitle: string;
} = require("./ApplicationBar.scss");


interface IApplicationBarProps {
}

export class ApplicationBar extends React.PureComponent<IApplicationBarProps> {

  render() {
    return (
      <AppBar
        position="relative"
      >
        <Toolbar
          className={styles.toolbar}
        >
          <div
            className={styles.applicationTitleContainer}
          >
            <div
              className={styles.applicationTitle}
            >
              TTIP
              {/* <LytxLogo
                height={35}
                viewBox="0 0 90 48"
              /> */}
            </div>
          </div>
          <UserInformation />
        </Toolbar>
      </AppBar>
    );
  }
}
