// dependencies
import {
  React,
  bind,
  _,
} from "$Imports/Imports";

import {
  ApplicationSecurityProvider
} from "$Providers/AuthenticationProvider";

import {
  TimeZoneProvider
} from "$Providers/TimeZoneProvider";

import { ISinglePageApplicationProps } from "@yahara/react-spa";
import { getLogger, DefaultLogger } from "@yahara/logging";
import { APPCONFIG } from "../constants/Config";
import { MuiThemeProvider, createMuiTheme, Theme } from "$Imports/MaterialUIStyles";
import { MuiPickersUtilsProvider, MomentUtils } from "$Imports/MaterialUIPickers";
import * as managedAjaxUtil from "../modules/managedAjaxUtil";
import { LoginUserContainer } from "./LoginUserContainer";
import { AuthenticationSettingsInstance } from "$Utilities/Security";

import { Routing } from "./Routing";

import { MainLayout } from "./MainLayout";
import { ApplicationBar } from "./ApplicationBar";
import { LoginSplashPage } from "$Components/Security/LoginSplashPage";
import { SideNavigation } from "./SideNavigation";
import { ErrorStackPanel } from "./ErrorStackPanel";
import { SitePubSubManager } from "$Utilities/PubSubUtil";
import { IThemeConfiguration } from "$Themes/ThemeConfiguration";
import { YaharaThemeProvider } from "$Providers/YaharaThemeProvider";
import { defaultTheme, lytxTheme, smartdriveTheme, netradyneTheme } from "$Themes/defaultTheme";
import { ClientIdsEnum } from "$Utilities/enums";

// stylesheets
require("./../css/global.scss");

// themes
// const lightTheme: {} = require("./themes/lightTheme");
// const darkTheme: {} = require("./themes/darkTheme");
// const grayTheme: {} = require("./themes/grayTheme");

// icons and Images
const locoFavicon: string = require("./../images/logo.ico") as string;

declare var require: {
  <T>(path: string): T;
  (paths: string[], callback: (...modules: any[]) => void): void;
  ensure: (paths: string[], callback: (require: <T>(path: string) => T) => void) => void;
};

const logger: DefaultLogger = getLogger("Application");

// state and Prop
interface IAppSettingsState {
  fixedHeader: boolean;
  navCollapsed: boolean;
  layoutBoxed: boolean;
  navBehind: boolean;
  sidebarWidth: string;
  theme: string;
  colorOption: string;
  advancedActionsModalOpen: boolean;
}

export class Application extends React.Component<ISinglePageApplicationProps, IAppSettingsState> {
  state: IAppSettingsState = APPCONFIG.settings;
  constructor(props: ISinglePageApplicationProps) {
    super(props);

    managedAjaxUtil.setBaseUrl(APPCONFIG.baseUrl);
    APPCONFIG.apiURL = this.props.apiBaseUrl + "/api/v1/";
    managedAjaxUtil.setFetch(APPCONFIG.fetch);

    SitePubSubManager.subscribe("application:logout", this.onLogout);
  }

  componentDidMount(): void {
    document.addEventListener("keydown", this.handleControlShiftY.bind(this), true);
  }

  private renewTokenTimeout: null | any = null;

  @bind
  handleControlShiftY(event: KeyboardEvent): void {
    if (event.ctrlKey && event.shiftKey && event.keyCode === 89) {
      this.showAdvancedActionsModal();
    }
  }

  componentWillUnmount(): void {
    clearTimeout(this.renewTokenTimeout);
    document.removeEventListener("keydown", this.handleControlShiftY.bind(this));
  }

  @bind
  closeAdvancedActionsModal(): void {
    this.setState({
      advancedActionsModalOpen: false,
    });
  }

  @bind
  showAdvancedActionsModal(): void {
    this.setState({
      advancedActionsModalOpen: true,
    }, () => {
      this.forceUpdate();
    });
  }

  @bind
  onLogin(): void {
    this.forceUpdate();
  }

  @bind
  private onLogout(): void {
    this.forceUpdate();
  }

  private getThemeConfig(): IThemeConfiguration {

    // If the user has access to multiple clients, use the default theme.
    if(AuthenticationSettingsInstance.hasMultipleClients()){
      return defaultTheme;
    }

    // Determine the client type in order to apply the theme.
    if(AuthenticationSettingsInstance.isClientType(ClientIdsEnum.Lytx)) {
      return lytxTheme;
    }

    if(AuthenticationSettingsInstance.isClientType(ClientIdsEnum.SmartDrive)) {
      return smartdriveTheme;
    }

    if(AuthenticationSettingsInstance.isClientType(ClientIdsEnum.Netradyne)) {
      return netradyneTheme;
    }

    // return the default theme if no client type is found
    return defaultTheme; 
  }

  render(): JSX.Element {
    this.props.setFaviconUrl(locoFavicon);

    logger.info("Rendering application");

    const themeConfig = this.getThemeConfig();
    const materialUITheme: Theme = createMuiTheme(themeConfig.themeOptions);

    return (
      <MuiThemeProvider
        theme={materialUITheme}
      >
        <MuiPickersUtilsProvider
          utils={MomentUtils}
        >
          <LoginUserContainer
            loginComponent={(<LoginSplashPage onLogin={this.onLogin} />)}
          >
            <ApplicationSecurityProvider>
              <TimeZoneProvider>
                <YaharaThemeProvider
                  themeConfig={themeConfig}>
                  <MainLayout
                    applicationBar={(
                      <ApplicationBar />
                    )}
                    sideNavigation={(
                      <SideNavigation />
                    )}
                  >
                    <Routing />
                  </MainLayout>
                  <ErrorStackPanel />
                </YaharaThemeProvider>
              </TimeZoneProvider>
            </ApplicationSecurityProvider>
          </LoginUserContainer>
        </MuiPickersUtilsProvider>
      </MuiThemeProvider >
    );
  }
}
