import {
  React,
  _,
  bind
} from "$Imports/Imports";

import {
  LytxCredentials,
  SingleLytxCredentials
} from "$Generated/api";

import {
  ICredentialProps
} from "$State/CredentialTypes";

import {
  SingleLytx
} from "./SingleLytx";

import {
  Button,
  TextField
} from "$Imports/MaterialUIComponents";

interface ILytxBaseProps {

}
type ILytxProps = ILytxBaseProps & ICredentialProps<LytxCredentials>;

export class Lytx extends React.PureComponent<ILytxProps> {

  @bind
  private onChange(index: number, data: Partial<SingleLytxCredentials>): void {
    const cloned = _.cloneDeep(this.getCredentials());
    cloned[index] = _.assign(cloned[index], data);
    this.props.onChange(this.props.providerKey, { credentials: cloned });
  }

  @bind
  private delete(index: number) {
    const cloned = _.cloneDeep(this.getCredentials());
    cloned.splice(index, 1);
    this.props.onChange(this.props.providerKey, { credentials: cloned });
  }

  @bind
  private addClick() {
    const cloned = _.cloneDeep(this.getCredentials());
    cloned.push({
      autoAddDriver: false,
      endpointId: "",
      password: "",
      submissionEndPoint: "",
      username: "",
      validateOnly: false
    })
    this.props.onChange(this.props.providerKey, { credentials: cloned });
  }

  @bind
  private getCredentials(): SingleLytxCredentials[] {
    return (this.props.data && this.props.data.credentials) 
      ? this.props.data.credentials 
      : [] as SingleLytxCredentials[];
  }  

  render(): JSX.Element {
    const lytxList: SingleLytxCredentials[] = (this.props.data && this.props.data.credentials) ? this.props.data.credentials : {} as SingleLytxCredentials[];
    const elementList: JSX.Element[] = [];
    let index = 0;
    for (const lytx of lytxList) {
      elementList.push(
        <SingleLytx
          key={index}
          data={lytx}
          index={index}
          onChange={this.onChange}
          delete={this.delete}
        />
      );
      index++;
    }

    return (
      <div>
        {elementList}
        <Button
          color="primary"
          onClick={this.addClick}
        >
          Add Credential
        </Button>
      </div>
    );
  }
}