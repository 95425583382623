import {
  FreezerService,
  IAjaxState,
  managedAjaxUtil,
  _,
  bind
} from "$Imports/Imports";

import {
  JobViewModel,
  ResponseBase,
  ActivityApiFactory
} from "$Generated/api";

import {
  SitePubSubManager
} from "$Utilities/PubSubUtil";

interface ITenantViewAdminMessageResubmissionFreezerState {
  messageResubmissionResults: IAjaxState<ResponseBase>;
  jobDetail: JobViewModel | null;
}

const InjectedPropName = "tenantViewAdminMessageResubmissionService";

class TenantViewAdminMessageResubmissionFreezerService extends FreezerService<ITenantViewAdminMessageResubmissionFreezerState, typeof InjectedPropName> {
  constructor() {
    super({
      jobDetail: null,
      messageResubmissionResults: managedAjaxUtil.createInitialState(),
    }, InjectedPropName);

    SitePubSubManager.subscribe("application:login:before", this.clearResults);
    SitePubSubManager.subscribe("job-detail:job-id-change", this.clearResults);
  }

  public setJobDetail(jobDetail: JobViewModel | null) {
    this.freezer.get().set({
      jobDetail
    });
  }

  @bind
  private clearResults() {
    this.freezer.get().set({
      jobDetail: null,
      messageResubmissionResults: managedAjaxUtil.createInitialState(),
    });
  }

  public async resubmitMessage(messageId: string) {
    managedAjaxUtil.fetchResults({
      freezer: this.freezer,
      ajaxStateProperty: "messageResubmissionResults",
      onExecute: (apiOptions, params, options) => {
        const jobDetail = this.freezer.get().jobDetail?.toJS();
        if (jobDetail && jobDetail.id) {
          return ActivityApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl).apiV1ActivityResubmitMessagePost({ jobId: jobDetail.id, messageId });
        }
        throw new Error("Job Id Undefined");
      },
    });
  }
}

export const TenantViewAdminMessageResubmissionService = new TenantViewAdminMessageResubmissionFreezerService();
export type ITenantViewAdminMessageResubmissionServiceInjectedProps = ReturnType<TenantViewAdminMessageResubmissionFreezerService["getPropsForInjection"]>;
