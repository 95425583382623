import {
  React,
  _
} from "$Imports/Imports";

import {
  CircularProgress
} from "$Imports/MaterialUIComponents";

import { ICredentialProps, credentialType } from "$State/CredentialTypes";

import {
  TenantCredentialService,
  ITenantCredentialServiceInjectedProps,
} from "$State/tenant-views/TenantCredentialFreezerService";

interface IEditCredentialsDialogContentsBaseProps {
  tenantId: string;
}

type IEditCredentialsDialogContentsProps = IEditCredentialsDialogContentsBaseProps & ITenantCredentialServiceInjectedProps;

class _EditCredentialsDialogContents extends React.Component<IEditCredentialsDialogContentsProps> {

  render(): JSX.Element {
    const sourceProviders = this.props.tenantCredentialService.getSourceProviders();
    const submissionProviders = this.props.tenantCredentialService.getSubmissionProviders();

    return (
      <div>
        {
          ((sourceProviders.length > 0) || (submissionProviders.length > 0)) ?
            this.props.tenantCredentialService.allCredentialsLoaded() && !this.props.tenantCredentialService.isSaving() ?
              <div style={{ display: "flex", flexDirection: "row" }}>
                <div style={{ width: "50%" }}>
                  {
                    _.map(sourceProviders, (adapter, index) => {
                      return (
                        <div key={index} >
                          <br />
                          <section className="container-fluid with-maxwidth chapter">
                            <label>{adapter.title}</label>
                            <section className="box box-default">
                              <div className="box-body">
                                {
                                  React.createElement<ICredentialProps<credentialType>>(
                                    adapter.componentClass,
                                    {
                                      data: this.props.tenantCredentialService.getCredential(adapter.categoryItemId),
                                      providerKey: adapter.categoryItemId,
                                      onChange: this.props.tenantCredentialService.updateCredential
                                    },
                                  )
                                }
                              </div>
                            </section>
                          </section >
                        </div>
                      );
                    })
                  }
                </div>
                <div style={{ width: "50%" }}>
                  {
                    _.map(submissionProviders, (adapter, index) => {
                      return (
                        <div key={index} >
                          <br />
                          <section className="container-fluid with-maxwidth chapter">
                            <label>Submission</label>
                            {/* <label>{adapter.title}</label> */}
                            <section className="box box-default">
                              <div className="box-body">
                                {
                                  React.createElement<ICredentialProps<credentialType>>(
                                    adapter.componentClass,
                                    {
                                      data: this.props.tenantCredentialService.getCredential(adapter.categoryItemId),
                                      providerKey: adapter.categoryItemId,
                                      onChange: this.props.tenantCredentialService.updateCredential
                                    },
                                  )
                                }
                              </div>
                            </section>
                          </section >
                        </div>
                      );
                    })
                  }
                </div>
              </div>
              :
              <div>
                <CircularProgress style={{ display: "block", marginLeft: "auto", marginRight: "auto" }} />
              </div>
            :
            <div>
              <br />
              Tenant is not configured correctly. Associated entity is missing, it must be configured.
            </div>
        }
      </div>
    );
  }
}


export const EditCredentialsDialogContents = TenantCredentialService.inject(
  _EditCredentialsDialogContents
);