import {
  React,
  bind,
  _,
  numeral,
  moment
} from "$Imports/Imports";

import {
  SubmissionDateSelection
} from "./SubmissionDateSelection";

import {
  SearchBox,
  DateFormatter,
  timeZoneType,
  DataTablePager
} from "$Components/Common";

import {
  SubmissionErrorSummaryGrid
} from "./SubmissionErrorSummaryGrid";

import {
  IPagerState
} from "$State/PagerPagingState";

import {
  TenantViewErrorReportService
} from "$State/job-views/ErrorReportFreezerService";

import {
  DriveCamSubmissionResultViewModelResponseBase,
  SubmissionErrorDetailViewModel,
  ChildJobViewModel,
} from "$Generated/api";

import {
  IconButton
} from "$Imports/MaterialUIComponents";

import {
  ArrowDownward,
} from "$Imports/MaterialUIIcons";

import {
  TimeZoneService,
  ITimeZoneServiceInjectedProps
} from "$State/TimeZoneFreezerService";

const styles: {
  paperMargin: string;
  filterContainer: string;
  adapterContainer: string;
  dateRangeContainer: string;
  searchHeaderContainer: string;
  searchLabel: string;
  headerGroup: string;
} = require("./SubmissionErrorReport.scss");


interface ISubmissionErrorReportBaseProps {
  search: string;
  pagerState: IPagerState;
  data: DriveCamSubmissionResultViewModelResponseBase;
  childJobs: ChildJobViewModel[];
  selectedSubmissionJobId: string;
}

type ISubmissionErrorReportProps = ISubmissionErrorReportBaseProps & ITimeZoneServiceInjectedProps;

class _SubmissisonsErrorReport extends React.Component<ISubmissionErrorReportProps> {

  componentWillMount() {
    // TenantViewErrorReportService.fetchSubmissionResults();
  }

  @bind
  private _onPagerStateChange(pagerState: IPagerState) {
    TenantViewErrorReportService.setVehicleSubmissionPagerState(pagerState);
  }

  @bind
  private _onPagerStateChangePager(page: number, rowsPerPage: number) {
    this._onPagerStateChange({
      page,
      rowsPerPage,
    });
  }

  private _onSearch(data: SubmissionErrorDetailViewModel[], search: string): SubmissionErrorDetailViewModel[] {
    if (search.trim() === "") {
      return data;
    }

    return _.filter(data, (d) => {

      const lastNameResults = (d.lastName ? d.lastName.toLowerCase() : "").indexOf(search.toLowerCase()) !== -1;

      if (lastNameResults) {
        return true;
      }

      const firstNameResults = (d.firstName ? d.firstName.toLowerCase() : "").indexOf(search.toLowerCase()) !== -1;

      if (firstNameResults) {
        return true;
      }

      const driverIdResults = (d.driverId ? d.driverId.toLowerCase() : "").indexOf(search.toLowerCase()) !== -1;

      if (driverIdResults) {
        return true;
      }

      const vehicleIdResults = (d.vehicleId ? d.vehicleId.toLowerCase() : "").indexOf(search.toLowerCase()) !== -1;

      if (vehicleIdResults) {
        return true;
      }

      const endpointIdResults = (d.additionalInformation ? d.additionalInformation["EndpointId"].toLowerCase() : "").indexOf(search.toLowerCase()) !== -1;

      if (endpointIdResults) {
        return true;
      }

      return false;
    });
  }

  @bind
  private _changeErrorFile(e: React.ChangeEvent<{ name?: string | undefined; value: unknown; }>, child: React.ReactNode, value: ChildJobViewModel | undefined) {
    TenantViewErrorReportService.setChildJobId(value?.jobId || "");
    TenantViewErrorReportService.fetchSubmissionResults(true);
  }

  @bind
  private _onClearSearch() {
    TenantViewErrorReportService.setVehicleSubmissionErrorSearch("");
  }

  @bind
  private _onDebounceChange(newValue: string) {
    TenantViewErrorReportService.setVehicleSubmissionErrorSearch(newValue);
  }

  @bind
  private _findChildJob(data: ChildJobViewModel[], jobId: string): ChildJobViewModel | undefined {
    return _.find(data, (d) => d.jobId === jobId);
  }

  @bind
  private saveCSV() {
    const { search, data, selectedSubmissionJobId } = this.props;


    const responseData = (data && data.data) ? data.data : {};
    const errorData = responseData.errorDetails ? responseData.errorDetails : [];
    const filterData = this._onSearch(errorData, search);
    const timeZone: timeZoneType = this.props.timeZoneService.getTimeZone() || "UTC";
    const isMilitaryTime: boolean = this.props.timeZoneService.getisMilitaryTime() || false;

    let csvData = "First Name, Last Name, Driver Id, Vehicle Id, Start Date, End Date, Response Code, Description, Endpoint Id\n";

    filterData.forEach(row => {
      csvData +=
        this._createCsvColumnValue(row.firstName) +
        this._createCsvColumnValue(row.lastName) +
        this._createCsvColumnValue(row.driverId) +
        this._createCsvColumnValue(row.vehicleId) +
        (row.startDate ? DateFormatter.formatDateString(row.startDate, timeZone, "", isMilitaryTime) : "") + "," +
        ((row.endDate && !moment(row.endDate).isSame("0001-01-01T00:00:00")) ? DateFormatter.formatDateString(row.endDate, timeZone, "", isMilitaryTime) : "") + "," +
        this._createCsvColumnValue(row.responseCode && row.responseCode !== "" ? row.responseCode : row.additionalInformation ? row.additionalInformation["ResponseCode"] : "") +
        this._createCsvColumnValue(row.description && row.description !== "" ? row.description : row.additionalInformation ? row.additionalInformation["Description"] : "") + 
        this._createCsvColumnValue(row.endpointId && row.endpointId !== "" ? row.endpointId : row.additionalInformation ? row.additionalInformation["EndpointId"] : "", true);
    });

    const saveDocument = document.createElement('a');
    saveDocument.href = 'data:text/csv;charset=utf-8,' + encodeURI(csvData);
    saveDocument.target = '_blank';
    saveDocument.download = 'submissionErrors-' + selectedSubmissionJobId  + '.csv';
    saveDocument.click();
  }

  private _createCsvColumnValue(value?: string, lastColumn?: boolean): string
  {
    return (!value ? "" : encodeURIComponent(value)) + (lastColumn ? "\n" : ",");
  }

  @bind
  private _onChangePage() {
    // Do nothing.  Handled by the component.  Remove once typescript has been updated.
  }

  render() {
    const { search, pagerState, childJobs, data, selectedSubmissionJobId } = this.props;
    const responseData = (data && data.data) ? data.data : {};
    const errorData = responseData.errorDetails ? responseData.errorDetails : [];
    const filterData = this._onSearch(errorData, search);
    const foundJob = this._findChildJob(childJobs, selectedSubmissionJobId);
    const resultsFound: boolean = foundJob?.jobStatus !== "Processing" || (responseData.totalCount != null && responseData.totalCount > 0);

    return (
      <>
        <div className={styles.searchHeaderContainer}>
          <div className={styles.headerGroup}>
            <div>
              <SearchBox
                value={this.props.search}
                label=" "
                filterStatusLabelClassName={styles.searchLabel}
                onDebouncedChange={this._onDebounceChange}
                filterStatusLabel={`${numeral(filterData.length).format("0,000")} ${filterData.length === 1 ? "Error" : "Errors"}`}
                onClearClick={this._onClearSearch}
              />
            </div>
            <div style={{ paddingLeft: 15 }}>
              <SubmissionDateSelection
                data={childJobs}
                selectedValue={selectedSubmissionJobId}
                onChange={this._changeErrorFile}
              />
            </div>
          </div>
          <div className={styles.headerGroup}>
            <div>
              <IconButton
                color="primary"
                title="Download"
                onClick={this.saveCSV}
              >
                <ArrowDownward />
              </IconButton>
            </div>
            <div>
              <DataTablePager
                onChangePage={this._onChangePage}
                count={errorData.length}
                page={pagerState.page}
                rowsPerPage={pagerState.rowsPerPage}
                onPagerStateChange={this._onPagerStateChangePager}
              />
            </div>
          </div>
        </div>
        {
          resultsFound ? (
            <SubmissionErrorSummaryGrid
              data={filterData}
              pager={pagerState}
              onPagerStateChange={this._onPagerStateChange}
              submissionStatus={responseData.status ? responseData.status : "Failed"}
            />
          ) : (
            <div>{status === "Processing" ? "Process results are not yet available for this job." : "Submission results were not available within 24 hours of the original submission. Results will no longer be polled."}</div>
          )
        }
      </>
    );
  }
}

export const SubmissionsErrorReport = TimeZoneService.inject(
  _SubmissisonsErrorReport
);