import {
  React,
  memoizeOne,
  _,
  bind,
  numeral
} from "$Imports/Imports";

import {
  Container
} from "./Container";

import {
  AjaxActionIndicator,
  DateRangeCalculator,
  PageHeader,
  SourceProviderSelector,
  UserRoleLimitedControl
} from "$Components/Common";

import {
  EditAddTenantDialog
} from "./edit-add-tenant/EditAddTenantDialog";

import {
  ActivateTenantDialog,
} from "./activate-tenant/ActivateTenantDialog"

import {
  AddTenantDialog
} from "./add-tenant/AddTenantDialog";

import {
  DeactivateTenantDialog
} from "./deactivate-tenant/DeactivateTenantDialog";

import {
  Paper,
  Divider,
  Snackbar,
  Slide,
  TransitionProps,
  IconButton,
  Button,
  Checkbox,
  FormControlLabel
} from "$Imports/MaterialUIComponents";

import {
  ITenantSummaryServiceInjectedProps,
  TenantSummaryService
} from "$State/TenantSummaryFreezerService";

import {
  ITenantAddServiceInjectedProps,
  TenantAddService
} from "$State/tenant-views/TenantAddFreezerService";

import {
  IResourceServiceInjectedProps,
  ResourceService
} from "$State/ResourceFreezerService";

import {
  TenantCredentialService,
  ITenantCredentialServiceInjectedProps
} from "$State/tenant-views/TenantCredentialFreezerService";

import {
  TenantContactsService,
  ITenantContactsServiceInjectedProps
} from "$State/tenant-views/TenantContactsFreezerService";

import {
  TenantRenameService,
  ITenantRenameServiceInjectedProps,
} from "$State/tenant-views/TenantRenameFreezerService";

import {
  TenantLytxEmailRecipientService,
  ITenantLytxEmailRecipientsInjectProps,
} from "$State/tenant-views/TenantEmailRecipientsFreezerService";

import {
  JobService
} from "$State/JobFreezerService";

import {
  ITenantServiceInjectedProps,
  TenantService
} from "$State/TenantFreezerService";

import {
  NavigationService
} from "$State/NavigationFreezerService";

import {
  TenantViewModel,
  TenantSummaryViewModel
} from "$Generated/api";

import {
  TenantSummary
} from "./TenantSummary";

const styles: {
  paperStyle: string;
  checkboxContainer: string;
  searchContainer: string;
  adapterSelector: string;
  selectorContainer: string;
  searchPaperStyle: string;
  addContainer: string;
} = require("./Tenant.scss");

import {
  SearchBox
} from "$Components/Common/SearchBox";

import {
  menuType
} from "./TenantMenu";

import {
  IPagerState
} from "$State/PagerPagingState";

import {
  ISortState
} from "$State/SortState";

import {
  EditCredentials
} from "./edit-credentials/EditCredentials";

import {
  EditLytxEmailRecipients
} from "./edit-email-recipients/EditLytxEmailRecipients";

import {
  EditLytxContacts
} from "./edit-lytx-contacts/EditLytxContacts";

import {
  RenameTenant
} from "./rename-tenant/RenameTenant";

import { Close } from "$Imports/MaterialUIIcons";
import { UserRoles } from "$Components/Common/UserRoles";
import { ApplicationSecurityConsumer } from "$Providers/AuthenticationProvider";



type ITenantProps = 
  ITenantSummaryServiceInjectedProps
  & ITenantAddServiceInjectedProps
  & IResourceServiceInjectedProps
  & ITenantCredentialServiceInjectedProps
  & ITenantContactsServiceInjectedProps
  & ITenantRenameServiceInjectedProps
  & ITenantServiceInjectedProps
  & ITenantLytxEmailRecipientsInjectProps;

class _Tenant extends React.Component<ITenantProps> {

  componentWillMount() {
    this.props.tenantSummaryService.fetchTenants();
    this.props.resourceService.fetchAdapterList();
  }

  @bind
  private async _onMenuItemClick(event: React.MouseEvent<HTMLLIElement, MouseEvent>, tenantSummaryViewModel: TenantSummaryViewModel, operation: menuType): Promise<void> {
    if (operation === "advance-settings") {
      this.props.tenantSummaryService.setEditTenant(tenantSummaryViewModel);
    }
    if (operation === "edit-credentials") {
      // Need to call freezer service to get AssociatedEntities here
      if (tenantSummaryViewModel.workflowInstanceId) {
        this.props.tenantCredentialService.setWorkflowInstanceId(tenantSummaryViewModel.workflowInstanceId);
      }
    }
    if (operation === "rename-tenant") {
      this.props.tenantRenameService.openRenameDialog(tenantSummaryViewModel);
    }
    if (operation === "activate-tenant") {
      this.props.tenantSummaryService.setActivateTenant(tenantSummaryViewModel);
    }
    if (operation === "deactivate-tenant") {
      this.props.tenantSummaryService.setDeactivateTenant(tenantSummaryViewModel);
    }
    if (operation === "edit-contacts") {
      if (tenantSummaryViewModel.tenantId) {
        this.props.tenantContactsService.setTenantId(tenantSummaryViewModel.tenantId);
      }
    }
    if(operation === "start-job")
    {
      if (tenantSummaryViewModel) {
        this.props.tenantService.startJob(tenantSummaryViewModel);
      }
    }
    if(operation === "edit-email-recipients") {
      if (tenantSummaryViewModel.tenantId) {
        this.props.tenantEditEmailRecipientsService.setTenantId(tenantSummaryViewModel.tenantId) ;
       }
    }
  }

  //#region Search

  private _onSearch(data: TenantSummaryViewModel[], search: string, adapter: string): TenantViewModel[] {
    return _.filter(data, (d) => {
      if (search.trim() === "" && adapter.trim() === "") {
        return true;
      }

      const nameFilter: boolean = (d.tenantFriendlyName ? d.tenantFriendlyName : "").toLowerCase().indexOf(search.toLowerCase()) !== -1;
      let adapterFilter: boolean = false;

      if (adapter.trim() !== "" && d.adapters) {
        adapterFilter = _.some(d.adapters, (a) => (a.friendlyName ? a.friendlyName : "").toLowerCase() === adapter.toLowerCase());
      }
      else {
        adapterFilter = true;
      }

      return nameFilter && adapterFilter;
    });
  }

  private readonly _onSearch_memoizeOne = memoizeOne(this._onSearch);

  @bind
  private _onSearchChange(newValue: string) {
    this.props.tenantSummaryService.setFilterValue({ search: newValue });
  }

  @bind
  private _onClearSearch() {
    this.props.tenantSummaryService.setFilterValue({ search: "" });
  }

  //#endregion

  //#region Show Inactive Tenants

  @bind
  private _onCheckboxChange(event: React.ChangeEvent<HTMLInputElement>, checked: boolean) {
    this.props.tenantSummaryService.setShowInactiveTenants(checked);
    this.props.tenantSummaryService.fetchTenants(true);
  }

  //#endregion

  //#region Source Provider Selector

  @bind
  private _onAdapterChange(
    e: React.ChangeEvent<{ name?: string | undefined; value: unknown; }>,
    child: React.ReactNode,
    adapterName: string) {

    this.props.tenantSummaryService.setFilterValue({
      adapter: adapterName,
    });
  }

  //#endregion

  //#region Tenant Summary

  @bind
  private _onTenantClick(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>, data: TenantSummaryViewModel) {
    if (event.button !== 0) {
      return;
    }
    JobService.setFilter({
      adapter: "",
      tenantId: data && data.workflowInstanceId ?
        data.workflowInstanceId : data && data.tenantId ?
          data.tenantId : undefined,
      dateRange: DateRangeCalculator.calcDateRange("today"),
      status: ""
    });
    JobService.fetchJobs(true);
    NavigationService.navigateTo("/jobs");
  }

  @bind
  private _onPagerStateChange(pagerState: IPagerState) {
    TenantSummaryService.setTenantPagerState(pagerState);
  }

  @bind
  private _onSortStateChange(sortState: ISortState) {
    TenantSummaryService.setTenantSort(sortState);
  }

  //#endregion

  //#region Add/Edit Tenant Dialogs

  // Used by both add and edit tenant dialogs
  @bind
  private _addTenantSave() {
    TenantAddService.createTenant();
    TenantAddService.closeAddDialog();
  }

  //#region Edit Tenant Dialog

  @bind
  private _onCloseEditAddDialog() {
    this.props.tenantSummaryService.closeEditAddDialog();
  }

  //#endregion

  //#region Add Tenant Dialog

  @bind
  private _onOpenAddDialog() {
    this.props.tenantAddService.openAddDialog();
  }

  @bind
  private _onCloseAddDialog() {
    this.props.tenantAddService.closeAddDialog();
  }

  //#endregion

  //#endregion Add/Edit Tenant Dialogs

  //#region Edit Lytx Contacts

  @bind
  private _onContactsSave() {
    TenantContactsService.saveContacts();
  }

  //#endregion

  //#region Rename Tenant

  @bind
  private _onRenameTenant() {
    TenantRenameService.renameTenant();
    TenantRenameService.closeRenameDialog();
  }

  @bind
  private _onCloseRenameTenant() {
    this.props.tenantRenameService.closeRenameDialog();
  }

  //#endregion

  //#region Activate Tenant

  @bind
  private _activateTenant() {
    TenantSummaryService.activateTenant();
    TenantSummaryService.closeActivateDialog();
  }

  @bind
  private _onCloseActivateDialog() {
    this.props.tenantSummaryService.closeActivateDialog();
  }

  //#endregion

  //#region Deactivate Tenant

  @bind
  private _deactivateTenant() {
    TenantSummaryService.deactivateTenant();
    TenantSummaryService.closeDeactivateDialog();
  }

  @bind
  private _onCloseDeactivateDialog() {
    this.props.tenantSummaryService.closeDeactivateDialog();
  }

  //#endregion

  //#region Snackbars

  @bind
  private _closeAddTenantSnackbar() {
    this.props.tenantAddService.setSnackbarStatus(false)
  }

  @bind
  private _onClose(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    this.props.tenantService.setStartJobDialogOpen(false);
  }

  @bind
  private _onSnackClose(event: React.SyntheticEvent<any>, reason: string) {
    this.props.tenantService.setStartJobDialogOpen(false);
  }

  private SlideTransition(props: TransitionProps) {
    return <Slide {...props} direction="down" />;
  }

  //#endregion

  render() {
    const {
      tenantResults,
      tenantSummaryResults,
      filter,
      tenantResultPagedState,
      tenantSortState,
      editAddTenant,
      view,
      deactivateTenant,
      activateTenant,
      showInactiveTenants,
    } = this.props.tenantSummaryService.getState();
    const {
      addDialogViewState,
      snackbarStatus,
      newTenantModel,
      workflowTemplatesResult
    } = this.props.tenantAddService.getState();
    const {
      invalidReason: invalidRenameMessage,
      open: openRenameDialog,
      originalName,
      renameTenantResult,
    } = this.props.tenantRenameService.getState();

    const {
      sourceProviderResults
    } = this.props.resourceService.getState();

    let tenantData = tenantResults.data?.data || [];
    let tenantSummaryData = tenantSummaryResults.data?.data || [];

    const adapterData = sourceProviderResults.data?.data || [];

    tenantData = this._onSearch_memoizeOne(tenantData, filter.search, filter.adapter);

    const open = this.props.tenantService.getStartJobDialogOpen();

    return (
      <Container>
        <PageHeader title="Tenants" />
        <div>
          <Paper
            className={styles.paperStyle}
          >
            <div
              className={styles.searchPaperStyle}
            >
              <SearchBox
                value={filter.search}
                onDebouncedChange={this._onSearchChange}
                filterStatusLabel={` ${numeral(tenantData.length).format("0,000")} ${tenantData.length === 1 ? "Tenant" : "Tenants"}`}
                onClearClick={this._onClearSearch}
              />
              <div
                className={styles.selectorContainer}
              >
                <SourceProviderSelector
                  sourceAdapterData={adapterData}
                  value={filter.adapter}
                  onAdapterChange={this._onAdapterChange}
                />
              </div>
              <div
                className={styles.checkboxContainer}
              >
                <FormControlLabel
                  control={<Checkbox checked={showInactiveTenants} onChange={this._onCheckboxChange}/>}
                  label="Show Inactive" 
                />
              </div>
              <div className={styles.addContainer}>
                <UserRoleLimitedControl
                  requiredUserRoles={
                    [
                      UserRoles.CreateTenant,
                      UserRoles.CreateAssocEntity,
                      UserRoles.CreateTenantSourceCreds,
                      UserRoles.CreateTenantSubmissionCreds,
                      UserRoles.CreateOrgUnits
                    ]
                  }
                >
                  <Button variant="outlined" color="primary" onClick={this._onOpenAddDialog}>Add Tenant</Button>
                </UserRoleLimitedControl>
              </div>
            </div>
            <Divider />
            <AjaxActionIndicator
              state={[
                tenantResults,
                sourceProviderResults,
                workflowTemplatesResult,
                renameTenantResult
              ]}
            />
            <ApplicationSecurityConsumer>
              { (securityContext) => {
                return (
                  <TenantSummary
                    securityContext={securityContext}
                    data={tenantData}
                    supportData={tenantSummaryData}
                    sortState={tenantSortState}
                    onTenantClick={this._onTenantClick}
                    onPagerStateChange={this._onPagerStateChange}
                    onMenuItemClick={this._onMenuItemClick}
                    onSortChange={this._onSortStateChange}
                    pager={tenantResultPagedState}
                  />
                )
              }}
            </ApplicationSecurityConsumer>
          </Paper>
          {/* <StatusIndicatorLegend
            className={styles.paperStyle}
          /> */}
        </div>
        <EditAddTenantDialog
          tenant={editAddTenant}
          view={view}
          onCancel={this._onCloseEditAddDialog}
          onSave={this._addTenantSave}
        />
        <AddTenantDialog
          view={addDialogViewState}
          onCancel={this._onCloseAddDialog}
          onSave={this._addTenantSave}
          adapters={adapterData}
          newTenantModel={newTenantModel}
        />
        <EditCredentials
          onClose={this.props.tenantCredentialService.resetFreezer} // Call freezer to close popup
          open={this.props.tenantCredentialService.getDataPopulated() && !(this.props.tenantCredentialService.allCredentialsSaved())}
          submitCredentialsData={this.props.tenantCredentialService.saveCredentials} // Submit/save credentials
          tenantId={this.props.tenantCredentialService.getWorkflowInstanceId()}
        />
        <EditLytxEmailRecipients
          submitEmailRecipients={this.props.tenantEditEmailRecipientsService.saveEmailRecipients}
          onClose={this.props.tenantEditEmailRecipientsService.resetFreezer} // Call freezer to close popup
          open={this.props.tenantEditEmailRecipientsService.getTenantId() != ""}
          tenantId={this.props.tenantEditEmailRecipientsService.getTenantId()}
        />
        <EditLytxContacts
          onClose={this.props.tenantContactsService.resetFreezer}
          open={this.props.tenantContactsService.getTenantId() != ""}
          tenantId={this.props.tenantContactsService.getTenantId()}
          onSave={this._onContactsSave}
        />
        <RenameTenant
          onClose={this._onCloseRenameTenant}
          onSave={this._onRenameTenant}
          open={openRenameDialog}
          originalName={originalName}
          invalidMessage={invalidRenameMessage}
        />
        <ActivateTenantDialog
          view={view}
          onCancelClick={this._onCloseActivateDialog}
          onActivateClick={this._activateTenant}
          activateTenant={activateTenant}
        />
        <DeactivateTenantDialog
          view={view}
          onCancelClick={this._onCloseDeactivateDialog}
          onDeactivateClick={this._deactivateTenant}
          deactivateTenant={deactivateTenant}
        />
        <Snackbar
          onClose={this._onSnackClose}
          open={open}
          message={"You have successfully started a job. It may take a few minutes to begin processing."}
          autoHideDuration={5000}
          anchorOrigin={{
            horizontal: "center",
            vertical: "top"
          }}
          TransitionComponent={this.SlideTransition}
          action={[
            <IconButton key="close" color="inherit" onClick={this._onClose}>
              <Close />
            </IconButton>,
          ]}
        />
        <Snackbar
          onClose={this._closeAddTenantSnackbar}
          open={snackbarStatus}
          message={"Tenant created and may take a few minutes for processing."}
          autoHideDuration={5000}
          anchorOrigin={{
            horizontal: "center",
            vertical: "top"
          }}
          TransitionComponent={this.SlideTransition}
          action={[
            <IconButton key="close" color="inherit" onClick={this._closeAddTenantSnackbar}>
              <Close />
            </IconButton>,
          ]}
        />
      </Container >
    );
  }
}

export const Tenant = TenantSummaryService.inject(
  TenantAddService.inject(
    TenantRenameService.inject(
      ResourceService.inject(
        TenantCredentialService.inject(
          TenantLytxEmailRecipientService.inject(
            TenantContactsService.inject(
              TenantService.inject(
                _Tenant
              )
            )
          )
        )
      )
    )
  )
);


