import * as React from "react";
import {
  BrowserRouter as Router,
  Route,
  Link,
  Redirect,
  Switch,
} from "react-router-dom";

import * as $ from "jquery"

import { withRouter } from "react-router";
import Freezer = require("freezer-js");
import * as numeral from "numeral";

import * as _ from "lodash";
import { bind } from "bind-decorator";
import * as moment from "moment-timezone";

import * as classnames from "classnames";
import { FreezerService } from "./FreezerService";

import { ILogger } from "@yahara/logging";
import { ChangeEvent } from "react";

import {
  IAjaxState,
} from "../modules/managedAjaxUtil";

import * as managedAjaxUtil from "../modules/managedAjaxUtil";
import * as Helpers from "../modules/helpers";

import memoizeOne from "memoize-one";

const html2pdf: (src: any, opt: any) => any = require("html2pdf.js");

import Select from 'react-select'
const React_Select = Select;

export {
  withRouter,
  Freezer,
  React,
  _,
  bind,
  moment,
  classnames,
  Router,
  Route,
  IAjaxState,
  managedAjaxUtil,
  Helpers,
  ILogger,
  FreezerService,
  memoizeOne,
  Link,
  Redirect,
  Switch,
  numeral,
  ChangeEvent,
  $,
  html2pdf,
  React_Select,
};
