import {
  React,
  _,
  bind
} from "$Imports/Imports";
import { Button } from "$Imports/MaterialUIComponents";

import { AdvanceTextField } from "$Components/Common";
import { ICredentialProps } from "$State/CredentialTypes";
import { ERoadsCredentials } from "$Generated/api";

interface IERoadsBaseProps {
}
type IERoadsProps = IERoadsBaseProps & ICredentialProps<ERoadsCredentials>;

export class ERoads extends React.PureComponent<IERoadsProps> {

  @bind
  onChange(e: any) {
    if (e.target.name === "apiKey") {
      this.props.onChange(this.props.providerKey, { apiKey: e.target.value });
    }
  }

  render(): JSX.Element {
    const eRoads: ERoadsCredentials = this.props.data ? this.props.data : {} as ERoadsCredentials;
    return (
      <div>
        <AdvanceTextField
          label="Api Key"
          name="apiKey"
          onChange={this.onChange}
          value={eRoads.apiKey ? eRoads.apiKey : ""}
          error={(eRoads.apiKey === "")}
        /><br />
        <br />
        <Button className="float-right" disabled={true} color={"primary"}>VALIDATE CREDENTIALS</Button>{/* Disabled for as per LDM - 483 */}
      </div>
    );
  }
}