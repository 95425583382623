import {
  React,
  bind
} from "$Imports/Imports";

import {
  Tabs,
  Tab
} from "$Imports/MaterialUIComponents";

import {
  viewType
} from "$State/VehicleSearchFreezerService";
interface IVehicleSearchOptionsProps {
  selectedView: viewType;
  onViewChange?: (event: React.ChangeEvent<{}>, value: viewType) => void;
}

export class VehicleSearchOptions extends React.PureComponent<IVehicleSearchOptionsProps> {

  @bind
  private _onChange(event: React.ChangeEvent<{}>, value: viewType): void {
    if (this.props.onViewChange) {
      this.props.onViewChange(event, value);
    }
  }

  render() {
    const { selectedView } = this.props;

    return (
      <Tabs
        value={selectedView}
        indicatorColor="primary"
        textColor="primary"
        onChange={this._onChange}
      >
        <Tab
          label="Assignment Records"
          value="assignmentRecords"
        />
        <Tab
          value="dutyStatus"
          label="Duty Status"
        />
      </Tabs>
    );
  }
}
