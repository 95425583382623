import {
  React,
  bind,
  _,
  moment,
  memoizeOne,
  numeral,
  ChangeEvent
} from "$Imports/Imports";

import {
  Paper,
  Divider,
  Button,
  Snackbar,
  Tab,
  Tabs,
} from "$Imports/MaterialUIComponents";

import {
  Container
} from "./Container";

import {
  AjaxActionIndicator,
  DateRangeFilter,
  IDateRange,
  DateRangeOptions,
  PageHeader,
  JobStatusSelector,
  TenantSelector
} from "$Components/Common";

import {
  IJobServiceInjectedProps,
  JobService
} from "$State/JobFreezerService";

import {
  NavigationService
} from "$State/NavigationFreezerService";

import {
  ITenantSummaryServiceInjectedProps,
  TenantSummaryService
} from "$State/TenantSummaryFreezerService";

import {
  JobSummaryViewModel, TenantSummaryViewModel
} from "$Generated/api";

import {
  JobSummaryGrid
} from "./JobSummaryGrid";

import {
  IPagerState
} from "$State/PagerPagingState";

import {
  ISortState
} from "$State/SortState";

import {
  IJobDetailServiceInjectedProps,
  JobDetailService
} from "$State/JobDetailFreezerService";
import { Box } from "@material-ui/core";
import { MultipleSubmissionErrorReportView } from ".././job-detail-view/tenant-views/multiple-error-report-view/MultipleSubmissionErrorReportView";
import { MultipleSmartDriveErrorReportView } from ".././job-detail-view/tenant-views/multiple-smartdrive-error-report-view/MultipleSmartDriveErrorReportView";

const styles: {
  paperMargin: string;
  jobSearchContainer: string;
  jobUpperSearchBox: string;
  jobLowerSearchBox: string;
  adapterContainer: string;
  dateRangeContainer: string;
  jobSearchButton: string;
  jobSearchButtonBox: string;
  filterStatusLabelClassName: string;
} = require("./Jobs.scss");

interface IJobsBaseProps {
}

type IJobsProps = IJobsBaseProps & IJobServiceInjectedProps & IJobDetailServiceInjectedProps & ITenantSummaryServiceInjectedProps;

interface TabPanelProps {
  children?: React.ReactNode;
  index: string;
  value: string;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box >{children}</Box>}
    </div>
  );
}

class _Jobs extends React.Component<IJobsProps, {openTab: string, selectedClientId: string | undefined, 
  appliedClientId: string | undefined, selectedValidDate: boolean, appliedValidDate: boolean, forceUpdate: boolean , previousJobData: JobSummaryViewModel[]}> {

  constructor(props: any) {
    super(props);
    this.state = {
      openTab: "Jobs",
      selectedClientId: "",
      appliedClientId: "initial",
      selectedValidDate: true,
      appliedValidDate: true,
      forceUpdate: false,
      previousJobData: [],
    };
  }
  
  componentDidMount() {
    this.props.tenantSummaryService.fetchTenants();
    this.props.jobService.freezer.get().set({ errorState: false });
  }

  handleChange = (event: ChangeEvent<{}>, newValue: string) => {
    this.setState( (prevState: { openTab: any; }) => ({... prevState,
      openTab: newValue
    }))
  }

  setForceUpdate = (update: boolean ) =>{
    this.setState( (prevState: { }) => ({... prevState,
      forceUpdate: update
    }))
  }
  
  setPreviousJobData = (update: JobSummaryViewModel[] ) =>{
    this.setState( (prevState: { }) => ({... prevState,
      previousJobData: update
    }))
  }

  checkIfDataRetrieved(newData: JobSummaryViewModel[]){
    if(newData.length!==this.state.previousJobData.length){
      this.setForceUpdate(true);
      this.setPreviousJobData(newData);
    }
  }

  private _onSearch(data: JobSummaryViewModel[], search: string, adapter: string, status: string): JobSummaryViewModel[] {
    return _.filter(data, (d) => {
      if (search.trim() === "" && adapter.trim() === "" && status === "") {
        return true;
      }

      const tenantName = (d.tenantFriendlyName ? d.tenantFriendlyName : "");
      const adapterNames = (d.adapters ? d.adapters
        .filter(x => !!x.friendlyName) // exclude null/undefined
        .map((x) => (x.friendlyName || "").toLowerCase()) : []);

      const searchMatch: boolean = search === "" || tenantName.toLowerCase().indexOf(search.toLowerCase()) !== -1;
      const adapterMatch: boolean = adapter === "" || adapterNames.indexOf(adapter.toLowerCase()) !== -1;
      const statusMatch: boolean = status === "" || d.jobStatus == status;

      return searchMatch && adapterMatch && statusMatch;
    });
  }

  private readonly _onSearch_memoize = memoizeOne(this._onSearch);

  @bind
  private _onStatusChange(
    e: React.ChangeEvent<{ name?: string | undefined; value: unknown; }>,
    child: React.ReactNode,
    status: string
  ) {
    this.props.jobService.setFilter({
      status: status
    });
  }

  @bind
  private _onTenantClick(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>, job: JobSummaryViewModel) {
    const url = "/jobs/" + (job.relatedJobId ? job.relatedJobId : job.jobId);
    if (e.button === 1 || e.button === 2) {
      window.open(url);
    }
    else if (e.button === 0) {
      NavigationService.navigateTo(url);
    }
  }

  @bind
  private _onDateRangeChange(dateRange: IDateRange): void {
    const range = this._testDateForToday(dateRange);
    this.setState( (prevState) => ({...prevState,
      selectedValidDate: range
    }));
    this.props.jobService.setFilter({
      dateRange
    });
  }

  private _testDateForToday(range: IDateRange){
    if(range.endDate!==null && range.endDate!==undefined &&
      range.startDate!==null && range.startDate!==undefined){
      const diff = moment(range.endDate).diff(range.startDate, 'day', false);
    return diff<=1;
    }
    return false;
  }

  @bind
  private _onPagerStateChange(pagerState: IPagerState) {
    JobService.setRecentJobsPagerState(pagerState);
  }

  @bind
  private _onSortStateChange(sortState: ISortState) {
    JobService.setRecentJobsSortState(sortState);
  }

  @bind
  private _onSearchClick() {
    this.setState( (prevState: { selectedClientId: any; selectedValidDate: any; appliedValidDate: any; appliedClientId: any}) => ({... prevState,
      appliedClientId: prevState.selectedClientId,
      appliedValidDate: prevState.selectedValidDate,
    }))
    JobService.fetchJobs(true);
    this.setForceUpdate(true);
  }

  @bind
  private _onTenantSelectionChanged(tenant: TenantSummaryViewModel | undefined) {
    //Not updating tenantSummaryService filter state to mirror how we're doing it for VehicleSearch. True state lives in jobsSummaryService
    if (tenant === undefined) { 
      this.props.jobService.freezer.get().set({ errorState: true }); 
    } else  { 
      this.props.jobService.freezer.get().set({ errorState: false }); 
    }
    this.setState( (prevState) => ({... prevState,
      selectedClientId: tenant?.clientOrgName
    }));
    this.props.jobService.setFilter({
      tenantId: tenant && tenant.workflowInstanceId ?
        tenant.workflowInstanceId : tenant && tenant.tenantId ?
          tenant.tenantId : undefined,
    });
  }

  private _findSelected(tenant: TenantSummaryViewModel[], searchValue: unknown): TenantSummaryViewModel | undefined {
    return _.find(tenant, (t) => {
      if (searchValue === undefined || searchValue === null) {
        return false;
      }

      return t.tenantId === searchValue || t.workflowInstanceId === searchValue || t.tenantFriendlyName === searchValue;
    });
  }

  render() {
    const { filter, recentJobsResults, recentJobsPagerState, recentJobsSortState, errorState } = this.props.jobService.getState();
    const jobData = recentJobsResults.data ? recentJobsResults.data.data ? recentJobsResults.data.data : [] : [];

    const filteredData = this._onSearch_memoize(jobData, filter.searchValue, filter.adapter, filter.status);
    this.checkIfDataRetrieved(filteredData);
    const { tenantResults } = this.props.tenantSummaryService.getState();
    const filteredTenantSummaryData = tenantResults.data && tenantResults.data.data ? tenantResults.data.data : [];

    const displayErrorTab = (match: string[])=>{
      let found = false;
      if(filteredTenantSummaryData!==undefined && filteredTenantSummaryData!==null && filteredTenantSummaryData.length!==0
        && filteredData!==undefined && filteredData!==null && filteredData.length!==0){
        const clientId = this._findSelected(filteredTenantSummaryData, filteredData[0].tenantId);
        match.forEach(client=>{
          if(clientId!==undefined && clientId.clientOrgName!==undefined){
            if((clientId.clientOrgName.indexOf(client)!==-1)){
              found = true;
            }
          }
        });
      }
      return found;
    }
    const filterStatusLabel=`${numeral(filteredData.length).format("0,000")} ${filteredData.length === 1 ? "Job" : "Jobs"}`;

    return (
      <Container>
        <PageHeader title="Jobs" />
        <Paper
          className={styles.paperMargin}
        >
          <div
            className={styles.jobSearchContainer}
          >
            <div
              className={styles.jobUpperSearchBox}
            >
              <div
                className={styles.adapterContainer}
              >
                <TenantSelector
                  value={filter.tenantId}
                  sourceTenantData={filteredTenantSummaryData}
                  onTenantChange={this._onTenantSelectionChanged}
                  errorState={errorState}
                />
              </div>
              <div
                className={styles.adapterContainer}
              >
                <DateRangeFilter
                  value={filter.dateRange}
                  onChange={this._onDateRangeChange}
                  rangeOptions={DateRangeOptions.AllRangeOptions}
                  maxDayRange={30}
                  maxDate={moment()}
                  minDate={moment().subtract(90, "days")}
                />
              </div>
              <div className={styles.jobSearchButtonBox}>
                <Button size="small"
                  className={styles.jobSearchButton}
                  variant="contained"
                  color="primary"
                  onClick={this._onSearchClick}>
                  Search
                </Button>
              </div>
            </div>
            <div
              className={styles.jobLowerSearchBox}
            >
              <div
                className={styles.adapterContainer}
              >
                <JobStatusSelector
                  value={filter.status}
                  onStatusChange={this._onStatusChange}
                />
              </div>
              {filterStatusLabel !== null && filterStatusLabel !== undefined ?
              (
                <div
                  className={styles.filterStatusLabelClassName}
                >
                  {filterStatusLabel}
                </div>
              )
              : null
          }  
            </div>
          </div>
          <Divider />
          <AjaxActionIndicator
            state={[
              recentJobsResults
            ]}
          />
          <>
          {(!(displayErrorTab(["Lytx", "Smart"]))&&filteredData.length==0) ?
          <JobSummaryGrid
            data={filteredData}
            sortState={recentJobsSortState}
            onSortChange={this._onSortStateChange}
            onTenantClick={this._onTenantClick}
            pager={recentJobsPagerState}
            onPagerStateChange={this._onPagerStateChange}
          />
          :
          <>
          <Tabs
          value={this.state.openTab} onChange={this.handleChange} aria-label="tabs"
          >
            <Tab
              value="Jobs"
              label="Job List"
            />
            <Tab
              value="Errors"
              label="Error Summary"
            />
          </Tabs>
            <CustomTabPanel value={this.state.openTab} index={"Jobs"}>
              <JobSummaryGrid
              data={filteredData}
              sortState={recentJobsSortState}
              onSortChange={this._onSortStateChange}
              onTenantClick={this._onTenantClick}
              pager={recentJobsPagerState}
              onPagerStateChange={this._onPagerStateChange}
              />
            </CustomTabPanel>
            <CustomTabPanel value={this.state.openTab} index={"Errors"}>
              {(this.state.appliedValidDate)? 
                (displayErrorTab(["Smart"]) ?
                  <MultipleSmartDriveErrorReportView job={filteredData} childJobs={[]} forceUpdate={this.state.forceUpdate} setForceUpdate={this.setForceUpdate}/>
                  :
                  <MultipleSubmissionErrorReportView job={filteredData} childJobs={[]} forceUpdate={this.state.forceUpdate} setForceUpdate={this.setForceUpdate}/>
                )
              :
              <div>Please select a single date.</div>} 
            </CustomTabPanel>
          </>
          }
          </>
        </Paper>
        <Snackbar
          open={errorState}
          message={"Search Criteria Required"}
          autoHideDuration={5000}
          anchorOrigin={{
            horizontal: "center",
            vertical: "bottom"
          }}
        />
      </Container>
    );
  }
}

export const Jobs = TenantSummaryService.inject(
  JobService.inject(
    JobDetailService.inject(
      _Jobs)
  )
);
