import {
  React
} from "$Imports/Imports";

import {
  ErrorIndicator
} from "./ErrorIndicator";

import {
  IssueIndicator
} from "./IssueIndicator";

import {
  ImprovingIndicator
} from "./ImprovingIndicator";

import {
  GoodIndicator
} from "./GoodIndicator";

import {
  StatusIndicatorLabel
} from "./StatusIndicatorLabel";

const styles: {
  mainContainer: string;
  statusLabelIndicator: string;
} = require("./StatusIndicatorLegend.scss");

interface IStatusIndicatorLegendProps extends React.HTMLProps<HTMLDivElement> {

}

export class StatusIndicatorLegend extends React.PureComponent<IStatusIndicatorLegendProps> {
  render() {
    const { className, ...passthroughProps } = this.props;
    return (
      <div
        className={className ? `${styles.mainContainer} ${className}` : styles.mainContainer}
        {...passthroughProps}
      >
        <StatusIndicatorLabel
          className={styles.statusLabelIndicator}
          icon={(
            <GoodIndicator
              fontSize="default"
            />
          )}
        >
          Good
        </StatusIndicatorLabel>
        <StatusIndicatorLabel
          className={styles.statusLabelIndicator}
          icon={(
            <ImprovingIndicator
              fontSize="default"
            />
          )}>
          Improving
        </StatusIndicatorLabel>
        <StatusIndicatorLabel
          className={styles.statusLabelIndicator}
          icon={(
            <IssueIndicator
              fontSize="default"
            />
          )}
        >
          Issues
        </StatusIndicatorLabel>
        <StatusIndicatorLabel
          className={styles.statusLabelIndicator}
          icon={(
            <ErrorIndicator
              fontSize="default"
            />
          )}
        >
          Error
        </StatusIndicatorLabel>
      </div>

    );
  }
}