import {
  React,
} from "$Imports/Imports";

import {
  Container
} from "./Container";

import {
  Paper,
} from "$Imports/MaterialUIComponents";

const styles: {
  paperStyle: string;
} = require("./AdminIntegration.scss");
interface IAdminIntegrationBaseProps {

}

type IAdminIntegrationProps = IAdminIntegrationBaseProps;

export class AdminIntegration extends React.Component<IAdminIntegrationProps> {
  render() {
    return (
      <Container>
        <div>
          <Paper
            className={styles.paperStyle}
          >
            Admin Integration Placeholder
          </Paper>
        </div>
      </Container>
    );
  }
}
