import {
  React,
  bind
} from "$Imports/Imports";

import {
  TableCell,
  TableCellProps,
} from "$Imports/MaterialUIComponents";

import {
  IDataTableColumn
} from "./IDataTableColumn";

interface IDataTableCellProps<T = unknown> {
  data: T;
  column: IDataTableColumn<T>;
  onCellClick?: (event: React.MouseEvent<HTMLTableCellElement, MouseEvent>, data: T, column: IDataTableColumn<T>) => void;
}

export class DataTableCell<T = unknown> extends React.PureComponent<IDataTableCellProps<T>> {
  @bind
  private _onCellClick(event: React.MouseEvent<HTMLTableCellElement, MouseEvent>) {
    if (this.props.onCellClick) {
      this.props.onCellClick(event, this.props.data, this.props.column);
    }
  }

  private _getCellProps(data: T, column: IDataTableColumn<T>): TableCellProps | undefined {
    if (typeof column.cellProps === "function") {
      return column.cellProps(data);
    }

    return column.cellProps;
  }

  render() {
    const { data, column } = this.props;

    const display = typeof column.columnFieldData === "function" ? column.columnFieldData(data) : data[column.columnFieldData];

    let cellProps = this._getCellProps(data, column);

    if (cellProps !== undefined) {
      const { onClick, ...passThroughCellProps } = cellProps;

      cellProps = passThroughCellProps;
    }

    return (
      <TableCell
        {...cellProps}
        onClick={this._onCellClick}
      >
        {display}
      </TableCell>
    )
  }
}