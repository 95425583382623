import {
  React,
  bind
} from "$Imports/Imports";

import {
  NavigationService
} from "$State/NavigationFreezerService";

import {
  LoginService
} from "$State/LoginFreezerService";

import {
  IconButton,
  Avatar,
  Button,
  Menu,
  MenuItem,
  ListItemText,
  ListItemIcon
} from "$Imports/MaterialUIComponents";

import {
  Help,
  ExitToApp,
  Person,
  AccessTimeIcon
} from "$Imports/MaterialUIIcons";

import {
  ApplicationSecurityConsumer
} from "$Providers/.";

import {
  withStyles
} from "$Imports/MaterialUIStyles";

import {
  TimeZoneDialog
} from "$Components/Common/TimeZoneDialog";

import {
  TimeZoneConsumer
} from "$Providers/TimeZoneProvider";

import {
  ITimeZoneServiceInjectedProps,
  TimeZoneService
} from "$State/TimeZoneFreezerService";

import {
  ThemeConsumer
} from "$Providers/YaharaThemeProvider";

const styles: {
  userInformation: string;
  userName: string;
} = require("./UserInformation.scss");



interface IUserInformationState {
  anchorEl: Element | null;
  isOpen: boolean;
}

interface IUserInformationBaseProps {
}

type IUserInformationProps = IUserInformationBaseProps & ITimeZoneServiceInjectedProps;

export class _UserInformation extends React.Component<IUserInformationProps, IUserInformationState> {

  state: IUserInformationState = {
    anchorEl: null,
    isOpen: false,
  };

  @bind
  private _onClose(event: {}, reason: "backdropClick" | "escapeKeyDown") {
    this.setState({
      anchorEl: null,
      isOpen: false
    });
  }

  @bind
  private _onClick(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    this.setState({
      anchorEl: event.currentTarget,
      isOpen: true
    });
  }

  @bind
  private _onLogout(event: React.MouseEvent<HTMLLIElement, MouseEvent>) {
    LoginService.Logout();
    NavigationService.navigateTo("/");
  }

  @bind _onTZClick(event: React.MouseEvent<HTMLLIElement, MouseEvent>) {
    this.props.timeZoneService.updateDialogIsOpen(true);
    this.setState({ isOpen: false });
  }


  render() {
    const { anchorEl, isOpen } = this.state;

    return (
      <ThemeConsumer>
        {(themeContext) => {
          const StyledIconButton = withStyles(themeContext.themeConfig.userInformationPanel)(IconButton);
          return (<div
            className={styles.userInformation}
          >
            <StyledIconButton>
              <Help
                fontSize="large"
              />
            </StyledIconButton>
            <Button
              onClick={this._onClick}
            >
              <ApplicationSecurityConsumer>
                {(context) => {
                  const givenName = `${context!.FirstName} ${context!.LastName}`.trim();
                  const email = context!.EmailAddress;
                  let avatarLetter = "U";

                  if (givenName) {
                    avatarLetter = givenName.charAt(0);
                  }
                  return (
                    <>
                      <Avatar
                        style={{
                          height: 29,
                          width: 29,
                          backgroundColor: themeContext.themeConfig.avatarColor
                        }}
                      >
                        {avatarLetter}
                      </Avatar>
                      <div
                        className={styles.userName}
                        title={email}
                      >
                        {givenName}
                      </div>
                    </>
                  );
                }}
              </ApplicationSecurityConsumer>
            </Button>
            <Menu
              getContentAnchorEl={null}
              anchorEl={anchorEl}
              open={isOpen}
              onClose={this._onClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
            >
              <MenuItem
                disabled={true}
              >
                <ListItemIcon>
                  <Person />
                </ListItemIcon>
                <ListItemText primary="Profile" />
              </MenuItem>
              <MenuItem
                onClick={this._onTZClick}
              >
                <ListItemIcon>
                  <AccessTimeIcon />
                </ListItemIcon>
                <ListItemText primary="Change Time Zone" />
              </MenuItem>
              <MenuItem
                onClick={this._onLogout}
              >
                <ListItemIcon>
                  <ExitToApp />
                </ListItemIcon>
                <ListItemText primary="Logout" />
              </MenuItem>
            </Menu>
            <TimeZoneConsumer>
              {(context) => {
                return <TimeZoneDialog />;
              }}
            </TimeZoneConsumer>
          </div>);
        }
        }
      </ThemeConsumer>
    );
  }
}


export const UserInformation = TimeZoneService.inject(
  _UserInformation
);