import {
    React,
    _,
  } from "$Imports/Imports";
  
  import {
    JobViewModel,
    JobStep
  } from "$Generated/api";
  
  import {
    DataTable,
    DateFormatter,
    IDataTableColumn
  } from "$Components/Common";
  
  import {
    Button
  } from "$Imports/MaterialUIComponents";
  
  
  interface ILytxIndividualPacketProps {
    data: JobViewModel;
    resubmit: (messageId: string) => void;
  }
  
  export class LytxIndividualPacket extends React.PureComponent<ILytxIndividualPacketProps> {
  
  
    resubmitMessage(id: string | undefined) {
      if (id && this.props.resubmit) {
        this.props.resubmit(id);
      }
      else {
        throw new Error("MessageId undefined");
      }
    }

    resubmitAllMessages() {
        const data = this.getErroredData();

        data.forEach(dt => {
            this.resubmitMessage(dt.messageId);
        });
    }

    getErroredData() {
        const data = (this.props.data.steps || []).filter(x => x.resultStatus !== "Success" && (x.friendlyName === "DriveCamSubmissionResultDataProcessor" || x.friendlyName === "DOLAssignmentDataProcessor"));

        return data;
    }
  
    private readonly columns: IDataTableColumn<JobStep>[] = [
      {
        columnName: "resubmit",
        columnFieldData: (d) => {
          return d.stepNumber !== 1 ?
            (
              <Button
                variant="contained"
                color="primary"
                onClick={() => this.resubmitMessage(d.messageId)}
  
              >
                Resubmit
              </Button>
            ) : (<div />);
        },
        headerValue: "",
        cellProps: { style: { width: "100px" } }
      },
      {
        columnName: "name",
        columnFieldData: (d) => d.friendlyName ? d.friendlyName : "",
        headerValue: "Name",
        cellProps: { style: { width: "150px", overflowWrap: "break-word" } }
      },
      {
        columnName: "status",
        columnFieldData: (d) => d.resultStatus ? d.resultStatus : "",
        headerValue: "Status",
        cellProps: { style: { maxWidth: "80px", overflowWrap: "break-word" } }
      },
      {
        columnName: "time",
        columnFieldData: (d) => d.timestamp ? <DateFormatter value={d.timestamp} /> : "",
        headerValue: "Date/Time",
        cellProps: { style: { maxWidth: "80px", overflowWrap: "break-word" } }
      },
    ];
  
  
    render() {
  
      const data = this.getErroredData();
  
      return (
        <>
        {data.length > 0 && <div className="testClassOneTwoThree" style={{margin : 16}}>
            <Button
                variant="contained"
                color="primary"
                onClick={() => this.resubmitAllMessages()}
  
            >
                Resubmit All
            </Button>    
        </div>}
        <DataTable
          columns={this.columns}
          data={data}
        />
        </>
      );
    }
  }