import {
  React,
  bind,
  moment,
  _
} from "$Imports/Imports";

import {
  timeZoneType
} from "./TimeZoneType";

import {
  Dialog,
  Button,
  DialogContent,
  DialogContentText,
  MenuItem,
  Select,
  DialogActions,
  Checkbox,
  FormControlLabel
} from "$Imports/MaterialUIComponents";

import {
  TimeZoneService,
  ITimeZoneServiceInjectedProps
} from "$State/TimeZoneFreezerService";

interface ITimeZoneDialogBaseProps {
}

type ITimeZoneDialogProps = ITimeZoneDialogBaseProps & ITimeZoneServiceInjectedProps;

const timeZones = moment.tz.names();
const timeZoneListItems = _.map(timeZones, (timeZone) => {
  if (timeZone.substring(0, 3) === "US/" || timeZone.substring(0, 3) === "UTC") // Filter out unneeded time zones - Moment.tz.names returns a couple hundred
  {
    return <MenuItem key={timeZone} value={timeZone}>{timeZone}</MenuItem>;
  }
}
);

export class _TimeZoneDialog extends React.Component<ITimeZoneDialogProps> {


  @bind
  private _onSubmit(): void {
    this.props.timeZoneService.updateTimeZone(this.props.timeZoneService.getDialogTimeZone());
    this.props.timeZoneService.updateIsMilitaryTime(this.props.timeZoneService.getDialogIsMilitaryTime());
    this.props.timeZoneService.updateDialogIsOpen(false);
  }

  @bind
  private _onClose(): void {
    this.props.timeZoneService.updateDialogIsOpen(false);
    this.props.timeZoneService.updateDialogTimeZone(this.props.timeZoneService.getTimeZone());
    this.props.timeZoneService.updateDialogIsMilitaryTime(this.props.timeZoneService.getisMilitaryTime());
  }

  @bind
  public handleTimeZoneChange(event: React.ChangeEvent<{ name?: string; value: unknown }>): void {
    this.props.timeZoneService.updateDialogTimeZone(event.target.value as timeZoneType);
  }

  @bind 
  public handleMilitaryTimeChange(event: React.ChangeEvent<{ name?: string; checked: unknown }>) : void {
    this.props.timeZoneService.updateDialogIsMilitaryTime(event.target.checked as boolean)
  }

  public render(): JSX.Element {

    return (
      <Dialog
        open={this.props.timeZoneService.getDialogIsOpen()}>
        <DialogContent>
          <DialogContentText>Select a time zone</DialogContentText>
          <Select
            value={this.props.timeZoneService.getDialogTimeZone()}
            onChange={this.handleTimeZoneChange}
          >
            {timeZoneListItems}
          </Select>
          <br /><br />
          <DialogContentText>Military Time</DialogContentText>
          <FormControlLabel
            control={<Checkbox checked={this.props.timeZoneService.getDialogIsMilitaryTime()} onChange={this.handleMilitaryTimeChange} />}
            label="Use 24 Hour Format" 
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={this._onSubmit}
            color="primary"
          >
            Save
          </Button>
          <Button
            onClick={this._onClose}
            color="primary"
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export const TimeZoneDialog = TimeZoneService.inject(
  _TimeZoneDialog
);