import {
	React
} from "$Imports/Imports";

import { 
	ApplicationSecurityConsumer,
	securityContextHasRoles,
} from "$Providers/AuthenticationProvider";

interface IUserRoleLimitedControl {
	/** Array of user roles the user must have to see the children. */
	requiredUserRoles: string[];
}

/**
 * This wrapper checks for a user having specific roles before rendering the children.
 * If a user doesn't have the access specified, the children won't be rendered.
 */
export class UserRoleLimitedControl extends React.PureComponent<IUserRoleLimitedControl> {
	render() {
		const { requiredUserRoles} = this.props;
		
		return (
			<ApplicationSecurityConsumer>
				{ (securityContext) => {
					return (
						<>
							{ securityContextHasRoles(securityContext, requiredUserRoles) ? this.props.children : null }	
						</>
					)
				}}
			</ApplicationSecurityConsumer>
		)
	}
}