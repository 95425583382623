import {
  React,
  _,
  bind
} from "$Imports/Imports";
import { Button } from "$Imports/MaterialUIComponents";
import { AdvanceTextField } from "$Components/Common";
import { RoadnetMobileCredentials } from "$Generated/api";
import { ICredentialProps } from "$State/CredentialTypes";

interface IRoadnetMobileBaseProps {
}

type IRoadnetMobileProps = IRoadnetMobileBaseProps & ICredentialProps<RoadnetMobileCredentials>;

export class RoadnetMobile extends React.PureComponent<IRoadnetMobileProps>{


  @bind
  onChange(e: any): void {
    if (e.target.name === "subscriberId") {
      this.props.onChange(this.props.providerKey, { subscriberId: e.target.value });
    }
    else if (e.target.name === "username") {
      this.props.onChange(this.props.providerKey, { username: e.target.value });
    }
    else if (e.target.name === "password") {
      this.props.onChange(this.props.providerKey, { password: e.target.value });
    }
  }

  render(): JSX.Element {
    const roadnetMobile: RoadnetMobileCredentials = this.props.data ? this.props.data : {} as RoadnetMobileCredentials;
    return (
      <div>
        <AdvanceTextField
          label="Subscriber ID*"
          name="subscriberId"
          onChange={this.onChange}
          value={roadnetMobile.subscriberId ? roadnetMobile.subscriberId : ''}
          error={(roadnetMobile.subscriberId === "")}

        /><br />
        <AdvanceTextField
          label="Username*"
          name="username"
          onChange={this.onChange}
          value={roadnetMobile.username ? roadnetMobile.username : ''}
          error={(roadnetMobile.username === "")}
        /><br />
        <AdvanceTextField
          label="Password*"
          name="password"
          onChange={this.onChange}
          value={roadnetMobile.password ? roadnetMobile.password : ''}
          error={(roadnetMobile.password === "")}
        /><br />
        <Button className="float-right" disabled={true} color={"primary"}>VALIDATE CREDENTIALS</Button>{/* Disabled for as per LDM - 481 */}
      </div>
    );
  }
}
