import { AdvanceTextField } from "$Components/Common/AdvanceTextField";
import {
  React,
  bind,
  _
} from "$Imports/Imports";

import {
  Dialog,
  DialogActions,
  Button,
  DialogTitle,
  DialogContent,
  TextField
} from "$Imports/MaterialUIComponents";

import {
  TenantLytxEmailRecipientService,
  ITenantLytxEmailRecipientsInjectProps,
} from "$State/tenant-views/TenantEmailRecipientsFreezerService";

interface IEditEmailRecipientsDialogContentsBaseProps {
  onClose(): void;
  tenantId: string;
  submitEmailRecipients(): void;
  onChange(e: React.ChangeEvent<HTMLInputElement>): void;
}

type IEditEmailRecipientsDialogContentsProps = IEditEmailRecipientsDialogContentsBaseProps & ITenantLytxEmailRecipientsInjectProps;

class _EditCredentialsDialogContents extends React.Component<IEditEmailRecipientsDialogContentsProps> {

  @bind
  onCancel() {
    this.props.onClose();
  }

  @bind
  onSave() {
    this.props.submitEmailRecipients();
  }

  render(): JSX.Element {
    return (
      <div>
        {
          <Dialog open={true}>
          <DialogTitle>Edit Email Recipients</DialogTitle>
          <DialogContent>
          <div style={{width: '350px'}}>
            <div>Enter email addresses one per line.</div>
            <AdvanceTextField
              onChange={this.props.onChange}
              value={this.props.tenantEditEmailRecipientsService.getEmailsAsString()}
              rows={8}
              multiline
              fullWidth
            />
            {this.props.tenantEditEmailRecipientsService.freezer.get().validInput
              ? undefined :
              <div style={{ color: "red", display: "block", marginLeft: "auto", marginRight: "auto" }}>Invalid email address.</div>
            }
          </div>
        </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              onClick={this.onCancel}
            >
              Cancel
            </Button>
            <Button 
              color="primary"
              onClick={this.onSave}
            >
              Save
            </Button>
          </DialogActions>
        </Dialog>
        }
      </div>
    );
  }
}


export const EditEmailRecipientsDialogContents = TenantLytxEmailRecipientService.inject(
  _EditCredentialsDialogContents
);