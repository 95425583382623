import {
  React,
  _,
  bind
} from "$Imports/Imports";

import {
  Button,
  MenuItem,
  Select
} from "$Imports/MaterialUIComponents";

import { AdvanceTextField } from "$Components/Common";
import { ICredentialProps } from "$State/CredentialTypes";
import { AdminFtpCredentials } from "$Generated/api";

interface IAdminFtpBaseProps { }

type IAdminFtpProps = IAdminFtpBaseProps & ICredentialProps<AdminFtpCredentials>;

export class AdminFtp extends React.PureComponent<IAdminFtpProps> {
  @bind
  onChange(e: any) {
    if (e.target.name === "transferServiceType") {
      this.props.onChange(this.props.providerKey, { transferServiceType: e.target.value });
    }
    else if (e.target.name === "portNumber") {
      this.props.onChange(this.props.providerKey, { portNumber: Number(e.target.value) || 0 });
    }
    else if (e.target.name === "ftpAddress") {
      this.props.onChange(this.props.providerKey, { ftpAddress: e.target.value });
    }
    else if (e.target.name === "ftpUsername") {
      this.props.onChange(this.props.providerKey, { ftpUsername: e.target.value });
    }
    else if (e.target.name === "ftpPassword") {
      this.props.onChange(this.props.providerKey, { ftpPassword: e.target.value });
    }
  }

  render(): JSX.Element {
    const adminFtp: AdminFtpCredentials = this.props.data ? this.props.data : {} as AdminFtpCredentials;
    return (
      <div>
        <Select
          label="Transfer Service Type*"
          name="transferServiceType"
          onChange={this.onChange}
          value={adminFtp.transferServiceType}
          error={(adminFtp.transferServiceType === "")}
          fullWidth
        >
          <MenuItem key="SFtp" value="SFtp">SFtp</MenuItem>
          <MenuItem key="Ftp" value="Ftp">Ftp</MenuItem>
        </Select>
        <AdvanceTextField
          label="Port Number*"
          name="portNumber"
          onChange={this.onChange}
          value={adminFtp.portNumber}
          error={(adminFtp.portNumber === 0)}
        />
        <AdvanceTextField
          label="FTP Address*"
          name="ftpAddress"
          onChange={this.onChange}
          value={adminFtp.ftpAddress ? adminFtp.ftpAddress : ""}
          error={(adminFtp.ftpAddress === "")}
        />
        <AdvanceTextField
          label="FTP Username*"
          name="ftpUsername"
          onChange={this.onChange}
          value={adminFtp.ftpUsername ? adminFtp.ftpUsername : ""}
          error={(adminFtp.ftpUsername === "")}
        />
        <AdvanceTextField
          label="FTP Password*"
          name="ftpPassword"
          onChange={this.onChange}
          value={adminFtp.ftpPassword ? adminFtp.ftpPassword : ""}
          error={(adminFtp.ftpPassword === "")}
        />
        <br />
        <br />
        <Button className="float-right" disabled={true} color={"primary"}>VALIDATE CREDENTIALS</Button>{/* Disabled for as per LDM - 483 */}
      </div>
    );
  }
}