import {
  React,
  bind
} from "$Imports/Imports";

import {
  TenantSummaryViewModel
} from "$Generated/api";

import {
  Link
} from "$Imports/MaterialUIComponents";

interface ITenantNameLinkProp {
  data: TenantSummaryViewModel;
  onClick?: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>, data: TenantSummaryViewModel) => void;
}

export class TenantNameLink extends React.PureComponent<ITenantNameLinkProp> {

  @bind
  private _onClick(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
    event.preventDefault();
    if (this.props.onClick) {
      this.props.onClick(event, this.props.data);
    }
  }
  render() {
    const { data } = this.props;

    return (
      <Link
        href=""
        onClick={this._onClick}
        onAuxClick={this._onClick}
      >
        {data.tenantFriendlyName}
      </Link>
    );
  }
}
