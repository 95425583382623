import {
  FreezerService,
  managedAjaxUtil,
  _,
  bind,
  IAjaxState
} from "$Imports/Imports";

import {
  ClientApiFactory,
  NewTenantViewModel,
  NewTenantViewModelFrequencyEnum,
  StringWorkflowDefinitionTemplateViewModelIDictionaryResponseBase,
  TenantApiFactory,
  TenantViewModelResponseBase
} from "$Generated/api";

import { credentialType } from "$State/CredentialTypes";

import { ErrorService } from "../ErrorFreezerService";
import { SitePubSubManager } from "$Utilities/PubSubUtil";
import { ClientIdsEnum } from "$Utilities/enums";

const InjectedPropName = "tenantAddService";

export type viewType = "Add" | "None";

export interface INewTenant {
  clientId: number;
  name: string;
  adapterFriendlyName: string;
  adapterId: number;
  frequency: NewTenantViewModelFrequencyEnum;
  sourceData: credentialType;
  submissionProviderId: number;
  submissionData: credentialType;
  workflowTemplateName: string;
  emails: string;
}

export interface IClientListItem {
  id: number;
  name: string;
}

interface ITenantAddState {
  addDialogViewState: viewType;
  snackbarStatus: boolean;
  newTenantModel: INewTenant;
  createTenantResult: IAjaxState<TenantViewModelResponseBase>;
  workflowTemplatesResult: IAjaxState<StringWorkflowDefinitionTemplateViewModelIDictionaryResponseBase>;
}

const blankTenant: INewTenant = {
  clientId: 0,
  name: "",
  adapterFriendlyName: "",
  adapterId: 0,
  frequency: "EveryHour",
  sourceData: {} as credentialType,
  submissionProviderId: 0,
  submissionData: {} as credentialType,
  workflowTemplateName: "",
  emails: ""
}

class TenantAddFreezerService extends FreezerService<ITenantAddState, typeof InjectedPropName> {
  constructor() {
    super({
      addDialogViewState: "None",
      snackbarStatus: false,
      newTenantModel: blankTenant,
      createTenantResult: managedAjaxUtil.createInitialState(),
      workflowTemplatesResult: managedAjaxUtil.createInitialState()
    }, InjectedPropName);

    SitePubSubManager.subscribe("application:login:before", this._resetWorkflowTemplates);
  }

  public openAddDialog() {
    this.freezer.get().set({
      addDialogViewState: "Add",
      newTenantModel: blankTenant
    });
  }

  public closeAddDialog() {
    this.freezer.get().set({
      addDialogViewState: "None",
      newTenantModel: blankTenant
    });

    this._resetWorkflowTemplates();
  }

  public onChange(change: Partial<INewTenant>) {
    // if client was changed, also reset selected adapter and submission data
    if (change.clientId !== undefined) {
      change.adapterId = blankTenant.adapterId;
      change.adapterFriendlyName = blankTenant.adapterFriendlyName;

      // Lytx: ensure an empty credentials array is available
      if (change.clientId === ClientIdsEnum.Lytx) {
        change.submissionData = { credentials: [] };
      }
      else {
        change.submissionData = {};
      }
    }

    var current = this.freezer.get().newTenantModel?.toJS();
    current = _.assign(current, change);

    this.freezer.get().set({
      newTenantModel: current
    });

    // If the client or adapter was changed, invalidate any source data captured.
    if (change.clientId ||
      (change.adapterFriendlyName && change.adapterId)) {
      this._resetSourceData();
    }
  }

  public async createTenant() {
    return managedAjaxUtil.fetchResults({
      freezer: this.freezer,
      ajaxStateProperty: "createTenantResult",
      onExecute: (apiOptions, params, options) => {
        const newTenantModel = this.freezer.get().newTenantModel;

        const newTenantViewModel: NewTenantViewModel = {
          clientId: newTenantModel.clientId.toString(),
          frequency: newTenantModel.frequency,
          sourceConfiguration: newTenantModel.sourceData,
          sourceProviderId: newTenantModel.adapterId,
          submissionConfiguration: newTenantModel.submissionData,
          submissionProviderId: newTenantModel.submissionProviderId,
          tenantName: newTenantModel.name,
          workflowDefinitionTemplateName: newTenantModel.workflowTemplateName,
          contactEmails: newTenantModel.emails.split("\n").map((e) => e.trim())
        };

        const tenantApi = TenantApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
        return tenantApi.apiV1TenantCreatePost({ body: newTenantViewModel });
      },
      onOk: (data: TenantViewModelResponseBase) => {
        if (data.success) {
          this.setSnackbarStatus(true);
          SitePubSubManager.publish("tenant:tenant-created", data.success);
        }
        // the message property is actually the Message property but typescript doesn't know that
        else if (data.error && (data.error as any)["Message"] == "Tenant name already in use") {
          ErrorService.pushErrorMessage("Tenant name already in use");
        }
        else {
          ErrorService.pushErrorMessage("Failed to create new tenant")
        }

        return data;
      },
      onError: () => {
        ErrorService.pushErrorMessage("Failed to create new tenant")
      }
    });
  }

  public setSnackbarStatus(status: boolean) {
    this.freezer.get().set({ snackbarStatus: status });
  }

  public onSourceDataChange(change: Partial<credentialType>) {
    var current = this.freezer.get().newTenantModel?.sourceData?.toJS();
    current = _.assign(current, change);
    this.freezer.get().newTenantModel.set({
      sourceData: current
    });
  }

  public onSubmissionDataChange(change: Partial<credentialType>) {
    var submissionData = this.freezer.get().newTenantModel?.submissionData?.toJS();
    submissionData = _.assign(submissionData, change);
    this.freezer.get().newTenantModel.set({
      submissionData: submissionData
    });
  }

  public async fetchWorkflowTemplates(clientId: number) {
    return managedAjaxUtil.fetchResults({
      ajaxStateProperty: "workflowTemplatesResult",
      freezer: this.freezer,
      onExecute: (apiOptions, params, options) => {
        const clientApiFactory = ClientApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
        return clientApiFactory.apiV1ClientGetWorkflowTemplatesClientIdGet(params);
      },
      onError: () => {
        ErrorService.pushErrorMessage("Failed to retrieve workflow templates")
      },
      params: {
        clientId
      }
    });
  }

  public setSubmissionProviderId(submissionProviderId: number) {
    this.freezer.get().newTenantModel.set({
      submissionProviderId: submissionProviderId
    });
  }

  public setWorkflowTemplate(workflowTemplate: string) {
    this.freezer.get().newTenantModel.set({
      workflowTemplateName: workflowTemplate
    });
  }

  @bind
  private _resetSourceData() {
    this.freezer.get().newTenantModel.set({
      sourceData: {}
    });
  }

  @bind
  private _resetWorkflowTemplates() {
    this.freezer.get().set({
      workflowTemplatesResult: managedAjaxUtil.createInitialState()
    })
  }
}

export const TenantAddService = new TenantAddFreezerService();
export type ITenantAddServiceInjectedProps = ReturnType<TenantAddFreezerService["getPropsForInjection"]>;
