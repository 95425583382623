import {
  React
} from "$Imports/Imports";

import {
  joinClasses
} from "$Utilities/css";

const styles: {
  mainContainer: string;
  countContainer: string;
  labelContainer: string;
} = require("./TenantSummaryPanel.scss");

interface ITenantSummaryPanelProps extends React.HTMLProps<HTMLDivElement> {
  count: string | number;
  countClassName?: string;
  countLabel: string | number;
  countLabelClassName?: string;
}

export class TenantSummaryPanel extends React.PureComponent<ITenantSummaryPanelProps> {
  render() {
    const {
      count,
      className,
      countClassName,
      countLabel,
      countLabelClassName,
      inputMode,
      ...passthroughProperties
    } = this.props;

    return (
      <div
        className={joinClasses([styles.mainContainer, className])}
        {...passthroughProperties}
      >
        <div
          className={joinClasses([styles.countContainer, countClassName])}
        >
          {count}
        </div>
        <div
          className={joinClasses([styles.labelContainer, countLabelClassName])}
        >
          {countLabel}
        </div>
      </div>
    );
  }
}
