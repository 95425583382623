import {
  React,
  bind
} from "$Imports/Imports";

import {
  timeZoneType
} from "$Components/Common";

import {
  TimeZoneService,
  ITimeZoneServiceInjectedProps
} from "$State/TimeZoneFreezerService";

interface ITimeZoneContext {
  timeZone: timeZoneType;
  updateTimeZone: (timeZone: timeZoneType) => void;
  isMilitaryTime: boolean;
  updateIsMilitaryTime: (isMilitaryTime: boolean) => void
}

const defaultTimeZone: ITimeZoneContext = {
  timeZone: "UTC",
  updateTimeZone: () => { },
  isMilitaryTime: false,
  updateIsMilitaryTime: ()=> {},
};

const TimeZoneContext = React.createContext(defaultTimeZone);

const TimeZoneConsumer = TimeZoneContext.Consumer;

interface ITimeZoneProviderBaseProps { }

type ITimeZoneProviderProps = ITimeZoneProviderBaseProps & ITimeZoneServiceInjectedProps;

interface ITimezoneProviderState {
  timeZoneContext: ITimeZoneContext;
}

class _TimeZoneProvider extends React.Component<ITimeZoneProviderProps, ITimezoneProviderState> {

  @bind 
  private updateTimeZone(timeZone: timeZoneType) {
    this.props.timeZoneService.updateTimeZone(timeZone);
  }

  @bind 
  private updateIsMilitaryTime(isMilitaryTime: boolean) {
    this.props.timeZoneService.updateIsMilitaryTime(isMilitaryTime);
  }

  render() {
    return (
      <TimeZoneContext.Provider
        value={{ timeZone: this.props.timeZoneService.getTimeZone(), updateTimeZone: this.updateTimeZone,
                  isMilitaryTime: this.props.timeZoneService.getisMilitaryTime(), updateIsMilitaryTime: this.updateIsMilitaryTime,
        }}
      >
        {this.props.children}
      </TimeZoneContext.Provider>
    );
  }
}

export {
  TimeZoneConsumer,
  TimeZoneContext
};

export const TimeZoneProvider = TimeZoneService.inject(
  _TimeZoneProvider
);
