import {
  FreezerService,
  IAjaxState,
  managedAjaxUtil,
  _,
  bind,
} from "$Imports/Imports";

import {
  ISecurityContext
} from '$Providers/index';

import {
  ActivityApiFactory,
  AssociatedEntitiesApiFactory,
  JobViewModelResponseBase,
  AssociatedEntityViewModelIEnumerablePagedResponseBase,
  ResponseBase,
  ChildJobViewModel
} from "$Generated/api";

import {
  tenantViews,
  ITenantViewItem
} from "./../job-detail-view/tenant-views";

import {
  ErrorService
} from "./ErrorFreezerService";

import {
  IPagerState
} from "./PagerPagingState";

import {
  SitePubSubManager
} from "$Utilities/PubSubUtil";

const InjectedPropName = "jobDetailService";

interface IJobDetailFreezerState {
  jobId: string | null;
  tenantId: string | null;
  jobDetailResults: IAjaxState<JobViewModelResponseBase>;
  resubmissionResults: IAjaxState<ResponseBase>;
  tenantViewResults: IAjaxState<AssociatedEntityViewModelIEnumerablePagedResponseBase>;
  childJobs: ChildJobViewModel[];
  resubmitResponse: string;
  tenantName: string;
  selectedTenantView: ITenantViewItem | null;
}

const defaultPagerState: IPagerState = {
  page: 0,
  rowsPerPage: 50,
};

class JobDetailFreezerService extends FreezerService<IJobDetailFreezerState, typeof InjectedPropName> {
  constructor() {
    super({
      jobId: null,
      tenantId: null,
      jobDetailResults: managedAjaxUtil.createInitialState(),
      resubmissionResults: managedAjaxUtil.createInitialState(),
      tenantViewResults: managedAjaxUtil.createInitialState(),
      resubmitResponse: "",
      tenantName: "",
      selectedTenantView: null,
      childJobs: [],
    }, InjectedPropName);

    SitePubSubManager.subscribe("application:login:before", this.clearResults);
  }

  @bind
  private clearResults() {
    this.freezer.get().set({
      jobId: null,
      tenantId: null,
      jobDetailResults: managedAjaxUtil.createInitialState(),
      tenantViewResults: managedAjaxUtil.createInitialState(),
      resubmissionResults: managedAjaxUtil.createInitialState(),
      childJobs: [],
      selectedTenantView: null,
    });
  }

  public setJobId(jobId: string | null) {

    const currentJobId = this.freezer.get().jobId;

    this.freezer.get().set({
      jobId,
      jobDetailResults: managedAjaxUtil.createInitialState(),
      tenantViewResults: managedAjaxUtil.createInitialState(),
      selectedTenantView: null,
    });

    if (currentJobId !== jobId) {
      SitePubSubManager.publish("job-detail:job-id-change", jobId);
    }
  }

  public setTenantView(view: ITenantViewItem | null) {
    this.freezer.get().set({
      selectedTenantView: view
    });
  }

  public async fetchJobDetail(forceUpdate: boolean = false) {
    const results = this.freezer.get().jobDetailResults;
    const jobId = this.freezer.get().jobId;

    if (results.hasFetched && !forceUpdate) {
      return;
    }

    return managedAjaxUtil.fetchResults({
      ajaxStateProperty: "jobDetailResults",
      freezer: this.freezer,
      onExecute: (apiOptions, params, options) => {
        const activityFactory = ActivityApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
        return activityFactory.apiV1ActivityIdGet(params);
      },
      params: {
        id: jobId ? jobId : "",
      },
      onOk: (data: JobViewModelResponseBase) => {

        let childJobs: ChildJobViewModel[] = [];

        if (data.success) {
          childJobs = data.data?.childJobs || [];

          if (data.data) {
            const parentJob: ChildJobViewModel = {
              endDate: data.data.endDate,
              jobId: data.data.jobId,
              jobStatus: data.data.jobStatus,
              relatedJobId: data.data.relatedJobId,
              startDate: data.data.startDate,
            };

            childJobs.push(parentJob);
          }
        }

        this.freezer.get().set({
          childJobs,
          tenantId: data.data?.tenant?.id,
        });

        this.fetchTenantViews();
      },
      onError: (err, errorMessage) => {
        ErrorService.pushErrorMessage("Failed to retrieve job detail data from the server.");
      }
    });
  }

  public async fetchTenantViews(forceUpdate: boolean = false) {
    if (this.freezer.get().tenantViewResults.hasFetched && !forceUpdate) {
      return;
    }

    const tenantId = this.freezer.get().tenantId;

    if (tenantId === null) {
      return;
    }

    managedAjaxUtil.fetchResults({
      freezer: this.freezer,
      ajaxStateProperty: "tenantViewResults",
      onExecute: (apiOptions, params, option) => {
        const associatedEntitiesApi = AssociatedEntitiesApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
        return associatedEntitiesApi.apiV1AssociatedEntitiesGetAssociatedEntitiesGet(params);
      },
      params: {
        categoryTypeId: 6, // Tenant Views
        entityId: tenantId,
      }
    });
  }

  public async resubmitJob() {
    managedAjaxUtil.fetchResults({
      freezer: this.freezer,
      ajaxStateProperty: "resubmissionResults",
      onExecute: (apiOptions, params, options) => {
        const jobId = this.freezer.get().jobId;
        if (jobId) {
          return ActivityApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl).apiV1ActivityResubmitJobPost({ jobId });
        }
        throw new Error("Job Id Undefined");
      },
      onOk: (data: ResponseBase) => {
        const success = data.success;
        if (success) {
          this.freezer.get().set({ resubmitResponse: "You have successfully resubmitted the job. It may take a few minutes to begin processing." });
        } else {
          this.freezer.get().set({ resubmitResponse: "Failed to resubmit job" });
        }
      },
      onError: (err, errorMessage) => {
        this.freezer.get().set({ resubmitResponse: "Failed to resubmit job" });
      },
    });
  }

  public getResubmitResponse(): string {
    return this.freezer.get().resubmitResponse;
  }

  public setResubmitResponse(open: string) {
    this.freezer.get().set({ resubmitResponse: open });
  }

  public setTenantName(name: string) {
    this.freezer.get().set({ tenantName: name });
  }
}

export const JobDetailService = new JobDetailFreezerService();
export type IJobDetailServiceInjectedProps = ReturnType<JobDetailFreezerService["getPropsForInjection"]>;
