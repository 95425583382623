import {
    React,
    bind
  } from "$Imports/Imports";
  
  import {
    LytxIndividualPacket
  } from "./LytxIndividualPacket";
  
  import {
    ITenantViewAdminMessageResubmissionServiceInjectedProps,
    TenantViewAdminMessageResubmissionService
  } from "$State/job-views/AdminMessageResubmissionFreezerService";
  
  import {
    ITenantViewProps
  } from "./../TenantViewProps";
  import { AjaxActionIndicator } from "$Components/Common";
  
  interface ILytxIndividualPacketViewBaseProps {
  
  }
  
  type ILytxIndividualPacketViewProps = ILytxIndividualPacketViewBaseProps
  & ITenantViewAdminMessageResubmissionServiceInjectedProps
  & ITenantViewProps;
  
  class _LytxIndividualPacketView extends React.Component<ILytxIndividualPacketViewProps> {
  
    componentDidMount() {
      if (this.props.job !== null && this.props.job.id !== undefined) {
        this.props.tenantViewAdminMessageResubmissionService.setJobDetail(this.props.job);
      }
    }
  
    @bind
    private _resubmitMessage(messageId: string) {
      this.props.tenantViewAdminMessageResubmissionService.resubmitMessage(messageId);
    }
  
    render() {
      const {
        jobDetail,
        messageResubmissionResults
      } = this.props.tenantViewAdminMessageResubmissionService.getState();
  
  
      return (
        <>
          <AjaxActionIndicator
            state={[messageResubmissionResults]}
          />
          <LytxIndividualPacket
            resubmit={this._resubmitMessage}
            data={jobDetail || {}}
          />
        </>
      );
    }
  }
  
  
  export const LytxIndividualPacketView = TenantViewAdminMessageResubmissionService.inject(
    _LytxIndividualPacketView
  );