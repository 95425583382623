import {
  React
} from "$Imports/Imports";

import {
  SvgIconProps
} from "$Imports/MaterialUIComponents";

import {
  HelpOutline
} from "$Imports/MaterialUIIcons";

import {
  joinClasses
} from "$Utilities/css";

const styles: {
  mainIcon: string;
} = require("./UnknownIndicator.scss");

export class UnknownIndicator extends React.PureComponent<SvgIconProps> {
  render() {
    const { className, ...passthroughProps } = this.props;

    return (
      <HelpOutline
        className={joinClasses([styles.mainIcon, className])}
        {...passthroughProps}
      />
    );
  }
}
