import {
  React,
  _,
} from "$Imports/Imports";

import {
  ITenantViewProps
} from "../TenantViewProps";

import {
  ISamsaraHosErrorReportFreezerServiceInjectedProps,
  SamsaraHosErrorReportService
} from "$State/job-views/SamsaraHosErrorReportFreezerService";

import {
  AjaxActionIndicator
} from "$Components/Common/AjaxActionIndicator";

import {
  Snackbar,
} from "@material-ui/core";

import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "$Imports/MaterialUIComponents";

import {
  DataTable,
  IDataTableColumn,
  DateFormatter,
} from "$Components/Common";

import { 
  SamsaraHosExportError,
  SamsaraHosExportSubmissionResult,
} from "$Generated/api";

interface ISamsaraHosErrorReportViewBaseProps {

}

type ISamsaraHosErrorReportViewProps = ISamsaraHosErrorReportViewBaseProps
  & ITenantViewProps
  & ISamsaraHosErrorReportFreezerServiceInjectedProps;

class _SamsaraHosErrorReportView extends React.Component<ISamsaraHosErrorReportViewProps> {
  state = {
    selectedSubmission: {
      exportErrors: undefined,
      exportResultDateTime: Date,
    },
  }

  private readonly columns: IDataTableColumn<SamsaraHosExportError>[] = [
    {
      columnName: "driverId",
      columnFieldData: (x) => x.driverId ?? "",
      sortMethod: (x) => (x.driverId ?? "").toLowerCase(),
      headerValue: "Driver Id",
      headerProps: {}
    },
    {
      columnName: "vehicleId",
      columnFieldData: (x) => x.vehicleId ?? "",
      sortMethod: (x) => (x.vehicleId ?? "").toLowerCase(),
      headerValue: "Vehicle Id",
      headerProps: {}
    },
    {
      columnName: "error",
      columnFieldData: (x) => x.error ?? "",
      sortMethod: (x) => (x.error ?? "").toLowerCase(),
      headerValue: "Error",
      headerProps: {}
    }
  ]

  componentDidMount() {
    if (this.props.job !== null && this.props.job.id !== undefined) {
      this.props.samsaraHosErrorReport.setJobId(this.props.job.id);
      this._initialize();
    }
  }

  // need to wait for fetchHosErrors to return to set the default selection
  private async _initialize() {
    await this.props.samsaraHosErrorReport.fetchHosErrors();
    const { hosErrorResults } = this.props.samsaraHosErrorReport.getState();
    const submissions = hosErrorResults.data?.success ? hosErrorResults?.data?.data ?? [] : [];
    if (submissions.length > 0)
    {
      this.setState({
        selectedSubmission: submissions[0],
      })
    }
  }

  private _getSubmissionFromDateString(submissionDateString: string): SamsaraHosExportSubmissionResult | undefined {
    const { hosErrorResults } = this.props.samsaraHosErrorReport.getState();
    const submissions = hosErrorResults.data?.success ? hosErrorResults?.data?.data ?? [] : [];

    for (const submission of submissions) {
      if (submission.exportResultDateTime?.toString() === submissionDateString) {
        return submission;
      }
    }
  }

  private changeSubmissionSelection = (e: React.ChangeEvent<{ name?: string | undefined; value: unknown; }>) => {
    this.setState({
      selectedSubmission: this._getSubmissionFromDateString(e.target.value as string),
    })
  }

  render() {
    const { hosErrorResults } = this.props.samsaraHosErrorReport.getState();
    const submissions = hosErrorResults.data?.success ? hosErrorResults?.data?.data ?? [] : [];

    return (
      <>
        <AjaxActionIndicator state={[hosErrorResults]} />
        <div style={{paddingLeft: 15}}>
          <FormControl>
            <InputLabel>Submission</InputLabel>
            <Select
              value={this.state.selectedSubmission.exportResultDateTime?.toString()}
              onChange={this.changeSubmissionSelection}
            >
              {_.map(submissions, (sub) => (
                <MenuItem value={sub.exportResultDateTime?.toString()}>
                  <DateFormatter value={sub.exportResultDateTime}/>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <DataTable
          columns={this.columns}
          data={this.state.selectedSubmission.exportErrors ?? []}
        />
        <Snackbar
          open={hosErrorResults.hasFetched && !hosErrorResults.data?.success}
          message={"Error loading data from server"}
          autoHideDuration={5000}
          anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
        />
      </>
    );
  }
}

export const SamsaraHosErrorReportView = SamsaraHosErrorReportService.inject(
  _SamsaraHosErrorReportView
);