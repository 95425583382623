import {
  IAdapterProviderMap
} from "./IAdapterProviderMap";

import {
  _
} from "$Imports/Imports";

// Providers
import * as SourceProviders from "./Source";
import * as SubmissionProviders from "./Submission";

import {
  ISAACInstrumentsCredentials, LytxCredentials,
} from "$Generated/api";

import { credentialType } from "$State/CredentialTypes";

export const AdapterComponentMapping: IAdapterProviderMap[] = [
  {
    categoryTypeId: 4,
    categoryItemId: 13,
    componentClass: SourceProviders.GeoTab,
    title: "Geotab Provider",
    loadCredentials: (tenantId: string, configurationApiFactory: any) => configurationApiFactory.apiV1ConfigurationGeotabLoadTenantIdGet({ tenantId }),
    saveCredentials: (tenantId: string, credentials: credentialType, configurationApiFactory: any) => configurationApiFactory.apiV1ConfigurationGeotabSavePost(_.assign({ tenantId }, credentials)),
  },
  {
    categoryTypeId: 5,
    categoryItemId: 14,
    componentClass: SubmissionProviders.SmartDrive,
    title: "SmartDrive Submission",
    loadCredentials: (tenantId: string, configurationApiFactory: any) => configurationApiFactory.apiV1ConfigurationSmartDriveLoadTenantIdGet({ tenantId: tenantId }),
    saveCredentials: (tenantId: string, credentials: credentialType, configurationApiFactory: any) => configurationApiFactory.apiV1ConfigurationSmartDriveSavePost(_.assign({ tenantId: tenantId }, credentials))
  },
  {
    categoryTypeId: 4,
    categoryItemId: 15,
    componentClass: SourceProviders.Omnitracs,
    title: "Omnitracs Provider",
    loadCredentials: (tenantId: string, configurationApiFactory: any) => configurationApiFactory.apiV1ConfigurationOmnitracsLoadTenantIdGet({ tenantId }),
    saveCredentials: (tenantId: string, credentials: credentialType, configurationApiFactory: any) => configurationApiFactory.apiV1ConfigurationOmnitracsSavePost(_.assign({ tenantId: tenantId }, credentials))
  },
  {
    categoryTypeId: 4,
    categoryItemId: 46,
    componentClass: SourceProviders.OmnitracsAuthLogs,
    title: "Omnitrac's Auth Logs Provider",
    loadCredentials: (tenantId: string, configurationApiFactory: any) => configurationApiFactory.apiV1ConfigurationOmnitracsAuthLogsTenantIdGet({ tenantId }),
    saveCredentials: (tenantId: string, credentials: credentialType, configurationApiFactory: any) => configurationApiFactory.apiV1ConfigurationOmnitracsAuthLogsSavePost(_.assign({ tenantId: tenantId }, credentials))
  },
  {
    categoryTypeId: 4,
    categoryItemId: 17,
    componentClass: SourceProviders.PeopleNet,
    title: "PeopleNet Provider",
    loadCredentials: (tenantId: string, configurationApiFactory: any) => configurationApiFactory.apiV1ConfigurationPeoplenetLoadTenantIdGet({ tenantId }),
    saveCredentials: (tenantId: string, credentials: credentialType, configurationApiFactory: any) => configurationApiFactory.apiV1ConfigurationPeoplenetSavePost(_.assign({ tenantId: tenantId }, credentials))
  },
  {
    categoryTypeId: 4,
    categoryItemId: 19,
    componentClass: SourceProviders.Drivertech,
    title: "Driver Tech Provider",
    loadCredentials: (tenantId: string, configurationApiFactory: any) => configurationApiFactory.apiV1ConfigurationDrivertechLoadTenantIdGet({ tenantId }),
    saveCredentials: (tenantId: string, credentials: credentialType, configurationApiFactory: any) => configurationApiFactory.apiV1ConfigurationDrivertechSavePost(_.assign({ tenantId: tenantId }, credentials))
  },
  {
    categoryTypeId: 4,
    categoryItemId: 20,
    componentClass: SourceProviders.JJKeller,
    title: "JJ Keller",
    loadCredentials: (tenantId: string, configurationApiFactory: any) => configurationApiFactory.apiV1ConfigurationJjkellerLoadTenantIdGet({ tenantId }),
    saveCredentials: (tenantId: string, credentials: credentialType, configurationApiFactory: any) => configurationApiFactory.apiV1ConfigurationJjkellerSavePost(_.assign(credentials, { tenantId: tenantId }))
  },
  {
    categoryTypeId: 4,
    categoryItemId: 82,
    componentClass: SourceProviders.Reveal,
    title: "Reveal Provider",
    loadCredentials: (tenantId: string, configurationApiFactory: any) => configurationApiFactory.apiV1ConfigurationRevealTenantIdGet({ tenantId }),
    saveCredentials: (tenantId: string, credentials: credentialType, configurationApiFactory: any) => configurationApiFactory.apiV1ConfigurationRevealSavePost(_.assign({ tenantId: tenantId }, credentials))
  },
  {
    categoryTypeId: 4,
    categoryItemId: 23,
    componentClass: SourceProviders.Telogis,
    title: "Telogis Provider",
    loadCredentials: (tenantId: string, configurationApiFactory: any) => configurationApiFactory.apiV1ConfigurationTelogisLoadTenantIdGet({ tenantId }),
    saveCredentials: (tenantId: string, credentials: credentialType, configurationApiFactory: any) => configurationApiFactory.apiV1ConfigurationTelogisSavePost(_.assign({ tenantId: tenantId }, credentials))
  },
  {
    categoryTypeId: 4,
    categoryItemId: 22,
    componentClass: SourceProviders.Teletrac,
    title: "Teletrac Provider",
    loadCredentials: (tenantId: string, configurationApiFactory: any) => configurationApiFactory.apiV1ConfigurationTeletracTenantIdGet({ tenantId }),
    saveCredentials: (tenantId: string, credentials: credentialType, configurationApiFactory: any) => configurationApiFactory.apiV1ConfigurationTeletracSavePost(_.assign({ tenantId: tenantId }, credentials))
  },
  {
    categoryTypeId: 5,
    categoryItemId: 34,
    componentClass: SubmissionProviders.Netradyne,
    title: "Netradyne submission",
    loadCredentials: (tenantId: string, configurationApiFactory: any) => configurationApiFactory.apiV1ConfigurationNetradyneTenantIdGet({ tenantId }),
    saveCredentials: (tenantId: string, credentials: credentialType, configurationApiFactory: any) => configurationApiFactory.apiV1ConfigurationNetradyneSavePost(_.assign({ tenantId: tenantId }, credentials))
  },
  {
    categoryTypeId: 5,
    categoryItemId: 79,
    title: "Samsara Submission",
    componentClass: SubmissionProviders.Samsara,
    loadCredentials: (tenantId: string, configurationApiFactory: any) => configurationApiFactory.apiV1ConfigurationSamsaraTenantIdGet({ tenantId }),
    saveCredentials: (tenantId: string, credentials: credentialType, configurationApiFactory: any) => configurationApiFactory.apiV1ConfigurationSamsaraSavePost(_.assign({ tenantId: tenantId }, credentials))
  },
  {
    categoryTypeId: 5,
    categoryItemId: 35,
    componentClass: SubmissionProviders.Lytx,
    title: "Lytx submission",
    loadCredentials: (tenantId: string, configurationApiFactory: any) => configurationApiFactory.apiV1ConfigurationLytxTenantIdGet({ tenantId }),
    saveCredentials: (tenantId: string, credentials: credentialType, configurationApiFactory: any) => {
      const lytxCredentials = credentials as LytxCredentials;
      return configurationApiFactory.apiV1ConfigurationLytxSavePost(_.assign({ tenantId: tenantId, body: lytxCredentials.credentials },));
    }
  },
  {
    categoryTypeId: 4,
    categoryItemId: 31,
    componentClass: SourceProviders.Shaw,
    title: "Shaw Provider",
    loadCredentials: (tenantId: string, configurationApiFactory: any) => configurationApiFactory.apiV1ConfigurationShawTenantIdGet({ tenantId }),
    saveCredentials: (tenantId: string, credentials: credentialType, configurationApiFactory: any) => configurationApiFactory.apiV1ConfigurationShawSavePost(_.assign({ tenantId }, credentials))
  },
  {
    categoryTypeId: 4,
    categoryItemId: 24,
    componentClass: SourceProviders.ISAACInstruments,
    title: "ISAACInstruments Provider",
    loadCredentials: (tenantId: string, configurationApiFactory: any) => configurationApiFactory.apiV1ConfigurationISAACInstrumentsTenantIdGet({ tenantId }),
    saveCredentials: (tenantId: string, credentials: credentialType, configurationApiFactory: any) => {

      // ISAAC has a nested internal object so needs to be handled special.
      const isaacCredentials = credentials as ISAACInstrumentsCredentials;

      if (isaacCredentials.authentication) {
        const saveObject = { "authenticationUsername": isaacCredentials.authentication.username, "authenticationPassword": isaacCredentials.authentication.password, "authenticationGrantType": isaacCredentials.authentication.grantType, "basicToken": isaacCredentials.basicToken, "hostUrl": isaacCredentials.hostUrl, "tenantId": tenantId };
        return configurationApiFactory.apiV1ConfigurationISAACInstrumentsSavePost(saveObject);
      }

      throw new Error("No Authentication Details");
    }
  },
  {
    categoryTypeId: 4,
    categoryItemId: 25,
    componentClass: SourceProviders.BigRoad,
    title: "BigRoad Provider",
    loadCredentials: (tenantId: string, configurationApiFactory: any) => configurationApiFactory.apiV1ConfigurationBigRoadTenantIdGet({ tenantId }),
    saveCredentials: (tenantId: string, credentials: credentialType, configurationApiFactory: any) => configurationApiFactory.apiV1ConfigurationBigRoadSavePost(_.assign({ tenantId: tenantId }, credentials))
  },
  {
    categoryTypeId: 4,
    categoryItemId: 38,
    componentClass: SourceProviders.Zonar,
    title: "Zonar Provider",
    loadCredentials: (tenantId: string, configurationApiFactory: any) => configurationApiFactory.apiV1ConfigurationZonarTenantIdGet({ tenantId }),
    saveCredentials: (tenantId: string, credentials: credentialType, configurationApiFactory: any) => configurationApiFactory.apiV1ConfigurationZonarSavePost(_.assign({ tenantId: tenantId }, credentials))
  },
  {
    categoryTypeId: 4,
    categoryItemId: 27,
    componentClass: SourceProviders.SFTP,
    title: "SFTP Provider",
    loadCredentials: (tenantId: string, configurationApiFactory: any) => configurationApiFactory.apiV1ConfigurationSFTPTenantIdGet({ tenantId }),
    saveCredentials: (tenantId: string, credentials: credentialType, configurationApiFactory: any) => configurationApiFactory.apiV1ConfigurationSFTPSavePost(_.assign({ tenantId: tenantId }, credentials))
  },
  {
    categoryTypeId: 4,
    categoryItemId: 39,
    componentClass: SourceProviders.BlueTree,
    title: "BlueTree Provider",
    loadCredentials: (tenantId: string, configurationApiFactory: any) => configurationApiFactory.apiV1ConfigurationBlueTreeTenantIdGet({ tenantId }),
    saveCredentials: (tenantId: string, credentials: credentialType, configurationApiFactory: any) => configurationApiFactory.apiV1ConfigurationBlueTreeSavePost(_.assign({ tenantId: tenantId }, credentials))
  },
  {
    categoryTypeId: 4,
    categoryItemId: 26,
    componentClass: SourceProviders.CADEC,
    title: "CADEC Provider",
    loadCredentials: (tenantId: string, configurationApiFactory: any) => configurationApiFactory.apiV1ConfigurationCADECTenantIdGet({ tenantId }),
    saveCredentials: (tenantId: string, credentials: credentialType, configurationApiFactory: any) => configurationApiFactory.apiV1ConfigurationCADECSavePost(_.assign({ tenantId: tenantId }, credentials))
  },
  {
    categoryTypeId: 4,
    categoryItemId: 92,
    componentClass: SourceProviders.DigitalFleet,
    title: "DigitalFleet Provider",
    loadCredentials: (tenantId: string, configurationApiFactory: any) => configurationApiFactory.apiV1ConfigurationDigitalFleetTenantIdGet({ tenantId }),
    saveCredentials: (tenantId: string, credentials: credentialType, configurationApiFactory: any) => configurationApiFactory.apiV1ConfigurationDigitalFleetSavePost(_.assign({ tenantId: tenantId }, credentials))
  },
  {
    categoryTypeId: 4,
    categoryItemId: 43,
    componentClass: SourceProviders.Samsara,
    title: "Samsara Provider",
    loadCredentials: (tenantId: string, configurationApiFactory: any) => configurationApiFactory.apiV1ConfigurationSamsaraTenantIdGet({ tenantId }),
    saveCredentials: (tenantId: string, credentials: credentialType, configurationApiFactory: any) => configurationApiFactory.apiV1ConfigurationSamsaraSavePost(_.assign({ tenantId: tenantId }, credentials))
  },
  {
    categoryTypeId: 4,
    categoryItemId: 16,
    componentClass: SourceProviders.OmnitracsXRS,
    title: "OmnitracsXRS Provider",
    loadCredentials: (tenantId: string, configurationApiFactory: any) => configurationApiFactory.apiV1ConfigurationOmnitracsXRSLoadTenantIdGet({ tenantId }),
    saveCredentials: (tenantId: string, credentials: credentialType, configurationApiFactory: any) => configurationApiFactory.apiV1ConfigurationOmnitracsXRSSavePost(_.assign({ tenantId: tenantId }, credentials))
  },
  {
    categoryTypeId: 4,
    categoryItemId: 21,
    componentClass: SourceProviders.KeepTruckin,
    title: "Motive Provider",
    loadCredentials: (tenantId: string, configurationApiFactory: any) => configurationApiFactory.apiV1ConfigurationKeepTruckinTenantIdGet({ tenantId }),
    saveCredentials: (tenantId: string, credentials: credentialType, configurationApiFactory: any) => configurationApiFactory.apiV1ConfigurationKeeptruckinSavePost(_.assign({ tenantId: tenantId }, credentials))
  },
  {
    categoryTypeId: 4,
    categoryItemId: 52,
    componentClass: SourceProviders.BigChange,
    title: "BigChange Provider",
    loadCredentials: (tenantId: string, configurationApiFactory: any) => configurationApiFactory.apiV1ConfigurationBigChangeLoadTenantIdGet({ tenantId }),
    saveCredentials: (tenantId: string, credentials: credentialType, configurationApiFactory: any) => configurationApiFactory.apiV1ConfigurationBigChangeSavePost(_.assign({ tenantId: tenantId }, credentials))
  },
  {
    categoryTypeId: 4,
    categoryItemId: 61,
    componentClass: SourceProviders.OmniOne,
    title: "OmniOne Provider",
    loadCredentials: (tenantId: string, configurationApiFactory: any) => configurationApiFactory.apiV1ConfigurationOmniOneTenantIdGet({ tenantId }),
    saveCredentials: (tenantId: string, credentials: credentialType, configurationApiFactory: any) => configurationApiFactory.apiV1ConfigurationOmniOneSavePost(_.assign({ tenantId: tenantId }, credentials))
  },
  {
    categoryTypeId: 4,
    categoryItemId: 69,
    componentClass: SourceProviders.ERoads,
    title: "ERoads Provider",
    loadCredentials: (tenantId: string, configurationApiFactory: any) => configurationApiFactory.apiV1ConfigurationEroadsTenantIdGet({ tenantId }),
    saveCredentials: (tenantId: string, credentials: credentialType, configurationApiFactory: any) => configurationApiFactory.apiV1ConfigurationEroadsSavePost(_.assign({ tenantId: tenantId }, credentials))
  },
  {
    categoryTypeId: 4,
    categoryItemId: 58,
    componentClass: SourceProviders.Coretex,
    title: "Coretex Provider",
    loadCredentials: (tenantId: string, configurationApiFactory: any) => configurationApiFactory.apiV1ConfigurationCoretexTenantIdGet({ tenantId }),
    saveCredentials: (tenantId: string, credentials: credentialType, configurationApiFactory: any) => configurationApiFactory.apiV1ConfigurationCoretexSavePost(_.assign({ tenantId: tenantId }, credentials))
  },
  {
    categoryTypeId: 4,
    categoryItemId: 55,
    componentClass: SourceProviders.LoadTrek,
    title: "LoadTrek Provider",
    loadCredentials: (tenantId: string, configurationApiFactory: any) => configurationApiFactory.apiV1ConfigurationLoadTrekTenantIdGet({ tenantId }),
    saveCredentials: (tenantId: string, credentials: credentialType, configurationApiFactory: any) => configurationApiFactory.apiV1ConfigurationLoadTrekSavePost(_.assign({ tenantId: tenantId }, credentials))
  },
  {
    categoryTypeId: 4,
    categoryItemId: 53,
    componentClass: SourceProviders.Pedigree,
    title: "Pedigree Provider",
    loadCredentials: (tenantId: string, configurationApiFactory: any) => configurationApiFactory.apiV1ConfigurationPedigreeTenantIdGet({ tenantId }),
    saveCredentials: (tenantId: string, credentials: credentialType, configurationApiFactory: any) => configurationApiFactory.apiV1ConfigurationPedigreeSavePost(_.assign({ tenantId: tenantId }, credentials))
  },
  {
    categoryTypeId: 4,
    categoryItemId: 37,
    componentClass: SourceProviders.TrackIt,
    title: "TrackIt Provider",
    loadCredentials: (tenantId: string, configurationApiFactory: any) => configurationApiFactory.apiV1ConfigurationTrackItTenantIdGet({ tenantId }),
    saveCredentials: (tenantId: string, credentials: credentialType, configurationApiFactory: any) => configurationApiFactory.apiV1ConfigurationTrackItSavePost(_.assign({ tenantId: tenantId }, credentials))
  },
  {
    categoryTypeId: 4,
    categoryItemId: 56,
    componentClass: SourceProviders.VisTracks,
    title: "VisTracks Provider",
    loadCredentials: (tenantId: string, configurationApiFactory: any) => configurationApiFactory.apiV1ConfigurationVisTracksTenantIdGet({ tenantId }),
    saveCredentials: (tenantId: string, credentials: credentialType, configurationApiFactory: any) => configurationApiFactory.apiV1ConfigurationVisTracksSavePost(_.assign({ tenantId: tenantId }, credentials))
  },
  {
    categoryTypeId: 4,
    categoryItemId: 47,
    componentClass: SourceProviders.AdminFtp,
    title: "AdminFTP Provider",
    loadCredentials: (tenantId: string, configurationApiFactory: any) => configurationApiFactory.apiV1ConfigurationAdminFtpTenantIdGet({ tenantId }),
    saveCredentials: (tenantId: string, credentials: credentialType, configurationApiFactory: any) => configurationApiFactory.apiV1ConfigurationAdminFtpSavePost(_.assign({ tenantId: tenantId }, credentials))
  },
  {
    categoryTypeId: 4,
    categoryItemId: 18,
    componentClass: SourceProviders.EmailCSV,
    title: "EmailCSV Provider",
    loadCredentials: (tenantId: string, configurationApiFactory: any) => configurationApiFactory.apiV1ConfigurationEmailCSVLoadTenantIdGet({ tenantId }),
    saveCredentials: (tenantId: string, credentials: credentialType, configurationApiFactory: any) => configurationApiFactory.apiV1ConfigurationEmailCSVSavePost(_.assign({ tenantId: tenantId }, credentials))
  },
  {
    categoryTypeId: 4,
    categoryItemId: 95,
    componentClass: SourceProviders.RoadnetMobile,
    title: "RoadnetMobile Provider",
    loadCredentials: (tenantId: string, configurationApiFactory: any) => configurationApiFactory.apiV1ConfigurationRoadnetMobileTenantIdGet({ tenantId }),
    saveCredentials: (tenantId: string, credentials: credentialType, configurationApiFactory: any) => configurationApiFactory.apiV1ConfigurationRoadnetMobileSavePost(_.assign({ tenantId: tenantId }, credentials))
  },
  {
    categoryTypeId: 4,
    categoryItemId: 93,
    componentClass: SourceProviders.PlatformScience,
    title: "PlatformScience Provider",
    loadCredentials: (tenantId: string, configurationApiFactory: any) => configurationApiFactory.apiV1ConfigurationPlatformScienceTenantIdGet({ tenantId }),
    saveCredentials: (tenantId: string, credentials: credentialType, configurationApiFactory: any) => configurationApiFactory.apiV1ConfigurationPlatformScienceSavePost(_.assign({ tenantId: tenantId }, credentials))
  }
];