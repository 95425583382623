import {
	React,
	bind,
	_
} from "$Imports/Imports";

import {
	viewType
} from "$State/TenantSummaryFreezerService";

import {
	TenantSummaryViewModel
} from "$Generated/api";

import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle
} from "$Imports/MaterialUIComponents";

interface IActivateTenantProps {
	/** The view type that should be loaded */
	view: viewType;

	/** The tenant to deactivate */
	activateTenant: TenantSummaryViewModel | null;

	/** Cancel button click handler */
	onCancelClick: () => void;

	/** Deactivate button click handler */
	onActivateClick: () => void;
}

export class ActivateTenantDialog extends React.Component<IActivateTenantProps> {

	@bind
	private _onCancelClick() {
		this.props.onCancelClick();
	}

	@bind
	private _onActivateClick() {
		this.props.onActivateClick();
	}

	render() {
		const { view, activateTenant } = this.props;
		const isOpen = view === "Activate";
		const primaryColor = "primary";
		return (
			<Dialog open={isOpen}>
				<DialogTitle>Activate Tenant</DialogTitle>
				<DialogContent>
					Continuing will Activate {activateTenant?.tenantFriendlyName ?? "the tenant"}.
				</DialogContent>
				<DialogActions>
					<Button color={primaryColor} onClick={this._onCancelClick}>Cancel</Button>
					<Button color={primaryColor} onClick={this._onActivateClick}>Activate</Button>
				</DialogActions>
			</Dialog>
		);
	}
}