import { React, _, bind } from "$Imports/Imports";
import { Button } from "$Imports/MaterialUIComponents";
import { AdvanceTextField } from "$Components/Common";
import { ICredentialProps } from "$State/CredentialTypes";
import { CADECCredentials } from "$Generated/api";

interface ICADECBaseProps {
}
type ICADECProps = ICADECBaseProps & ICredentialProps<CADECCredentials>;

export class CADEC extends React.PureComponent<ICADECProps> {

  @bind
  onChange(e: any) {
    this.props.onChange(this.props.providerKey, { clientId: e.target.value });
  }

  render(): JSX.Element {
    const cadec: CADECCredentials = this.props.data ? this.props.data : {} as CADECCredentials;
    return (
      <div>
        <AdvanceTextField
          label="Client Id*"
          name="clientId"
          value={cadec.clientId}
          onChange={this.onChange}
          error={(cadec.clientId === "")}
        /><br />
        <Button className="float-right" disabled={true} color={"primary"}>VALIDATE CREDENTIALS</Button>
      </div>
    );
  }
}