import {
  React,
  _,
  memoizeOne
} from "$Imports/Imports";

import {
  AdapterSummaryModelIEnumerableResponseBase,
  AdapterSummaryModel,
} from "$Generated/api";

import {
  TenantSummaryPanel
} from "./TenantSummaryPanel";

const styles: {
  mainContainer: string;
  tenantPanel: string;
  critical: string;
} = require("./TenantSummary.scss");

interface ITenantSummaryProps {
  data: AdapterSummaryModelIEnumerableResponseBase;
}
export class TenantSummary extends React.PureComponent<ITenantSummaryProps>{

  private _calcTotalTenants(data: AdapterSummaryModel[]): number {
    return _.sumBy(data, (d) => d.tenantCount === undefined ? 0 : d.tenantCount);
  }

  private readonly _calcTotalTenants_memoize = memoizeOne(this._calcTotalTenants);

  render() {
    const { data } = this.props.data;

    const totalTenant = this._calcTotalTenants_memoize(data === undefined ? [] : data);

    return (
      <div
        className={styles.mainContainer}
      >
        <TenantSummaryPanel
          className={styles.tenantPanel}
          count={totalTenant}
          countLabel={totalTenant === 1 ? "Tenant" : "Tenants"}
        />
      </div>
    );
  }
}
