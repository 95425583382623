import {
  React,
  _,
  bind
} from "$Imports/Imports";

import {
  Snackbar,
  IconButton
} from "$Imports/MaterialUIComponents";

import {
  ErrorService,
  IErrorServiceInjectedProps
} from "$State/ErrorFreezerService";

import {
  Close
} from "$Imports/MaterialUIIcons";

interface IErrorStackPanelBaseProps {

}

type IErrorStackPanelProps = IErrorStackPanelBaseProps & IErrorServiceInjectedProps;

class _ErrorStackPanel extends React.Component<IErrorStackPanelProps> {

  @bind
  private _onSnackClose(event: React.SyntheticEvent<any>, reason: string) {
    this.props.errorService.popLastItem();
  }

  @bind
  private _onClose(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    this.props.errorService.popLastItem();
  }

  render() {
    const { errorList } = this.props.errorService.getState();

    const isOpen = errorList.length !== 0;

    const message = isOpen ? errorList[errorList.length - 1] : "";

    return (
      <Snackbar
        onClose={this._onSnackClose}
        open={isOpen}
        message={message}
        autoHideDuration={6000}
        anchorOrigin={{
          horizontal: "center",
          vertical: "bottom"
        }}
        action={[
          <IconButton key="close" color="inherit" onClick={this._onClose}>
            <Close />
          </IconButton>,
        ]}
      />
    );
  }
}

export const ErrorStackPanel = ErrorService.inject(
  _ErrorStackPanel
);
