import {
  React,
  _,
  bind
} from "$Imports/Imports";

import {
  IconButton
} from "$Imports/MaterialUIComponents";

import {
  Search,
  Close
} from "$Imports/MaterialUIIcons";

import {
  AdvanceTextField,
  IAdvanceTextFieldProps
} from "./AdvanceTextField";

interface ISearchBoxBaseProps {
  filterStatusLabel?: string | boolean | number | null;
  filterStatusLabelClassName?: string;
  showClearButton?: boolean;
  onClearClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

type ISearchBoxProps = ISearchBoxBaseProps & IAdvanceTextFieldProps;

const styles: {
  container: string;
  container_sub: string;
} = require("./SearchBox.scss");

export class SearchBox extends React.PureComponent<ISearchBoxProps> {

  static defaultProps: Partial<ISearchBoxProps> = {
    showClearButton: true
  };

  @bind
  private _onClearClick(event: React.MouseEvent<HTMLButtonElement>) {
    if (this.props.onClearClick) {
      this.props.onClearClick(event);
    }
  }

  render() {
    const {
      filterStatusLabel,
      filterStatusLabelClassName,
      onClearClick,
      showClearButton,
      ref,
      value,
      ...passthroughProps
    } = this.props;

    const isBoxEmpty: boolean = value === null || value === undefined || value === ""

    return (
      <div
        className={styles.container}
      >
        <Search style={this.props.label ? { marginTop: "16px" } : {}} />
        <div
          className={styles.container_sub}
        >
          <AdvanceTextField
            value={value}
            {...passthroughProps}
          />
          {
            showClearButton && !isBoxEmpty ? (
              <IconButton
                size="small"
                onClick={this._onClearClick}
              >
                <Close
                  fontSize="small"
                />
              </IconButton>
            ) : null
          }
          {
            filterStatusLabel !== null && filterStatusLabel !== undefined ?
              (
                <div
                  className={filterStatusLabelClassName}
                >
                  {filterStatusLabel}
                </div>
              )
              : null
          }
        </div>
      </div>
    );
  }
}