import {
  React
} from "$Imports/Imports";

import {
  SitePubSubManager,
  messageType
} from "$Utilities/PubSubUtil";
import { LoginService, ILoginServiceInjectedProps } from "$State/LoginFreezerService";
import { IErrorMessage } from "$Modules/managedAjaxUtil";

interface IProps {
  loginComponent?: React.ReactNode;
  children?: React.ReactNode;
}

class _LoginUserContainer extends React.Component<IProps & ILoginServiceInjectedProps> {
  private token: any;

  componentDidMount(): void {
    LoginService.getUserInformation();
    this.token = SitePubSubManager.subscribe("application:fetch-error", (type: messageType, message: IErrorMessage) => {
      if(message.statusCode === 401 || message.statusCode === 403) {
        LoginService.Logout();
      }
    });
  }

  componentWillUnmount(): void {
    SitePubSubManager.unsubscribe(this.token);
  }

  render() {
    const { login } = this.props;

    const hasAttemptedLogin = login.getState().userInformationResults.hasFetched || login.getState().userInformationResults.state === "error";
    const isLoggedIn = login.getState().userInformationResults.data !== null && login.getState().userInformationResults.data !== undefined;

    return (
    <>
      { 
        hasAttemptedLogin ?
        (
          isLoggedIn ?
            this.props.children
          :
            this.props.loginComponent
        ) 
        : null
      } 
    </>
    );
  }
}

export const LoginUserContainer = LoginService.inject(_LoginUserContainer);