import { DateRangeFilter, IDateRange } from "$Components/Common";
import { Button } from "@material-ui/core";
import { bind, React, _, moment } from "$Imports/Imports";
import { IReportSearchFilter } from "$State/LytxGenericReportFreezerService";
import { SimpleStringSelector } from "./SimpleStringSelector";

const styles: {
  searchContainer: string;
  searchRow: string;
  searchButton: string;
  searchDiv: string;
} = require("./ReportFilter.scss");

const defaultDateRange: IDateRange = {
  startDate: null,
  endDate: null
};

const defaultFilter: IReportSearchFilter = {
  dateRange: defaultDateRange,
  group: undefined,
  vehicle: undefined
};

interface IReportFilterProps {
  onRunSearch: () => void;
  searchFilter: IReportSearchFilter;
  onFilterChanged: (filter: IReportSearchFilter, reload: boolean) => void;
  groupData: string[];
  vehicleData: string[];
  errorState: boolean;
  minDate: moment.Moment | undefined;
  maxDate: moment.Moment | undefined;
}

interface IReportFilterState {

}

export class ReportFilter extends React.PureComponent<IReportFilterProps, IReportFilterState>
{
  public constructor(props: IReportFilterProps) {
    super(props);
  }

  @bind
  private _runSearch() {
    if (this.props.onRunSearch) {
      this.props.onRunSearch();
    }
  }

  private _raiseFilterChange(filter: IReportSearchFilter) {
    if (this.props.onFilterChanged) {
      this.props.onFilterChanged(filter, false);
    }
  }

  @bind
  private _onDateRangeChange(date: IDateRange): void {
    const notNullFilter = this.props.searchFilter ? this.props.searchFilter : defaultFilter;

    this._raiseFilterChange({
      dateRange: date,
      group: notNullFilter.group,
      vehicle: notNullFilter.vehicle
    });
  }

  @bind
  private _onGroupSelectionChanged(e: React.ChangeEvent<{ name?: string | undefined; value: unknown; }>, child: React.ReactNode, value: string | undefined) {
    const notNullFilter = this.props.searchFilter ? this.props.searchFilter : defaultFilter;

    this._raiseFilterChange({
      dateRange: notNullFilter.dateRange,
      group: value,
      vehicle: undefined
    });
  }

  @bind
  private _onVehicleSelectionChanged(e: React.ChangeEvent<{ name?: string | undefined; value: unknown; }>, child: React.ReactNode, value: string | undefined) {
    const notNullFilter = this.props.searchFilter ? this.props.searchFilter : defaultFilter;

    this._raiseFilterChange({
      dateRange: notNullFilter.dateRange,
      group: notNullFilter.group,
      vehicle: value
    });
  }

  render() {
    const notNullFilter = this.props.searchFilter ? this.props.searchFilter : defaultFilter;
    return (
      <div className={styles.searchContainer}>
        <div className={styles.searchRow}>
          <div className={styles.searchDiv}>
            <DateRangeFilter
              value={notNullFilter.dateRange}
              maxDayRange={7}
              minDate={this.props.minDate}
              maxDate={this.props.maxDate}
              rangeOptions={["today", "yesterday", "last-7-days", "custom"]}
              onChange={this._onDateRangeChange}
            />
          </div>
        </div>
        <div className={styles.searchRow}>
          <div className={styles.searchDiv}>
            <SimpleStringSelector
              value={notNullFilter.group}
              sourceData={this.props.groupData}
              onValueChange={this._onGroupSelectionChanged}
              errorState={this.props.errorState}
              lowercaseLabel="group"
              uppercaseLabel="Group"
            />
          </div>
          <div className={styles.searchDiv}>
            <SimpleStringSelector
              value={notNullFilter.vehicle}
              sourceData={this.props.vehicleData}
              onValueChange={this._onVehicleSelectionChanged}
              errorState={this.props.errorState}
              lowercaseLabel="vehicle"
              uppercaseLabel="Vehicle"
            />
          </div>
          <div className={styles.searchDiv + ' theme-onPdfHide'}>
            <Button
              className={styles.searchButton}
              size="small"
              variant="contained"
              color="primary"
              onClick={this._runSearch}>
              Run
            </Button>
          </div>
        </div>
      </div>
    );
  }
}