import {
  React,
  _,
  bind
} from "$Imports/Imports";

import {
  TableBody,
} from "$Imports/MaterialUIComponents";

import {
  DataTableRow,
} from "./DataTableRow";

import {
  IDataTableColumn
} from "./IDataTableColumn";

interface IDataTableRowsProps<T = unknown> {
  data: T[];
  columns: IDataTableColumn<T>[];
  onRowClick?: (event: React.MouseEvent<HTMLTableRowElement, MouseEvent>, data: T) => void;
  onCellClick?: (event: React.MouseEvent<HTMLTableCellElement, MouseEvent>, data: T, column: IDataTableColumn<T>) => void;
  setRowStyle?: (rowData: T) => string;
}

export class DataTableRows<T = unknown> extends React.PureComponent<IDataTableRowsProps<T>> {

  @bind
  private _onRowClick(event: React.MouseEvent<HTMLTableRowElement, MouseEvent>, data: T) {
    if (this.props.onRowClick) {
      this.props.onRowClick(event, data);
    }
  }

  @bind
  private _onCellClick(event: React.MouseEvent<HTMLTableCellElement, MouseEvent>, data: T, column: IDataTableColumn<T>) {
    if (this.props.onCellClick) {
      this.props.onCellClick(event, data, column);
    }
  }

  render() {
    const { data, columns, setRowStyle } = this.props;

    return (
      <TableBody>
        {
          _.map(data, (d: T, dIdx: number) => (
            <DataTableRow
              key={dIdx}
              data={d}
              columns={columns}
              onRowClick={this._onRowClick}
              onCellClick={this._onCellClick}
              setRowStyle={setRowStyle}
            />
          ))
        }
      </TableBody>
    );
  }
}
