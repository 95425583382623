import {
  _,
  managedAjaxUtil,
  IAjaxState,
  FreezerService,
  bind,
} from "$Imports/Imports";

import { 
  AssociatedEntitiesApiFactory, 
  AssociatedEntityViewModelIEnumerablePagedResponseBase, 
  AssociatedEntityViewModel, 
  ConfigurationApiFactory 
} from "$Generated/api";

import {
  SitePubSubManager
} from "$Utilities/PubSubUtil";

import {
  credentialType,
  responseType,
  IEditCredential
} from "$State/CredentialTypes";

import { APPCONFIG } from "../../constants/Config";
import { entityTypes, categoryTypes } from "$Components/Shared/Consts";
import { AdapterComponentMapping } from "../../tenant-view/Providers/AdapterComponentMapping";
import { IAdapterProviderMap } from "../../tenant-view/Providers/IAdapterProviderMap";
import { isNullOrUndefined } from "../../modules/helpers";

import { ErrorService } from "../ErrorFreezerService";

const InjectedPropName = "tenantCredentialService";

interface ITenantCredentialState {
  workflowInstanceId: string;
  sourceProviders: IAdapterProviderMap[];
  submissionProviders: IAdapterProviderMap[];
  credentials: { [key: number]: IEditCredential };
  dataPopulated: boolean;
  credentialsMissing: boolean;
}

class TenantCredentialFreezerService extends FreezerService<ITenantCredentialState, typeof InjectedPropName>{

  private _isSaving: boolean = false;

  constructor() {
    super({
      credentials: {},
      sourceProviders: [],
      submissionProviders: [],
      workflowInstanceId: "",
      dataPopulated: false,
      credentialsMissing: false
    }, InjectedPropName);

    SitePubSubManager.subscribe("application:login:before", this.resetFreezer);
  }

  private async fetchAssociatedEntities(workflowInstanceId: string): Promise<void> {
    const associateEntityFactory = AssociatedEntitiesApiFactory(APPCONFIG.fetch, APPCONFIG.baseUrl);
    const associatedEntities: AssociatedEntityViewModelIEnumerablePagedResponseBase  =
      await associateEntityFactory.apiV1AssociatedEntitiesGetAssociatedEntitiesGet({
        entityId: workflowInstanceId,
        entityTypeId: entityTypes.tenant,
      });

    const filteredEntity: AssociatedEntityViewModel[] =
      _.filter(associatedEntities.data, (a) => (a.categoryType?.id === categoryTypes.sourceProvider) || (a.categoryType?.id === categoryTypes.submissionProvider));

    const entities: AssociatedEntityViewModel[] = filteredEntity ? filteredEntity : [];
    const adapters: IAdapterProviderMap[] = _.filter(AdapterComponentMapping, (a) => _.some(entities, (e) => e.categoryItem?.id === a.categoryItemId));

    let sourceProviders: IAdapterProviderMap[] = [];
    let submissionProviders: IAdapterProviderMap[] = [];
    var credentials: { [key: number]: IEditCredential } = {};

    for (const a of adapters) {
      if (a.categoryTypeId === categoryTypes.sourceProvider) {
        sourceProviders.push(a);
      }
      if (a.categoryTypeId === categoryTypes.submissionProvider) {
        submissionProviders.push(a);
      }
      credentials[a.categoryItemId] = { editCredential: null, loadCredentialResults: managedAjaxUtil.createInitialState(), saveCredentialResults: managedAjaxUtil.createInitialState() }
    }


    this.freezer.get().set({ submissionProviders: submissionProviders, sourceProviders: sourceProviders, dataPopulated: true, credentials: credentials });

    this.fetchCredentials();
  }

  private fetchCredentials() {
    const configurationApiFactory = ConfigurationApiFactory(APPCONFIG.fetch, APPCONFIG.baseUrl);
    const submissionProviders = this.freezer.get().submissionProviders.toJS();
    const sourceProviders = this.freezer.get().sourceProviders.toJS();

    for (var submissionProvider of submissionProviders) {
      const categoryID = submissionProvider.categoryItemId;
      var load = managedAjaxUtil.fetchResults({
        freezer: this.freezer,
        onExecute: (apiOptions, params, options) => {
          if (params === undefined) {
            throw new Error("Parameters is undefined");
          }
          return params.loadFunction(params.workflowInstanceId, configurationApiFactory);
        },
        getAjaxState: (options) => {
          return this.freezer.get().credentials[options.params ? options.params.providerKey : -1].loadCredentialResults.toJS(); //should never return -1, but should be fine to crash if that ever happens.
        },
        setAjaxState: (options, newStatus) => {
          var credentials = this.freezer.get().credentials.toJS();
          var credential = credentials[options.params ? options.params.providerKey : -1];
          credential.loadCredentialResults = newStatus;
          this.freezer.get().set({ credentials: credentials });
        },
        params: {
          loadFunction: submissionProvider.loadCredentials,
          workflowInstanceId: this.freezer.get().workflowInstanceId,
          providerKey: submissionProvider.categoryItemId,
        },
        onOk: (data: responseType) => {
          //store the stuff
          if (data.success && data.data) {
            var credentials = this.freezer.get().credentials.toJS();
            var credential = credentials[categoryID];
            credential.editCredential = data.data;
            this.freezer.get().set({ credentials: credentials });
          } else {
            let error: any = data?.error;
            if (error && error.Message)
            {
              ErrorService.pushErrorMessage(error.Message);
            }
          }
        },
      });
    }
    for (var sourceProvider of sourceProviders) {
      const categoryID = sourceProvider.categoryItemId;
      var load = managedAjaxUtil.fetchResults({
        freezer: this.freezer,
        onExecute: (apiOptions, params, options) => {
          if (params === undefined) {
            throw new Error("Parameters is undefined");
          }
          return params.loadFunction(params.workflowInstanceId, configurationApiFactory);
        },
        getAjaxState: (options) => {
          return this.freezer.get().credentials[options.params ? options.params.providerKey : -1].loadCredentialResults.toJS(); //should never return -1, but should be fine to crash if that ever happens.
        },
        setAjaxState: (options, newStatus) => {
          var credentials = this.freezer.get().credentials.toJS();
          var credential = credentials[options.params ? options.params.providerKey : -1];
          credential.loadCredentialResults = newStatus;
          this.freezer.get().set({ credentials: credentials });
        },
        params: {
          loadFunction: sourceProvider.loadCredentials,
          workflowInstanceId: this.freezer.get().workflowInstanceId,
          providerKey: sourceProvider.categoryItemId
        },
        onOk: (data: responseType) => {
          //store the stuff
          if (data.success && data.data) {
            var credentials = this.freezer.get().credentials.toJS();
            var credential = credentials[categoryID];
            credential.editCredential = data.data;
            this.freezer.get().set({ credentials: credentials });
          }
        },
      });
    }
  }

  public allCredentialsLoaded(): boolean {
    const credentials = this.freezer.get().credentials.toJS();
    for (var credentialKey in credentials) {
      if (!credentials[credentialKey].loadCredentialResults.hasFetched) {
        return false;
      }
    }

    return true;
  }

  isSaving(): boolean {
    return this._isSaving;
  }

  public allCredentialsSaved(): boolean {
    var hasSaved: boolean = true;
    const credentials = this.freezer.get().credentials.toJS();
    for (var credentialKey in credentials) {
      const credential = credentials[credentialKey];
      if (credential.saveCredentialResults.state == "initial" || !credential.saveCredentialResults.hasFetched) {
        hasSaved = false;
      }
    }
    if (hasSaved) {
      this._isSaving = false;
    }
    return hasSaved;
  }

  @bind
  public saveCredentials() {
    this._isSaving = true;
    const submissionProviders = this.freezer.get().submissionProviders.toJS();
    const sourceProviders = this.freezer.get().sourceProviders.toJS();
    const configurationApiFactory = ConfigurationApiFactory(APPCONFIG.fetch, APPCONFIG.baseUrl);

    for (var submissionProvider of submissionProviders) {
      var save = managedAjaxUtil.fetchResults({
        freezer: this.freezer,
        onExecute: (apiOptions, params, options) => {
          if (params === undefined) {
            throw new Error("Parameters is undefined");
          }
          const updateCredentials = this.freezer.get().credentials[options.params ? options.params.providerKey : -1].editCredential;
          if (updateCredentials) {
            return params.saveFunction(params.workflowInstanceId, updateCredentials.toJS(), configurationApiFactory);
          }
          throw new Error("Credentials undefined");
        },
        getAjaxState: (options) => {
          return this.freezer.get().credentials[options.params ? options.params.providerKey : -1].saveCredentialResults; //should never return -1, but should be fine to crash if that ever happens.
        },
        setAjaxState: (options, newStatus) => {
          var credentials = this.freezer.get().credentials.toJS();
          var credential = credentials[options.params ? options.params.providerKey : -1];
          credential.saveCredentialResults = newStatus as IAjaxState<responseType>;
          this.freezer.get().set({ credentials: credentials });
        },
        params: {
          saveFunction: submissionProvider.saveCredentials,
          workflowInstanceId: this.freezer.get().workflowInstanceId,
          providerKey: submissionProvider.categoryItemId
        },
      });
    }
    for (var sourceProvider of sourceProviders) {
      var save = managedAjaxUtil.fetchResults({
        freezer: this.freezer,
        onExecute: (apiOptions, params, options) => {
          if (params === undefined) {
            throw new Error("Parameters is undefined");
          }
          const updateCredentials = this.freezer.get().credentials[options.params ? options.params.providerKey : -1].editCredential;
          if (updateCredentials) {
            return params.saveFunction(params.workflowInstanceId, updateCredentials.toJS(), configurationApiFactory);
          }
          throw new Error("Credentials undefined");
        },
        getAjaxState: (options) => {
          return this.freezer.get().credentials[options.params ? options.params.providerKey : -1].saveCredentialResults; //should never return -1, but should be fine to crash if that ever happens.
        },
        setAjaxState: (options, newStatus) => {
          var credentials = this.freezer.get().credentials.toJS();
          var credential = credentials[options.params ? options.params.providerKey : -1];
          credential.saveCredentialResults = newStatus as IAjaxState<responseType>;
          this.freezer.get().set({ credentials: credentials });
        },
        params: {
          saveFunction: sourceProvider.saveCredentials,
          workflowInstanceId: this.freezer.get().workflowInstanceId,
          providerKey: sourceProvider.categoryItemId
        }
      });
    }
  }

  public setWorkflowInstanceId(workflowInstanceId: string) {
    this.freezer.get().set({ workflowInstanceId: workflowInstanceId });

    this.fetchAssociatedEntities(workflowInstanceId);

  }

  public getWorkflowInstanceId(): string {
    return this.freezer.get().workflowInstanceId;
  }

  public getSourceProviders(): IAdapterProviderMap[] {
    return this.freezer.get().sourceProviders.toJS();
  }

  public getSubmissionProviders(): IAdapterProviderMap[] {
    return this.freezer.get().submissionProviders.toJS();
  }

  public getDataPopulated(): boolean {
    return this.freezer.get().dataPopulated;
  }

  public getCredential(providerKey: number): credentialType | null {
    var credential = this.freezer.get().credentials[providerKey].editCredential;
    if (credential) {
      return credential.toJS();
    }
    return null;
  }

  @bind
  public updateCredential(providerKey: number, data: Partial<credentialType>) {
    var credentials = this.freezer.get().credentials.toJS();
    var credential = credentials[providerKey];
    credential.editCredential = _.assign(credential.editCredential, data);
    this.freezer.get().set({ credentials: credentials });
    this.credentialsMissing();
  }

  @bind
  public resetFreezer() {
    this.freezer.get().set({
      credentials: {},
      sourceProviders: [],
      submissionProviders: [],
      workflowInstanceId: "",
      dataPopulated: false,
      credentialsMissing: false
    });
  }

  @bind
  public credentialsMissing() {
    var allPopulated = true;
    const credentials = this.freezer.get().credentials.toJS();
    for (const credentialKey in credentials) {
      var credential = credentials[credentialKey];
      if (credential.editCredential) {
        if (!this.credentialsMissingHelper(credential.editCredential)) {
          allPopulated = false;
          break;
        }
      }
    }
    this.freezer.get().set({ credentialsMissing: !allPopulated });
  }

  @bind public credentialsMissingHelper(objToCheck: object): boolean {
    for (var fieldKey in objToCheck) {
      var field = (objToCheck as any)[fieldKey];
      if(Array.isArray(field))
      {
        if(field.length <= 0)
        {
          return false;
        }
      }
      else if (typeof (field) === "object") {
        if (!this.credentialsMissingHelper(field)) {
          return false;
        }
      } 
      else {
        if (isNullOrUndefined(field) || field === "") {
          return false;
        }
      }
    }
    return true;
  }
}

export const TenantCredentialService = new TenantCredentialFreezerService();
export type ITenantCredentialServiceInjectedProps = ReturnType<TenantCredentialFreezerService["getPropsForInjection"]>;