import {
  React
} from "$Imports/Imports";

import {
  ErrorIndicator
} from "./ErrorIndicator";

import {
  GoodIndicator
} from "./GoodIndicator";

import {
  StatusIndicatorLabel
} from "./StatusIndicatorLabel";

import {
  ProcessingIndicator
} from "./ProcessingIndicator";

const styles: {
  mainContainer: string;
  statusLabelIndicator: string;
} = require("./StatusIndicatorLegend.scss");

interface IJobStatusLegendProps extends React.HTMLProps<HTMLDivElement> {

}

export class JobStatusLegend extends React.PureComponent<IJobStatusLegendProps> {
  render() {
    const { className, ...passthroughProps } = this.props;
    return (
      <div
        className={className ? `${styles.mainContainer} ${className}` : styles.mainContainer}
        {...passthroughProps}
      >

        <StatusIndicatorLabel
          className={styles.statusLabelIndicator}
          icon={(
            <ProcessingIndicator
              fontSize="default"
            />
          )}
        >
          Processing
        </StatusIndicatorLabel>
        <StatusIndicatorLabel
          className={styles.statusLabelIndicator}
          icon={(
            <GoodIndicator
              fontSize="default"
            />
          )}
        >
          Good
        </StatusIndicatorLabel>
        <StatusIndicatorLabel
          className={styles.statusLabelIndicator}
          icon={(
            <ErrorIndicator
              fontSize="default"
            />
          )}
        >
          Error
        </StatusIndicatorLabel>
      </div>

    );
  }
}