import {
  FreezerService,
  IAjaxState,
  managedAjaxUtil,
  _,
  bind
} from "$Imports/Imports";

const InjectPropName = "vehicleSearchService";

export type viewType = "assignmentRecords" | "dutyStatus";

import {
  IDateRange,
  DateRangeCalculator,
} from "$Components/Common";

import {
  IPagerState
} from "./PagerPagingState";

import {
  DutyStatusActivityIEnumerableResponseBase,
  VehicleSearchApiFactory,
  AssignmentIEnumerableResponseBase
} from "$Generated/api";

import {
  SitePubSubManager
} from "$Utilities/PubSubUtil";
import {
  ISortState
} from "./SortState";

import {
  ErrorService
} from "./ErrorFreezerService";

export interface ISearchFilter {
  dateRange: IDateRange;
  searchValue: string;
  tenantId?: string;
  vehicleId?: string;
  driverId?: string;
  lastName?: string;
}

interface IVehicleSearchState {
  dutyStatusFilter: ISearchFilter;
  assignmentFilter: ISearchFilter;
  view: viewType;
  dutyStatusResults: IAjaxState<DutyStatusActivityIEnumerableResponseBase>;
  dutyStatusPager: IPagerState;
  dutyStatusSort: ISortState;
  vehicleAssignmentResults: IAjaxState<AssignmentIEnumerableResponseBase>;
  assignmentPager: IPagerState;
  assignmentSort: ISortState;
  errorState: boolean;
  hasSearched: boolean;
}

class VehicleSearchFreezerService extends FreezerService<IVehicleSearchState, typeof InjectPropName> {
  constructor() {
    super({
      dutyStatusFilter: {
        dateRange: DateRangeCalculator.calcDateRange("today"),
        searchValue: "",
        tenantId: ""
      },
      assignmentFilter: {
        dateRange: DateRangeCalculator.calcDateRange("last-7-days"),
        searchValue: "",
        tenantId: ""
      },
      dutyStatusResults: managedAjaxUtil.createInitialState(),
      dutyStatusPager: {
        page: 0,
        rowsPerPage: 25,
      },
      assignmentPager: {
        page: 0,
        rowsPerPage: 25,
      },
      view: "dutyStatus",
      dutyStatusSort: {
        sortColumnName: "event-date",
        sortDirection: "desc"
      },
      assignmentSort: {
        sortColumnName: "start-time",
        sortDirection: "desc"
      },
      vehicleAssignmentResults: managedAjaxUtil.createInitialState(),
      errorState: false,
      hasSearched: false,
    }, InjectPropName);

    SitePubSubManager.subscribe("application:login:before", this.clearResults);
  }

  @bind
  private clearResults() {
    this.freezer.get().set({
      dutyStatusResults: managedAjaxUtil.createInitialState(),
      view: "dutyStatus",
      vehicleAssignmentResults: managedAjaxUtil.createInitialState(),
      errorState: false
    });

    this.freezer.get().dutyStatusFilter.set({
      searchValue: "",
      tenantId: "",
    });

    this.freezer.get().dutyStatusPager.set({
      page: 0,
    });
  }

  public async fetchDutyStatus(forceUpdate: boolean = false) {
    const results = this.freezer.get().dutyStatusResults;
    const filter = this.freezer.get().dutyStatusFilter.toJS();

    if (results.hasFetched && !forceUpdate) {
      return;
    }

    // If any required fields aren't filled in, return and set error state
    if (filter.tenantId === undefined || filter.tenantId === "") {
      // Display message
      this.freezer.get().set({errorState: true});

      return;
    }

    this.freezer.get().set({errorState: false});
    const dateRange = DateRangeCalculator.convertToISOString(filter.dateRange);

    if (dateRange.endDate === undefined || dateRange.startDate === undefined) {
      return;
    }

    return managedAjaxUtil.fetchResults({
      ajaxStateProperty: "dutyStatusResults",
      freezer: this.freezer,
      onExecute: (apiOptions, params, options) => {
        const vehicleSearchFactory = VehicleSearchApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
        return vehicleSearchFactory.apiV1VehicleSearchDutyStatusGet(params);
      },
      params: {
        tenantId: filter.tenantId,
        startDateTime: dateRange.startDate,
        endDateTime: dateRange.endDate,
        vehicleId: filter.vehicleId,
        driverId: filter.driverId,
        lastName: filter.lastName,
      },
      onError: () => {
        ErrorService.pushErrorMessage("Failed to retrieve duty status data from the server.");
      },
      onOk: () => {
        this.freezer.get().set({hasSearched: true})
      }
    });
  }

  public async fetchVehicleAssignments(forceUpdate: boolean = false) {
    const results = this.freezer.get().vehicleAssignmentResults;
    const filter = this.freezer.get().assignmentFilter.toJS();

    if (results.hasFetched && !forceUpdate) {
      return;
    }

    // If any required fields aren't filled in, return and set error state
    if (filter.tenantId === undefined || filter.tenantId === "") {
      // Display message
      this.freezer.get().set({errorState: true});

      return;
    }

    this.freezer.get().set({errorState: false});
    const dateRange = DateRangeCalculator.convertToISOString(filter.dateRange);

    if (dateRange.endDate === undefined || dateRange.startDate === undefined) {
      return;
    }

    return managedAjaxUtil.fetchResults({
      ajaxStateProperty: "vehicleAssignmentResults",
      freezer: this.freezer,
      onExecute: (apiOptions, params, options) => {
        const vehicleSearchFactory = VehicleSearchApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
        return vehicleSearchFactory.apiV1VehicleSearchVehicleAssignmentGet(params);
      },
      params: {
        tenantId: filter.tenantId,
        startDateTime: dateRange.startDate,
        endDateTime: dateRange.endDate,
        vehicleId: filter.vehicleId,
        driverId: filter.driverId,
        lastName: filter.lastName,
      },
      onError: () => {
        ErrorService.pushErrorMessage("Failed to retrieve vehicle assignment data from the server.");
      },
      onOk: () => {
        this.freezer.get().set({hasSearched: true})
      }
    });
  }

  public setDutyStatusFilter(filter: Partial<ISearchFilter>, reload: boolean) {
    const currentPager = this.freezer.get().dutyStatusPager.toJS();
    const currentFilter = this.freezer.get().dutyStatusFilter.toJS();

    const assignedFilterValues = _.assign({}, currentFilter, filter);
    const assignedDates = DateRangeCalculator.convertToISOString(assignedFilterValues.dateRange);
    const currentDates = DateRangeCalculator.convertToISOString(currentFilter.dateRange);

    this.freezer.get().set({
      dutyStatusPager: _.assign(currentPager, { page: 0 }),
      dutyStatusFilter: assignedFilterValues,
    });

    if (reload &&
      (assignedFilterValues.tenantId !== currentFilter.tenantId ||
        assignedDates.endDate !== currentDates.endDate ||
        assignedDates.startDate !== currentDates.startDate ||
        assignedFilterValues.vehicleId !== currentFilter.vehicleId ||
        assignedFilterValues.driverId !== currentFilter.driverId ||
        assignedFilterValues.lastName !== currentFilter.lastName)) {
      this.fetchDutyStatus(true);
    }
  }

  public setAssignmentFilter(filter: Partial<ISearchFilter>, reload: boolean) {
    const currentPager = this.freezer.get().assignmentPager.toJS();
    const currentFilter = this.freezer.get().assignmentFilter.toJS();

    const assignedFilterValues = _.assign({}, currentFilter, filter);
    const assignedDates = DateRangeCalculator.convertToISOString(assignedFilterValues.dateRange);
    const currentDates = DateRangeCalculator.convertToISOString(currentFilter.dateRange);

    this.freezer.get().set({
      assignmentPager: _.assign(currentPager, { page: 0 }),
      assignmentFilter: assignedFilterValues,
    });

    if (reload && (assignedFilterValues.tenantId !== currentFilter.tenantId ||
      assignedDates.endDate !== currentDates.endDate ||
      assignedDates.startDate !== currentDates.startDate ||
      assignedFilterValues.vehicleId !== currentFilter.vehicleId ||
      assignedFilterValues.driverId !== currentFilter.driverId ||
      assignedFilterValues.lastName !== currentFilter.lastName)) {
      this.fetchVehicleAssignments(true);
    }
  }

  public setView(view: viewType) {
    this.freezer.get().set({
      view,
      errorState: false,
      hasSearched: false,
    });
  }

  public setDutyStatusPagerState(pagerState: Partial<IPagerState>) {
    this.freezer.get().dutyStatusPager.set(pagerState);
  }
  public setDutyStatusSortState(sortState: Partial<ISortState>) {
    this.freezer.get().dutyStatusSort.set(sortState);
  }

  public setAssignmentPagerState(pagerState: Partial<IPagerState>) {
    this.freezer.get().assignmentPager.set(pagerState);
  }
  public setAssignmentSortState(sortState: Partial<ISortState>) {
    this.freezer.get().assignmentSort.set(sortState);
  }
}

export const VehicleSearchService = new VehicleSearchFreezerService();
export type IVehicleSearchServiceInjectedProps = ReturnType<VehicleSearchFreezerService["getPropsForInjection"]>;
