import {
  React,
  _,
  bind,
} from "$Imports/Imports";

import {
  Container
} from "./Container";

import {
  Paper,
  TextField,
  Button,
  Stepper,
  Step,
  StepButton
} from "$Imports/MaterialUIComponents";

const styles: {
  paperStyle: string;
} = require("./OnBoardClient.scss");

interface IOnBoardClientBaseProps {

}

type IOnBoardClientProps = IOnBoardClientBaseProps;

interface IStep {
  id: number;
  stepText: string;
}

const steps: IStep[] = [
  {
    id: 0,
    stepText: "Account Step",
  },
  {
    id: 1,
    stepText: "Profile",
  },
  {
    id: 2,
    stepText: "Validation",
  },
  {
    id: 3,
    stepText: "Finish",
  },
];

interface IOnBoardClientState {
  currentStepId: number;
}

export class OnBoardClient extends React.Component<IOnBoardClientProps, IOnBoardClientState> {

  state: IOnBoardClientState = {
    currentStepId: 0,
  };

  @bind
  private _setStep(index: number) {
    this.setState({
      currentStepId: index,
    });
  }

  @bind
  private _moveNext() {
    const { currentStepId } = this.state;

    if (currentStepId < 3) {
      this.setState({
        currentStepId: currentStepId + 1,
      });
    }
  }

  @bind
  private _movePrevious() {
    const { currentStepId } = this.state;

    if (currentStepId > 0) {
      this.setState({
        currentStepId: currentStepId - 1,
      });
    }
  }

  render() {
    const { currentStepId } = this.state;

    return (
      <Container>
        <Paper
          className={styles.paperStyle}
          style={{
            padding: 4
          }}
        >
          <Stepper
            activeStep={currentStepId}
          >
            {
              _.map(steps, (s, sIdx) => (
                <Step key={sIdx}>
                  <StepButton onClick={() => this._setStep(s.id)} completed={s.id < currentStepId}>
                    {s.stepText}
                  </StepButton>
                </Step>
              ))
            }
          </Stepper>
        </Paper>
        <Paper
          className={styles.paperStyle}
          style={{
            padding: 4,
            height: 300,
          }}
        >
          {
            currentStepId === 0 ? (
              <div
                style={{
                  padding: 8,
                  width: 200,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  height: "100%"
                }}
              >
                <div>
                  <TextField
                    label="User Name"
                  />
                </div>
                <div>
                  <TextField
                    label="Password"
                    type="Password"
                  />
                </div>
                <div>
                  <TextField
                    label="Confirm Password"
                    type="Password"
                  />
                </div>
              </div>
            ) : null
          }
          {
            currentStepId === 1 ? (
              <div
                style={{
                  padding: 8,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%"
                }}
              >
                <div>
                  Profile information will be collected on this step.
                </div>
              </div>
            ) : null
          }
          {
            currentStepId === 2 ? (
              <div
                style={{
                  padding: 8,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%"
                }}
              >
                <div>
                  The user will validate all entered information on this step.
                </div>
              </div>
            ) : null
          }
          {
            currentStepId === 3 ? (
              <div
                style={{
                  padding: 8,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%"
                }}
              >
                <div>
                  Account setup is complete.
                </div>
              </div>
            ) : null
          }
        </Paper>
        <Paper
          className={styles.paperStyle}
          style={{
            display: "flex",
            justifyContent: "flex-end",
            padding: 4
          }}
        >
          <div
            style={{
              paddingRight: 8,
            }}
          >
            <Button
              disabled={currentStepId === 0}
              onClick={this._movePrevious}
            >
              Back
            </Button>
          </div>
          <div>
            <Button
              color="primary"
              variant="contained"
              disabled={currentStepId === 3}
              onClick={this._moveNext}
            >
              Next
            </Button>
          </div>
        </Paper>
      </Container>
    );
  }
}
