import {
    React,
    _,
    bind
  } from "$Imports/Imports";
  import { Button } from "$Imports/MaterialUIComponents";
  
  import { AdvanceTextField } from "$Components/Common";
  import { ICredentialProps } from "$State/CredentialTypes";
  import { LoadTrekCredentials } from "$Generated/api";
  
  interface ILoadTrekBaseProps {
  }
  type ILoadTrekProps = ILoadTrekBaseProps & ICredentialProps<LoadTrekCredentials>;
  
  export class LoadTrek extends React.PureComponent<ILoadTrekProps> {
  
    @bind
    onChange(e: any) {
      if (e.target.name === "username") {
        this.props.onChange(this.props.providerKey, { username: e.target.value });
      }
      else if (e.target.name === "password") {
        this.props.onChange(this.props.providerKey, { password: e.target.value });
      }
      else if (e.target.name === "company") {
        this.props.onChange(this.props.providerKey, { company: e.target.value });
      }
      else if (e.target.name === "driverGroup") {
        this.props.onChange(this.props.providerKey, { driverGroup: e.target.value });
      }
    }
  
    render(): JSX.Element {
      const loadTrek: LoadTrekCredentials = this.props.data ? this.props.data : {} as LoadTrekCredentials;
      return (
        <div>
          <AdvanceTextField
            label="User Name"
            name="username"
            onChange={this.onChange}
            value={loadTrek.username ? loadTrek.username : ""}
            error={(loadTrek.username === "")}
          /><br />
          <AdvanceTextField
            label="Password"
            name="password"
            type="password"
            onChange={this.onChange}
            value={loadTrek.password ? loadTrek.password : ""}
            error={(loadTrek.password === "")}
          /><br />
          <AdvanceTextField
            label="Company"
            name="company"
            onChange={this.onChange}
            value={loadTrek.company ? loadTrek.company : ""}
            error={(loadTrek.company === "")}
          /><br />
          <AdvanceTextField
            label="Group"
            name="driverGroup"
            onChange={this.onChange}
            value={loadTrek.driverGroup ? loadTrek.driverGroup : ""}
            error={(loadTrek.driverGroup === "")}
          /><br />
          <br />
          <Button className="float-right" disabled={true} color={"primary"}>VALIDATE CREDENTIALS</Button>{/* Disabled for as per LDM - 483 */}
        </div>
      );
    }
  }