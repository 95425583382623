import {
  React,
  _,
  bind,
  moment
} from "$Imports/Imports";

import {
  Button,
  InputLabel,
  MenuItem,
  Select
} from "$Imports/MaterialUIComponents";

import { AdvanceTextField } from "$Components/Common";
import { ICredentialProps } from "$State/CredentialTypes";
import { TrackItCredentials } from "$Generated/api";
import { ValidateCredentialsResults, validateCredentials } from "$State/ValidateCredentials";

const timeZones = moment.tz.names();
const timeZoneListItems = _.map(timeZones, (timeZone) => {
  if (timeZone.substring(0, 3) === "US/" || timeZone.substring(0, 3) === "UTC") // Filter out unneeded time zones - Moment.tz.names returns a couple hundred
  {
    return <MenuItem key={timeZone} value={timeZone}>{timeZone}</MenuItem>;
  }
});

interface ITrackItBaseProps { }

type TrackItValidateCredentials = TrackItCredentials & ValidateCredentialsResults;

type ITrackItProps = ITrackItBaseProps & ICredentialProps<TrackItValidateCredentials>;

export class TrackIt extends React.PureComponent<ITrackItProps> {
  @bind
  onChange(e: any) {
    if (e.target.name === "webKey") {
      this.props.onChange(this.props.providerKey, { webKey: e.target.value });
    }
    else if (e.target.name === "timeZoneString") {
      this.props.onChange(this.props.providerKey, { timeZoneString: e.target.value });
    }
  }

  @bind
  async validate (e:any) {
    this.props.onChange(this.props.providerKey, { running: true });
    var promiseResults = validateCredentials(this.props.data, this.props.providerKey);
    var results = await promiseResults;
    this.props.onChange(this.props.providerKey, { success: results.success ? 'true' : 'false' });
    this.props.onChange(this.props.providerKey, { errorResult: results.errorMessage });
    this.props.onChange(this.props.providerKey, { running: false });
  }

  render(): JSX.Element {
    const trackIt: TrackItCredentials = this.props.data ? this.props.data : {} as TrackItCredentials;
    return (
      <div>
        <AdvanceTextField
          label="Web Key*"
          name="webKey"
          onChange={this.onChange}
          value={trackIt.webKey ? trackIt.webKey : ""}
          error={(trackIt.webKey === "")}
        />
        <br />
        <InputLabel
          htmlFor="timeZone"
          style={{marginTop: "16px"}}
        >
          Time Zone*
        </InputLabel>
        <Select
          label="Time Zone*"
          name="timeZoneString"
          onChange={this.onChange}
          value={trackIt.timeZoneString}
          error={(trackIt.timeZoneString === "")}
          fullWidth
        >
          {timeZoneListItems}
        </Select>
        <br />
        <br />
        <Button disabled={this.props?.data?.running} onClick={this.validate} color={"primary"}>VALIDATE CREDENTIALS</Button>
        {this.props?.data?.success == 'none' || this.props?.data?.success === undefined ?
            <div />
            :
            this.props?.data?.success == 'false' ?
              <div style={{ color: "red", display: "block", marginLeft: "auto", marginRight: "auto" }}>{this.props?.data?.errorResult}</div>
              :
              <div style={{ color: "green", display: "block", marginLeft: "auto", marginRight: "auto", textAlign: "center" }}>Passed</div>}
      </div>
    );
  }
}