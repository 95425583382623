import {
  React,
  _,
  bind} from "$Imports/Imports";

import {
  Tabs,
  Tab,
  TabsProps
} from "$Imports/MaterialUIComponents";


import {
  ITenantViewItem
} from "./tenant-views/index";


interface IJobDetailTabsProps extends TabsProps {
  tenantViews: ITenantViewItem[];
  selectedTenantView: ITenantViewItem | null;
  onTenantViewChange?: (event: React.ChangeEvent<{}>, view?: ITenantViewItem) => void;
}
export class JobDetailTabs extends React.PureComponent<IJobDetailTabsProps> {

  @bind
  private _onChange(event: React.ChangeEvent<{}>, value: number) {
    if (this.props.onChange) {
      this.props.onChange(event, value);
    }

    if (this.props.onTenantViewChange) {
      this.props.onTenantViewChange(event, _.find(this.props.tenantViews, (t) => t.entityId === value));
    }
  }



  render() {
    const {
      selectedTenantView,
      tenantViews,
      value,
      onChange,
      ...passThroughProps
    } = this.props;

    return (
      <Tabs
        {...passThroughProps}
        onChange={this._onChange}
        value={selectedTenantView ? selectedTenantView.entityId : value}
      >
        {
          _.map(tenantViews, (t, tidx) => (
            <Tab
              key={tidx}
              value={t.entityId}
              label={t.label}
            />
          ))
        }
      </Tabs>
    );
  }
}