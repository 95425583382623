import {
  React,
} from "$Imports/Imports";

import {
  ArrowDownward,
  ArrowUpward
} from "$Imports/MaterialUIIcons";

const styles: {
  trendingUp: string;
  trendingDown: string;
  trendingContainer: string;
} = require("./TrendingIndicator.scss");

interface ITrendingIndicatorProps {
  trendingDirection: "Up" | "Down" | "None";
  labelClassName?: string;
}

export class TrendingIndicator extends React.PureComponent<ITrendingIndicatorProps> {
  render() {
    const {
      trendingDirection,
      labelClassName
    } = this.props;

    return (
      <div
        className={styles.trendingContainer}
      >
        <div
          className={labelClassName}
        >
          {this.props.children}
        </div>
        <div
          className={
            trendingDirection === "Up" ?
              styles.trendingUp
              : trendingDirection === "Down" ?
                styles.trendingDown : undefined}
        >

          {trendingDirection !== "None" ?
            trendingDirection === "Up" ? (
              <ArrowUpward />
            ) : (
                <ArrowDownward />
              ) : null
          }
        </div>

      </div>
    );
  }
}
