import {
  React,
  bind,
} from "$Imports/Imports";

import {
  AdvanceTextField
} from "$Components/Common";

import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Button,
  FormControlLabel,
  Switch
} from "$Imports/MaterialUIComponents";

import {
  viewType,

} from "$State/TenantSummaryFreezerService";

import {
  TenantViewModel
} from "$Generated/api";

interface IEditAddTenantDialogProps {
  view: viewType;
  tenant: TenantViewModel | null;
  onUpdateTenant?: (tenant: TenantViewModel) => void;
  onCancel?: () => void;
  onSave?: () => void;
}

export class EditAddTenantDialog extends React.PureComponent<IEditAddTenantDialogProps> {

  @bind
  private _onCancelClick(event: React.MouseEvent<HTMLButtonElement>) {
    if (this.props.onCancel) {
      this.props.onCancel();
    }
  }

  @bind
  private _onSaveClick(event: React.MouseEvent<HTMLButtonElement>) {
    if (this.props.onSave) {
      this.props.onSave();
    }
  }

  render() {

    const {
      view,
      tenant
    } = this.props;

    const isOpen = view !== "None";

    return tenant ? (
      <Dialog
        open={isOpen}
      >
        <DialogTitle>
          {
            view === "Add" ? "Add Tenant" : "Edit Tenant"
          }
        </DialogTitle>
        <DialogContent>
          <div>
            <div>
              <AdvanceTextField
                label="Tenant Id"
              />
              <AdvanceTextField
                label="Friendly Name"
              />
              <AdvanceTextField
                label="Image Name"
              />
            </div>
            <div>
              <AdvanceTextField
                label="Cron Tab Schedule"
              />
              <FormControlLabel
                control={
                  (<Switch checked={true} value="active" />)
                }
                label="Is Active"
              />
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={this._onCancelClick} color="primary">
            Cancel
          </Button>
          <Button onClick={this._onSaveClick} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    ) : null;
  }
}
