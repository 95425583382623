import {
  React,
  bind,
  _
} from "$Imports/Imports";

import {
  TableRow,
  TableHead,
  TableCell
} from "$Imports/MaterialUIComponents";

import {
  directionType,
  DataGridHeaderSortColumn,
} from "../";

import {
  IDataTableColumn,
} from "./IDataTableColumn";

interface IDataTableHeaderProps<T = unknown> {
  sortColumnName?: string;
  sortDirection?: directionType;
  columns: IDataTableColumn<T>[];
  onSortColumnClick?: (event: React.MouseEvent<HTMLElement>, sortColumnName: string | undefined, sortDirection: directionType) => void;
}

export class DataTableHeader<T = unknown> extends React.PureComponent<IDataTableHeaderProps<T>> {

  @bind
  private _onSortColumnClick(event: React.MouseEvent<HTMLElement>, sortColumnName: string | undefined, sortDirection: directionType) {
    if (this.props.onSortColumnClick) {
      this.props.onSortColumnClick(event, sortColumnName, sortDirection);
    }
  }

  render() {
    const { sortDirection, sortColumnName, columns } = this.props;

    return (
      <TableHead>
        <TableRow>
          {_.map(columns, (c, cIdx: number) => {
            const headerProps = c.headerProps ? c.headerProps : {};
            const { onClick, ref, ...passThroughHeaderProps } = headerProps;

            return c.sortMethod !== undefined ? (
              <DataGridHeaderSortColumn
                key={cIdx}
                label={c.headerValue}
                {...passThroughHeaderProps}
                propertyName={c.columnName}
                direction={c.columnName === sortColumnName ? sortDirection : null}
                onSortClick={this._onSortColumnClick}
              />
            ) : (
                <TableCell
                  key={cIdx}
                  {...passThroughHeaderProps}
                >
                  {c.headerValue}
                </TableCell>
              );
          })}
        </TableRow>
      </TableHead>
    );
  }
}
