import {
  React,
  bind,
  _
} from "$Imports/Imports";

import {
  TableRow,
} from "$Imports/MaterialUIComponents";

import {
  DataTableCell
} from "./DataTableCell";

import {
  IDataTableColumn
} from "./IDataTableColumn";

export interface IDataTableRowProps<T = unknown> {
  data: T;
  columns: IDataTableColumn<T>[];
  onRowClick?: (event: React.MouseEvent<HTMLTableRowElement, MouseEvent>, data: T) => void;
  onCellClick?: (event: React.MouseEvent<HTMLTableCellElement, MouseEvent>, data: T, column: IDataTableColumn<T>) => void;
  setRowStyle?: (rowData: T) => string;
}

export class DataTableRow<T = unknown> extends React.PureComponent<IDataTableRowProps<T>> {

  @bind
  private _onRowClick(event: React.MouseEvent<HTMLTableRowElement, MouseEvent>) {
    if (this.props.onRowClick) {
      this.props.onRowClick(event, this.props.data);
      event.stopPropagation();
    }
  }

  @bind
  private _onCellClick(event: React.MouseEvent<HTMLTableCellElement, MouseEvent>, data: T, column: IDataTableColumn<T>) {
    if (this.props.onCellClick) {
      this.props.onCellClick(event, data, column);
    }
  }

  render() {
    const { data, columns, setRowStyle } = this.props;

    return (
      <TableRow className={setRowStyle ? setRowStyle(data) : ""}
        onClick={this._onRowClick}
      >
        {
          _.map(columns, (c, cidx) => (
            <DataTableCell 
              column={c}
              data={data}
              key={cidx}
              onCellClick={this._onCellClick}
            />
          ))
        }
      </TableRow>
    );
  }
}
