import { React } from "$Imports/Imports";

const styles: {
  container: string;
} = require("./Container.scss");

export class Container extends React.PureComponent {
  render() {
    return (
      <div
        className={styles.container}
      >
        {this.props.children}
      </div>
    );
  }
}
