import {
  React,
  _,
  bind
} from "$Imports/Imports";
import { Button } from "$Imports/MaterialUIComponents";

// data models
import { AdvanceTextField } from "$Components/Common";
import { ICredentialProps } from "$State/CredentialTypes";
import { DriverTechCredentials } from "$Generated/api";

interface IDrivertechBaseProps {
}


type IDrivertechProps = IDrivertechBaseProps & ICredentialProps<DriverTechCredentials>;
export class Drivertech extends React.PureComponent<IDrivertechProps> {

  @bind
  handleOnChange(e: any): void {
    if (e.target.name === "username") {
      this.props.onChange(this.props.providerKey, { username: e.target.value });
    }
    else if (e.target.name === "password") {
      this.props.onChange(this.props.providerKey, { password: e.target.value });
    }
    else if (e.target.name === "datastoreName") {
      this.props.onChange(this.props.providerKey, { datastoreName: e.target.value });
    }
    else if (e.target.name === "companyCode") {
      this.props.onChange(this.props.providerKey, { companyCode: e.target.value });
    }
  }

  render(): JSX.Element {
    const drivertech: DriverTechCredentials = this.props.data ? this.props.data : {} as DriverTechCredentials;
    return (
      <div>
        <AdvanceTextField
          label="Username*"
          name="username"
          autoComplete="off"
          value={drivertech.username}
          onChange={this.handleOnChange}
          error={(drivertech.username === "")}
        /><br />
        <AdvanceTextField
          name="password"
          autoComplete="new-password"
          type="password"
          label="Password*"
          value={drivertech.password}
          onChange={this.handleOnChange}
          error={(drivertech.password === "")}
        /><br />
        <AdvanceTextField
          name="datastoreName"
          autoComplete="off"
          label="DataStore Name*"
          value={drivertech.datastoreName}
          onChange={this.handleOnChange}
          error={(drivertech.datastoreName === "")}
        /><br />
        <AdvanceTextField
          label="Company ID*"
          name="companyCode"
          value={drivertech.companyCode}
          onChange={this.handleOnChange}
          error={(drivertech.companyCode === "")}
        /><br />
        <Button className="float-right" disabled={true} color={"primary"} >VALIDATE CREDENTIALS</Button>
      </div>
    );
  }
}