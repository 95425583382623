import {
  React,
  _,
  bind
} from "$Imports/Imports";

import {
  Button
} from "$Imports/MaterialUIComponents";

import { AdvanceTextField } from "$Components/Common";

import {
  BigChangeCredentials,
} from "$Generated/api";

import {
  ICredentialProps
} from "$State/CredentialTypes";

interface IBigChangeBaseProps {
}

type IBigChangeProps = IBigChangeBaseProps & ICredentialProps<BigChangeCredentials>;

export class BigChange extends React.PureComponent<IBigChangeProps> {

  @bind
  private _onChange(e: any) {
    if (e.target.name === "username") {
      this.props.onChange(this.props.providerKey, { username: e.target.value });
    }
    else if (e.target.name === "password") {
      this.props.onChange(this.props.providerKey, { password: e.target.value });
    }
    else if (e.target.name === "apiKey") {
      this.props.onChange(this.props.providerKey, { apiKey: e.target.value });
    }
    else if (e.target.name === "baseUrl") {
      this.props.onChange(this.props.providerKey, { baseUrl: e.target.value });
    }
  }

  render(): JSX.Element {
    const bigChange: BigChangeCredentials = this.props.data ? this.props.data : {} as BigChangeCredentials;

    return (
      <div>
        <AdvanceTextField
          label="Username*"
          name="username"
          autoComplete="off"
          value={bigChange.username}
          onChange={this._onChange}
          error={(bigChange.username === "")}
        /><br />
        <AdvanceTextField
          name="password"
          autoComplete="new-password"
          type="password"
          label="Password*"
          value={bigChange.password}
          onChange={this._onChange}
          error={(bigChange.password === "")}
        /><br />
        <AdvanceTextField
          name="apiKey"
          autoComplete="off"
          label="API Key*"
          value={bigChange.apiKey}
          onChange={this._onChange}
          error={(bigChange.apiKey === "")}
        /><br />
        <AdvanceTextField
          name="baseUrl"
          autoComplete="off"
          label="Base URL*"
          value={bigChange.baseUrl}
          onChange={this._onChange}
          error={(bigChange.baseUrl === "")}
        /><br />
        <Button className="float-right" disabled={true} color={"primary"}>VALIDATE CREDENTIALS</Button>
      </div>
    );
  }
}