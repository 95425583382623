import {
  React,
  _,
  bind
} from "$Imports/Imports";
import { Button } from "$Imports/MaterialUIComponents";
import { AdvanceTextField } from "$Components/Common";
import { ICredentialProps } from "$State/CredentialTypes";
import { EmailCSVCredentials } from "$Generated/api";

interface IEmailCSVBaseProps {

}

type IEmailCSVProps = IEmailCSVBaseProps & ICredentialProps<EmailCSVCredentials>;

export class EmailCSV extends React.PureComponent<IEmailCSVProps>{

  @bind
  onChange(e: any) {
    if (e.target.name === "messageHeaderIdentificationPrefix") {
      this.props.onChange(this.props.providerKey, { messageHeaderIdentificationPrefix: e.target.value });
    }
  }

  render(): JSX.Element {
    const emailCSV: EmailCSVCredentials = this.props.data ? this.props.data : {} as EmailCSVCredentials;
    return (
      <div>
        <AdvanceTextField
          label="Unique ID*"
          name="messageHeaderIdentificationPrefix"
          autoComplete="off"
          onChange={this.onChange}
          value={emailCSV.messageHeaderIdentificationPrefix ? emailCSV.messageHeaderIdentificationPrefix : ''}
          error={(emailCSV.messageHeaderIdentificationPrefix === "")}
        /><br />
        <br />
        <Button className="float-right" disabled={true} color={"primary"} >VALIDATE CREDENTIALS</Button>{/* Disabled for as per LDM - 483 */}
      </div>
    );
  }
}