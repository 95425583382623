import { AdvanceTextField } from "$Components/Common";
import {
  React,
  bind,
  _
} from "$Imports/Imports";

import {
  Dialog,
  DialogActions,
  Button,
  DialogTitle,
  DialogContent,
} from "$Imports/MaterialUIComponents";

import {
  TenantContactsService,
  ITenantContactsServiceInjectedProps
} from "$State/tenant-views/TenantContactsFreezerService";

interface IEditLytxContactsBaseProps {
  onClose(): void;
  open: boolean;
  tenantId: string;
  onSave(): void;
}

type IEditLytxContactsProps = IEditLytxContactsBaseProps & ITenantContactsServiceInjectedProps;

class _EditLytxContacts extends React.Component<IEditLytxContactsProps> {
  @bind
  private _onCancel(): void {
    this.props.onClose();
  }

  @bind
  private _onSave(): void {
    this.props.onSave();
  }

  @bind
  private _onContactsTextChange(e: React.ChangeEvent<HTMLInputElement>) {
    this.props.tenantContactsService.onEditChange(e.target.value);
  }

  render() {
    const actions = [
      (
        <Button variant="contained"
          onClick={this._onCancel}
          key={"Cancel"}
        >Cancel</Button>
      ),
      (
        <Button variant="contained"
          onClick={this._onSave}
          key={"Save"}
        >Save</Button>
      )
    ];

    return (
      <Dialog
        open={this.props.open}
        onClose={this.props.onClose}
        key={"dialog"}
      >
        <DialogTitle>Error Report Email Recipients</DialogTitle>
        <DialogContent>
          <div style={{width: '350px'}}>
            <div>Enter email addresses one per line.</div>
            <AdvanceTextField
              onChange={this._onContactsTextChange}
              value={this.props.tenantContactsService.getEmailsAsString()}
              rows={8}
              multiline
              fullWidth
            />
            {this.props.tenantContactsService.freezer.get().valid
              ? undefined :
              <div style={{ color: "red", display: "block", marginLeft: "auto", marginRight: "auto" }}>Invalid email address.</div>
            }
          </div>
        </DialogContent>
        <DialogActions>{actions}</DialogActions>
      </Dialog>
    );
  }
}

export const EditLytxContacts = TenantContactsService.inject(
  _EditLytxContacts
);