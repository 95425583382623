import { Freezer, _ } from "$Imports/Imports";
import { FetchAPI } from "$Generated/api";
import { SitePubSubManager } from "$Utilities/PubSubUtil";

export interface IErrorMessage {
  statusCode: number;
  message: string;  
}

export type ajaxStateType = "initial" | "error" | "precondition-failed" | "will-fetch-soon" | "fetching" | "ok" | "cleared";

export type IFetcher = typeof fetch;

let startingRequestId = -1;

function getStartingRequestId(): number {
  startingRequestId += 1;
  return startingRequestId;
}

export interface IApiOptions {
  baseUrl: string;
  wrappedFetch: IFetcher | FetchAPI;
}

const apiOptions: IApiOptions = {
  baseUrl: "",
  wrappedFetch: fetch,
};

export function setBaseUrl(baseUrl: string) {
  apiOptions.baseUrl = baseUrl;
}

export function setFetch(fetch: IFetcher | FetchAPI) {
  apiOptions.wrappedFetch = fetch;
}

export function createInitialState<T>(startRequestId: number | null = null): IAjaxState<T> {
  const newId = startRequestId === null ? getStartingRequestId() : startRequestId;

  return {
    isFetching: false,
    error: null,
    errorMessage: null,
    hasFetched: false,
    requestId: newId,
    data: null,
    state: "initial",
  };
}

export function clear<T>(state: IAjaxState<T>) {
  state.isFetching = false;
  state.error = null;
  state.errorMessage = null;
  state.hasFetched = false;
  state.data = null;
  state.state = "cleared";
  return state;
}

export function clearError<T>(state: IAjaxState<T>) {
  state.isFetching = false;
  state.error = null;
  state.errorMessage = null;
  state.state = "cleared";
  return state;
}

export interface IAjaxState<T> {
  isFetching: boolean;
  error?: object | null;
  errorMessage?: string | null;
  hasFetched: boolean;
  requestId?: number;
  data?: T | null;
  state?: ajaxStateType;
}

export interface IFetchOptions<D, P extends object, F extends Freezer.Types.IFreezer> {
  freezer: F;
  ajaxStateProperty?: string;
  getAjaxState?: (options: IFetchOptions<D, P, F>) => IAjaxState<D>;
  setAjaxState?: (options: IFetchOptions<D, P, F>, newStatus: IAjaxState<D>) => void;
  clearDataOnFetch?: boolean;
  onExecute: (apiOptions: IApiOptions, params: P, options?: any) => Promise<D>;
  precondition?: () => boolean;
  onOk?: (data: D) => D | null | void;
  onFetching?: () => void;
  onError?: (err: IErrorMessage, errorString: string) => void | string;
  errorString?: string;
  params?: P;
}

function getAjaxState<D, P extends object, F extends Freezer.Types.IFreezer>(options: IFetchOptions<D, P, F>): IAjaxState<D> {
  if (options.ajaxStateProperty) {
    return options.freezer.get()[options.ajaxStateProperty];
  }

  if (options.getAjaxState) {
    return options.getAjaxState(options);
  }

  throw new Error("ajaxStateProperty or getAjaxState is required.");
}

function setAjaxState<D, P extends object, F extends Freezer.Types.IFreezer>(options: IFetchOptions<D, P, F>, newState: IAjaxState<D>): void {
  if (
    (options.ajaxStateProperty === undefined || options.ajaxStateProperty === null) &&
    (options.setAjaxState === undefined || options.setAjaxState === null)
  ) {
    throw new Error("ajaxStateProperty or setAjaxState is required.");
  }

  if (options.ajaxStateProperty) {
    options.freezer.get()[options.ajaxStateProperty].set(newState);
  }

  if (options.setAjaxState) {
    options.setAjaxState(options, newState);
  }
}

export async function fetchResults<P extends object, D, F extends Freezer.Types.IFreezer>(options: IFetchOptions<D, P, F>): Promise<void | D> {

  if (options.freezer === null) {
    throw new Error("Freezer is null");
  }

  const initialState = getAjaxState(options);
  const clearData: boolean = options.clearDataOnFetch === null || options.clearDataOnFetch === undefined ? false : options.clearDataOnFetch;
  const requestId = (initialState.requestId || 1) + 1;

  // Check if precondition is true.
  if (options.precondition) {
    if (!options.precondition()) {

      setAjaxState(options, {
        isFetching: false,
        error: null,
        hasFetched: false,
        requestId,
        data: null,
        state: "precondition-failed"
      });

      // options.helper.getLogger().warn(options, `Request #${requestId} precondition not met`, options);

      // Stop execution
      return Promise.reject<D>();
    }
  }

  setAjaxState(options, {
    isFetching: true,
    error: null,
    hasFetched: false,
    requestId,
    data: clearData ? null : initialState.data,
    state: "fetching",
  });

  if (options.onFetching) {
    options.onFetching();
  }

  let params: P = {} as P;

  if (options.params !== undefined) {
    params = options.params;
  }

  await options.onExecute(apiOptions, params, options)
    .then((data: D) => dataFunc(data, options, requestId))
    .catch((err: Response) => errorFunc(err, options, requestId));
}

function dataFunc<D, P extends object, F extends Freezer.Types.IFreezer>(data: D, options: IFetchOptions<D, P, F>, requestId: number) {

  if (options.freezer === null) {
    throw new Error("Freezer is null");
  }

  const currentState = getAjaxState(options);

  if (requestId === currentState.requestId) {

    if (options.onOk) {
      // Massage data
      data = options.onOk(data) || data;
    }

    // Set OK state
    setAjaxState(options, {
      isFetching: false,
      error: null,
      hasFetched: true,
      requestId,
      data,
      state: "ok",
    });

  } else {
    // options.helper.getLogger().warn(options, `Request #${requestId} stale ok result`);
  }

  return data;
}

function errorFunc<D, P extends object, F extends Freezer.Types.IFreezer>(err: Response, options: IFetchOptions<D, P, F>, requestId: number) {
  if (options.freezer === null) {
    throw new Error("Freezer is null");
  }

  const currentState = getAjaxState(options);
  const clearData: boolean = options.clearDataOnFetch === null || options.clearDataOnFetch === undefined ? false : options.clearDataOnFetch;

  // options.helper.getLogger().x(`Error has occurred in request #${requestId}`, options.ajaxStateProperty, err);

  if (requestId === currentState.requestId) {

    let errorString = options.errorString || " Server Error";

    if (options.onError) {
      errorString = options.onError({
        message: err.statusText,
        statusCode: err.status
      }, errorString) || errorString;
    }

    setAjaxState(options, {
      isFetching: false,
      error: err.body,
      errorMessage: errorString,
      hasFetched: false,
      requestId,
      data: clearData ? null : currentState.data,
      state: "error",
    });

    SitePubSubManager.publish("application:fetch-error", { statusCode: err.status, message: err.statusText} as IErrorMessage);
  } else {
    // options.helper.getLogger().warn(options, `Request #${requestId} stale error result`);
  }

  throw err;
}

function getQueryString(values: { [k: string]: string }): string | null {
  if (values === null || values === undefined) {
    return null;
  }
  let finalQueryString = "?";

  const keys = _.keys(values);
  _.forEach(keys, (c: string, idx) => {
    const keyValuePair: string = values[c] === null || values[c] === undefined ? `${encodeURIComponent(c)}=` : `${encodeURIComponent(c)}=${encodeURIComponent(String(values[c]))}`;

    if (finalQueryString.length === 1) {
      finalQueryString += keyValuePair;
    } else {
      finalQueryString += `&${keyValuePair}`;
    }
  });

  return finalQueryString;
}
