import {
  FreezerService,
  _,
  bind
} from "$Imports/Imports";

import { INavigationItem, mainNavigation } from "../modules/navigation";
import { isNullOrUndefined } from "../modules/helpers";

import * as H from "history";

const InjectedPropName = "navigationService";

interface INavigationState {
  url?: string;
  navigationParentNavigation: INavigationItem | null;
}

class NavigationFreezerService extends FreezerService<INavigationState, typeof InjectedPropName> {
  constructor() {
    super({
      url: window.location.pathname,
      navigationParentNavigation: null,
    }, InjectedPropName);

    this.freezer.get().set({
      navigationParentNavigation: this.getParentPath(window.location.pathname),
    });
  }

  private history: H.History | null = null;

  public navigateTo(url: string) {
    if (this.history) {
      this.history.push(url);
    }
  }

  public setWindowsTitle(title: string) {
    document.title = title;
  }
  public updateTitle() {
    const navigation = this.getParentPath(window.location.pathname);

    if (navigation) {
      this.setWindowsTitle(navigation.title ? navigation.title : `TTIP - ${navigation.label}`);
    } else {
      this.setWindowsTitle(`TTIP`);
    }
  }

  public getNavigationDetail(): INavigationItem | null {
    return this.freezer.get().toJS().navigationParentNavigation;
  }

  public getCurrentParentPath(): INavigationItem | null {
    const currentPage = this.getNavigationDetail();
    if (currentPage) {
      return this.getParentPath(currentPage.url);
    }

    return null;
  }

  public getParentPath(url: string): INavigationItem | null {
    const currentPath = _.split(url, "/");

    if (currentPath.length <= 2) {
      return this.findNavigation(url);
    } else {
      currentPath.pop();
      const combinedString = _.join(currentPath, "/");
      return this.findNavigation(combinedString);
    }
  }

  public initHistory(historyObject: H.History) {
    if (historyObject) {
      this.history = historyObject;
      this.history.listen(this._onHistoryChanged);
    }
  }

  private updateState(url: string) {
    const navRec = this.findNavigation(url);
    this.freezer.get().set({
      url,
      navigationParentNavigation: navRec,
    });
  }

  @bind
  private _onHistoryChanged(event: H.Location) {
    this.updateState(event.pathname);
  }

  private findNavigation(url: string, navigationItems: INavigationItem[] = mainNavigation): INavigationItem | null {
    let foundItem: INavigationItem | null | undefined = _.find(navigationItems, (d: INavigationItem) => {
      return d.url === url;
    });

    if (foundItem) {
      return foundItem;
    }

    _.forEach(navigationItems, (m: INavigationItem) => {
      if (!isNullOrUndefined(m.childNavigation) && m.childNavigation !== undefined && m.childNavigation.length !== 0) {
        foundItem = this.findNavigation(url, m.childNavigation);
      }

      // Break the foreach each loop if navigation item found.
      if (!isNullOrUndefined(foundItem)) {
        return false;
      }
    });

    return foundItem === undefined ? null : foundItem;
  }
}

export const NavigationService = new NavigationFreezerService();
export type INavigationServiceInjectedProps = ReturnType<NavigationFreezerService["getPropsForInjection"]>;
