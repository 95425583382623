import {
  React,
  _,
  bind
} from "$Imports/Imports";
import { Button, Select, MenuItem, InputLabel, Checkbox, ListItemText, FormControl  } from "$Imports/MaterialUIComponents";
import { AdvanceTextField } from "$Components/Common";
import { ICredentialProps } from "$State/CredentialTypes";
import { GeotabCredentials } from "$Generated/api";
import { ValidateCredentialsResults, validateCredentials } from "$State/ValidateCredentials";

interface IGeoTabBaseProps {
}

type GeotabCredentialsValidation = GeotabCredentials & ValidateCredentialsResults;

type IGeoTabProps = IGeoTabBaseProps & ICredentialProps<GeotabCredentialsValidation>;

const geoTabServiceOptions = [
  //'DriverChange',  //disabled for now as DriverChange option is not implemented
  'DriverLog',
  'Trips', 
];

export class GeoTab extends React.PureComponent<IGeoTabProps>{
  defaultSet = false;
 
  @bind
  onChange(e: any) {
    if (e.target.name === "userName") {
      this.props.onChange(this.props.providerKey, { userName: e.target.value});
    }
    else if (e.target.name === "password") {
      this.props.onChange(this.props.providerKey, { password: e.target.value});
    }
    else if (e.target.name === "server") {
      this.props.onChange(this.props.providerKey, { server: e.target.value});
    }
    else if (e.target.name === "database") {
      this.props.onChange(this.props.providerKey, { database: e.target.value});
    }
    else if (e.target.name == "serviceOptions") {
      this.props.onChange(this.props.providerKey, { serviceOptions:  e.target.value as string[]});
    }
    else
    {
      this.props.onChange(this.props.providerKey, { sessionId: " " });
      this.defaultSet = true;
    }
  }

  @bind
  async validate (e:any) {
    this.props.onChange(this.props.providerKey, { running: true });
    var promiseResults = validateCredentials(this.props.data, this.props.providerKey);
    var results = await promiseResults;
    this.props.onChange(this.props.providerKey, { success: results.success ? 'true' : 'false' });
    this.props.onChange(this.props.providerKey, { errorResult: results.errorMessage });
    this.props.onChange(this.props.providerKey, { running: false });
  }

  render(): JSX.Element {
    const geoTab: GeotabCredentials = this.props.data ? this.props.data : {} as GeotabCredentials;
    if (!this.defaultSet) this.onChange({target: {}});
    return (
      <div>
        <AdvanceTextField
          label="Username*"
          name="userName"
          autoComplete="off"
          onChange={this.onChange}
          value={geoTab.userName ? geoTab.userName : ''}
          error={(geoTab.userName === "")}
        /><br />
        <AdvanceTextField
          label="Password*"
          name="password"
          type="password"
          autoComplete="new-password"
          onChange={this.onChange}
          value={geoTab.password ? geoTab.password : ''}
          error={(geoTab.password === "")}
        /><br />
        <AdvanceTextField
          label="Server*"
          name="server"
          onChange={this.onChange}
          value={geoTab.server ? geoTab.server : 'my.geotab.com'} // As it is will required field validator for firstime we are assigning deafult server
          error={(geoTab.server === "")}
        />
        <br />
        <AdvanceTextField
          label="Database*"
          onChange={this.onChange}
          name="database"
          value={geoTab.database ? geoTab.database : ''}
          error={(geoTab.database === "")}
        />
        <br />      
        <FormControl>
          <InputLabel id="serviceOptionsLabel" >Service Options*</InputLabel>
          <Select 
            style={{ width: "182px" }}
            labelId="serviceOptionsLabel"
            id="serviceOptions"
            name="serviceOptions"
            value={geoTab.serviceOptions ? geoTab.serviceOptions : [] }
            onChange={this.onChange}
            renderValue={(selected) => (selected as string[]).join(', ')}
            multiple={true}
            error={geoTab.serviceOptions && geoTab.serviceOptions.length <= 0}
          >
            {geoTabServiceOptions.map((serviceOption) => (
              <MenuItem key={serviceOption} value={serviceOption}>
                <Checkbox checked={(geoTab.serviceOptions ? geoTab.serviceOptions : []).indexOf(serviceOption) > -1} />
                <ListItemText primary={serviceOption} />
              </MenuItem>
            ))}
          </Select>  
        </FormControl>
        <br />
        <Button disabled={this.props?.data?.running} onClick={this.validate} color={"primary"}>VALIDATE CREDENTIALS</Button>
        {this.props?.data?.success == 'none' || this.props?.data?.success === undefined ?
            <div />
            :
            this.props?.data?.success == 'false' ?
              <div style={{ color: "red", display: "block", marginLeft: "auto", marginRight: "auto" }}>{this.props?.data?.errorResult}</div>
              :
              <div style={{ color: "green", display: "block", marginLeft: "auto", marginRight: "auto", textAlign: "center" }}>Passed</div>}
      </div>
    );
  }
}