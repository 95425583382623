// enum (string) value is Pascal case (current api/generated convention)
export function getEnumLabel(enumValue: string) : string {
    // regex w/ positive lookahead to split, but keep single capitals
    return enumValue.split(/(?=[A-Z])/)
        .join(" ");
}

export const enum ClientIdsEnum {
    SmartDrive = 12,
    Netradyne = 44,
    Lytx = 45,
    Samsara = 80
}

export const enum SubmissionProviderIdsEnum {
    SmartDrive = 14,
    Netradyne = 34,
    Lytx = 35,
    Samsara = 79
}
