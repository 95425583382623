import {
  FreezerService,
  IAjaxState,
  managedAjaxUtil,
  _,
  bind
} from "$Imports/Imports";

import {
  CategoryItemIEnumerableResponseBase,
  AssociatedEntitiesApiFactory,
} from "$Generated/api";

import { SitePubSubManager } from "$Utilities/PubSubUtil";

import { ErrorService } from "./ErrorFreezerService";

interface IResourceFreezerState {
  sourceProviderResults: IAjaxState<CategoryItemIEnumerableResponseBase>;
}

const InjectedPropName = "resourceService";

class ResourceFreezerService extends FreezerService<IResourceFreezerState, typeof InjectedPropName>{
  constructor() {
    super({
      sourceProviderResults: managedAjaxUtil.createInitialState()
    }, InjectedPropName);

    SitePubSubManager.subscribe("application:login:before", this.clearResults);
  }

  @bind
  private clearResults() {
    this.freezer.get().set({
      sourceProviderResults: managedAjaxUtil.createInitialState(),
    });
  }

  public async fetchAdapterList(forceUpdate: boolean = false) {
    const sourceState = this.freezer.get().sourceProviderResults;

    if (sourceState.hasFetched && !forceUpdate) {
      return;
    }

    return managedAjaxUtil.fetchResults({
      ajaxStateProperty: "sourceProviderResults",
      freezer: this.freezer,
      onExecute: (apiOptions) => {
         const associatedEntitiesFactory = AssociatedEntitiesApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);        
         return associatedEntitiesFactory.apiV1AssociatedEntitiesSourceProvidersGet();
      },
      onError: () => {
        ErrorService.pushErrorMessage("Failed to retrieve source provider data from the server.");
      }
    });
  }
}

export const ResourceService = new ResourceFreezerService();
export type IResourceServiceInjectedProps = ReturnType<ResourceFreezerService["getPropsForInjection"]>;
