export const categoryTypes: {
  adapterType: number;
  client: number;
  configuration: number;
  sourceProvider: number;
  submissionProvider: number;
} = {
  adapterType: 1,
  client: 2,
  configuration: 3,
  sourceProvider: 4,
  submissionProvider: 5
};

export const entityTypes: {
  tenant: number;
  job: number;
} = {
  job: 2,
  tenant: 1,
};
