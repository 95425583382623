import {
  React,
  bind,
  _
} from "$Imports/Imports";

import {
  Dialog,
  DialogActions,
  Button
} from "$Imports/MaterialUIComponents";

// Components
import { EditCredentialsDialogContents } from "./EditCredentialsDialogContents";

// data models
import {
  TenantCredentialService,
  ITenantCredentialServiceInjectedProps
} from "$State/tenant-views/TenantCredentialFreezerService";

// state and props
interface IEditCredentialsDialogBaseProps {
  onClose(): void;
  open: boolean;
  tenantId: string;
  submitCredentialsData(): void;
}

type IEditCredentialsDialogProps = IEditCredentialsDialogBaseProps & ITenantCredentialServiceInjectedProps;

export class _EditCredentials extends React.Component<IEditCredentialsDialogProps> {

  @bind
  private _onCancel(): void {
    this.props.onClose();
  }

  @bind
  private _onSubmit(): void {
    this.props.submitCredentialsData();
  }

  render(): JSX.Element {
    const actions = [
      (
        <Button variant="contained"
          onClick={this._onCancel}
          key={"Cancel"}
        >Cancel</Button>
      ),
      (
        <Button variant="contained"
          onClick={this._onSubmit}
          key={"Submit"}
          disabled={this.props.tenantCredentialService.freezer.get().credentialsMissing}
        >Submit</Button>
      )
    ];

    const title: string = "Edit Credentials";
    return (
      <Dialog
        title={title}
        open={this.props.open}
        onClose={this.props.onClose}
        key={"dialog"}
      >
        <EditCredentialsDialogContents
          key={"editCredentialsContents"}
          tenantId={this.props.tenantId}
        />
        <DialogActions>{actions}</DialogActions>
        {this.props.tenantCredentialService.freezer.get().credentialsMissing ? <div style={{ color: "red", display: "block", marginLeft: "auto", marginRight: "auto" }}>All Fields Required</div> : <div />}
      </Dialog>
    );
  }
}

export const EditCredentials = TenantCredentialService.inject(
  _EditCredentials
);