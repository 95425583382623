import {
  _
} from "$Imports/Imports";

import {
  DutyStatusActivityDutyStatusEnum,
} from "$Generated/api";

export interface IDutyStatus {
  status: DutyStatusActivityDutyStatusEnum;
  label: string;
}

export const dutyStatusList: IDutyStatus[] = [
  {
    status: "Unknown",
    label: "Unknown"
  },
  {
    status: "LogOff",
    label: "Log Off",
  },
  {
    status: "OffDuty",
    label: "Off Duty"
  },
  {
    status: "Sleeper",
    label: "Sleeper"
  },
  {
    status: "LogIn",
    label: "Log In"
  },
  {
    status: "OnDuty",
    label: "On Duty"
  },
  {
    status: "OffDutyDriving",
    label: "Off Duty Driving"
  },
  {
    status: "Driving",
    label: "Driving"
  }
];

export function getDutyStatus(status?: DutyStatusActivityDutyStatusEnum, statusList: IDutyStatus[] = dutyStatusList): IDutyStatus | undefined {
  return _.find(statusList, (s) => s.status === status);
}

export function getDutyStatusName(status?: DutyStatusActivityDutyStatusEnum, statusList: IDutyStatus[] = dutyStatusList): string | undefined {
  const foundId = getDutyStatus(status, statusList);

  if (foundId !== undefined) {
    return foundId.label;
  }

  return undefined;
}
