import {
  React,
  _
} from "$Imports/Imports";

import {
  AdvanceTextField
} from "$Components/Common";

import {
  CategoryItem,
} from "$Generated/api";

interface IAdapterDisplayProps {
  sourceAdapterData: CategoryItem[];
  adapterId?: number;
  adapterFriendlyName?: string;
}

export class AdapterDisplay extends React.PureComponent<IAdapterDisplayProps> {
  render() {
    const { sourceAdapterData, adapterId, adapterFriendlyName } = this.props;

    var display = "";
    if (adapterFriendlyName) {
      display = adapterFriendlyName;
    } else {
      var selection = _.find(sourceAdapterData, (a) => {
        return a.id == adapterId;
      });
      display = selection?.value || "";
    }

    return (
      <AdvanceTextField
        label="Adapter"
        name="adapter-display"
        value={adapterFriendlyName}
        fullWidth
        disabled
      />
    );
  }
}