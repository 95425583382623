import {
  React,
  bind,
  _
} from "$Imports/Imports";

import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Button,
  Stepper
} from "$Imports/MaterialUIComponents";

import {
  viewType,
  INewTenant,
  TenantAddService,
  ITenantAddServiceInjectedProps,
  IClientListItem
} from "$State/tenant-views/TenantAddFreezerService";

import {
  CategoryItem,
  LytxCredentials,
  NetradyneCredentials,
  SamsaraCredentials,
  SingleLytxCredentials,
  SmartDriveCredentials,
  WorkflowDefinitionTemplateViewModel
} from "$Generated/api";

import { InitialStep } from "./InitialStep";
import { EmailStep } from "./EmailStep";
import { ReviewStep } from "./ReviewStep";

import { credentialType, ICredentialProps } from "$State/CredentialTypes";

import {
  AdapterComponentMapping
} from "../Providers/AdapterComponentMapping";

import { IAdapterProviderMap } from "../Providers/IAdapterProviderMap";

import { AuthenticationSettingsInstance } from "$Utilities/Security";
import { ClientIdsEnum, SubmissionProviderIdsEnum } from "$Utilities/enums";

const styles: {
  addTenantStepLabel: string;
} = require("./AddTenantDialog.scss");

const maxStepId: number = 4;

interface IAddTenantDialogOwnProps {
  view: viewType;
  onCancel: () => void;
  onSave: () => void;
  adapters: CategoryItem[]; // _all_ adapters; component filters down to acceptable adapters when a client/workflow template is known
  newTenantModel: INewTenant;
}

type AddTenantDialogProps = IAddTenantDialogOwnProps
  & ITenantAddServiceInjectedProps;

interface IAddTenantDialogState {
  currentStepId: number;
}

// TODO: better "allowed for tenant create" logic
// previously assumed only Lytx w/ a defined workflow template was enough
const allowedClients: IClientListItem[] = [{
  id: ClientIdsEnum.Lytx,
  name: "Lytx"
}, {
  id: ClientIdsEnum.Netradyne,
  name: "Netradyne"
}, {
  id: ClientIdsEnum.Samsara,
  name: "Samsara"
}, {
  id: ClientIdsEnum.SmartDrive,
  name: "SmartDrive"
}];

class _AddTenantDialog extends React.Component<AddTenantDialogProps, IAddTenantDialogState> {
  state: IAddTenantDialogState = {
    currentStepId: 0,
  };

  @bind
  private _onCancelClick() {
    this._setStep(0);
    this.props.onCancel();
  }

  @bind
  private _onSaveClick() {
    const { currentStepId } = this.state;

    if (currentStepId === maxStepId) {
      this._setStep(0);
      this.props.onSave();
    }
  }

  @bind
  private _setStep(index: number) {
    this.setState({
      currentStepId: index,
    });
  }

  @bind
  private _moveNext() {
    const { currentStepId } = this.state;

    if (currentStepId < maxStepId) {
      this.setState({
        currentStepId: currentStepId + 1,
      });
    }
  }

  @bind
  private _movePrevious() {
    const { currentStepId } = this.state;

    if (currentStepId > 0) {
      this.setState({
        currentStepId: currentStepId - 1,
      });
    }
  }

  @bind
  private _onChange(e: any): void {
    if (e.target.name === "name") {
      TenantAddService.onChange({ name: e.target.value });
     } else if (e.target.name === "frequency") {
      TenantAddService.onChange({ frequency: e.target.value });
    } else if (e.target.name === "emails") {
      TenantAddService.onChange({ emails: e.target.value });
    }
  }

  @bind
  private async _onClientChange(clientId: number): Promise<void> {
    // load workflow templates for the selected client
    await this.props.tenantAddService.fetchWorkflowTemplates(clientId);
    TenantAddService.onChange({ clientId: clientId });
  }

  @bind
  private _onSourceDataChange(providerKey: number, data: Partial<credentialType>): void {
    TenantAddService.onSourceDataChange(data);
  }

  @bind
  private _onSubmissionDataChange(providerKey: number, data: Partial<credentialType>): void {
    TenantAddService.onSubmissionDataChange(data);
  }

  @bind
  private _onAdapterChange(
    //e: React.ChangeEvent<{ name?: string | undefined; value: unknown; }>, child: React.ReactNode, adapterName: string) => void
    e: React.ChangeEvent<{ name?: string | undefined; value: unknown; }>,
    child: React.ReactNode,
    adapterName: string) {
    // We need to capture the adapter ID in addition to the friendly name.
    // This will be used to select the correct source provider credentials component to render later.
    TenantAddService.onChange({
      adapterFriendlyName: adapterName,
      adapterId: parseInt(e.target.value as string)
    });
  }

  @bind
  private _validate() {
    const { currentStepId } = this.state;
    var isValid = false;
    if (currentStepId === 0) {
      isValid = this._validateTenantAdapterStep();
    } else if (currentStepId === 1) {
      isValid = this._validateSourceProviderDataStep();
    } else if (currentStepId === 2) {
      isValid = this._validateSubmissionProviderDataStep();
    } else if (currentStepId === 3) {
      isValid = this._validateEmailStep();
    } else if (currentStepId === 4) {
      // "review" step - always valid, as assumed all prior steps are valid up to this point
      isValid = true;
    }
    return isValid;
  }

  @bind
  private _validateTenantAdapterStep(): boolean {
    return !(
      this.props.newTenantModel.clientId === 0 ||
      _.isEmpty(this.props.newTenantModel.name) ||
      _.isEmpty(this.props.newTenantModel.adapterFriendlyName) ||
      this.props.newTenantModel.adapterId === 0 ||
      _.isEmpty(this.props.newTenantModel.frequency)
    );
  }

  @bind
  private _validateSourceProviderDataStep(): boolean {
    // TODO: Figure out how to validate data for an arbitrary source provider.
    return true;
  }

  @bind
  private _validateSubmissionProviderDataStep(): boolean {
    const data = this.props.newTenantModel.submissionData;

    if (!data) {
      return false;
    }

    // TODO: better way to validate an arbitrary submission provider
    switch (this.props.newTenantModel.submissionProviderId) {
      case SubmissionProviderIdsEnum.SmartDrive:
        const smartdrive = data as SmartDriveCredentials;
        if (_.isEmpty(smartdrive.bearer) || _.isEmpty(smartdrive.siteName) || _.isEmpty(smartdrive.url)) {
          return false;
        }
        break;

      case SubmissionProviderIdsEnum.Netradyne:
        const netradyne = data as NetradyneCredentials;
        if (_.isEmpty(netradyne.basePath) || _.isEmpty(netradyne.ftpAddress) || _.isEmpty(netradyne.ftpUsername) || _.isEmpty(netradyne.ftpPassword)) {
          return false;
        }
        break;

      case SubmissionProviderIdsEnum.Lytx:
        const lytxData = data as LytxCredentials;
        if (!lytxData.credentials?.length) {
          return false;
        }

        const isDataMissing = _.some<SingleLytxCredentials>(lytxData.credentials,
          x => !x || _.isEmpty(x.endpointId) || _.isEmpty(x.submissionEndPoint) || _.isEmpty(x.username) || _.isEmpty(x.password));
        if(isDataMissing) {
          return false;
        }

        break;

      case SubmissionProviderIdsEnum.Samsara:
        const samsara = data as SamsaraCredentials;
        if (_.isEmpty(samsara.accessToken) || _.isEmpty(samsara.url)) {
          return false;
        }
        break;
    }

    return true;
  }

  @bind
  private _validateEmailStep(): boolean {
    let valid = true;

    const data = this.props.newTenantModel.emails.split("\n").map((e) => e.trim());
    if (data.length > 0) {
      const emailRegex = RegExp(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/);
      valid = data.every((e) => e === "" || emailRegex.test(e));
    }

    return valid;
  }

  render() {
    const {
      view,
      newTenantModel,
    } = this.props;
    const {
      currentStepId
    } = this.state;
    const isOpen = view === "Add";

    const {
      workflowTemplatesResult
    } = this.props.tenantAddService.getState();

    let availableClients: IClientListItem[] = [];

    // use first client ID if only one available; otherwise, admin will select in first step
    const clientIds = AuthenticationSettingsInstance.getClientIds();
    if ((clientIds.length === 1) && !workflowTemplatesResult.isFetching) {
      if (TenantAddService.getState().newTenantModel.clientId != clientIds[0]) {
        this._onClientChange(clientIds[0]);
      }
    }
    else {
      availableClients = allowedClients.filter((x) => clientIds.indexOf(x.id) > -1);
    }

    // get the default workflow template from the retrieved workflow templates
    const workflowTemplates = workflowTemplatesResult.data?.data || {};
    let workflowTemplate: WorkflowDefinitionTemplateViewModel | undefined;
    let newTenantAdapters: CategoryItem[] = [];
    let submissionProviderId: number | undefined;

    Object.keys(workflowTemplates)
      .some((x) => {
        if (workflowTemplates[x].isDefault === true) {
          workflowTemplate = workflowTemplates[x];
          this.props.tenantAddService.setWorkflowTemplate(x);

          // TODO: support selecting a submission provider - assume first defined for now
          submissionProviderId = (workflowTemplate.submissionProviders || [0])[0];
          this.props.tenantAddService.setSubmissionProviderId(submissionProviderId);

          // pull allowed adapters for new tenants from workflow template
          const allowedSourceProviders = workflowTemplate.sourceProviders || null;
          newTenantAdapters = allowedSourceProviders ? this.props.adapters.filter((x) => allowedSourceProviders.indexOf(x.id || 0) > -1) : this.props.adapters;
          return true;
        }
      });

    // Get the adapter provider based on the selected adapter.
    const adapterProvider = _.find<IAdapterProviderMap>(
      AdapterComponentMapping,
      x => x.categoryItemId === newTenantModel.adapterId);

    // Get the submission provider.
    const submissionProvider = _.find<IAdapterProviderMap>(
      AdapterComponentMapping,
      x => x.categoryItemId === submissionProviderId);
    
    let stepLabelText;
    let stepElement;
    if (currentStepId === 0) {
      stepLabelText = '';
      stepElement = <InitialStep
        onChange={this._onChange}
        onClientChange={this._onClientChange}
        onAdapterChange={this._onAdapterChange}
        adapters={newTenantAdapters}
        clients={availableClients}
        newTenantModel={this.props.newTenantModel}
      />
    } else if (currentStepId === 1 && adapterProvider) {
      stepLabelText = adapterProvider.title;
      stepElement =React.createElement<ICredentialProps<credentialType>>(
        adapterProvider.componentClass,
        {
          data: this.props.newTenantModel.sourceData,
          providerKey: adapterProvider.categoryItemId,
          onChange: this._onSourceDataChange
        }
      );
    } else if (currentStepId === 2 && submissionProvider) {
      stepLabelText = 'Submission Settings';
      stepElement = React.createElement<ICredentialProps<credentialType>>(
        submissionProvider.componentClass,
        {
          data: this.props.newTenantModel.submissionData,
          providerKey: submissionProvider.categoryItemId,
          onChange: this._onSubmissionDataChange
        }
      );
    } else if (currentStepId === 3 && submissionProvider) {
      stepLabelText = 'Error Report Email Recipients';
      stepElement = <EmailStep
        onChange={this._onChange}
        newTenantModel={this.props.newTenantModel}
        isValid={this._validateEmailStep()}
      />
    } else if (currentStepId === 4) {
      stepLabelText = '';
      stepElement = <ReviewStep
        adapters={newTenantAdapters}
        newTenantModel={this.props.newTenantModel}
      />
    } else {
      stepLabelText = '';
      stepElement = <div></div>
    }

    return (
      <Dialog open={isOpen}>
        <DialogTitle>Add Tenant</DialogTitle>
        <DialogContent>
          <div>
            <section>
              {!!stepLabelText && <label className={styles.addTenantStepLabel}>{stepLabelText}</label>}
              <div className="box">
                <Stepper activeStep={currentStepId}>
                  { stepElement }
                </Stepper>
              </div>
            </section>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={this._onCancelClick} color="primary">
            Cancel
          </Button>
          <Button color="primary" disabled={currentStepId === 0} onClick={this._movePrevious}>
            Previous
          </Button>
          {currentStepId < maxStepId &&
            <Button
              color="primary"
              onClick={this._moveNext}
              disabled={!this._validate()}
            >
              Next
            </Button>}
          {currentStepId === maxStepId &&
            <Button
              color="primary"
              onClick={this._onSaveClick}
              disabled={!this._validate()}
            >
              Add Tenant
            </Button>}
        </DialogActions>
      </Dialog>
    );
  }
}

export const AddTenantDialog = TenantAddService.inject(
  _AddTenantDialog
);