import {
  React,
  bind,
  numeral,
  _
} from "$Imports/Imports";

import {
  DataTable,
  DataTablePager,
  IDataTableColumn,
  DateFormatter
} from "$Components/Common";

import {
  TableCell,
  TableRow
} from "$Imports/MaterialUIComponents";

import {
  IPagerState,
  calcPagedState
} from "$State/PagerPagingState";

import {
  ISortState
} from "$State/SortState";

import {
  DrivingFaultEvent
} from "$Generated/api";

interface IJobDetailFaultsProps {
  data: DrivingFaultEvent[];
  pagerState: IPagerState;
  onPagerStateChange?: (pagerState: IPagerState) => void;
}

export class FaultsGrid extends React.PureComponent<IJobDetailFaultsProps> {
  private readonly columns: IDataTableColumn<DrivingFaultEvent>[] = [
    {
      columnName: "vin",
      columnFieldData: (f) => f.vin ? f.vin : "",
      sortMethod: (f) => (f.vin ? f.vin : "").toLowerCase(),
      headerValue: "VIN"
    },
    {
      columnName: "datetime",
      columnFieldData: (f) => f.eventDateTime ? (<DateFormatter value={f.eventDateTime} />) : null,
      sortMethod: (f) => f.eventDateTime,
      headerValue: "Date Time"
    },
    {
      columnName: "faultCode",
      columnFieldData: (f) => f.faultCode ? f.faultCode : "",
      sortMethod: (f) => (f.faultCode ? f.faultCode : "").toLowerCase(),
      headerValue: "Fault Code"
    },
    {
      columnName: "faultFailureMode",
      columnFieldData: (f) => f.failureMode ? f.failureMode : "",
      sortMethod: (f) => (f.failureMode ? f.failureMode : "").toLowerCase(),
      headerValue: "Fault Failure Mode"
    },
    {
      columnName: "occurrenceCount",
      columnFieldData: (f) => numeral(f.occuranceCount).format("0"),
      sortMethod: (f) => f.occuranceCount,
      headerValue: "Occurrence Count"
    }
  ];

  @bind
  private _onPagerStateChanged(page: number, rowsPerPage: number) {
    if (this.props.onPagerStateChange) {
      this.props.onPagerStateChange({
        page,
        rowsPerPage,
      });
    }
  }

  @bind
  private _onChangePage() {

  }

  render() {
    const { data, pagerState } = this.props;
    const pageState = calcPagedState(pagerState);

    return (
      <DataTable
        columns={this.columns}
        data={data}
        skipRows={pageState.skip}
        takeRows={pageState.take}
        tableFooterComponent={(
          <TableRow>
            <DataTablePager
              onChangePage={this._onChangePage}
              count={data.length}
              page={pagerState.page}
              rowsPerPage={pagerState.rowsPerPage}
              onPagerStateChange={this._onPagerStateChanged}
            />
          </TableRow>
        )}
      />
    );
  }
}