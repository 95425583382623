import {
    React,
    _,
  } from "$Imports/Imports";
  
  import {
    ITenantViewProps
  } from "../TenantViewProps";
  
  import {
    ISmartDriveErrorReportFreezerServiceInjectedProps,
    SmartDriveErrorReportService
  } from "$State/job-views/SmartDriveErrorReportFreezerService";
  
  import {
    AjaxActionIndicator
  } from "$Components/Common/AjaxActionIndicator";
  
  import {
    Snackbar,
  } from "@material-ui/core";
  
  import {
    FormControl,
    InputLabel,
    Select,
    MenuItem,
  } from "$Imports/MaterialUIComponents";
  
  import {
    DataTable,
    IDataTableColumn,
    DateFormatter,
  } from "$Components/Common";
  
  import { 
    SmartDriveExportError,
    SmartDriveExportResult,
  } from "$Generated/api";
  
  interface ISmartDriveErrorReportViewBaseProps {
  
  }
  
  type ISmartDriveErrorReportViewProps = ISmartDriveErrorReportViewBaseProps
    & ITenantViewProps
    & ISmartDriveErrorReportFreezerServiceInjectedProps;
  
  class _SmartDriveErrorReportView extends React.Component<ISmartDriveErrorReportViewProps> {
    state = {
      selectedSubmission: {
        exportErrors: undefined,
        exportResultDateTime: Date,
      },
    }
  
    private readonly columns: IDataTableColumn<SmartDriveExportError>[] = [
      {
        columnName: "employeeId",
        columnFieldData: (x) => x.employeeId ?? "",
        sortMethod: (x) => (x.employeeId ?? "").toLowerCase(),
        headerValue: "Employee Id",
        headerProps: {}
      },
      {
        columnName: "vehicleSN",
        columnFieldData: (x) => x.vehicleSN ?? "",
        sortMethod: (x) => (x.vehicleSN ?? "").toLowerCase(),
        headerValue: "Vehicle SN",
        headerProps: {}
      },
      {
        columnName: "startDateTime",
        columnFieldData: (x) => x.startDateTime ? (<DateFormatter value={x.startDateTime} />) : "",
        sortMethod: (x) => x.startDateTime,
        headerValue: "Start Date",
        headerProps: {}
      },
      {
        columnName: "endDateTime",
        columnFieldData: (x) => x.endDateTime ? (<DateFormatter value={x.endDateTime} />) : "",
        sortMethod: (x) => x.endDateTime,
        headerValue: "End Date",
        headerProps: {}
      },
      {
        columnName: "responseCode",
        columnFieldData: (x) => x.responseCode ?? "",
        sortMethod: (x) => (x.responseCode ?? "").toLowerCase(),
        headerValue: "Response Code",
        headerProps: {}
      },
      {
        columnName: "responseMessage",
        columnFieldData: (x) => x.responseMessage ?? "",
        sortMethod: (x) => (x.responseMessage ?? "").toLowerCase(),
        headerValue: "Response Message",
        headerProps: {}
      }
    ]
  
    componentDidMount() {
      if (this.props.job !== null && this.props.job.id !== undefined) {
        this.props.smartDriveErrorReport.setJobId(this.props.job.id);
        this._initialize();
      }
    }
  
    // need to wait for fetchHosErrors to return to set the default selection
    private async _initialize() {
      await this.props.smartDriveErrorReport.fetchErrors();
      const { errorResults } = this.props.smartDriveErrorReport.getState();
      const submissions = errorResults.data?.success ? errorResults?.data?.data ?? [] : [];
      if (submissions.length > 0)
      {
        this.setState({
          selectedSubmission: submissions[0],
        })
      }
    }
  
    private _getSubmissionFromDateString(submissionDateString: string): SmartDriveExportResult | undefined {
      const { errorResults } = this.props.smartDriveErrorReport.getState();
      const submissions = errorResults.data?.success ? errorResults?.data?.data ?? [] : [];
  
      for (const submission of submissions) {
        if (submission.exportResultDateTime?.toString() === submissionDateString) {
          return submission;
        }
      }
    }
  
    private changeSubmissionSelection = (e: React.ChangeEvent<{ name?: string | undefined; value: unknown; }>) => {
      this.setState({
        selectedSubmission: this._getSubmissionFromDateString(e.target.value as string),
      })
    }
  
    render() {
      const { errorResults } = this.props.smartDriveErrorReport.getState();
      const submissions = errorResults.data?.success ? errorResults?.data?.data ?? [] : [];
  
      return (
        <>
          <AjaxActionIndicator state={[errorResults]} />
          <div style={{paddingLeft: 15}}>
            <FormControl>
              <InputLabel>Submission</InputLabel>
              <Select
                value={this.state.selectedSubmission.exportResultDateTime?.toString()}
                onChange={this.changeSubmissionSelection}
              >
                {_.map(submissions, (sub) => (
                  <MenuItem value={sub.exportResultDateTime?.toString()}>
                    <DateFormatter value={sub.exportResultDateTime}/>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <DataTable
            columns={this.columns}
            data={this.state.selectedSubmission.exportErrors ?? []}
          />
          <Snackbar
            open={errorResults.hasFetched && !errorResults.data?.success}
            message={"Error loading data from server"}
            autoHideDuration={5000}
            anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
          />
        </>
      );
    }
  }
  
  export const SmartDriveErrorReportView = SmartDriveErrorReportService.inject(
    _SmartDriveErrorReportView
  );