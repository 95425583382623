import { credentialType } from "./CredentialTypes";
import { ConfigurationApiFactory } from "../generated/api";
import { APPCONFIG } from "../constants/Config";

export interface ValidateCredentialsResults {
  'success'?: 'none' | 'true' | 'false',
  'errorResult'?: string,
  'running'?: boolean
}
  
export async function validateCredentials(credentials: credentialType | null, providerId: number) {
  const configurationApiFactory = ConfigurationApiFactory(APPCONFIG.fetch, APPCONFIG.baseUrl);

  var test = configurationApiFactory.apiV1ConfigurationValidatePost({providerType:providerId.toString(), body:credentials});
  return test;
}
