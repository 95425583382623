import {
  React,
  _,
  bind
} from "$Imports/Imports";
import { Button } from "$Imports/MaterialUIComponents";
import { AdvanceTextField } from "$Components/Common";
import { ICredentialProps } from "$State/CredentialTypes";
import { SmartDriveCredentials } from "$Generated/api";

interface ISmartDriveBaseProps {

}

type ISmartDriveProps = ISmartDriveBaseProps & ICredentialProps<SmartDriveCredentials>;

export class SmartDrive extends React.PureComponent<ISmartDriveProps> {

  @bind
  private _onChange(e: any): void {
    if (e.target.name === "bearer") {
      this.props.onChange(this.props.providerKey, { bearer: e.target.value });
    }
    else if (e.target.name === "siteName") {
      this.props.onChange(this.props.providerKey, { siteName: e.target.value });
    }
    else if (e.target.name === "url") {
      this.props.onChange(this.props.providerKey, { url: e.target.value });
    }
  }

  render(): JSX.Element {
    const smartDrive: SmartDriveCredentials = this.props.data ? this.props.data : {} as SmartDriveCredentials;
    return (
      <div>
        <AdvanceTextField
          label="Bearer*"
          name="bearer"
          onChange={this._onChange}
          value={smartDrive.bearer ? smartDrive.bearer : ""}
          error={(smartDrive.bearer === "")}
        /><br />
        <AdvanceTextField
          label="Site Name*"
          name="siteName"
          onChange={this._onChange}
          value={smartDrive.siteName ? smartDrive.siteName : ""}
          error={(smartDrive.siteName === "")}
        /><br />
        <AdvanceTextField
          label="URL*"
          name="url"
          onChange={this._onChange}
          value={smartDrive.url ? smartDrive.url : ""}
          error={(smartDrive.url === "")}

        />
        <br />
        <Button className="float-right" disabled={true} color={"primary"}>VALIDATE CREDENTIALS</Button>{/* Disabled for as per LDM - 481 */}
      </div>
    );
  }
}
