import {
  React,
  bind,
  _
} from "$Imports/Imports";

import {
  Step,
  TextField
} from "$Imports/MaterialUIComponents";

import {
  INewTenant
} from "$State/tenant-views/TenantAddFreezerService";

interface IEmailStepProps {
  onChange: (e: any) => void;
  newTenantModel: INewTenant;
  isValid: boolean;
}

export class EmailStep extends React.PureComponent<IEmailStepProps> {

  render() {
    const {
      newTenantModel,
      isValid
    } = this.props;

    return (
      <Step>
        <>
          <div style={{width: '350px'}}>
            <div>Enter email addresses one per line.</div>
            <TextField
              onChange={this.props.onChange}
              value={newTenantModel.emails}
              name={"emails"}
              rows={8}
              multiline
              fullWidth
              error={!isValid}
            />
          </div>
        </>
      </Step>
    );
  }
}