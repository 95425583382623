import {
  React
} from "$Imports/Imports";

import {
  SvgIconProps
} from "$Imports/MaterialUIComponents";

import {
  ErrorIndicator
} from "./ErrorIndicator";

import {
  IssueIndicator
} from "./IssueIndicator";

import {
  ImprovingIndicator
} from "./ImprovingIndicator";

import {
  GoodIndicator
} from "./GoodIndicator";

import {
  ProcessingIndicator
} from "./ProcessingIndicator";

import {
  UnknownIndicator
} from "./UnknownIndicator";

export type statusType = "Good" | "Improving" | "Issues" | "Error" | "Processing" | "Unknown";

interface IStatusIndicatorProps extends SvgIconProps {
  status?: statusType;
}

export class StatusIndicator extends React.PureComponent<IStatusIndicatorProps>{

  static defaultProps: Partial<IStatusIndicatorProps> = {
    status: "Good",
  };
  render() {
    const { status, ref, ...passthroughProps} = this.props;

    switch (status) {
      case "Good":
        return (<GoodIndicator {...passthroughProps} />);

      case "Improving":
        return (<ImprovingIndicator {...passthroughProps} />);

      case "Issues":
        return (<IssueIndicator {...passthroughProps} />);

      case "Error":
        return (<ErrorIndicator {...passthroughProps} />);

      case "Processing":
        return (<ProcessingIndicator {...passthroughProps} />);

      case "Unknown":
      default:
        return (<UnknownIndicator {...passthroughProps} />);
    }
  }
}
