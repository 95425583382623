import {
  React,
  _,
  bind
} from "$Imports/Imports";

import {
  ITenantViewProps
} from '../TenantViewProps';

import {
  TenantViewReadingsService,
  ITenantViewReadingsServiceInjectedProps
} from "$State/job-views/JobDetailReadingsFreezerService";

import {
  JobDetailReadings
} from "./JobDetailReadings";

import {
  AjaxActionIndicator
} from "$Components/Common";

interface IJobDetailReadingsViewBaseProp {

}

type IJobDetailReadingsViewProp = IJobDetailReadingsViewBaseProp
& ITenantViewReadingsServiceInjectedProps
& ITenantViewProps;

class _JobDetailReadingsView extends React.Component<IJobDetailReadingsViewProp> {
  componentDidMount() {
    if (this.props.job !== null && this.props.job.id !== undefined) {
      this.props.jobDetailReadingsService.setReadingsJobId(this.props.job.id);
      this.props.jobDetailReadingsService.fetchReadings();
    }
  }

  render() {
    const state = this.props.jobDetailReadingsService.getState();
    const {
      jobDetailReadingsResults,
      jobDetailReadingsPager,
      jobDetailReadingsSearch
    } = state;

    return (
      <>
        <AjaxActionIndicator
          state={[jobDetailReadingsResults]}
        />
        <JobDetailReadings
          data={jobDetailReadingsResults.data?.data || []}
          pager={jobDetailReadingsPager}
          search={jobDetailReadingsSearch}
        />
      </>
    );
  }
}

export const JobDetailReadingsView = TenantViewReadingsService.inject(
  _JobDetailReadingsView
);