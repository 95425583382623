
import {
  React
} from "$Imports/Imports";

import {
  SvgIconProps
} from "$Imports/MaterialUIComponents";

import {
  Loop
} from "$Imports/MaterialUIIcons";

import {
  joinClasses
} from "$Utilities/css";

const styles: {
  mainIcon: string;
} = require("./ProcessingIndicator.scss");

export class ProcessingIndicator extends React.PureComponent<SvgIconProps> {
  render() {
    const { className, ...passthroughProps } = this.props;

    return (
      <Loop
        className={joinClasses([styles.mainIcon, className])}
        {...passthroughProps}
      />
    );
  }
}
