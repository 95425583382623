import {
  React,
  bind,
  _
} from "$Imports/Imports";

import {
  TableRow
} from "$Imports/MaterialUIComponents";

import {
  TenantNameLink
} from "./TenantNameLink";

import {
  DataTablePager,
  DataTable,
  IDataTableColumn,
  directionType,
  ProcessingIndicator,
  StatusIndicatorLabel
} from "$Components/Common";

import {
  TenantMenu, menuType
} from "./TenantMenu";

import {
  TenantSuccessSummaryViewModel,
  TenantSummaryViewModel
} from "$Generated/api";

import {
  IPagerState,
  calcPagedState
} from "$State/PagerPagingState";

import {
  ISortState
} from "$State/SortState";
import { ApplicationSecurityContext, ISecurityContext, securityContextHasRoles } from "$Providers/AuthenticationProvider";

const styles: {
  grayRow: string;
} = require("./TenantSummary.scss");

const statusLabelStyles: {
  statusLabelIndicator: string;
} = require("$Components/Common/StatusIndicator/StatusIndicatorLegend.scss");

interface ITenantSummaryProps {
  securityContext: ISecurityContext;
  data: TenantSummaryViewModel[];
  supportData: TenantSuccessSummaryViewModel[];
  pager: IPagerState;
  sortState: ISortState;

  onPagerStateChange?: (pagerState: IPagerState) => void;
  onSortChange?: (sortState: ISortState) => void;
  onTenantClick?: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>, data: TenantSummaryViewModel) => void;
  onMenuItemClick?: (event: React.MouseEvent<HTMLLIElement, MouseEvent>, tenantSummaryViewModel: TenantSummaryViewModel, operation: menuType) => void;
}

export class TenantSummary extends React.PureComponent<ITenantSummaryProps> {

  private getColumns(securityContext: ISecurityContext): IDataTableColumn<TenantSummaryViewModel>[] {

    // Tenant and Adapters columns
    var table: IDataTableColumn<TenantSummaryViewModel>[] = [
      {
        columnFieldData: (d) => (
          <TenantNameLink
            data={d}
            onClick={this._onLinkClick}
          />
        ),
        columnName: "tenant-friendly-name",
        sortMethod: (d) => d.tenantFriendlyName ? d.tenantFriendlyName.toLowerCase() : "",
        headerValue: "Tenant"
      },
      {
        columnName: "adapters",
        columnFieldData: (d) => (d && d.adapters ? _.join(_.map(d.adapters, (a) => a.friendlyName)) : ""),
        sortMethod: (d) => (d && d.adapters ? _.join(_.map(d.adapters, (a) => a.friendlyName)) : "").toLowerCase(),
        headerValue: "Adapters"
      },
      {
        columnName: "job-success-percentage",
        columnFieldData: (d) => (this.successSummaryDisplayCalc(d, 'jobSuccessRate')),
        sortMethod: (d) => (d && this.props.supportData.filter((x) => x.tenantId == d.tenantId)[0] ? this.props.supportData.filter((x) => x.tenantId == d.tenantId)[0].jobSuccessRate : ""),
        headerValue: "Job Success Percentage"
      },
      {
        columnName: "submission-success-percentage",
        columnFieldData: (d) => (this.successSummaryDisplayCalc(d, 'submissionSuccessRate')),
        sortMethod: (d) => (d && this.props.supportData.filter((x) => x.tenantId == d.tenantId)[0] ? this.props.supportData.filter((x) => x.tenantId == d.tenantId)[0].submissionSuccessRate : ""),
        headerValue: "Submission Success Percentage"
      }
    ];

    // Internal columns
    if (securityContext.IsAdminUser){
      table.push(
        {
          columnFieldData: (d) => d.tenantId ?? "",
          sortMethod: (d) => d.tenantId?.toLocaleLowerCase() ?? "",
          columnName: "tenantId",
          headerValue: "Tenant Id",
        },
        {
          columnFieldData: (d) => d.clientOrgName ?? "",
          sortMethod: (d) => d.clientOrgName?.toLowerCase() ?? "",
          columnName: "client-friendly-name",
          headerValue: "Client",
        }
      );
    }

    // Action Menu column
    table.push(
    {
      columnName: "action-menu",
      headerValue: "Action",
      headerProps: {
        style: {
          width: 100,
          textAlign: "center",
        }
      },
      cellProps: {
        style: {
          textAlign: "center",
        }
      },
      columnFieldData: (d) => (
        <TenantMenu
          data={d}
          onMenuItemClick={this._onMenuItemClick}
        />
      )
    });

    return table;
    // Add d in to TenantMenu?
    // {
    //   columnName: "last-completed-date",
    //   columnFieldData: (d) => null,
    //   headerValue: "Last Completed Date"
    // },
    // {
    //   columnName: "polling-interval",
    //   columnFieldData: (d) => null,
    //   headerValue: "Polling Interval"
    // },
    // {
    //   columnName: "7-day-success-rate",
    //   columnFieldData: (d) => null,
    //   headerValue: "7 Day Success Rate"
    // },
    // {
    //   columnName: "status",
    //   columnFieldData: (d) => null,
    //   headerValue: "Status"
    // }
  };

  private successSummaryDisplayCalc(d: TenantSummaryViewModel, propName: string) {
    if (this.props.supportData.filter((x) => x.tenantId == d.tenantId)[0]) {
      switch (propName) {
        case "jobSuccessRate": {
          return this.props.supportData.filter((x) => x.tenantId == d.tenantId)[0].jobSuccessRate + "%";
        }
        case "submissionSuccessRate": {
          return this.props.supportData.filter((x) => x.tenantId == d.tenantId)[0].submissionSuccessRate + "%";;
        }
      }
      return;
    }
    
    if (this.props.supportData.length > 0) {
      return "0%";
    }

    return (<StatusIndicatorLabel
      className={statusLabelStyles.statusLabelIndicator}
      icon={(
        <ProcessingIndicator
          fontSize="default" />
      )}
    ></StatusIndicatorLabel>);
  }

  private _setRowStyle(rowData: TenantSummaryViewModel): string {
    if (!rowData.active) {
      return styles.grayRow;
    }
    return "";
  }

  @bind
  private _onLinkClick(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>, data: TenantSummaryViewModel) {
    if (this.props.onTenantClick) {
      this.props.onTenantClick(event, data);
    }
  }

  @bind
  private _onMenuItemClick(event: React.MouseEvent<HTMLLIElement, MouseEvent>, tenantViewModel: TenantSummaryViewModel, operation: menuType) {
    if (this.props.onMenuItemClick) {
      this.props.onMenuItemClick(event, tenantViewModel, operation)
    }
  }

  @bind
  private _onSortChange(event: React.MouseEvent<HTMLElement, MouseEvent>, columnName: string | undefined, sortDirection: directionType) {
    if (this.props.onSortChange) {
      this.props.onSortChange({
        sortColumnName: columnName,
        sortDirection
      });
    }
  }

  @bind
  private _onPagerStateChange(page: number, rowsPerPage: number) {
    if (this.props.onPagerStateChange) {
      this.props.onPagerStateChange({
        page,
        rowsPerPage
      });
    }
  }

  @bind
  private _onChangePage() {
    // Do nothing.  Handled by the component.  Remove once typescript has been updated.
  }

  render() {
    const { securityContext, data, pager, sortState } = this.props;
    const pagedState = calcPagedState(pager);

    return (
      <DataTable
        defaultSortColumnName={sortState.sortColumnName}
        defaultSortDirection={sortState.sortDirection}
        columns={this.getColumns(securityContext)}
        data={data}
        skipRows={pagedState.skip}
        takeRows={pagedState.take}
        onSortChange={this._onSortChange}
        setRowStyle={this._setRowStyle}
        tableFooterComponent={(
          <TableRow>
            <DataTablePager
              onChangePage={this._onChangePage}
              count={data.length}
              page={pager.page}
              rowsPerPage={pager.rowsPerPage}
              onPagerStateChange={this._onPagerStateChange}
            />
          </TableRow>
        )}
      />
    );
  }
}
