import {
  React,
  bind,
  _
} from "$Imports/Imports";

import {
  AdvanceTextField,
  AdapterDisplay
} from "$Components/Common";

import {
  Step
} from "$Imports/MaterialUIComponents";

import {
  INewTenant
} from "$State/tenant-views/TenantAddFreezerService";

import {
  CategoryItem
} from "$Generated/api";

import {
  getEnumLabel
} from "$Utilities/enums";

interface IReviewStepProps {
  adapters: CategoryItem[];
  newTenantModel: INewTenant;
}

export class ReviewStep extends React.PureComponent<IReviewStepProps> {
  render() {
    return (
      <Step>
        <>
          <AdvanceTextField
            label="Tenant Name"
            name="name"
            value={this.props.newTenantModel.name}
            fullWidth
            disabled
          />
          <br/>
          <AdapterDisplay
            sourceAdapterData={this.props.adapters}
            adapterFriendlyName={this.props.newTenantModel.adapterFriendlyName}
          />
          <br/>
          <AdvanceTextField
            label="Preferred Frequency"
            name="frequency"
            value={getEnumLabel(this.props.newTenantModel.frequency)}
            fullWidth
            disabled
          />
        </>
      </Step>
    );
  }
}