export interface IPagedState {
  take: number;
  skip: number;
}

export interface IPagerState {
  page: number;
  rowsPerPage: number;
}

export function calcPagedState(pager: IPagerState): IPagedState {
  return {
    skip: pager.page * pager.rowsPerPage,
    take: pager.rowsPerPage
  };
}
