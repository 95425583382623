import {
  React,
  numeral,
  bind,
  _
} from "$Imports/Imports";

import {
  JobStatusIndicator
} from "$Components/Common/JobStatusIndicator/JobStatusIndicator";

import {
  JobViewModel
} from "$Generated/api";

import {
  DateFormatter,
  TruncatedTextView,
  UserRoleLimitedControl,
  UserRoles
} from "$Components/Common";

import {
  LabelValue
} from "./LabelValue";

import {
  Button
} from "$Imports/MaterialUIComponents";

interface IJobSummaryProps {
  data: JobViewModel;
  resubmit: () => void;
}

const styles: {
  titleContainer: string;
  title: string;
  countContainer: string;
  countColumn: string;
  countColWide: string;
  innerColumn: string;
  grow1: string;
  labelValueContainer: string;
  label: string;
  value: string;
} = require("./JobSummary.scss");

export class JobSummary extends React.PureComponent<IJobSummaryProps> {

  @bind
  private _resubmitJob(): void {
    if (this.props.resubmit) {
      this.props.resubmit();
    }
  }

  render() {
    const { data } = this.props;
    const jobStatus = (data.childJobs && data.childJobs.length > 0) ? data.childJobs[0].jobStatus : data.jobStatus;
    return (
      <div>
        <div
          className={styles.titleContainer}
        >
          <div
            style={{ marginTop: "8px" }}
          >
            <JobStatusIndicator
              jobStatus={jobStatus}
              jobStartDate={(data.childJobs && data.childJobs.length > 0) ? data.childJobs[0].startDate : data.startDate}
            />
          </div>
          <div
            className={styles.title}
          >
            {data.tenant ? data.tenant.friendlyName : null} - {data.adapters ?  _.join(_.map(data.adapters, (a) => a.friendlyName), ", ") : null}
          </div>
          <UserRoleLimitedControl 
            requiredUserRoles={[
              UserRoles.CreateJobs,
            ]}
          >
            <Button
              variant="contained"
              color="primary"
              style={{ marginTop: "8px", marginLeft: "16px" }}
              onClick={this._resubmitJob}
              disabled={(!data.canResubmit || jobStatus === "Processing")}
            >
              Resubmit
            </Button>
          </UserRoleLimitedControl>
        </div>
        <div
          className={styles.countContainer}
        >
          <div
            className={styles.countColumn}
          >
            <LabelValue
              labelValue="Start Date:"
              containerClassName={styles.labelValueContainer}
              labelValueClassName={styles.label}
              valueClassName={styles.value}
            >
              <DateFormatter
                value={(data.childJobs && data.childJobs.length > 0) ? data.childJobs[0].startDate : data.startDate}
              />
            </LabelValue>
            <LabelValue
              containerClassName={styles.labelValueContainer}
              labelValueClassName={styles.label}
              labelValue="End Date:"
              valueClassName={styles.value}
            >
              <DateFormatter
                value={(data.childJobs && data.childJobs.length > 0) ? data.childJobs[0].endDate : data.endDate}
              />
            </LabelValue>
          </div>
          <div
            className={styles.countColWide}
          >
            <div className={styles.countContainer}>
              <div className={styles.innerColumn}>
                <LabelValue
                  containerClassName={styles.labelValueContainer}
                  labelValueClassName={styles.label}
                  labelValue="Total:"
                  valueClassName={styles.value}
                >
                  {numeral(data.itemsSubmitted).format("0,000")}
                </LabelValue>
                <LabelValue
                  containerClassName={styles.labelValueContainer}
                  labelValueClassName={styles.label}
                  labelValue="Success Count:"
                  valueClassName={styles.value}
                >
                  {numeral(data.itemsSuccessful).format("0,000")}
                </LabelValue>
                <LabelValue
                  containerClassName={styles.labelValueContainer}
                  labelValueClassName={styles.label}
                  labelValue="Failure Count:"
                  valueClassName={styles.value}
                >
                  {numeral(data.itemsFailed).format("0,000")}
                </LabelValue>
              </div>
              {((data.childJobs && data.childJobs.length > 0) ?
            ([
              <div
                className={styles.innerColumn}
              >
                <LabelValue
                  containerClassName={styles.labelValueContainer}
                  labelValueClassName={styles.label}
                  labelValue="Resubmitted Count:"
                  valueClassName={styles.value}
                >
                  {data.childJobs.length}
                </LabelValue>
              </div>
              ])
            : <div />)}
            </div>
            
            
            {(data.jobStatus === "Error" ?
              (
                <LabelValue
                  containerClassName={styles.labelValueContainer}
                  labelValueClassName={styles.label}
                  labelValue="Error Message:"
                  valueClassName={styles.grow1}
                >
                  {<TruncatedTextView
                    text={data.statusMessage || ''}
                    maxDisplayLength={150}
                  />}
              </LabelValue>
              ) : <div />)}
          </div>
          
          {((data.childJobs && data.childJobs.length > 0) ?
            <div
              className={styles.countColumn}
            >
              <LabelValue
                labelValue="Original Start Date:"
                containerClassName={styles.labelValueContainer}
                labelValueClassName={styles.label}
                valueClassName={styles.value}
              >
                <DateFormatter
                  value={data.startDate}
                />
              </LabelValue>
              <LabelValue
                containerClassName={styles.labelValueContainer}
                labelValueClassName={styles.label}
                labelValue="Original End Date:"
                valueClassName={styles.value}
              >
                <DateFormatter
                  value={data.endDate}
                />
              </LabelValue>
            </div> : <div />)}
        </div>
      </div>
    );
  }
}
