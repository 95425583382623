import {
  React,
  bind,
  _,
  numeral,
  memoizeOne
} from "$Imports/Imports";

import {
  ISearchFilter,
} from "$State/VehicleSearchFreezerService";

import {
  Filter
} from "./Filter";

import {
  DutyStatusActivity,
  TenantSummaryViewModel
} from "$Generated/api";

import {
  IPagerState,
} from "$State/PagerPagingState";

import {
  DutyStatusGrid
} from "./DutyStatusGrid";

import {
  ISortState
} from "$State/SortState";

import {
  Divider,
  Snackbar,
  IconButton,
} from "$Imports/MaterialUIComponents";

import {
  ArrowDownward,
} from "$Imports/MaterialUIIcons";

import {
  DataTablePager,
  timeZoneType,
  DateFormatter
} from "$Components/Common";

import {
  TimeZoneService,
  ITimeZoneServiceInjectedProps
} from "$State/TimeZoneFreezerService";

import {
  INavigationServiceInjectedProps,
  NavigationService
} from "$State/NavigationFreezerService";

import { ApplicationSecurityConsumer } from "$Providers/AuthenticationProvider";

const styles: {
  paperStyle: string;
  searchPaperStyle: string;
  headerGroup: string;
} = require("./DutyStatusSearch.scss");

interface IDutyStatusSearchBaseProps {
  filter: ISearchFilter;
  pagerState: IPagerState;
  sortState: ISortState;
  dutyStatusData: DutyStatusActivity[];
  tenantSummaryData: TenantSummaryViewModel[];
  onFilterChange?: (filter: ISearchFilter, reload: boolean) => void;
  onPagerStateChange?: (pagerState: IPagerState) => void;
  onSortChange?: (sortState: ISortState) => void;
  onRunSearch?: () => void;
  errorState: boolean;
  hasSearched: boolean;
}

type IDutyStatusSearchProps = IDutyStatusSearchBaseProps & ITimeZoneServiceInjectedProps & INavigationServiceInjectedProps;


export class _DutyStatusSearch extends React.PureComponent<IDutyStatusSearchProps> {

  @bind
  private _onFilterChange(filter: ISearchFilter, reload: boolean): void {
    if (this.props.onFilterChange) {
      this.props.onFilterChange(filter, reload);
    }
  }

  @bind
  private _onRunSearch() {
    if (this.props.onRunSearch) {
      this.props.onRunSearch();
    }
  }

  @bind
  private _onPagerStateChange(pagerState: IPagerState) {
    if (this.props.onPagerStateChange) {
      this.props.onPagerStateChange(pagerState);
    }
  }

  @bind
  private _onPagerStateChangePager(page: number, rowsPerPage: number) {
    this._onPagerStateChange({
      page,
      rowsPerPage,
    });
  }

  @bind
  private _onSortChange(sortState: ISortState) {
    if (this.props.onSortChange) {
      this.props.onSortChange(sortState);
    }
  }

  private _onJobTimeClick(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>, data: DutyStatusActivity) {
    if (data.jobId) {
      const url = "/jobs/" + data.jobId;
      if (event.button === 1 || event.button === 2) {
        window.open(url);
      } else if (event.button === 0) {
        NavigationService.navigateTo(url);
      }
    }
  }

  @bind
  private _onChangePage() {
    // Do nothing.  Handled by the component.  Remove once typescript has been updated.
  }

  @bind
  private saveCSV() {
    const { dutyStatusData } = this.props;
    var timeZone: timeZoneType = this.props.timeZoneService.getTimeZone() || "UTC";
    var isMilitaryTime: boolean = this.props.timeZoneService.getisMilitaryTime() || false;


    let csvData = "First Name, Last Name, Driver Id, Vehicle Id, Event Date, Tenant Id, Duty Status\n";

    dutyStatusData.forEach(row => {
      csvData +=
        row.driverFirstname + "," +
        row.driverLastname + "," +
        row.driverId + "," +
        row.vehicleId + "," +
        (row.eventDate ? DateFormatter.formatDateString(row.eventDate, timeZone, "", isMilitaryTime) : "") + "," +
        row.tenantId + "," +
        row.dutyStatus + "\n";
    })

    let saveDocument = document.createElement('a');
    saveDocument.href = 'data:text/csv;charset=utf-8,' + encodeURI(csvData);
    saveDocument.target = '_blank';
    saveDocument.download = 'dutyStatusSearchResults.csv';
    saveDocument.click();
  }


  render() {
    const {
      filter,
      dutyStatusData,
      tenantSummaryData,
      pagerState,
      sortState,
      hasSearched
    } = this.props;

    return (
      <div>
        <div
          className={styles.searchPaperStyle}
        >
          <Filter
            filter={filter}
            onFilterChanged={this._onFilterChange}
            onRunSearch={this._onRunSearch}
            tenantSummaryData={tenantSummaryData}
            filterStatusLabel={`${numeral(dutyStatusData.length).format("0,000")} ${dutyStatusData.length === 1 ? "Duty Status Record" : "Duty Status Records"}`}
            errorState={this.props.errorState}
          />
          <div
            className={styles.headerGroup}
          >
            <IconButton
              color="primary"
              title="Download"
              onClick={this.saveCSV}
            >
              <ArrowDownward />
            </IconButton>
            <DataTablePager
              onChangePage={this._onChangePage}
              count={dutyStatusData.length}
              page={pagerState.page}
              rowsPerPage={pagerState.rowsPerPage}
              onPagerStateChange={this._onPagerStateChangePager}
            />
          </div>
        </div>
        <Divider />
        <div>
        <ApplicationSecurityConsumer>
          {(securityContext) => {
            return (
              <DutyStatusGrid
                securityContext={securityContext}
                data={dutyStatusData}
                sortState={sortState}
                pagerState={pagerState}
                onPagerStateChange={this._onPagerStateChange}
                onSortChange={this._onSortChange}
                onJobTimeClick={this._onJobTimeClick}
              />
            )
          }}
          </ApplicationSecurityConsumer>
        </div>
        <Snackbar
          open={hasSearched && dutyStatusData.length === 0}
          message={"No data found"}
          autoHideDuration={5000}
          anchorOrigin={{
            horizontal: "center",
            vertical: "bottom"
          }}
        />
      </div>

    );
  }
}


export const DutyStatusSearch = TimeZoneService.inject(
  NavigationService.inject(
    _DutyStatusSearch
  )
);