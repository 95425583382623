import {
  React,
  bind,
  _
} from "$Imports/Imports";
  
import {
  Dialog,
  DialogActions,
  Button,
  DialogTitle,
  DialogContent,
  TextField
} from "$Imports/MaterialUIComponents";
  
import {
  ITenantRenameServiceInjectedProps, 
  TenantRenameService
} from "$State/tenant-views/TenantRenameFreezerService";
  
interface IRenameTenantBaseProps {
  /** Why the new name is invalid, if applicable */
  invalidMessage: string;
  /** If this dialog should be open */
  open: boolean;
  /** The original friendly name of the tenant */
  originalName: string;
  /** Callback when user clicks close button */
  onClose(): void;
  /** Callback when user clicks save button */
  onSave(): void;
}

type IRenameTenantProps = IRenameTenantBaseProps & ITenantRenameServiceInjectedProps;

class _RenameTenant extends React.Component<IRenameTenantProps> {
  @bind
  private _onCancelClick(): void {
    this.props.onClose();
  }

  @bind
  private _onSaveClick(): void {
    this.props.onSave();
  }

  @bind
  private _onTextChange(e: React.ChangeEvent<HTMLInputElement>) {
    this.props.tenantRenameService.onNameChange(e.target.value);
  }

  render() {
    const { invalidMessage, open, originalName } = this.props;
    const invalid = invalidMessage.length > 0;
    return (
      <Dialog open={open}>
        <DialogTitle>Rename Tenant</DialogTitle>
        <DialogContent>
          <TextField
            onChange={this._onTextChange}
            defaultValue={originalName}
            fullWidth
            name="renameField"
            error={invalid}
            helperText={invalidMessage}
          />
        </DialogContent>
        <DialogActions>
          <Button color="primary"onClick={this._onCancelClick}>
            Cancel
          </Button>
          <Button 
            color="primary"
            disabled={!this.props.tenantRenameService.hasNameChanged() || invalid}
            onClick={this._onSaveClick}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export const RenameTenant = TenantRenameService.inject(
  _RenameTenant
);