import {
  React
} from "$Imports/Imports";

const styles: {
  label: string;
  value: string;
  container: string;
} = require("./LabelValue.scss");

import {
  joinClasses
} from "$Utilities/css";

interface ILabelValueProp {
  labelValue: string | number | boolean | React.ComponentClass;
  labelValueClassName?: string;
  containerClassName?: string;
  valueClassName?: string;
}

export class LabelValue extends React.PureComponent<ILabelValueProp> {

  render() {
    const {
      containerClassName,
      labelValue,
      labelValueClassName,
      valueClassName
    } = this.props;

    return (
      <div
        className={
          joinClasses([
            styles.container,
            containerClassName
          ])
        }
      >
        <div
          className={
            joinClasses([
              styles.label,
              labelValueClassName
            ])
          }
        >
          {labelValue}
        </div>
        <div
          className={
            joinClasses([
              styles.value,
              valueClassName
            ])
          }
        >
          {this.props.children}
        </div>
      </div>
    );
  }
}
