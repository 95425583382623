import {
  React,
  _,
  bind
} from "$Imports/Imports";

import {
  getDutyStatusName
} from "$State/DutyStatuses";

import {
  IPagerState,
  calcPagedState
} from "$State/PagerPagingState";

import {
  DataTable,
  DataTablePager,
  IDataTableColumn,
  directionType,
  DateFormatter
} from "$Components/Common";

import {
  TableRow
} from "$Imports/MaterialUIComponents";

import {
  DutyStatusActivity
} from "$Generated/api";

import {
  JobTimeLink
} from "./JobTimeLink";

import {
  ISortState
} from "$State/SortState";
import { ApplicationSecurityContext, ISecurityContext, securityContextHasRoles } from "$Providers/AuthenticationProvider";

interface IDutyStatusGridProps {
  securityContext: ISecurityContext;
  pagerState: IPagerState;
  data: DutyStatusActivity[];
  sortState: ISortState;
  onPagerStateChange?: (pagerState: IPagerState) => void;
  onSortChange?: (sortState: ISortState) => void;
  onJobTimeClick?: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>, data: DutyStatusActivity) => void;
}

const styles: {
  columnHeader: string;
} = require("./DutyStatusSearch.scss");

export class DutyStatusGrid extends React.PureComponent<IDutyStatusGridProps> {
  
  private getColumns(securityContext: ISecurityContext): IDataTableColumn<DutyStatusActivity>[] {
      var table: IDataTableColumn<DutyStatusActivity>[] =[
        {
          columnName: "driver-first-name",
          columnFieldData: "driverFirstname",
          sortMethod: (d) => d.driverFirstname ? d.driverFirstname.toLowerCase() : "",
          headerValue: "First Name",
          headerProps: { className: styles.columnHeader }
        },
        {
          columnName: "driver-last-name",
          columnFieldData: "driverLastname",
          sortMethod: (d) => d.driverLastname ? d.driverLastname.toLowerCase() : "",
          headerValue: "Last Name",
          headerProps: { className: styles.columnHeader }
        },
        {
          columnName: "driver-id",
          columnFieldData: "driverId",
          sortMethod: (d) => d.driverId ? d.driverId.toLowerCase() : "",
          headerValue: "Driver Id",
          headerProps: { className: styles.columnHeader }
        },
        {
          columnName: "vehicle-id",
          columnFieldData: "vehicleId",
          sortMethod: (d) => d.vehicleId ? d.vehicleId.toLowerCase() : "",
          headerValue: "Vehicle Id",
          headerProps: { className: styles.columnHeader }
        },
        {
          columnName: "event-date",
          columnFieldData: (d) => (<DateFormatter value={d.eventDate} />),
          sortMethod: (d) => d.eventDate,
          headerValue: "Event Date",
          headerProps: { className: styles.columnHeader }
        }
      ];
      
      if(securityContext.IsAdminUser) {
        table.push(
          {
            columnName: "tenant-id",
            columnFieldData: "tenantId",
            headerValue: "Tenant Id",
            headerProps: { className: styles.columnHeader }
        });

        table.push(
        {
          columnName: "duty-status",
          columnFieldData: (d) => getDutyStatusName(d.dutyStatus),
          sortMethod: (d) => getDutyStatusName(d.dutyStatus),
          headerValue: "Duty Status",
          headerProps: { className: styles.columnHeader }
        },
        {
          columnName: "activity-timestamp",
          columnFieldData: (d) => (
            <JobTimeLink data={d} onClick={this._onJobTimeClick}/>
          ),
          sortMethod: (d) => d.activityTimestamp,
          headerValue: "Job Time",
          headerProps: { className: styles.columnHeader }
        }
        );
      };

    return table;
  };

  @bind 
  private _onJobTimeClick(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>, data: DutyStatusActivity): void {
    if (this.props.onJobTimeClick) {
      this.props.onJobTimeClick(event, data);
    }
  }

  @bind
  private _onPagerStateChanged(page: number, rowsPerPage: number) {
    if (this.props.onPagerStateChange) {
      this.props.onPagerStateChange({
        page,
        rowsPerPage,
      });
    }
  }


  @bind
  private _onSortChange(event: React.MouseEvent<HTMLElement, MouseEvent>, columnName: string | undefined, sortDirection: directionType) {
    if (this.props.onSortChange) {
      this.props.onSortChange({
        sortColumnName: columnName,
        sortDirection
      });
    }
  }

  @bind
  private _onChangePage() {
    // Do nothing.  Handled by the component.  Remove once typescript has been updated.
  }

  render() {
    const { securityContext, data, pagerState, sortState } = this.props;
    const pagedState = calcPagedState(pagerState);

    return (
      <DataTable
        columns={this.getColumns(securityContext)}
        data={data}
        skipRows={pagedState.skip}
        takeRows={pagedState.take}
        onSortChange={this._onSortChange}
        defaultSortColumnName={sortState.sortColumnName}
        defaultSortDirection={sortState.sortDirection}
        tableFooterComponent={(
          <TableRow>
            <DataTablePager
              count={data.length}
              onChangePage={this._onChangePage}
              page={pagerState.page}
              rowsPerPage={pagerState.rowsPerPage}
              onPagerStateChange={this._onPagerStateChanged}
            />
          </TableRow>
        )}
      />
    );
  }
}
