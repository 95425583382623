import {
  getLogger
} from "@yahara/logging";

import {
  _,
  moment
} from "$Imports/Imports";

import { UserRoles } from "$Components/Common";
import { ClientIdsEnum } from "./enums";
import { UserInfoViewModel } from "$Generated/api";

class AuthenticationSettings {

  private _logger = getLogger("AuthenticationSettings");
  private _currentUser: UserInfoViewModel | undefined = undefined;

  constructor() {
  
  }

  public getLastName(): string {
    return this._currentUser?.lastName ?? "";
  }

  public getFirstName(): string {
    return this._currentUser?.firstName ?? "";
  }

  public getEmailAddress(): string {
    return this._currentUser?.emailAddress ?? "";
  }

  public getRoles(): string[] {
    return this._currentUser?.roles ?? [];
  }

  public isAdminUser(): boolean {
    return (this._currentUser?.roles?.indexOf(UserRoles.PortalSuperuser) ?? -1) > -1;
  }

  public isOrgUser(): boolean {
    return (this._currentUser?.roles?.indexOf(UserRoles.PortalOrganizationUser) ?? -1) > -1;
  }

  public isClientUser(): boolean {
    return (this._currentUser?.roles?.indexOf(UserRoles.PortalClientUser) ?? -1) > -1;
  }

  public isClientType(type: ClientIdsEnum): boolean{
    return (this._currentUser?.clientIds?.indexOf(type) ?? -1) > -1;
  }

  // check if user has access to multiple clients
  public hasMultipleClients(): boolean {
    const clientIds = [
      ClientIdsEnum.SmartDrive,
      ClientIdsEnum.Netradyne,
      ClientIdsEnum.Lytx,
      ClientIdsEnum.Samsara
    ];
    
    const associatedClients = _.filter(clientIds, (clientId) => this.isClientType(clientId));

    if(associatedClients?.length > 1) {
      return true;
    }

    return false;
  }

  public canCreateTenants(): boolean {
    return (this._currentUser?.roles?.indexOf(UserRoles.CreateTenant) ?? -1) > -1;
  }

  public getClientIds(): number[] {
    return this._currentUser?.clientIds ?? [];
  }

  // save raw claims from login
  public setUserSecurityContext(userInformation: UserInfoViewModel | undefined) {
    this._currentUser = userInformation;
  }

  public isLoggedInAndHasAccess(): boolean | undefined {
    if(this._currentUser === undefined) {
      return false;
    }

    return true;
  }
}

export const AuthenticationSettingsInstance = new AuthenticationSettings();
