import {
  React,
  _,
  bind
} from "$Imports/Imports";

import {
  Button
} from "$Imports/MaterialUIComponents";

import { AdvanceTextField } from "$Components/Common";
import { ICredentialProps } from "$State/CredentialTypes";
import { ISAACInstrumentsCredentials } from "$Generated/api";
import { ValidateCredentialsResults, validateCredentials } from "$State/ValidateCredentials";

interface ISAACInstrumentsBaseProps {
}
type ISAACInstrumentsValidateCredentials = ISAACInstrumentsCredentials & ValidateCredentialsResults;

type ISAACInstrumentsProps = ISAACInstrumentsBaseProps & ICredentialProps<ISAACInstrumentsValidateCredentials>;

export class ISAACInstruments extends React.PureComponent<ISAACInstrumentsProps> {


  @bind
  onChange(e: any) {
    const iSAACInstruments: ISAACInstrumentsCredentials = this.props.data ? this.props.data : {};
    
    if (e.target.name === "password") {
      this.props.onChange(this.props.providerKey, { authentication: { password: e.target.value, username: iSAACInstruments.authentication?.username, grantType: iSAACInstruments.authentication?.grantType } });
    }

    if (e.target.name === "username") {
      this.props.onChange(this.props.providerKey, { authentication: { password: iSAACInstruments.authentication?.password, username: e.target.value, grantType: iSAACInstruments.authentication?.grantType } });
    }

    if (e.target.name === "basicToken") {
      this.props.onChange(this.props.providerKey, { basicToken: e.target.value });
    }

    if (e.target.name === "hostUrl") {
      this.props.onChange(this.props.providerKey, { hostUrl: e.target.value });
    }

  }

  @bind
  async validate (e:any) {
    this.props.onChange(this.props.providerKey, { running: true });
    var promiseResults = validateCredentials(this.props.data, this.props.providerKey);
    var results = await promiseResults;
    this.props.onChange(this.props.providerKey, { success: results.success ? 'true' : 'false' });
    this.props.onChange(this.props.providerKey, { errorResult: results.errorMessage });
    this.props.onChange(this.props.providerKey, { running: false });
  }

  render(): JSX.Element {
    const iSAACInstruments: ISAACInstrumentsCredentials = this.props.data ? this.props.data : {} as ISAACInstrumentsCredentials;
    const authentication = iSAACInstruments.authentication !== null && iSAACInstruments.authentication !== undefined ? iSAACInstruments.authentication : {
      username: "",
      password: ""
    };
    return (
      <div>
        <AdvanceTextField
          label="URL*"
          name="hostUrl"
          onChange={this.onChange}
          value={iSAACInstruments.hostUrl ? iSAACInstruments.hostUrl : ""}
          error={(iSAACInstruments.hostUrl === "")}
        /><br />
        <AdvanceTextField
          label="Username*"
          name="username"
          autoComplete="off"
          onChange={this.onChange}
          value={authentication.username ? authentication.username : ""}
          error={(authentication.username === "")}
        /><br />
        <AdvanceTextField
          label="Password*"
          name="password"
          type="password"
          autoComplete="new-password"
          onChange={this.onChange}
          value={authentication.password ? authentication.password : ""}
          error={(authentication.password === "")}
        /><br />
        <AdvanceTextField
          label="Basic Token*"
          name="basicToken"
          onChange={this.onChange}
          value={iSAACInstruments.basicToken ? iSAACInstruments.basicToken : ""}
          error={(iSAACInstruments.basicToken === "")}
        />
        <br />

        <Button disabled={this.props?.data?.running} onClick={this.validate} color={"primary"}>VALIDATE CREDENTIALS</Button>
        {this.props?.data?.success == 'none' || this.props?.data?.success === undefined ?
            <div />
            :
            this.props?.data?.success == 'false' ?
              <div style={{ color: "red", display: "block", marginLeft: "auto", marginRight: "auto" }}>{this.props?.data?.errorResult}</div>
              :
              <div style={{ color: "green", display: "block", marginLeft: "auto", marginRight: "auto", textAlign: "center" }}>Passed</div>}
      </div>
    );
  }
}