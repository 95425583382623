import {
  React,
  Switch,
  Route,
  Redirect,
  Router,
  _
} from "$Imports/Imports";

import { mainNavigation, INavigationItem } from "../modules/navigation";
import { AppContainer } from "./AppContainer";

import {
  ISecurityContext,
  ApplicationSecurityConsumer
} from "$Providers/.";

interface IRoutingProps {

}

export class Routing extends React.Component<IRoutingProps> {
  private _renderRouter(navigation: INavigationItem, levelIdx: number, idx: number, securityContext: ISecurityContext): JSX.Element[] {
    const results: JSX.Element[] = [];

    // Check if the menu item is enabled.
    const isEnabled = typeof navigation.enabled === "function" ? navigation.enabled(navigation, securityContext) : navigation.enabled;

    // Check if the user has access.
    const hasAccess = navigation.hasAccess !== undefined ?
      typeof navigation.hasAccess === "function" ? navigation.hasAccess(navigation, securityContext) : navigation.hasAccess
      : true;

    if (!navigation.externalLink && isEnabled && hasAccess) {
      if (navigation.redirectUrls !== undefined && _.isArray(navigation.redirectUrls)) {
        _.forEach(navigation.redirectUrls, (s, sIdx) => {
          results.push(
            (
              <Route key={`${levelIdx}-${idx}-${sIdx}`} exact={true} path={s}>
                <Redirect
                  to={navigation.url}
                />
              </Route>
            )
          );
        });
      }

      const router: JSX.Element = (
        <Route key={`${levelIdx}-${idx}`} path={navigation.url} component={navigation.component} />
      );

      if (navigation.childNavigation && _.isArray(navigation.childNavigation)) {
        _.forEach(navigation.childNavigation, (n, nIdx) => {
          const routes = this._renderRouter(n, levelIdx + 1, nIdx, securityContext);
          results.push(...routes);
        });
      }

      results.push(router);
    }

    return results;
  }
  render() {
    return (
      <ApplicationSecurityConsumer>
        {(context) => {
          const navigationRoutes: JSX.Element[][] = _.map(mainNavigation, (n, mnIdx: number) => {
            return this._renderRouter(n, 0, mnIdx, context);
          });

          const routes: JSX.Element[] = _.flatten(navigationRoutes);

          return (
            <Router>
              <AppContainer>
                <Switch>
                  {routes}
                </Switch>
              </AppContainer>
            </Router >
          );
        }
        }
      </ApplicationSecurityConsumer>
    );
  }
}
