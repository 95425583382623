import {
  React,
  bind,
  _,
  Helpers
} from "$Imports/Imports";

import {
  JobDetailViewRender
} from "./JobDetailViewRender";

import {
  Paper,
  Snackbar,
  IconButton,
  Slide,
  TransitionProps
} from "$Imports/MaterialUIComponents";

import {
  Container
} from "./Container";

import {
  AjaxActionIndicator,
} from "$Components/Common";

import {
  IJobDetailServiceInjectedProps,
  JobDetailService,
} from "$State/JobDetailFreezerService";

import {
  JobSummary
} from "./JobSummary";

import {
  ITenantViewItem,
  tenantViews,
  filterViews
} from "./tenant-views/index";

import {
  JobService
} from "$State/JobFreezerService";

import {
  Close
} from "$Imports/MaterialUIIcons";

import {
  JobDetailTabs
} from "./JobDetailTabs";

import {
  ApplicationSecurityContext, ISecurityContext
} from "$Providers/AuthenticationProvider";

const styles: {
  paperMargin: string;
  summaryContainer: string;
  adapterContainer: string;
} = require("./JobDetail.scss");

interface IJobDetailBaseProps {
  jobId: string;
}

type IJobDetailProps = IJobDetailBaseProps & IJobDetailServiceInjectedProps;

class _JobDetail extends React.Component<IJobDetailProps> {


  static contextType = ApplicationSecurityContext;

  componentWillMount() {
    const { jobId } = this.props;
    this.props.jobDetailService.setJobId(jobId);
    this.props.jobDetailService.fetchJobDetail();
  }

  @bind
  private _resubmitJob() {

    const {
      jobDetailResults,
    } = this.props.jobDetailService.getState();
    this.props.jobDetailService.resubmitJob();
    const jobDetail = jobDetailResults.data && jobDetailResults.data.data ? jobDetailResults.data.data : {};

    this.props.jobDetailService.setTenantName(jobDetail.tenant ? (jobDetail.tenant.friendlyName ? jobDetail.tenant.friendlyName : "") : "");

    JobService.clearRecentJobs();
  }

  @bind
  private _onTenantViewChange(event: React.ChangeEvent<{}>, view?: ITenantViewItem) {
    this.props.jobDetailService.setTenantView(view ? view : null);
  }

  @bind
  private _onClose(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    this.props.jobDetailService.setResubmitResponse("");
    this.props.jobDetailService.setTenantName("");
  }

  @bind
  private _onSnackClose(event: React.SyntheticEvent<any>, reason: string) {
    this.props.jobDetailService.setResubmitResponse("");
    this.props.jobDetailService.setTenantName("");
  }

  private SlideTransition(props: TransitionProps) {
    return <Slide {...props} direction="down" />;
  }

  render() {
    const {
      jobDetailResults,
      tenantViewResults,
      resubmissionResults,
      childJobs,
      selectedTenantView
    } = this.props.jobDetailService.getState();

    const haveJob: boolean = !Helpers.isNullOrUndefined(jobDetailResults.data) && jobDetailResults.hasFetched && !Helpers.isNullOrUndefined(childJobs);
    const jobDetail = jobDetailResults.data && jobDetailResults.data.data ? jobDetailResults.data.data : {};
    const associatedEntities = tenantViewResults?.data?.data || [];
    const resubmitResponse = this.props.jobDetailService.getResubmitResponse();
    const filteredViews = filterViews(tenantViews, this.context, associatedEntities);
    const currentView = selectedTenantView !== null ? selectedTenantView : _.first(filteredViews);

    const displayDetailView = tenantViewResults.hasFetched && jobDetailResults.hasFetched;

    return (
      <Container>
        <Paper
          className={styles.paperMargin}
        >
          <AjaxActionIndicator
            state={[
              jobDetailResults,
              tenantViewResults,
              resubmissionResults,
            ]}
          />
          <div
            className={styles.summaryContainer}
          >
            <JobSummary
              data={jobDetail}
              resubmit={this._resubmitJob}
            />
          </div>
        </Paper>
        {displayDetailView ? (
          <>
            <Paper
              className={styles.paperMargin}
            >
              <JobDetailTabs
                textColor="primary"
                indicatorColor="primary"
                tenantViews={filteredViews}
                selectedTenantView={currentView || null}
                onTenantViewChange={this._onTenantViewChange}
                value={-1}
              />
            </Paper>
            <Paper
              className={styles.paperMargin}
            >
              {
                haveJob && currentView ?
                  (
                    <JobDetailViewRender
                      childJobs={childJobs}
                      tenantItem={currentView}
                      job={jobDetail}
                    />
                  ) : null
              }
            </Paper>
          </>
        ) : null}
        <Snackbar
          onClose={this._onSnackClose}
          open={resubmitResponse.length > 0}
          message={resubmitResponse}
          autoHideDuration={5000}
          anchorOrigin={{
            horizontal: "center",
            vertical: "top"
          }}
          TransitionComponent={this.SlideTransition}
          action={[
            (
              <IconButton
                key="close"
                color="inherit"
                onClick={this._onClose}
              >
                <Close />
              </IconButton>
            ),
          ]}
        />
      </Container>
    );
  }
}

export const JobDetail = JobDetailService.inject(
  _JobDetail
);
