import {
  bind,
  FreezerService,
  IAjaxState,
  managedAjaxUtil,
  moment
} from "$Imports/Imports";

import {
  DrivingStatusEventIEnumerableResponseBase,
  TransportationApiFactory,
} from "$Generated/api";

import {
  IPagerState
} from "../PagerPagingState";

import {
  SitePubSubManager
} from "$Utilities/PubSubUtil";

import {
  ErrorService
} from "$State/ErrorFreezerService";

const InjectedPropName = "jobDetailReadingsService";

const defaultPagerState: IPagerState = {
  page: 0,
  rowsPerPage: 50,
};

interface ITenantViewReadingFreezerState {
  jobDetailReadingsResults: IAjaxState<DrivingStatusEventIEnumerableResponseBase>;
  jobDetailReadingsPager: IPagerState;
  jobDetailReadingsSearch: string;
  jobId: string | null;
}

class TenantViewReadingFreezerService extends FreezerService<ITenantViewReadingFreezerState, typeof InjectedPropName> {
  constructor() {
    super({
      jobDetailReadingsResults: managedAjaxUtil.createInitialState(),
      jobDetailReadingsPager: defaultPagerState,
      jobDetailReadingsSearch: "",
      jobId: null
    }, InjectedPropName);

    SitePubSubManager.subscribe("application:login:before", this.clearResults);
    SitePubSubManager.subscribe("job-detail:job-id-change", this.clearResults);
  }

  public setSearch(search: string) {
    const currentPaging = this.freezer.get().jobDetailReadingsPager.toJS();

    this.freezer.get().set({
      jobDetailReadingsSearch: search,
      jobDetailReadingsPager: {
        page: 0,
        rowsPerPage: currentPaging.rowsPerPage
      }
    });
  }

  public setReadingsJobId(jobId: string) {
    this.freezer.get().set({
      jobId
    });
  }

  @bind
  private clearResults() {
    this.freezer.get().set({
      jobDetailReadingsResults: managedAjaxUtil.createInitialState(),
      jobDetailReadingsSearch: "",
      jobDetailReadingsPager: defaultPagerState,
    });
  }

  public setPagerState(pager: IPagerState) {
    this.freezer.get().jobDetailReadingsPager.set(pager);
  }

  public async fetchReadings(forceUpdate: boolean = false) {
    const results = this.freezer.get().jobDetailReadingsResults;
    const jobId = this.freezer.get().jobId;

    if (results.hasFetched && !forceUpdate) {
      return;
    }

    return managedAjaxUtil.fetchResults({
      ajaxStateProperty: "jobDetailReadingsResults",
      freezer: this.freezer,
      onExecute: (apiOptions, params, options) => {
        const transportationFactory = TransportationApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
        return transportationFactory.apiV1DrivingStatusesGet(params);
      },
      params: {
        jobId: jobId ? jobId : undefined,
      },
      onError: () => {
        ErrorService.pushErrorMessage("Failed to retrieve vehicle assignment data from the server.");
      }
    });
  }
}

export const TenantViewReadingsService = new TenantViewReadingFreezerService();
export type ITenantViewReadingsServiceInjectedProps = ReturnType<TenantViewReadingFreezerService["getPropsForInjection"]>;