import {
  React
} from "$Imports/Imports";

const styles: {
  mainContainer: string;
  iconContainer: string;
  labelContainer: string;
} = require("./StatusIndicatorLabel.scss");

interface IStatusIndicatorLabelProps extends React.HTMLProps<HTMLDivElement> {
  icon: JSX.Element;
}

export class StatusIndicatorLabel extends React.PureComponent<IStatusIndicatorLabelProps> {
  render() {
    const { icon, className, ...passthroughProps } = this.props;
    return (
      <div
        className={className ? `${styles.mainContainer} ${className}` : styles.mainContainer}
        {...passthroughProps}
      >
        <div
          className={styles.iconContainer}
        >
          {icon}
        </div>
        <div
          className={styles.labelContainer}
        >
          {this.props.children}
        </div>
      </div>

    );
  }
}