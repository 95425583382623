import {
    FreezerService,
    IAjaxState,
    managedAjaxUtil,
    _,
    bind
  } from "$Imports/Imports";
  
  import {
    JobDetailApiFactory,
    SmartDriveExportResultListResponseBase,
  } from "$Generated/api";
  
  import {
    SitePubSubManager
  } from "$Utilities/PubSubUtil";
  
  import {
    ErrorService
  } from "$State/ErrorFreezerService";
  
  
  const InjectedPropName = "smartDriveErrorReport";
  
  interface ISmartDriveErrorReportFreezerState {
    jobId: string | null;
    errorResults: IAjaxState<SmartDriveExportResultListResponseBase>;
  }
  
  class SmartDriveErrorReportFreezerService extends FreezerService<ISmartDriveErrorReportFreezerState, typeof InjectedPropName> {
    constructor() {
      super({
        jobId: null,
        errorResults: managedAjaxUtil.createInitialState(),
      }, InjectedPropName);
  
      SitePubSubManager.subscribe("application:login:before", this.clearResults);
      SitePubSubManager.subscribe("job-detail:job-id-change", this.clearResults);
    }
  
    @bind
    private clearResults() {
      this.freezer.get().set({
        jobId: null,
        errorResults: managedAjaxUtil.createInitialState()
       });
    }
  
  
    public setJobId(jobId: string): void {
      this.freezer.get().set({
        jobId,
      });
    }
  
    public async fetchErrors(forceUpdate: boolean = false) {
      if (this.freezer.get().errorResults.hasFetched
        && !forceUpdate) {
        return;
      }
  
      const jobId = this.freezer.get().jobId;
  
      return managedAjaxUtil.fetchResults({
        ajaxStateProperty: "errorResults",
        freezer: this.freezer,
        onExecute: (apiOptions, params, options) => {
          const submissionFactory = JobDetailApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
          return submissionFactory.apiV1JobDetailSmartDriveExportErrorsGet(params);
        },
        params: {
          jobId: jobId ? jobId : undefined
        },
        onError: () => {
          ErrorService.pushErrorMessage("Failed to retrieve error data from server");
        }
      })
    }
  }
  
  export const SmartDriveErrorReportService = new SmartDriveErrorReportFreezerService();
  export type ISmartDriveErrorReportFreezerServiceInjectedProps = ReturnType<SmartDriveErrorReportFreezerService["getPropsForInjection"]>;