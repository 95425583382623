// #region Imports
import {
  React,
  bind
} from "$Imports/Imports";

import {
  Button,
  Paper
} from "$Imports/MaterialUIComponents";

import {
  SitePubSubManager
} from "$Utilities/PubSubUtil";

import {
  AdvanceTextField,
  AjaxActionIndicator
} from "$Components/Common";

import {
  AuthenticationSettingsInstance
} from "$Utilities/Security";

import {
  ILoginServiceInjectedProps,
  LoginService
} from "$State/LoginFreezerService";

// #endregion Imports

// tslint:disable-next-line
const styles = require('./LoginSplashPage.scss') as {
  actions: string;
  form: string;
  main: string;
  loginTitle: string;
  applicationTitle: string;
  applicationSubTitle: string;
  applicationTitleContainer: string;
  loginPaper: string;
};

interface ILoginSplashPageBaseProps {
  onLogin?: () => void;
}

type ILoginSplashPageProps = ILoginSplashPageBaseProps &
  ILoginServiceInjectedProps;

class _LoginSplashPage extends React.Component<ILoginSplashPageProps> {

  componentDidMount() {
    SitePubSubManager.publish("application:login:before", null);
  }

  @bind
  private handleEmailUpdate(newValue: string) {
    this.props.login.setFormValue({ username: newValue });
  }

  @bind
  private async handleLoginClick() {
    await this.props.login.attemptLogin();
    
    if (AuthenticationSettingsInstance.isLoggedInAndHasAccess()) {
      if (this.props.onLogin) {
        this.props.onLogin();
      }
    }
  }

  @bind
  private handlePasswordUpdate(e: React.ChangeEvent<HTMLInputElement>) {
    this.props.login.setFormValue({ password: e.target.value });
  }

  @bind
  private handleKeyPress(e: React.KeyboardEvent<{}>) {
    if (e.key === "Enter") {
      this.handleLoginClick();
    }
  }

  render(): JSX.Element {
    const loginState = this.props.login.getState();
    const { hasError, formFields, attemptLoginResults } = loginState;

    return (
      <div className={styles.main}>
        <div
          className={styles.applicationTitleContainer}
        >
          <div
            className={styles.applicationTitle}
          >
            TTIP
          </div>
          <div
            className={styles.applicationSubTitle}
          >
            Transportation Telematics Integration Platform
          </div>
        </div>
        <Paper
          className={styles.loginPaper}
        >
          <AjaxActionIndicator
            state={[attemptLoginResults]}
          />
          <div
            className={styles.form}
          >
            <div
              className={styles.loginTitle}
            >
              Login
            </div>

            <AdvanceTextField
              name="Email"
              label="Email Address"
              value={formFields.username}
              autoComplete="no"
              fullWidth={true}
              onDebouncedChange={this.handleEmailUpdate}
            />

            <br />

            <AdvanceTextField
              name="Password"
              autoComplete="no"
              type="password"
              label="Password"
              value={formFields.password}
              onChange={this.handlePasswordUpdate}
              error={hasError}
              helperText={hasError ? "Invalid password or user name." : undefined}
              onKeyPress={this.handleKeyPress}
              fullWidth={true}
            />

            <div className={styles.actions}>
              <Button
                color="primary"
                variant="contained"
                fullWidth={true}
                onClick={this.handleLoginClick}
                disabled={attemptLoginResults.state === "fetching"}
              >
                Login
              </Button>
            </div>
          </div>
        </Paper>
      </div>
    );
  }
}

export const LoginSplashPage = LoginService.inject(
  _LoginSplashPage,
);
