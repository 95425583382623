import {
  React,
  _,
  bind
} from "$Imports/Imports";
import { Button } from "$Imports/MaterialUIComponents";
// data models
import { AdvanceTextField } from "$Components/Common";
import { ICredentialProps } from "$State/CredentialTypes";
import { OmnitracsCredentials } from "$Generated/api";
import { ValidateCredentialsResults, validateCredentials } from "$State/ValidateCredentials";

interface IOmnitracsBaseProps {
}

type OmnitracsCredentialsValidation = OmnitracsCredentials & ValidateCredentialsResults;

type IOmnitracsProps = IOmnitracsBaseProps & ICredentialProps<OmnitracsCredentialsValidation>;
export class Omnitracs extends React.PureComponent<IOmnitracsProps> {

  @bind
  async validate (e:any) {
    this.props.onChange(this.props.providerKey, { running: true });
    var promiseResults = validateCredentials(this.props.data, this.props.providerKey);
    var results = await promiseResults;
    this.props.onChange(this.props.providerKey, { success: results.success ? 'true' : 'false' });
    this.props.onChange(this.props.providerKey, { errorResult: results.errorMessage });
    this.props.onChange(this.props.providerKey, { running: false });
  }
  
    @bind
  handleOnChange(e: any): void {
    if (e.target.name === "username") {
      this.props.onChange(this.props.providerKey, { username: e.target.value });
    }
    else if (e.target.name === "password") {
      this.props.onChange(this.props.providerKey, { password: e.target.value });
    }
    else if (e.target.name === "company") {
      this.props.onChange(this.props.providerKey, { company: e.target.value });
    }
  }

  componentDidMount() {
  }

  render(): JSX.Element {
    const omnitracs: OmnitracsCredentials = this.props.data ? this.props.data : {} as OmnitracsCredentials;
    return (
      <div>
        <AdvanceTextField
          label="Username*"
          name="username"
          autoComplete="off"
          value={omnitracs.username}
          onChange={this.handleOnChange}
          error={(omnitracs.username === "")}
        /><br />
        <AdvanceTextField
          name="password"
          autoComplete="new-password"
          type="password"
          label="Password"
          value={omnitracs.password}
          onChange={this.handleOnChange}
          error={(omnitracs.password === "")}
        /><br />
        <AdvanceTextField
          label="Company*"
          name="company"
          value={omnitracs.company}
          onChange={this.handleOnChange}
          error={(omnitracs.company === "")}
        /><br />
        <Button disabled={this.props?.data?.running} onClick={this.validate} color={"primary"}>VALIDATE CREDENTIALS</Button>
        {this.props?.data?.success == 'none' || this.props?.data?.success === undefined ?
            <div style={{ display: "block", marginLeft: "auto", marginRight: "auto", textAlign: "center" }}>Can fail due to timeout if there are a lot of depots.</div>
            :
            this.props?.data?.success == 'false' ?
              <div style={{ color: "red", display: "block", marginLeft: "auto", marginRight: "auto" }}>{this.props?.data?.errorResult}</div>
              :
              <div style={{ color: "green", display: "block", marginLeft: "auto", marginRight: "auto", textAlign: "center" }}>Passed</div>}
      </div>
    );
  }
}