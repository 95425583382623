import { MuiThemeProvider } from "@material-ui/core/styles";
import createMuiTheme from "@material-ui/core/styles/createMuiTheme";
import { withStyles, Theme } from "@material-ui/core/styles";
import { ThemeOptions } from "@material-ui/core/styles/createMuiTheme";
import { WithStylesOptions, Styles } from "@material-ui/core/styles/withStyles";

export {
  MuiThemeProvider,
  createMuiTheme,
  ThemeOptions,
  Styles,
  WithStylesOptions,
  withStyles,
  Theme,
};
