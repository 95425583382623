import {
  React,
} from "$Imports/Imports";
import * as _ from "lodash";

interface IJsonPropertyDisplayProps {
  data: { [key: string]: any; }
}

export class JsonPropertyDisplay extends React.PureComponent<IJsonPropertyDisplayProps> {
  render() {
    const { data } = this.props;

    var jsonProperties: JSX.Element[] = [];
    for (const key in data) {
      const val = _.isNil(data[key]) ? "" : data[key].toString();
      jsonProperties.push(<><span key={key}><strong>{key}:</strong> {val}</span><br/></>);
    }

    return (
      <>
        {jsonProperties}
      </>
    );
  }
}