import {
  React,
  withRouter
} from "$Imports/Imports";

import {
  NavigationService
} from "$State/NavigationFreezerService";

class AppContainerComponent extends React.PureComponent<any, {}> {

  render() {
    NavigationService.initHistory(this.props.history);

    return (
      <div>
        {this.props.children}
      </div>
    );
  }
}

const AppContainer = withRouter(AppContainerComponent);
export { AppContainer };
