import {
  React,
  _,
  bind
} from "$Imports/Imports";
import { Button } from "$Imports/MaterialUIComponents";

import { AdvanceTextField } from "$Components/Common";
import { ICredentialProps } from "$State/CredentialTypes";
import { CoretexCredentials } from "$Generated/api";

interface ICoretexBaseProps { }

type ICoretexProps = ICoretexBaseProps & ICredentialProps<CoretexCredentials>;

export class Coretex extends React.PureComponent<ICoretexProps> {
  @bind
  onChange(e: any) {
    if (e.target.name === "username") {
      this.props.onChange(this.props.providerKey, { username: e.target.value });
    }
    else if (e.target.name === "password") {
      this.props.onChange(this.props.providerKey, { password: e.target.value });
    }
    else if (e.target.name === "apiKey") {
      this.props.onChange(this.props.providerKey, { apiKey: e.target.value });
    }
  }

  render(): JSX.Element {
    const coretex: CoretexCredentials = this.props.data ? this.props.data : {} as CoretexCredentials;
    return (
      <div>
        <AdvanceTextField
          label="User Name*"
          name="username"
          onChange={this.onChange}
          value={coretex.username ? coretex.username : ""}
          error={(coretex.username === "")}
        />
        <br />
        <AdvanceTextField
          label="Password*"
          name="password"
          type="password"
          onChange={this.onChange}
          value={coretex.password ? coretex.password : ""}
          error={(coretex.password === "")}
        />
        <br />
        <AdvanceTextField
          label="Api Key*"
          name="apiKey"
          onChange={this.onChange}
          value={coretex.apiKey ? coretex.apiKey : ""}
          error={(coretex.apiKey === "")}
        />
        <br />
        <br />
        <Button className="float-right" disabled={true} color={"primary"}>VALIDATE CREDENTIALS</Button>{/* Disabled for as per LDM - 483 */}
      </div>
    );
  }
}