import {
  React,
  bind,
  _,
} from "$Imports/Imports";
  
import {
  ITenantViewProps
} from "../TenantViewProps";
  
import {
  ILytxAdminErrorReportFreezerServiceInjectedProps,
  LytxAdminErrorReportService
} from "$State/tenant-views/LytxAdminErrorReportFreezerService";
  
import {
  AjaxActionIndicator
} from "$Components/Common/AjaxActionIndicator";
  
import {
  Divider,
  Snackbar, TableRow,
} from "@material-ui/core";
  
import {
  DataTable,
  IDataTableColumn,
  DataTablePager,
} from "$Components/Common";
  
import { 
  ErrorDetails, LytxAdminReportView, StringInt32KeyValuePair,
} from "$Generated/api";

import {
  calcPagedState
} from "$State/PagerPagingState";

import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "$Imports/MaterialUIComponents";
  
interface ILytxAdminErrorReportViewBaseProps {

}
  
type ILytxAdminErrorReportViewProps = ILytxAdminErrorReportViewBaseProps
  & ITenantViewProps
  & ILytxAdminErrorReportFreezerServiceInjectedProps;

class _LytxAdminErrorReportView extends React.Component<ILytxAdminErrorReportViewProps> {

  private readonly metadataColumns: IDataTableColumn<StringInt32KeyValuePair>[] = [
    {
      columnName: "action",
      columnFieldData: (x) => x.key ?? "",
      headerValue: "Action",
    },
    {
      columnName: "actionCount",
      columnFieldData: (x) => x.value,
      headerValue: "Action Count",
    },
  ]

  private readonly columns: IDataTableColumn<ErrorDetails>[] = [
    {
      columnName: "action",
      columnFieldData: (x) => x.action ?? "",
      sortMethod: (x) => (x.action ?? "").toLowerCase(),
      headerValue: "Action",
    },
    {
      columnName: "errorType",
      columnFieldData: (x) => x.errorType ?? "",
      sortMethod: (x) => (x.errorType ?? "").toLowerCase(),
      headerValue: "Success",
    },
    {
      columnName: "employeeId",
      columnFieldData: (x) => x.employeeId ?? "",
      sortMethod: (x) => (x.employeeId ?? "").toLowerCase(),
      headerValue: "Employee ID",
    },
    {
      columnName: "lastName",
      columnFieldData: (x) => x.lastName ?? "",
      sortMethod: (x) => (x.lastName ?? "").toLowerCase(),
      headerValue: "Last Name",
    },
    {
      columnName: "firstName",
      columnFieldData: (x) => x.firstName ?? "",
      sortMethod: (x) => (x.firstName ?? "").toLowerCase(),
      headerValue: "First Name",
    },
    {
      columnName: "error",
      columnFieldData: (x) => x.error ?? "",
      sortMethod: (x) => (x.error ?? "").toLowerCase(),
      headerValue: "Error Details",
    }
  ]

  componentDidMount() {
    if (this.props.job !== null && this.props.job.id !== undefined) {
      this.props.LytxAdminErrorReport.setJobId(this.props.job.id);
      this.props.LytxAdminErrorReport.fetchErrors();
    }
  }

  private _getSubmissionFromDateString(submissionDateString: string): LytxAdminReportView | undefined {
    const { reportListResults } = this.props.LytxAdminErrorReport.getState();
    const reportList = reportListResults.data?.success ? reportListResults?.data?.data ?? [] : [];

    for (const report of reportList) {
      if (report.createdOn === submissionDateString) {
        return report;
      }
    }
  }

  private _changeSubmissionSelection = (e: React.ChangeEvent<{ name?: string | undefined; value: unknown; }>) => {
    const report = this._getSubmissionFromDateString(e.target.value as string);

    if (report){
      this.props.LytxAdminErrorReport.setSelectedLytxReport(report);
    }
  }

  @bind
  private _onPagerStateChange(page: number, rowsPerPage: number) {
    LytxAdminErrorReportService.setLytxReportPagerState({
      page,
      rowsPerPage
    });
  }

  @bind
  private _onChangePage() {
    // Do nothing.  Handled by the component.  Remove once typescript has been updated.
  }

  render() {
    const { reportListResults, selectedReport, lytxReportPagedState } = this.props.LytxAdminErrorReport.getState();
    const reportList = reportListResults.data?.success ? reportListResults?.data?.data ?? [] : []
    const errorData = selectedReport?.errors ?? [];
    const metaData = selectedReport?.actionMetadata ?? [];
    const pagedState = calcPagedState(lytxReportPagedState);

    return (
      <>
        <AjaxActionIndicator state={[reportListResults]} />
        <div style={{padding: 15}}>
          <FormControl>
            <InputLabel>Report</InputLabel>
            <Select
              value={selectedReport?.createdOn ?? ""}
              onChange={this._changeSubmissionSelection}
            >
              {_.map(reportList, (sub) => (
                <MenuItem value={sub.createdOn}>
                  {sub.createdOn}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <div style={{width: "50%"}}>
          <Divider/>
          <DataTable
            columns={this.metadataColumns}
            data={metaData}
            hideHeader={true}
          />
        </div>
        <Divider/>
        <DataTable
          columns={this.columns}
          data={errorData}
          skipRows={pagedState.skip}
          takeRows={pagedState.take}
          tableFooterComponent={(
            <TableRow>
              <DataTablePager
                onChangePage={this._onChangePage}
                count={errorData.length}
                page={lytxReportPagedState.page}
                rowsPerPage={lytxReportPagedState.rowsPerPage}
                onPagerStateChange={this._onPagerStateChange}
              />
            </TableRow>
          )}
        />
        <Snackbar
          open={reportListResults.hasFetched && !reportListResults.data?.success}
          message={"Error loading data from server"}
          autoHideDuration={5000}
          anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
        />
      </>
    );
  }
}

export const LytxAdminErrorReportView = LytxAdminErrorReportService.inject(
  _LytxAdminErrorReportView
);