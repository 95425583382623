import {
  React,
  _,
  bind
} from "$Imports/Imports";
import { Button } from "$Imports/MaterialUIComponents";
import { AdvanceTextField } from "$Components/Common";
import { ICredentialProps } from "$State/CredentialTypes";
import { BlueTreeCredentials } from "$Generated/api";

interface IBlueTreeBaseProps {
}
type IBlueTreeProps = IBlueTreeBaseProps & ICredentialProps<BlueTreeCredentials>;

export class BlueTree extends React.PureComponent<IBlueTreeProps>{

  @bind
  onChange(e: any) {
    if (e.target.name === "username") {
      this.props.onChange(this.props.providerKey, { username: e.target.value });
    }
    else {
      this.props.onChange(this.props.providerKey, { password: e.target.value });
    }
  }

  render(): JSX.Element {
    const blueTree: BlueTreeCredentials = this.props.data ? this.props.data : {} as BlueTreeCredentials;
    return (
      <div>
        <AdvanceTextField
          label="Username*"
          name="username"
          autoComplete="off"
          value={blueTree.username}
          onChange={this.onChange}
          error={(blueTree.username === "")}
        /><br />
        <AdvanceTextField
          name="password"
          autoComplete="new-password"
          type="password"
          label="Password"
          value={blueTree.password}
          onChange={this.onChange}
          error={(blueTree.password === "")}
        /><br />
        <Button className="float-right" disabled={true} color={"primary"} >VALIDATE CREDENTIALS</Button>
      </div>
    );
  }
}