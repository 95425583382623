import {
  React,
  _,
  bind,
  numeral,
  moment
} from "$Imports/Imports";

import {
  SearchBox,
  DateFormatter,
  timeZoneType,
  DataTablePager
} from "$Components/Common";

import {
  TenantViewVehicleAssignmentService
} from "$State/job-views/VehicleAssignmentFreezerService";

import {
  VehicleAssignmentGrid
} from "./VehicleAssignmentGrid";

import {
  VehicleAssignmentViewModel
} from "$Generated/api";

import {
  IconButton,
  Divider
} from "$Imports/MaterialUIComponents";

import {
  ArrowDownward,
} from "$Imports/MaterialUIIcons";

import { IPagerState } from "$State/PagerPagingState";

import {
  TimeZoneService,
  ITimeZoneServiceInjectedProps
} from "$State/TimeZoneFreezerService";

interface IVehicleAssignmentBaseProps {
  data: VehicleAssignmentViewModel[];
  search: string;
  pagerState: IPagerState;
  parentJobId?: string;
}

type IVehicleAssignmentProps = IVehicleAssignmentBaseProps & ITimeZoneServiceInjectedProps;

const styles: {
  searchHeaderContainer: string;
  rightItemsContainer: string;
} = require("./VehicleAssignment.scss");

export class _VehicleAssignment extends React.PureComponent<IVehicleAssignmentProps> {

  @bind
  private _onDebounceChange(newValue: string) {
    TenantViewVehicleAssignmentService.setVehicleAssignmentSearch(newValue);
  }

  private _onSearch(data: VehicleAssignmentViewModel[], search: string): VehicleAssignmentViewModel[] {
    if (search.trim() === "") {
      return data;
    }

    return _.filter(data, (d) => {

      const lastNameResults = (d.lastName ? d.lastName.toLowerCase() : "").indexOf(search.toLowerCase()) !== -1;

      if (lastNameResults) {
        return true;
      }

      const firstNameResults = (d.firstName ? d.firstName.toLowerCase() : "").indexOf(search.toLowerCase()) !== -1;

      if (firstNameResults) {
        return true;
      }

      const driverIdResults = (d.driverId ? d.driverId.toLowerCase() : "").indexOf(search.toLowerCase()) !== -1;

      if (driverIdResults) {
        return true;
      }

      const vehicleIdResults = (d.vehicleId ? d.vehicleId.toLowerCase() : "").indexOf(search.toLowerCase()) !== -1;

      if (vehicleIdResults) {
        return true;
      }

      const infoResults = (d.info ? d.info.toLowerCase() : "").indexOf(search.toLowerCase()) !== -1;

      if (infoResults) {
        return true;
      }

      return false;
    });
  }

  @bind
  private _onPagerStateChange(pagerState: IPagerState) {
    TenantViewVehicleAssignmentService.setVehicleAssignmentPagerState(pagerState);
  }

  @bind
  private _onPagerStateChangePager(page: number, rowsPerPage: number) {
    this._onPagerStateChange({
      page,
      rowsPerPage,
    });
  }

  @bind
  private _onClearSearch() {
    TenantViewVehicleAssignmentService.setVehicleAssignmentSearch("");
  }

  @bind
  private saveCSV() {
    const { data, search, parentJobId } = this.props;
    const filterData = this._onSearch(data, search);
    const timeZone: timeZoneType = this.props.timeZoneService.getTimeZone() || "UTC";
    const isMilitaryTime: boolean = this.props.timeZoneService.getisMilitaryTime() || false;

    let csvData = "First Name, Last Name, Driver Id, Vehicle Id, Start Date, End Date\n";
    filterData.forEach((row) => {
      csvData +=
        row.firstName + "," +
        row.lastName + "," +
        row.driverId + "," +
        row.vehicleId + "," +
        (row.startDate ? DateFormatter.formatDateString(row.startDate, timeZone, "", isMilitaryTime) : "") + "," +
        ((row.endDate && !moment(row.endDate).isSame("0001-01-01T00:00:00")) ? DateFormatter.formatDateString(row.endDate, timeZone, "", isMilitaryTime) : "") + "\n";
    });

    const saveDocument = document.createElement('a');
    saveDocument.href = 'data:text/csv;charset=utf-8,' + encodeURI(csvData);
    saveDocument.target = '_blank';
    if (parentJobId != undefined) {
      saveDocument.download = 'jobDetails-' + parentJobId + '.csv';
    } else {
      saveDocument.download = 'jobDetails.csv'
    }
    saveDocument.click();
  }

  @bind
  private _onChangePage() {
    // Do nothing.  Handled by the component.  Remove once typescript has been updated.
  }

  render() {
    const { data, search, pagerState } = this.props;

    const filterData = this._onSearch(data, search);

    return (
      <div>
        <div
          className={styles.searchHeaderContainer}
        >
          <div>
            <SearchBox
              value={search}
              onDebouncedChange={this._onDebounceChange}
              filterStatusLabel={`${numeral(filterData.length).format("0,000")} ${filterData.length === 1 ? "Assignment" : "Assignments"}`}
              onClearClick={this._onClearSearch}
            />
          </div>
          <div
            className={styles.rightItemsContainer}
          >
            <div>
              <IconButton
                color="primary"
                title="Download"
                onClick={this.saveCSV}
              >
                <ArrowDownward />
              </IconButton>
            </div>
            <div>
              <DataTablePager
                onChangePage={this._onChangePage}
                count={data.length}
                page={pagerState.page}
                rowsPerPage={pagerState.rowsPerPage}
                onPagerStateChange={this._onPagerStateChangePager}
              />
            </div>
          </div>
        </div>
        <Divider />
        <div>
          <VehicleAssignmentGrid
            data={filterData}
            pagerState={pagerState}
            onPagerStateChange={this._onPagerStateChange}
          />
        </div>
      </div>
    );
  }
}

export const VehicleAssignment = TimeZoneService.inject(
  _VehicleAssignment
);