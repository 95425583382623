import {
  React,
  _,
  bind
} from "$Imports/Imports";
import { Button } from "$Imports/MaterialUIComponents";
import { AdvanceTextField } from "$Components/Common";
import { PlatformScienceCredentials } from "$Generated/api";
import { ICredentialProps } from "$State/CredentialTypes";
import { ValidateCredentialsResults, validateCredentials } from "$State/ValidateCredentials";

interface IPlatformScienceBaseProps {
}

type PlatformScienceValidateCredentials = PlatformScienceCredentials & ValidateCredentialsResults;

type IPlatformScienceProps = IPlatformScienceBaseProps & ICredentialProps<PlatformScienceValidateCredentials>;

export class PlatformScience extends React.PureComponent<IPlatformScienceProps>{


  @bind
  onChange(e: any): void {
    if (e.target.name === "baseUrl") {
      this.props.onChange(this.props.providerKey, { baseUrl: e.target.value });
    }
    else if (e.target.name === "bearerToken") {
      this.props.onChange(this.props.providerKey, { bearerToken: e.target.value });
    }
    else if (e.target.name === "carrierIdFilter") {
      this.props.onChange(this.props.providerKey, { carrierIdFilter: e.target.value });
    }
  }

  @bind
  async validate (e:any) {
    this.props.onChange(this.props.providerKey, { running: true });
    var promiseResults = validateCredentials(this.props.data, this.props.providerKey);
    var results = await promiseResults;
    this.props.onChange(this.props.providerKey, { success: results.success ? 'true' : 'false' });
    this.props.onChange(this.props.providerKey, { errorResult: results.errorMessage });
    this.props.onChange(this.props.providerKey, { running: false });
  }

  render(): JSX.Element {
    const platformScience: PlatformScienceCredentials = this.props.data ? this.props.data : {} as PlatformScienceCredentials;
    return (
      <div>
        <AdvanceTextField
          label="URL*"
          name="baseUrl"
          onChange={this.onChange}
          value={platformScience.baseUrl ? platformScience.baseUrl : ''}
          error={(platformScience.baseUrl === "")}
          placeholder="https://<subnet>.pltsci.com"
        /><br />
        <AdvanceTextField
          label="Bearer Token*"
          name="bearerToken"
          onChange={this.onChange}
          value={platformScience.bearerToken ? platformScience.bearerToken : ''}
          error={(platformScience.bearerToken === "")}
        /><br />
        <AdvanceTextField
          label="Carrier Id"
          name="carrierIdFilter"
          onChange={this.onChange}
          value={platformScience.carrierIdFilter ? platformScience.carrierIdFilter : ''}
        /><br />
        <Button disabled={this.props?.data?.running} onClick={this.validate} color={"primary"}>VALIDATE CREDENTIALS</Button>
        {this.props?.data?.success == 'none' || this.props?.data?.success === undefined ?
            <div />
            :
            this.props?.data?.success == 'false' ?
              <div style={{ color: "red", display: "block", marginLeft: "auto", marginRight: "auto" }}>{this.props?.data?.errorResult}</div>
              :
              <div style={{ color: "green", display: "block", marginLeft: "auto", marginRight: "auto", textAlign: "center" }}>Passed</div>}
      </div>
    );
  }
}
