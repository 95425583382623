import {
  React,
  _,
  bind
} from "$Imports/Imports";
import { Button } from "$Imports/MaterialUIComponents";
import { AdvanceTextField } from "$Components/Common";
import { TeletracCredentials } from "$Generated/api";
import { ICredentialProps } from "$State/CredentialTypes";


interface ITeletracBaseProps {
}

type ITeletracProps = ITeletracBaseProps & ICredentialProps<TeletracCredentials>;
export class Teletrac extends React.PureComponent<ITeletracProps>{

  @bind
  onChange(e: any) {
    if (e.target.name === "username") {
      this.props.onChange(this.props.providerKey, { username: e.target.value });
    }
    else if (e.target.name === "password") {
      this.props.onChange(this.props.providerKey, { password: e.target.value });
    }
    else if (e.target.name === "tenantId") {
      this.props.onChange(this.props.providerKey, { tenantId: e.target.value });
    }
    else if (e.target.name === "accountId") {
      this.props.onChange(this.props.providerKey, { accountId: e.target.value });
    }
  }

  render(): JSX.Element {
    const teletrac: TeletracCredentials = this.props.data ? this.props.data : {} as TeletracCredentials;
    return (
      <div>
        <AdvanceTextField
          label="Username*"
          name="username"
          autoComplete="off"
          onChange={this.onChange}
          value={teletrac.username ? teletrac.username : ''}
          error={(teletrac.username === "")}
        /><br />
        <AdvanceTextField
          label="Password*"
          name="password"
          type="password"
          autoComplete="new-password"
          onChange={this.onChange}
          value={teletrac.password ? teletrac.password : ''}
          error={(teletrac.password === "")}
        /><br />
        <AdvanceTextField
          label="Tenant ID*"
          name="tenantId"
          onChange={this.onChange}
          value={teletrac.tenantId ? teletrac.tenantId : ''}
          error={(teletrac.tenantId === "")}
        />
        <br />
        <AdvanceTextField
          label="Account ID*"
          onChange={this.onChange}
          name="accountId"
          value={teletrac.accountId ? teletrac.accountId : ''}
          error={(teletrac.accountId === "")}
        />
        <br />
        <Button className="float-right" disabled={true} color={"primary"}>VALIDATE CREDENTIALS</Button>{/* Disabled for as per LDM - 483 */}
      </div>
    );
  }
}