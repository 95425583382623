import {
	React,
	bind,
	_
} from "$Imports/Imports";

import {
	viewType
} from "$State/TenantSummaryFreezerService";

import {
	TenantSummaryViewModel
} from "$Generated/api";

import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle
} from "$Imports/MaterialUIComponents";

interface IDeactivateTenantProps {
	/** The view type that should be loaded */
	view: viewType;

	/** The tenant to deactivate */
	deactivateTenant: TenantSummaryViewModel | null;

	/** Cancel button click handler */
	onCancelClick: () => void;

	/** Deactivate button click handler */
	onDeactivateClick: () => void;
}

export class DeactivateTenantDialog extends React.Component<IDeactivateTenantProps> {

	@bind
	private _onCancelClick() {
		this.props.onCancelClick();
	}

	@bind
	private _onDeactivateClick() {
		this.props.onDeactivateClick();
	}

	render() {
		const { view, deactivateTenant } = this.props;
		const isOpen = view === "Deactivate";
		const primaryColor = "primary";
		return (
			<Dialog open={isOpen}>
				<DialogTitle>Deactivate Tenant</DialogTitle>
				<DialogContent>
					Continuing will deactivate {deactivateTenant?.tenantFriendlyName ?? "the tenant"}.
				</DialogContent>
				<DialogActions>
					<Button color={primaryColor} onClick={this._onCancelClick}>Cancel</Button>
					<Button color={primaryColor} onClick={this._onDeactivateClick}>Deactivate</Button>
				</DialogActions>
			</Dialog>
		);
	}
}