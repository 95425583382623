import {
  React,
  bind
} from "$Imports/Imports";

import {
  Dialog
} from "$Imports/MaterialUIComponents";

// Components
import { EditEmailRecipientsDialogContents } from "./EditLytxEmailRecipientsDialogContents";

// data models
import {
  TenantLytxEmailRecipientService as TenantLytxEmailRecipientService,
  ITenantLytxEmailRecipientsInjectProps,
} from "$State/tenant-views/TenantEmailRecipientsFreezerService";

// state and props
interface IEditLytxEmailRecipientsDialogBaseProps {
  onClose(): void;
  open: boolean;
  tenantId: string;
  submitEmailRecipients(): void;
}

type IEditLytxEmailRecipientsDialogProps = IEditLytxEmailRecipientsDialogBaseProps & ITenantLytxEmailRecipientsInjectProps;

export class _EditLytxEmailRecipients extends React.Component<IEditLytxEmailRecipientsDialogProps> {

  @bind
  private _onCancel(): void {
    this.props.onClose();
  }

  @bind
  private _onSubmit(): void {
    this.props.submitEmailRecipients();
  }

  @bind
  private _onEmailRecipientsChange(e: React.ChangeEvent<HTMLInputElement>) {
    this.props.tenantEditEmailRecipientsService.onEditChange(e.target.value);
  }

  render(): JSX.Element {  
  const title: string = "Edit Email Recipients";
    return (
      <Dialog
        title={title}
        open={this.props.open}
        onClose={this.props.onClose}
        key={"dialog"}
      >
        <EditEmailRecipientsDialogContents
          key={"editEmailRecipients"}
          tenantId={this.props.tenantId}
          onClose={this._onCancel}
          submitEmailRecipients={this._onSubmit}
          onChange={this._onEmailRecipientsChange}
        />
      </Dialog>
    );
  }
}

export const EditLytxEmailRecipients = TenantLytxEmailRecipientService.inject(
  _EditLytxEmailRecipients
);