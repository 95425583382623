import {
  React,
  _,
  bind
} from "$Imports/Imports";
import { Button } from "$Imports/MaterialUIComponents";
// data models
import { AdvanceTextField } from "$Components/Common";
import { ICredentialProps } from "$State/CredentialTypes";
import { OmnitracsAuthLogsCredentials } from "$Generated/api";

interface IOmnitracsAuthLogsBaseProps {
}


type IOmnitracsAuthLogsProps = IOmnitracsAuthLogsBaseProps & ICredentialProps<OmnitracsAuthLogsCredentials>;
export class OmnitracsAuthLogs extends React.PureComponent<IOmnitracsAuthLogsProps> {

  @bind
  handleOnChange(e: any): void {
    if (e.target.name === "username") {
      this.props.onChange(this.props.providerKey, { authUsername: e.target.value });
    }
    else if (e.target.name === "password") {
      this.props.onChange(this.props.providerKey, { authPassword: e.target.value });
    }
    else if (e.target.name === "company") {
      this.props.onChange(this.props.providerKey, { authCompany: e.target.value });
    }
  }

  render(): JSX.Element {
    const omnitracsAuthLogs: OmnitracsAuthLogsCredentials = this.props.data ? this.props.data : {} as OmnitracsAuthLogsCredentials;
    return (
      <div>
        <AdvanceTextField
          label="Username*"
          name="username"
          autoComplete="off"
          value={omnitracsAuthLogs.authUsername}
          onChange={this.handleOnChange}
          error={(omnitracsAuthLogs.authUsername === "")}
        /><br />
        <AdvanceTextField
          name="password"
          autoComplete="new-password"
          type="password"
          label="Password"
          value={omnitracsAuthLogs.authPassword}
          onChange={this.handleOnChange}
          error={(omnitracsAuthLogs.authPassword === "")}
        /><br />
        <AdvanceTextField
          label="Company*"
          name="company"
          value={omnitracsAuthLogs.authCompany}
          onChange={this.handleOnChange}
          error={(omnitracsAuthLogs.authCompany === "")}
        /><br />
        <Button className="float-right" disabled={true} color={"primary"}>VALIDATE CREDENTIALS</Button>
      </div>
    );
  }
}