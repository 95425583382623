import {
  FreezerService,
} from "$Imports/Imports";

const InjectedPropName = "errorService";

interface IErrorFreezerState {
  errorList: string[];
}

class ErrorFreezerService extends FreezerService<IErrorFreezerState, typeof InjectedPropName> {
  constructor() {
    super({
      errorList: [],
    }, InjectedPropName);
  }

  public pushErrorMessage(message: string) {
    this.freezer.get().errorList.unshift(message);
  }

  public hasErrorMessages(): boolean {
    return this.freezer.get().errorList.length !== 0;
  }

  public popLastItem(): string | undefined {
    const lengthOfList = this.freezer.get().errorList.length;
    let message: string | undefined;

    if (lengthOfList !== 0) {
      message = this.freezer.get().errorList[lengthOfList - 1];
      this.freezer.get().errorList.pop();
    }

    return message;
  }
}

export const ErrorService = new ErrorFreezerService();
export type IErrorServiceInjectedProps = ReturnType<ErrorFreezerService["getPropsForInjection"]>;
