import {
  FreezerService,
  IAjaxState,
  managedAjaxUtil,
  _,
  bind
} from "$Imports/Imports";

import {
  IPagerState
} from "./../PagerPagingState";

import {
  JobDetailApiFactory,
  DriveCamSubmissionResultViewModelResponseBase,
  ChildJobViewModel
} from "$Generated/api";

import {
  SitePubSubManager
} from "$Utilities/PubSubUtil";

import {
  ErrorService
} from "$State/ErrorFreezerService";

const InjectedPropName = "tenantViewErrorReport";

const defaultPagerState: IPagerState = {
  page: 0,
  rowsPerPage: 50,
};

interface ITenantViewErrorReportFreezerState {
  submissionResults: IAjaxState<DriveCamSubmissionResultViewModelResponseBase>;
  vehicleSubmissionSearch: string;
  vehicleSubmissionPager: IPagerState;
  jobId: string | null;
  childJobs: ChildJobViewModel[];
  submissionReportJobId: string;
}

class TenantViewErrorReportFreezerService extends FreezerService<ITenantViewErrorReportFreezerState, typeof InjectedPropName>  {
  constructor() {
    super({
      submissionResults: managedAjaxUtil.createInitialState(),
      vehicleSubmissionPager: defaultPagerState,
      vehicleSubmissionSearch: "",
      jobId: null,
      childJobs: [],
      submissionReportJobId: "",
    }, InjectedPropName);

    SitePubSubManager.subscribe("application:login:before", this.clearResults);
    SitePubSubManager.subscribe("job-detail:job-id-change", this.clearResults);
  }

  @bind
  private clearResults() {
    this.freezer.get().set({
      childJobs: [],
      submissionReportJobId: "",
      jobId: null,
      submissionResults: managedAjaxUtil.createInitialState(),
      vehicleSubmissionPager: defaultPagerState,
      vehicleSubmissionSearch: "",
    });
  }

  public setVehicleSubmissionPagerState(pager: IPagerState) {
    this.freezer.get().vehicleSubmissionPager.set(pager);
  }

  public setVehicleSubmissionErrorSearch(search: string) {
    const currentPaging = this.freezer.get().vehicleSubmissionPager.toJS();

    this.freezer.get().set({
      vehicleSubmissionSearch: search,
      vehicleSubmissionPager: {
        page: 0,
        rowsPerPage: currentPaging.rowsPerPage
      }
    });
  }

  public setChildJobId(jobId: string): void {
    this.freezer.get().set({submissionReportJobId: jobId});
  }

  public setJobId(jobId: string, childJobs: ChildJobViewModel[]): void {
    this.freezer.get().set({
      jobId,
      childJobs,
      submissionReportJobId: childJobs.length !== 0 ? childJobs[0].jobId : ""
    });
  }

  public async fetchSubmissionResults(forceUpdate: boolean = false) {
    if (this.freezer.get().submissionResults.hasFetched
      && !forceUpdate) {
      return;
    }

    const jobId = this.freezer.get().submissionReportJobId;

    return managedAjaxUtil.fetchResults({
      ajaxStateProperty: "submissionResults",
      freezer: this.freezer,
      onExecute: (apiOptions, params, options) => {
        const submissionFactory = JobDetailApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
        return submissionFactory.apiV1JobDetailSubmissionResultsGet(params);
      },
      params: {
        jobId: jobId ? jobId : undefined,
      },
      onError: () => {
        ErrorService.pushErrorMessage("Failed to retrieve submission data from the server.");
      }
    });
  }
}


export const TenantViewErrorReportService = new TenantViewErrorReportFreezerService();
export type ITenantViewErrorReportServiceeInjectedProps = ReturnType<TenantViewErrorReportFreezerService["getPropsForInjection"]>;