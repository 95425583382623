import {
  _,
  managedAjaxUtil,
  IAjaxState,
  FreezerService,
  bind,
} from "$Imports/Imports";

import { 
  ConfigurationApiFactory,
  ResponseBase,
  LytxEmailRecipients,
  LytxEmailRecipientsResponseBase
} from "$Generated/api";

import {
  SitePubSubManager
} from "$Utilities/PubSubUtil";

const InjectedPropName = "tenantEditEmailRecipientsService";

interface ITenantLytxEditEmailRecipientsState {
  editEmailRecipients: string;
  tenantId: string;
  dialogIsOpen: boolean;
  validInput: boolean;
  emailRecipients: IAjaxState<LytxEmailRecipients>;
  saveResult: IAjaxState<LytxEmailRecipients>;
}

class TenantLytxEmailRecipientsFreezerService extends FreezerService<ITenantLytxEditEmailRecipientsState, typeof InjectedPropName>{

  constructor() {
    super({
      editEmailRecipients: "",
      tenantId: "",
      dialogIsOpen: false,
      validInput: true,
      emailRecipients: managedAjaxUtil.createInitialState(),
      saveResult: managedAjaxUtil.createInitialState()
    }, InjectedPropName);

    SitePubSubManager.subscribe("application:login:before", this.resetFreezer);
  }

  @bind
  public closeDialog(): void {
    this.freezer.get().set({dialogIsOpen:false});
  }

  private async fetchEmailRecipients(tenantId: string): Promise<void> {
    await managedAjaxUtil.fetchResults({
      freezer: this.freezer,
      ajaxStateProperty: "emailRecipients",
      onExecute: (apiOptions, params, options) => {
        const configurationApiFactory = ConfigurationApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
        return configurationApiFactory.apiV1EmailRecipientsLytxTenantIdGet({ tenantId: params.tenantId });
      },
      onOk: (data: LytxEmailRecipientsResponseBase) => {
        if (data.success && data.data) {
          this.freezer.get().set({ editEmailRecipients: data.data.recipients?.join("\n") });
        }
      },
      params: {
        tenantId: tenantId
      }
    });
  }

  @bind
  public async saveEmailRecipients() {
    if (this.validateEmails()) {
      return managedAjaxUtil.fetchResults({
        freezer: this.freezer,
        ajaxStateProperty: "saveResult",
        onExecute: (apiOptions, params, options) => {
          const emailRecipients: LytxEmailRecipients = {
            recipients: this.getEmails()
          };
          const configurationApiFactory = ConfigurationApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
          return configurationApiFactory.apiV1EmailRecipientsLytxSavePost({ tenantId: params.tenantId, body: emailRecipients });
        },
        onOk: (data: ResponseBase) => {
          if (data.success) {
            this.resetFreezer();
          }
        },
        params: {
          tenantId: this.freezer.get().tenantId
        }
      });
    } else {
      this.freezer.get().set({ validInput: false });
      if (this.freezer.get().editEmailRecipients === "") {
        this.closeDialog()
      }
    }
  }

  private validateEmails(): boolean {
    var isValid = true;
    const emails = this.getEmails();
    if (emails.length > 0) {
      const emailRegex = RegExp(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/);
      isValid = emails.every((e) => emailRegex.test(e));
    }
    return isValid;
  }

  public setTenantId(tenantId: string) {
    this.freezer.get().set({ tenantId: tenantId });

    this.fetchEmailRecipients(tenantId);
  }

  public getTenantId(): string {
    return this.freezer.get().tenantId;
  }

  public getEmailsAsString(): string {
    return this.freezer.get().editEmailRecipients;
  }

  public getEmails(): string[] {
    var emails = this.freezer.get().editEmailRecipients.split("\n").map((e) => e.trim());
    if (emails.length === 1 && emails[0] === "") {
      emails = [];
    }
    return emails;
  }

  public onEditChange(newValue: string) {
    this.freezer.get().set({ editEmailRecipients: newValue });
  }


  @bind
  public resetFreezer() {
    this.freezer.get().set(
      {
        dialogIsOpen : false,
        editEmailRecipients : "",
        tenantId: "",
        validInput: true,
        saveResult: managedAjaxUtil.createInitialState(),
        emailRecipients: managedAjaxUtil.createInitialState()
      }
    );
  }
}

export const TenantLytxEmailRecipientService = new TenantLytxEmailRecipientsFreezerService();
export type ITenantLytxEmailRecipientsInjectProps = ReturnType<TenantLytxEmailRecipientsFreezerService["getPropsForInjection"]>;