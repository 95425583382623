import {
  React,
  _,
  bind
} from "$Imports/Imports";
import { Button } from "$Imports/MaterialUIComponents";
import { AdvanceTextField } from "$Components/Common";
import { DigitalFleetCredentials } from "$Generated/api";
import { ICredentialProps } from "$State/CredentialTypes";
import { display } from "html2canvas/dist/types/css/property-descriptors/display";

interface IDigitalFleetBaseProps {
}

type IDigitalFleetProps = IDigitalFleetBaseProps & ICredentialProps<DigitalFleetCredentials>;

export class DigitalFleet extends React.PureComponent<IDigitalFleetProps>{


  @bind
  onChange(e: any): void {
    if (e.target.name === "apiKey") {
      this.props.onChange(this.props.providerKey, { apiKey: e.target.value });
    }
    else if (e.target.name === "pin") {
      this.props.onChange(this.props.providerKey, { driverIdKey: e.target.value });
    }
  }

  render(): JSX.Element {
    const digitalFleet: DigitalFleetCredentials = this.props.data ? this.props.data : {} as DigitalFleetCredentials;
    return (
      <div>
        <AdvanceTextField
          label="API Key*"
          name="apiKey"
          onChange={this.onChange}
          value={digitalFleet.apiKey ? digitalFleet.apiKey : ''}
          error={(digitalFleet.apiKey === "")}
          placeholder="12345678-1234-1234-1234-1234567890123"
        /><br />
        <AdvanceTextField
          label="Driver Id Key"
          name="driverIdKey"
          value={digitalFleet.driverIdKey ? digitalFleet.driverIdKey : 'Pin'}
          disabled
          style={{display: "none"}}
        /><br />
        <Button className="float-right" disabled={true} color={"primary"}>VALIDATE CREDENTIALS</Button>{/* Disabled for as per LDM - 481 */}
      </div>
    );
  }
}
