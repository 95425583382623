import { bind, React, _, moment, html2pdf } from "$Imports/Imports";
import { Divider, Paper, Snackbar, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Grid } from "@material-ui/core";
import { AjaxActionIndicator, PageHeader } from "$Components/Common";
import { ILytxGenericReportServiceInjectedProps, LytxGenericReportService, IReportSearchFilter } from "$State/LytxGenericReportFreezerService";
import { ReportFilter } from "./ReportFilter";
import { DaySummary} from "$Generated/api";
import { TimeZoneService } from "$State/TimeZoneFreezerService";
import { Button } from "$Imports/MaterialUIComponents";

const styles: {
  paperStyle: string;
  searchPaperStyle: string;
  vehicleHeader: string;
  downloadButton: string;
  reportGrid: string;
  reportTable: string;
} = require("./LytxGenericReport.scss");

interface ILytxGenericReportBaseProps { }

interface ILytxGenericReportState { }

type ILytxGenericReportProps = ILytxGenericReportBaseProps & ILytxGenericReportServiceInjectedProps;

class LytxGenericReportHTML extends React.Component<ILytxGenericReportProps, ILytxGenericReportState> {
  componentWillMount() {
    this.props.reportService.fetchGroups();
    this.props.reportService.fetchVehicles();
    this.props.reportService.fetchDataDateRange();
  }

  @bind
  private _onRunSearch() {
    this.props.reportService.fetchDataResult(true);
  }

  @bind
  private _onFilterChange(filter: IReportSearchFilter, reload: boolean = true) {
    this.props.reportService.setReportFilter(filter, reload);
  }

  @bind
  private async _onPdfReportClick() {

    const now = moment().local();
    const reportTitle = 'Report-' + now.format('YYYY-DD-MM-HHmmss');
    const pdfOptions = {
      filename: reportTitle,
      margin: [8, 48],
      image: { type: 'jpeg', quality: 1 },
      jsPDF: {
        orientation: 'l',
        unit: 'pt',
        putOnlyUsedFonts: true,
        compress: true
      },
      pagebreak: {
        mode: 'css',
        before: '.theme-printPdfReport .MuiGrid-grid-xs-6:nth-child(4n+4)'
      },
      html2canvas: {
        scale: 2
      }
    };
    const htmlElement = document.getElementById('PrintDriverReportCard');
    htmlElement?.classList.add('theme-printPdfReport');

    await html2pdf(htmlElement, pdfOptions);

    htmlElement?.classList.remove('theme-printPdfReport');
  }

  private makeMoment(input: Date | string | undefined, useTz : boolean): moment.Moment | undefined {
    if (input === undefined) {
      return undefined;
    }
    else if (input === null) {
      return undefined;
    }
    if (useTz) {
      const zone = TimeZoneService.getTimeZone();
      return moment.parseZone(input).tz(zone);
    }
    else {
      return moment.parseZone(input);
    }
  }

  private makeDateDisplay(date: moment.Moment | undefined): string {
    if (date === undefined) {
      return "";
    }
    return date.format("M/D");
  }

  private makeTimeDisplay(date: moment.Moment | undefined): string {
    if (date === undefined) {
      return "";
    }
    return date.format("h:mm A");
  }

  render(): JSX.Element {
    const {
      errorState,
      hasFetched,
      reportSearchFilter,
      groupsResult,
      vehiclesResult,
      dataResult,
      dataDateRangeResult
    } = this.props.reportService.getState();

    const groupsData = groupsResult.data && groupsResult.data.data ? groupsResult.data.data : [];
    const vehicleData = vehiclesResult.data && vehiclesResult.data.data ? vehiclesResult.data.data : [];
    const dateRangeData = dataDateRangeResult.data ? dataDateRangeResult.data.data : undefined;

    return (
      <div id="DriverReportCard">
        <PageHeader title="Driver Report Card" />
        <Paper className={styles.paperStyle}>
          <AjaxActionIndicator state={dataResult} />
          <div className={styles.searchPaperStyle}>
            <ReportFilter
              onRunSearch={this._onRunSearch}
              searchFilter={reportSearchFilter}
              onFilterChanged={this._onFilterChange}
              groupData={groupsData}
              vehicleData={vehicleData}
              errorState={errorState}
              minDate={this.makeMoment(dateRangeData?.item1, false)}
              maxDate={this.makeMoment(dateRangeData?.item2, false)}
            />
          </div>
          {dataResult.data?.success && dataResult.data?.data !== undefined ? (
              <React.Fragment>
                <div className={styles.downloadButton}>
                <Button
                    size="small"
                    variant="contained"
                    color="primary" onClick={this._onPdfReportClick}>
                    Download PDF
                  </Button>
                </div>
                <Divider />
                <Grid id="PrintDriverReportCard" container={true} xs={12} spacing={1} className={styles.reportGrid}>
                  {_.map(dataResult.data?.data, (value: DaySummary[], key: string) => (
                    <Grid item={true} xs={6}>
                      <h6 className={styles.vehicleHeader}>Vehicle: {key}</h6>
                      <TableContainer>
                        <Table className={styles.reportTable}>
                          <TableHead>
                            <TableRow>
                              <TableCell/>
                              {
                                _.map(value, (daySummary: DaySummary) => (
                                  <TableCell>
                                    <b>{!daySummary.isTotal ? this.makeDateDisplay(this.makeMoment(daySummary.date, true)) : "Total"}</b>
                                  </TableCell>
                                ))
                              }
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            <TableRow>
                              <TableCell>
                                <b>Hours on Job</b>
                              </TableCell>
                              {
                                _.map(value, (daySummary: DaySummary) => (
                                  <TableCell>
                                    {daySummary.hoursOnJob}
                                  </TableCell>
                                ))
                              }
                            </TableRow>
                            <TableRow>
                              <TableCell>
                                <b>Office Hours</b>
                              </TableCell>
                              {
                                _.map(value, (daySummary: DaySummary) => (
                                  <TableCell>
                                    {daySummary.officeHours}
                                  </TableCell>
                                ))
                              }
                            </TableRow>
                            <TableRow>
                              <TableCell>
                                <b>Drive Time</b>
                              </TableCell>
                              {
                                _.map(value, (daySummary: DaySummary) => (
                                  <TableCell>
                                    {daySummary.driveTime}
                                  </TableCell>
                                ))
                              }
                            </TableRow>
                            <TableRow>
                              <TableCell>
                                <b>Idle Time</b>
                              </TableCell>
                              {
                                _.map(value, (daySummary: DaySummary) => (
                                  <TableCell>
                                    {daySummary.idleTime}
                                  </TableCell>
                                ))
                              }
                            </TableRow>
                            <TableRow>
                              <TableCell>
                                <b>Parked Time</b>
                              </TableCell>
                              {
                                _.map(value, (daySummary: DaySummary) => (
                                  <TableCell>
                                    {daySummary.parkedTime}
                                  </TableCell>
                                ))
                              }
                            </TableRow>
                            <TableRow>
                              <TableCell>
                                <b>Total Mileage</b>
                              </TableCell>
                              {
                                _.map(value, (daySummary: DaySummary) => (
                                  <TableCell>
                                    {daySummary.totalMileage}
                                  </TableCell>
                                ))
                              }
                            </TableRow>
                            <TableRow>
                              <TableCell>
                                <b>Home Departure</b>
                              </TableCell>
                              {
                                _.map(value, (daySummary: DaySummary) => (
                                  <TableCell>
                                    {this.makeTimeDisplay(this.makeMoment(daySummary.homeDeparture, true))}
                                  </TableCell>
                                ))
                              }
                            </TableRow>
                            <TableRow>
                              <TableCell>
                                <b>Job Arrival</b>
                              </TableCell>
                              {
                                _.map(value, (daySummary: DaySummary) => (
                                  <TableCell>
                                    {this.makeTimeDisplay(this.makeMoment(daySummary.jobArrival, true))}
                                  </TableCell>
                                ))
                              }
                            </TableRow>
                            <TableRow>
                              <TableCell>
                                <b>Job Departure</b>
                              </TableCell>
                              {
                                _.map(value, (daySummary: DaySummary) => (
                                  <TableCell>
                                    {this.makeTimeDisplay(this.makeMoment(daySummary.jobDeparture, true))
                                    }</TableCell>
                                ))
                              }
                            </TableRow>
                            <TableRow>
                              <TableCell>
                                <b>Home Arrival</b>
                              </TableCell>
                              {
                                _.map(value, (daySummary: DaySummary) => (
                                  <TableCell>
                                    {this.makeTimeDisplay(this.makeMoment(daySummary.homeArrival, true))}
                                  </TableCell>
                                ))
                              }
                            </TableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Grid>
                  ))}
                </Grid>
              </React.Fragment>
            ) : null
          }
          <Snackbar
            open={hasFetched && !dataResult.data?.success}
            message={"Error loading data from server"}
            autoHideDuration={5000}
            anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
          />
        </Paper>
      </div>
    );
  }
}

export const LytxGenericReport = LytxGenericReportService.inject(LytxGenericReportHTML);