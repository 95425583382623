import { DelinquentTenantViewModel } from "$Generated/api";
import {
    _,
    bind,
    React,
  } from "$Imports/Imports";

import { Link } from "$Imports/MaterialUIComponents";

interface ITenantJobsLinkProps {
    tenant: DelinquentTenantViewModel;
    onClick: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>, d: DelinquentTenantViewModel) => void;
}

export class TenantJobsLink extends React.PureComponent<ITenantJobsLinkProps> {

    @bind
    private onClick(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>)
    {
        event.preventDefault();
        this.props.onClick(event, this.props.tenant);
    }
  
    render() {
  
        let link = this.props.tenant.tenantName ?? "";
  
        return <Link
            href=""
            onClick={this.onClick}
            onAuxClick={this.onClick}
        >
            {link}
        </Link>
    }
  }
