import {
  React,
  _,
  bind,
  numeral,
  moment,
  memoizeOne
} from "$Imports/Imports";

import {
  SearchBox,
  DateFormatter,
  timeZoneType,
  DataTablePager
} from "$Components/Common";

import {
  TenantViewFaultsService
} from "$State/job-views/JobDetailFaultsFreezerService";

import {
  DrivingFaultEvent
} from "$Generated/api";

import {
  TimeZoneService,
} from "$State/TimeZoneFreezerService";

import {
  IconButton,
  Divider
} from "$Imports/MaterialUIComponents";

import {
  ArrowDownward,
} from "$Imports/MaterialUIIcons";

import { IPagerState } from "$State/PagerPagingState";

import {
  FaultsGrid
} from "./FaultsGrid";

const styles: {
  searchHeaderContainer: string;
  rightItemsContainer: string;
} = require("./DetailFaults.scss");

interface IJobDetailFaultsBaseProps {
  data: DrivingFaultEvent[];
  search: string;
  pager: IPagerState;
}

type IJobDetailFaultsProps = IJobDetailFaultsBaseProps;

export class JobDetailFaults extends React.PureComponent<IJobDetailFaultsProps> {
  private _onSearch(data: DrivingFaultEvent[], search: string): DrivingFaultEvent[] {
    if (search.trim() === "") {
      return data;
    }

    return _.filter(data, (f) => {
      const vinResults = (f.vin ? f.vin.toLowerCase() : "").indexOf(search.toLowerCase()) !== -1;
      if (vinResults) {
        return true;
      }

      const faultCodeResults = (f.faultCode ? f.faultCode.toLowerCase() : "").indexOf(search.toLowerCase()) !== -1;
      if (faultCodeResults) {
        return true;
      }

      const faultFailureModeResults = (f.failureMode ? f.failureMode.toLowerCase() : "").indexOf(search.toLowerCase()) !== -1;
      if (faultFailureModeResults) {
        return true;
      }

      const faultSourceResults = (f.faultCode ? f.faultCode.toLowerCase() : "").indexOf(search.toLowerCase()) !== -1;
      if (faultSourceResults) {
        return true;
      }

      return false;
    });
  }

  @bind
  private _onPagerStateChange(pagerState: IPagerState) {
    TenantViewFaultsService.setPagerState(pagerState);
  }

  @bind
  private _onPagerStateChangePager(page: number, rowsPerPage: number) {
    this._onPagerStateChange({
      page,
      rowsPerPage,
    });
  }

  @bind
  private _onClearSearch() {
    TenantViewFaultsService.setSearch("");
  }

  @bind
  private _onDebounceChange(newValue: string) {
    TenantViewFaultsService.setSearch(newValue);
  }

  private readonly _memoize_onSearch = memoizeOne(this._onSearch);

  @bind
  private saveCSV() {
    const { data, search } = this.props;
    const filterData = this._onSearch(data, search);
    const timeZone: timeZoneType = TimeZoneService.getTimeZone() || "UTC";
    const isMilitaryTime: boolean = TimeZoneService.getisMilitaryTime() || false;

    let csvData = "VIN, Date Time, Fault Code, Fault Failure Mode, Occurrence Count\n";
    filterData.forEach((row) => {
      csvData +=
        row.vin + "," +
        (row.eventDateTime ? DateFormatter.formatDateString(row.eventDateTime, timeZone, "", isMilitaryTime) : "") + "," +
        row.faultCode + "," +
        row.failureMode + "," +
        row.occuranceCount + "\n";
    });

    const saveDocument = document.createElement('a');
    saveDocument.href = 'data:text/csv;charset=utf-8,' + encodeURI(csvData);
    saveDocument.target = '_blank';
    saveDocument.download = 'jobDetailFaults.csv';
    saveDocument.click();
  }

  @bind
  private _onChangePage() {
    // Do nothing.  Handled by the component.  Remove once typescript has been updated.
  }

  render() {
    const { data, search, pager } = this.props;

    const filterData = this._memoize_onSearch(data, search);

    return (
      <div>
        <div className={styles.searchHeaderContainer}>
          <div>
            <SearchBox
              value={search}
              onDebouncedChange={this._onDebounceChange}
              filterStatusLabel={`${numeral(filterData.length).format("0,000")} ${filterData.length === 1 ? "Fault" : "Faults"}`}
              onClearClick={this._onClearSearch}
            />
          </div>
          <div className={styles.rightItemsContainer}>
            <div>
              <IconButton
                color="primary"
                title="Download"
                onClick={this.saveCSV}
              >
                <ArrowDownward />
              </IconButton>
            </div>
            <div>
              <DataTablePager
                onChangePage={this._onChangePage}
                count={data.length}
                page={pager.page}
                rowsPerPage={pager.rowsPerPage}
                onPagerStateChange={this._onPagerStateChangePager}
              />
            </div>
          </div>
        </div>
        <Divider />
        <div>
          <FaultsGrid
            data={filterData}
            pagerState={pager}
            onPagerStateChange={this._onPagerStateChange}
          />
        </div>
      </div>
    );
  }
}
