import {
  $
} from "$Imports/Imports";

import { Application } from "./root-components/Application";
import { SPA } from "@yahara/react-spa";
import { SitePubSubManager } from "$Utilities/PubSubUtil";

const baseUrl = `${$("base").attr("href")}`;

// managedAjaxUtil.setBaseUrl(baseUrl);


const googleFont = document.createElement("link");
googleFont.href = "https://fonts.googleapis.com/icon?family=Material+Icons";
googleFont.type = "stylesheet";
document.head.appendChild(googleFont);

import { APPCONFIG } from "./constants/Config";

APPCONFIG.baseUrl = baseUrl === "/" ? "" : baseUrl;

declare var __webpack_public_path__: string;
__webpack_public_path__ = baseUrl;



/*
    In order to allow Windows Authentication, fetch needs to be wrapped with a version
    that's configured to pass through credentials.  This sets a flag so that it doesn't
    wrap fetch every time the hot reloader reloads the module.
*/
const WRAP_FETCH_FLAG: string = "__fetch_is_wrapped_for_windows_auth__";
if (!(WRAP_FETCH_FLAG in window)) {
  let oldFetch: (input: RequestInfo, init?: RequestInit) => Promise<Response> = window.fetch;

  // for Edge, bind the old fetch to the window object to avoid "Invalid Calling Object"
  // errors.
  oldFetch = oldFetch.bind(window);

  APPCONFIG.fetch = (input: RequestInfo, init?: RequestInit): Promise<Response> => {
    const config: RequestInit = init || {};
    config.headers = config.headers || {};
    config.credentials = "include";
    // Send a message on fetch
    SitePubSubManager.publish("application:fetch", "");
    return oldFetch(input, config);
  };
  (window as any)[WRAP_FETCH_FLAG] = true;

  window.fetch = APPCONFIG.fetch;
}

const el: HTMLElement | null = document.getElementById("reactApp");
const app: SPA = (el == null) ? new SPA(baseUrl) : new SPA(baseUrl, el);

app.setRootComponent(Application).render();

// when no module name is provided to module.hot.accept(), it assumes the current
// module:
//   https://github.com/webpack/webpack/blob/7871b6b3dc32425406b3938be490a87aeb6fa794/lib/HotModuleReplacement.runtime.js#L89-L99
declare var module: any;

// tslint:disable-next-line: no-unused-expression
module.hot && module.hot.accept();