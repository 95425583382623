import { 
  ISecurityContext,
} from '$Providers/index';

import {
  AssociatedEntityViewModel
} from "$Generated/api";
 
import {
 React,
 _,
 memoizeOne,
} from "$Imports/Imports";

import {
 ITenantViewProps
} from "./TenantViewProps";

import {
  VehicleAssignmentView
} from './vehicle-assignment-view/VehicleAssignmentView';

import {
  SubmissionErrorReportView
} from "./error-report-view/SubmissionErrorReportView";

import {
  AdminMessageResubmissionView
} from "./admin-message-resubmission/AdminMessageResubmissionView";

import {
  JobDetailReadingsView
} from "./reading-view/JobDetailReadingsView";

import {
  JobDetailFaultsView
} from "./fault-view/JobDetailFaultsView";

import {
  SamsaraRairErrorReportView
} from "./samsara-rair-error-report-view/SamsaraRairErrorReportView"

import {
  SamsaraHosErrorReportView
} from "./samsara-hos-error-report-view/SamsaraHosErrorReportView"

import {
  SmartDriveErrorReportView
} from "./smartdrive-error-report-view/SmartdriveErrorReportView"

import {
  LytxAdminErrorReportView
} from "./lytx-admin-error-report-view/LytxAdminErrorReportView"

import {
  LytxIndividualPacketView
} from "./lytx-individual-packet-review/LytxIndividualPacketView"

export interface ITenantViewItem {
  entityId: number;
  displayView: boolean | ((
    securityContext: ISecurityContext,
    tenantViewItem: ITenantViewItem,
    associatedEntities: AssociatedEntityViewModel[]) => boolean);
  label: string;
  component: React.ComponentType<ITenantViewProps>;
  order: number;
}

function filterViews(
  views: ITenantViewItem[],
  securityContext: ISecurityContext,
  associatedEntities?: AssociatedEntityViewModel[] | null): ITenantViewItem[] {
  const entityData = associatedEntities || [];
  return _.filter(views, (ed) => typeof ed.displayView === "function" ? ed.displayView(securityContext, ed, entityData) : ed.displayView) || [];
}

const filterViews_memoize = memoizeOne(filterViews);

function IsTenantViewAvailable(
  tenantView: ITenantViewItem,
  associatedEntities: AssociatedEntityViewModel[]): boolean {
  return _.some(associatedEntities, (ae) => ae.categoryItem?.id === tenantView.entityId);
}

export const tenantViews: ITenantViewItem[] = [
  {
    entityId: 62,
    displayView: (sctx, tvi, ae) => {
      return IsTenantViewAvailable(tvi, ae);
    },
    label: "Item Submission Errors",
    component: SubmissionErrorReportView,
    order: 4,
  },
  {
    entityId: 87,
    displayView: (sctx, tvi, ae) => {
      return IsTenantViewAvailable(tvi, ae);
    },
    label: "Submission Errors",
    component: SamsaraRairErrorReportView,
    order: 4,
  },
  {
    entityId: 88,
    displayView: (sctx, tvi, ae) => {
      return IsTenantViewAvailable(tvi, ae);
    },
    label: "Submission Errors",
    component: SamsaraHosErrorReportView,
    order: 4,
  },
  {
    entityId: 89,
    displayView: (sctx, tvi, ae) => {
      return IsTenantViewAvailable(tvi, ae);
    },
    label: "Submission Errors",
    component: SmartDriveErrorReportView,
    order: 4,
  },
  {
    entityId: 90,
    displayView: (sctx, tvi, ae) => {
      return IsTenantViewAvailable(tvi, ae);
    },
    label: "Admin Errors",
    component: LytxAdminErrorReportView,
    order: 4,
  },
  {
    entityId: 63,
    displayView: (sctx, tvi, ae) => {
      return IsTenantViewAvailable(tvi, ae);
    },
    label: "Vehicle Assignment",
    component: VehicleAssignmentView,
    order: 3,
  },
  {
    entityId: 67,
    displayView: (sctx, tvi, ae) => {
      return IsTenantViewAvailable(tvi, ae);
    },
    label: "Vehicle Readings",
    component: JobDetailReadingsView,
    order: 4,
  },
  {
    entityId: 68,
    displayView: (sctx, tvi, ae) => {
      return IsTenantViewAvailable(tvi, ae);
    },
    label: "Vehicle Fault",
    component: JobDetailFaultsView,
    order: 5,
  },
  {
    entityId: 91,
    displayView: (sctx, tvi, ae) => {
      return IsTenantViewAvailable(tvi, ae);
    },
    label: "Individual Review",
    component: LytxIndividualPacketView,
    order: 7,
  },
  {
    entityId: -1,
    displayView: (sctx, tvi, ae) => {
      return sctx.IsAdminUser;
    },
    label: "Admin Message Resubmission",
    component: AdminMessageResubmissionView,
    order: 6,
  }
];

export {
  VehicleAssignmentView,
  SubmissionErrorReportView,
  AdminMessageResubmissionView,
  JobDetailReadingsView,
  JobDetailFaultsView,
  SamsaraRairErrorReportView,
  SamsaraHosErrorReportView,
  filterViews_memoize as filterViews,
};
