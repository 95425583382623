import {
  React,
  _,
  bind
} from "$Imports/Imports";
import { Button } from "$Imports/MaterialUIComponents";

import { AdvanceTextField } from "$Components/Common";
import { ICredentialProps } from "$State/CredentialTypes";
import { RevealCredentials } from "$Generated/api";

interface IRevealBaseProps { }

type IRevealProps = IRevealBaseProps & ICredentialProps<RevealCredentials>;

export class Reveal extends React.PureComponent<IRevealProps> {
  @bind
  onChange(e: any) {
    if (e.target.name === "appId") {
      this.props.onChange(this.props.providerKey, { appId: e.target.value });
    }
    else if (e.target.name === "username") {
      this.props.onChange(this.props.providerKey, { username: e.target.value });
    }
    else if (e.target.name === "password") {
      this.props.onChange(this.props.providerKey, { password: e.target.value });
    }
    else if (e.target.name === "url") {
      this.props.onChange(this.props.providerKey, { url: e.target.value });
    }
  }

  render(): JSX.Element {
    const reveal: RevealCredentials = this.props.data ? this.props.data : {} as RevealCredentials;
    return (
      <div>
        <AdvanceTextField
          label="App Id*"
          name="appId"
          onChange={this.onChange}
          value={reveal.appId ? reveal.appId : ""}
          error={(reveal.appId === "")}
        />
        <br />
        <AdvanceTextField
          label="User Name*"
          name="username"
          onChange={this.onChange}
          value={reveal.username ? reveal.username : ""}
          error={(reveal.username === "")}
        />
        <br />
        <AdvanceTextField
          label="Password*"
          name="password"
          type="password"
          onChange={this.onChange}
          value={reveal.password ? reveal.password : ""}
          error={(reveal.password === "")}
        />
        <br />
        <AdvanceTextField
          label="Url*"
          name="url"
          onChange={this.onChange}
          value={reveal.url ? reveal.url : "https://fim.api.us.fleetmatics.com/"}
          error={(reveal.url === "")}
        />
        <br />
        <Button className="float-right" disabled={true} color={"primary"}>VALIDATE CREDENTIALS</Button>{/* Disabled for as per LDM - 483 */}
      </div>
    );
  }
}