import {
  React,
  _,
  bind
} from "$Imports/Imports";

import { Button } from "$Imports/MaterialUIComponents";

// data models
import { AdvanceTextField } from "$Components/Common";
import { ICredentialProps } from "$State/CredentialTypes";
import { OmnitracsXRSCredentials } from "$Generated/api";
import { ValidateCredentialsResults, validateCredentials } from "$State/ValidateCredentials";
interface IOmnitracsXRSBaseProps {
}

type OmnitracsXRSCredentialsValidation = OmnitracsXRSCredentials & ValidateCredentialsResults;

type IOmnitracsXRSProps = IOmnitracsXRSBaseProps & ICredentialProps<OmnitracsXRSCredentialsValidation>;

export class OmnitracsXRS extends React.PureComponent<IOmnitracsXRSProps> {

  @bind
  handleOnChange(e: any): void {
    if (e.target.name === "username") {
      this.props.onChange(this.props.providerKey, { username: e.target.value });
    }
    else if (e.target.name === "password") {
      this.props.onChange(this.props.providerKey, { password: e.target.value });
    }
    else if (e.target.name === "companyId") {
      this.props.onChange(this.props.providerKey, { companyId: e.target.value });
    }
    else if (e.target.name === "url") {
      this.props.onChange(this.props.providerKey, { url: e.target.value });
    }
  }

  @bind
  async validate (e:any) {
    this.props.onChange(this.props.providerKey, { running: true });
    var promiseResults = validateCredentials(this.props.data, this.props.providerKey);
    var results = await promiseResults;
    this.props.onChange(this.props.providerKey, { success: results.success ? 'true' : 'false' });
    this.props.onChange(this.props.providerKey, { errorResult: results.errorMessage });
    this.props.onChange(this.props.providerKey, { running: false });
  }

  render(): JSX.Element {
    const omnitracsXRS: OmnitracsXRSCredentials = this.props.data ? this.props.data : {} as OmnitracsXRSCredentials;
    return (
      <div>
        <AdvanceTextField
          label="URL*"
          name="url"
          onChange={this.handleOnChange}
          value={omnitracsXRS.url ? omnitracsXRS.url : ''}
          error={(omnitracsXRS.url === "")}
        /><br />
        <AdvanceTextField
          label="Username*"
          name="username"
          autoComplete="off"
          value={omnitracsXRS.username}
          onChange={this.handleOnChange}
          error={(omnitracsXRS.username === "")}
        /><br />
        <AdvanceTextField
          name="password"
          autoComplete="new-password"
          type="password"
          label="Password*"
          value={omnitracsXRS.password}
          onChange={this.handleOnChange}
          error={(omnitracsXRS.password === "")}
        /><br />
        <AdvanceTextField
          label="Company Id*"
          name="companyId"
          value={omnitracsXRS.companyId}
          onChange={this.handleOnChange}
          error={(omnitracsXRS.companyId === "")}
        /><br />
        <Button disabled={this.props?.data?.running} onClick={this.validate} color={"primary"}>VALIDATE CREDENTIALS</Button>
        {this.props?.data?.success == 'none' || this.props?.data?.success === undefined ?
            <div style={{ display: "block", marginLeft: "auto", marginRight: "auto", textAlign: "center" }}>Can fail due to timeout if there are a lot of depots.</div>
            :
            this.props?.data?.success == 'false' ?
              <div style={{ color: "red", display: "block", marginLeft: "auto", marginRight: "auto" }}>{this.props?.data?.errorResult}</div>
              :
              <div style={{ color: "green", display: "block", marginLeft: "auto", marginRight: "auto", textAlign: "center" }}>Passed</div>}
      </div>
    );
  }
}