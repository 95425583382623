import {
  React,
  _,
  bind
} from "$Imports/Imports";
import { Button } from "$Imports/MaterialUIComponents";
import { AdvanceTextField } from "$Components/Common";
import { SamsaraCredentials } from "$Generated/api";
import { ICredentialProps } from "$State/CredentialTypes";

interface ISamsaraBaseProps {
}

type ISamsaraProps = ISamsaraBaseProps & ICredentialProps<SamsaraCredentials>;

export class Samsara extends React.PureComponent<ISamsaraProps>{


  @bind
  onChange(e: any): void {
    if (e.target.name === "url") {
      this.props.onChange(this.props.providerKey, { url: e.target.value });
    }
    else if (e.target.name === "accessToken") {
      this.props.onChange(this.props.providerKey, { accessToken: e.target.value });
    }
  }

  render(): JSX.Element {
    const samsara: SamsaraCredentials = this.props.data ? this.props.data : {} as SamsaraCredentials;
    return (
      <div>
        <AdvanceTextField
          label="URL*"
          name="url"
          onChange={this.onChange}
          value={samsara.url ? samsara.url : ''}
          error={(samsara.url === "")}

        /><br />
        <AdvanceTextField
          label="Access Token*"
          name="accessToken"
          onChange={this.onChange}
          value={samsara.accessToken ? samsara.accessToken : ''}
          error={(samsara.accessToken === "")}
        /><br />
        <Button className="float-right" disabled={true} color={"primary"}>VALIDATE CREDENTIALS</Button>{/* Disabled for as per LDM - 481 */}
      </div>
    );
  }
}
