import {
  React,
  _,
  bind
} from "$Imports/Imports";

import {
  Button,
  FormControlLabel,
  Checkbox
} from "$Imports/MaterialUIComponents";

import {
  AdvanceTextField
} from "$Components/Common";

import {
  BigRoadCredentials,
} from "$Generated/api";

import {
  ICredentialProps
} from "$State/CredentialTypes";

interface IBigRoadBaseProps {
}

type IBigRoadProps = IBigRoadBaseProps & ICredentialProps<BigRoadCredentials>;

export class BigRoad extends React.PureComponent<IBigRoadProps> {

  @bind
  private _onChange(newValue: string) {
    this.props.onChange(this.props.providerKey, { apiKey: newValue });
  }

  @bind
  private _onCheck(e: React.ChangeEvent<HTMLInputElement>, checked: boolean): void {
    this.props.onChange(this.props.providerKey, { useTruckId: checked });
  }

  render(): JSX.Element {
    const bigRoad: BigRoadCredentials = this.props.data ? this.props.data : {} as BigRoadCredentials;

    return (
      <div>
        <AdvanceTextField
          label="Api Key*"
          name="apiKey"
          autoComplete="off"
          value={bigRoad.apiKey}
          onDebouncedChange={this._onChange}
          error={(bigRoad.apiKey === "")}
        /><br />
        <FormControlLabel
          control={
            (
              <Checkbox
                name="password"
                checked={bigRoad.useTruckId}
                onChange={this._onCheck}
              />
            )
          }
          label="Use Truck Id"
        />
        <br />
        <Button className="float-right" disabled={true} color={"primary"}>VALIDATE CREDENTIALS</Button>
      </div>
    );
  }
}