import {
  React,
  bind
} from "$Imports/Imports";

import {
  AdminMessageResubmission
} from "./AdminMessageResubmission";

import {
  ITenantViewAdminMessageResubmissionServiceInjectedProps,
  TenantViewAdminMessageResubmissionService
} from "$State/job-views/AdminMessageResubmissionFreezerService";

import {
  ITenantViewProps
} from "./../TenantViewProps";
import { AjaxActionIndicator } from "$Components/Common";

interface IAdminMessageResubmissionViewBaseProps {

}

type IAdminMessageResubmissionViewProps = IAdminMessageResubmissionViewBaseProps
& ITenantViewAdminMessageResubmissionServiceInjectedProps
& ITenantViewProps;

class _AdminMessageResubmissionView extends React.Component<IAdminMessageResubmissionViewProps> {

  componentDidMount() {
    if (this.props.job !== null && this.props.job.id !== undefined) {
      this.props.tenantViewAdminMessageResubmissionService.setJobDetail(this.props.job);
    }
  }

  @bind
  private _resubmitMessage(messageId: string) {
    this.props.tenantViewAdminMessageResubmissionService.resubmitMessage(messageId);
  }

  render() {
    const {
      jobDetail,
      messageResubmissionResults
    } = this.props.tenantViewAdminMessageResubmissionService.getState();


    return (
      <>
        <AjaxActionIndicator
          state={[messageResubmissionResults]}
        />
        <AdminMessageResubmission
          resubmit={this._resubmitMessage}
          data={jobDetail || {}}
        />
      </>
    );
  }
}


export const AdminMessageResubmissionView = TenantViewAdminMessageResubmissionService.inject(
  _AdminMessageResubmissionView
);