import {
  React,
  _,
  bind,
  memoizeOne,
  numeral
} from "$Imports/Imports";

import {
  Container
} from "./Container";

import {
  AjaxActionIndicator,
  PageHeader,
  SearchBox
} from "$Components/Common";

import {
  Paper,
  TextField,
  Select,
  MenuItem
} from "$Imports/MaterialUIComponents";

import {
  CloudDownload
} from "$Imports/MaterialUIIcons";

import {
  DataTable,
  DataTablePager,
  IDataTableColumn,
  directionType,
  DateFormatter
} from "$Components/Common";

import {
  TableCell,
  TableRow,
  Button
} from "$Imports/MaterialUIComponents";

import { 
  VehicleCountReportViewModel,
  VehicleCountReportLine
} from "$Generated/api";

import {
  IPagerState,
  calcPagedState
} from "$State/PagerPagingState";

import {
  VehicleCountService,
  IVehicleCountServiceInjectedProps,
} from "$State/VehicleCountFreezerService";

const styles: {
  paperStyle: string;
  controls: string;
} = require("./VehicleCount.scss");

interface IVehicleCountBaseProps {

}

interface IVehicleCountState {

}

type IVehicleCountProps = IVehicleCountBaseProps
  & IVehicleCountServiceInjectedProps
;

class _VehicleCount extends React.Component<IVehicleCountProps, IVehicleCountState> {

  private readonly fileListColumns: IDataTableColumn<VehicleCountReportLine>[] = [
    {
      columnName: "tenantId",
      columnFieldData: (c) => c.tenantId,
      headerValue: "Tenant Id"
    },
    {
      columnName: "count",
      columnFieldData: (c) => c.count,
      headerValue: "Count"
    },
    {
      columnName: "friendlyName",
      columnFieldData: (c) => c.friendlyName,
      headerValue: "Friendly Name"
    },
    {
      columnName: "adapter",
      columnFieldData: (c) => c.adapter,
      headerValue: "Adapter"
    },
    {
      columnName: "client",
      columnFieldData: (c) => c.client || "Lytx Dispatch",
      headerValue: "Client"
    }
  ];

  componentDidMount() {
    this.props.vehicleCountService.fetchFileList();
  }

  @bind
  private _onChangeSelectedReport(e: React.ChangeEvent<{ name?: string | undefined; value: unknown; }>, child: React.ReactNode) {
    this.props.vehicleCountService.setSelectedReport(e.target.value as string);
  }

  @bind
  private _onDebounceChange(newValue: string) {
    this.props.vehicleCountService.setFilterText(newValue);
  }

  @bind
  private _onClearFilter() {
    this.props.vehicleCountService.setFilterText("");
  }

  @bind
  private _onPagerStateChanged(page: number, rowsPerPage: number) {
    this.props.vehicleCountService.setPagerState({
      page,
      rowsPerPage
    });
  }

  @bind
  private _onChangePage() { }

  @bind
  private _download() {
    const { vehicleCountReportResults } = this.props.vehicleCountService.getState();
    let csvData = vehicleCountReportResults.data?.data?.rawData ?? "";

    const saveDocument = document.createElement('a');
    saveDocument.href = 'data:text/csv;charset=utf-8,' + encodeURI(csvData);
    saveDocument.target = '_blank';
    saveDocument.download = vehicleCountReportResults.data?.data?.filename ?? "vehicleCountReport.csv";
    saveDocument.click();
  }

  private _onSearch(data: VehicleCountReportLine[], filterText: string): VehicleCountReportLine[] {
    return _.filter(data, (line) => {
      if (filterText.trim() === "") {
        return true;
      }

      let caseInsensitive = filterText.toLowerCase();
      let match = _.some(line, value => value ? value?.toLowerCase().indexOf(caseInsensitive) > -1 : false);
      return match;
    });
  }

  private readonly _onSearch_memoize = memoizeOne(this._onSearch);

  render() {
    const {
      fileListResults,
      fileOptions,
      selectedReport,
      vehicleCountReportResults,
      filterText,
      vehicleCountPager
    } = this.props.vehicleCountService.getState();

    const vehicleReportData = vehicleCountReportResults.data?.data?.reportLines ?? [];
    const pager = calcPagedState(vehicleCountPager);

    const filteredData = this._onSearch_memoize(vehicleReportData, filterText);

    return (
      <Container>
        <PageHeader title="Vehicle Count Report" />
        <div>
          <Paper
            className={styles.paperStyle}
          >
            <div className={styles.controls}>
              <Select
                label="Vehicle Count Report Date"
                name="reportDate"
                onChange={this._onChangeSelectedReport}
                value={selectedReport}
                style={{ width: "150px" }}
              >
                {
                  fileOptions.map((x, idx) => (
                    <MenuItem key={idx} value={x.filename}>
                      {x.display}
                    </MenuItem>
                  ))
                }
              </Select>
              <SearchBox
                value={filterText}
                onDebouncedChange={this._onDebounceChange}
                filterStatusLabel={`${numeral(filteredData.length).format("0,000")} ${filteredData.length === 1 ? "match" : "matches"}`}
                onClearClick={this._onClearFilter}
              />
              <Button
                onClick={this._download}
                startIcon={<CloudDownload />}
                color="primary"
              >
                Download
              </Button>
            </div>
            <AjaxActionIndicator
              state={[fileListResults, vehicleCountReportResults]}
            />
            <DataTable
              columns={this.fileListColumns}
              data={filteredData}
              skipRows={pager.skip}
              takeRows={pager.take}
              tableFooterComponent={(
                <TableRow>
                  <DataTablePager
                    count={filteredData.length}
                    page={vehicleCountPager.page}
                    rowsPerPage={vehicleCountPager.rowsPerPage}
                    onPagerStateChange={this._onPagerStateChanged}
                    onChangePage={this._onChangePage}
                  />
                </TableRow>
              )}
            />
          </Paper>
        </div>
      </Container>
    );
  }
}

export const VehicleCount = VehicleCountService.inject(
  _VehicleCount
);
