import {
    React,
    bind
  } from "$Imports/Imports";

import {
    DutyStatusActivity,
    Assignment
} from "$Generated/api";

import {
  Link
} from "$Imports/MaterialUIComponents";

import {
  DateFormatter
} from "$Components/Common";

interface IJobTimeLinkProp {
  data: DutyStatusActivity;
  onClick?: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>, data: DutyStatusActivity) => void;
}

export class JobTimeLink extends React.PureComponent<IJobTimeLinkProp> {

  @bind
  private _onContextMenu(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
    event.preventDefault();
  }

  @bind
  private _onClick(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
    event.preventDefault();
    if (this.props.onClick) {
      this.props.onClick(event, this.props.data);
    }
  }

  render() {
    const { data } = this.props;

    return (
      <Link
        href=""
        onClick={this._onClick}
        onAuxClick={this._onClick}
        onContextMenu={this._onContextMenu}
      >
          <DateFormatter value={data.activityTimestamp} />
      </Link>
    );
  }
}
