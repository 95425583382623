import {
    React, bind, _
} from "$Imports/Imports";

import {
  Link
} from "$Imports/MaterialUIComponents";

import { AdapterSummaryModel, SourceProviderModel } from "$Generated/api";


interface DashboardLinkProps {
    data: AdapterSummaryModel;
    onClick: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>, d: AdapterSummaryModel) => void;
}

export class DashboardLink extends React.Component<DashboardLinkProps> {

    @bind
    private onClick(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>)
    {
        event.preventDefault();
        this.props.onClick(event, this.props.data);
    }

    render() {

        let link ="";
        let names = _.map(this.props.data.sourceProviders, (s: SourceProviderModel) => s.friendlyName);
        link = _.join(names, ", ");

        return <Link
            href=""
            onClick={this.onClick}
            onAuxClick={this.onClick}
        >
            {link}
        </Link>
    }
}