import {
  React,
  bind,
  _,
} from "$Imports/Imports";

import {
  ApplicationSecurityConsumer
} from "$Providers/.";

import { SideNavigationMenuItem } from "./SideNavigationMenuItem";

import { INavigationItem, mainNavigation } from "./../modules/navigation";

import { MenuList } from "$Imports/MaterialUIComponents";
import { withStyles } from "$Imports/MaterialUIStyles";
import { INavigationServiceInjectedProps, NavigationService } from "$State/NavigationFreezerService";

import { defaultTheme } from "$Themes/.";
import { IThemeConfiguration } from "$Themes/ThemeConfiguration";
import { ThemeConsumer } from "$Providers/YaharaThemeProvider";


interface ISideNavigationBaseProps {
}

type ISideNavigationProps = ISideNavigationBaseProps & INavigationServiceInjectedProps;

class _SideNavigation extends React.Component<ISideNavigationProps> {

  @bind
  private _onChange(e: React.MouseEvent<HTMLLIElement, MouseEvent>, newValue: INavigationItem) {
    this.props.navigationService.navigateTo(newValue.url);
  }

  render() {
    const navDetails = this.props.navigationService.getParentPath(window.location.pathname);
    const mainNav = _.filter(mainNavigation, (n) => !n.externalLink);

    this.props.navigationService.updateTitle();

    return (
      <ThemeConsumer>
        {(context) =>
        {
          const StylesMenuList = withStyles(context.themeConfig.sideNavigationMenu)(MenuList);
          return (<StylesMenuList
            style={{
              width: "300px",
            }}
          >
            <ApplicationSecurityConsumer>
              {(context) => {
                return _.map(mainNav, (n, nIdx) => (
                  <SideNavigationMenuItem
                    key={nIdx}
                    navigationItem={n}
                    selected={n.url === (navDetails === null ? null : navDetails.url)}
                    onMenuItemClick={this._onChange}
                    securityContext={context}
                  />
                ));
              }}
            </ApplicationSecurityConsumer>
          </StylesMenuList>);
        }
      }
      </ThemeConsumer>
    );
  }
}

export const SideNavigation = NavigationService.inject(
  _SideNavigation,
);
