import {
  FreezerService,
  IAjaxState,
  managedAjaxUtil,
  _,
  bind
} from "$Imports/Imports";

import {
  JobDetailApiFactory,
  SamsaraHosExportSubmissionResultListResponseBase,
} from "$Generated/api";

import {
  SitePubSubManager
} from "$Utilities/PubSubUtil";

import {
  ErrorService
} from "$State/ErrorFreezerService";


const InjectedPropName = "samsaraHosErrorReport";

interface ISamsaraHosErrorReportFreezerState {
  jobId: string | null;
  hosErrorResults: IAjaxState<SamsaraHosExportSubmissionResultListResponseBase>;
}

class SamsaraHosErrorReportFreezerService extends FreezerService<ISamsaraHosErrorReportFreezerState, typeof InjectedPropName> {
  constructor() {
    super({
      jobId: null,
      hosErrorResults: managedAjaxUtil.createInitialState(),
    }, InjectedPropName);

    SitePubSubManager.subscribe("application:login:before", this.clearResults);
    SitePubSubManager.subscribe("job-detail:job-id-change", this.clearResults);
  }

  @bind
  private clearResults() {
    this.freezer.get().set({
      jobId: null,
      hosErrorResults: managedAjaxUtil.createInitialState()
     });
  }


  public setJobId(jobId: string): void {
    this.freezer.get().set({
      jobId,
    });
  }

  public async fetchHosErrors(forceUpdate: boolean = false) {
    if (this.freezer.get().hosErrorResults.hasFetched
      && !forceUpdate) {
      return;
    }

    const jobId = this.freezer.get().jobId;

    return managedAjaxUtil.fetchResults({
      ajaxStateProperty: "hosErrorResults",
      freezer: this.freezer,
      onExecute: (apiOptions, params, options) => {
        const submissionFactory = JobDetailApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
        return submissionFactory.apiV1JobDetailSamsaraHosExportErrorsGet(params);
      },
      params: {
        jobId: jobId ? jobId : undefined
      },
      onError: () => {
        ErrorService.pushErrorMessage("Failed to retrieve HOS error data from server");
      }
    })
  }
}

export const SamsaraHosErrorReportService = new SamsaraHosErrorReportFreezerService();
export type ISamsaraHosErrorReportFreezerServiceInjectedProps = ReturnType<SamsaraHosErrorReportFreezerService["getPropsForInjection"]>;