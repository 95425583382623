import {
  React,
  _,
  bind
} from "$Imports/Imports";
import { Button } from "$Imports/MaterialUIComponents";

// data models
import { AdvanceTextField } from "$Components/Common";
import { ICredentialProps } from "$State/CredentialTypes";
import { JJKellerCredentials } from "$Generated/api";

interface IJJKellerBaseProps {
}

type IJJKellerProps = IJJKellerBaseProps & ICredentialProps<JJKellerCredentials>;

export class JJKeller extends React.PureComponent<IJJKellerProps> {

  @bind
  handleOnChange(e: any): void {
    if (e.target.name === "userName") {
      this.props.onChange(this.props.providerKey, { userName: e.target.value });
    }
    else if (e.target.name === "password") {
      this.props.onChange(this.props.providerKey, { password: e.target.value });
    }
  }

  render(): JSX.Element {
    const jjkeller: JJKellerCredentials = this.props.data ? this.props.data : {} as JJKellerCredentials;
    return (
      <div>
        <AdvanceTextField
          label="Username*"
          name="userName"
          autoComplete="off"
          value={jjkeller.userName}
          onChange={(e) => this.handleOnChange(e)}
          error={(jjkeller.userName === "")}
        /><br />
        <AdvanceTextField
          name="password"
          autoComplete="new-password"
          type="password"
          label="Password"
          value={jjkeller.password}
          onChange={(e) => this.handleOnChange(e)}
          error={(jjkeller.password === "")}
        /><br />
        <Button className="float-right" disabled={true} color={"primary"} >VALIDATE CREDENTIALS</Button>
      </div>
    );
  }
}