import {
  React,
  bind,
  _,
  memoizeOne,
  numeral
} from "$Imports/Imports";

import {
  Container
} from "./Container";

import {
  DashboardLink
} from "./DashboardLink";

import {
  Paper,
} from "$Imports/MaterialUIComponents";

import {
  IDashboardServiceInjectedProps,
  DashboardService
} from "$State/DashboardFreezerService";

import {
  AjaxActionIndicator
} from "$Components/Common/AjaxActionIndicator";

import {
  DelinquentTenantTable
} from "./DelinquentTenantTable"

import {
  TenantSummary
} from "./TenantSummary";

import {
  StatusIndicatorLegend,
} from "$Components/Common/StatusIndicator";

import {
  DataTable,
  IDataTableColumn,
  PageHeader,
  SearchBox
} from "../components/Common";

import {
  AdapterSummaryModel,
  AdapterSummaryModelIEnumerableResponseBase,
  SourceProviderModel
} from "../generated/api";

import { TenantSummaryService } from "../state/TenantSummaryFreezerService";

import { NavigationService } from "../state/NavigationFreezerService";

const styles: {
  mainContainer: string;
  leftPanel: string;
  rightPanel: string;
  paperPadding: string;
} = require("./Dashboard.scss");

const dashboardImage = require("./../images/demo/dashboard-graph.png");

interface IAdapterSummaryBaseProps {

}

type IAdapterSummaryProps = IAdapterSummaryBaseProps & IDashboardServiceInjectedProps;

export class _Dashboard extends React.Component<IAdapterSummaryProps> {

  @bind
  private _onClick(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>, data: AdapterSummaryModel) {
    if (event.button !== 0) {
      return;
    }
    TenantSummaryService.setFilterValue({
      adapter: data.sourceProviders !== undefined && data.sourceProviders[0].friendlyName !== undefined ? data.sourceProviders[0].friendlyName : "",
    });
    NavigationService.navigateTo("/tenants");
  }

  private readonly columns: Array<IDataTableColumn<AdapterSummaryModel>> = [
    {
      columnName: "adapter",
      columnFieldData: (d) => {
        return(
        <DashboardLink
          data={d}
          onClick={this._onClick}
        />)
      },
      headerValue: "Adapter",
      sortMethod: (d) => this.getAdapterNames(d)
    },
    {
      columnName: "tenantCount",
      columnFieldData: (d) => d.tenantCount ? d.tenantCount : "",
      headerValue: "Tenant Count",
      sortMethod: (d) => d.tenantCount ? d.tenantCount : 0
    }
  ];

  componentWillMount() {
    this.props.dashboardService.fetchAdapterSummary(true);
    this.props.dashboardService.fetchDelinquentTenants(true);
  }

  /*private readonly columns: Array<IDataTableColumn<AdapterSummaryModel>> = [
    {
      columnName: "first-name",
      columnFieldData: (d) => d. ? d.firstName : "",
      sortMethod: (d) => (d.firstName ? d.firstName : "").toLowerCase(),
      headerValue: "First Name"
    }
  ];*/

  private readonly _onSearch_memoizeOne = memoizeOne(this._onSearch);

  @bind
  private getAdapterNames(d: AdapterSummaryModel) {
    var names = _.map(d.sourceProviders, (s: SourceProviderModel) => s.friendlyName);
    return _.join(names, ", ");
  }

  @bind
  private _onClearAdapterSearch() {
    this.props.dashboardService.setAdapterSearch("");
  }

  @bind
  private _onChangeAdapterSearch(newValue: string) {
    this.props.dashboardService.setAdapterSearch(newValue);
  }

  private _onSearch(adapterData: AdapterSummaryModelIEnumerableResponseBase | null | undefined, adapterFilter: string): AdapterSummaryModelIEnumerableResponseBase | null | undefined {
    if (adapterData?.data) {
      var filteredAdapters = _.clone(adapterData);
      filteredAdapters.data = _.filter(adapterData.data, (d) => {
        if (adapterFilter.trim() === "") {
          return true;
        }

        const names = this.getAdapterNames(d);
        const nameFilter: boolean = names.toLowerCase().indexOf(adapterFilter.toLowerCase()) !== -1;

        return nameFilter;
      });

      return filteredAdapters;
    }

    return adapterData;
  }

  render() {
    const { adapterSummaryResults, adapterFilter, delinquentTenantResults } = this.props.dashboardService.getState();
    const { data } = adapterSummaryResults;

    var tenantData = this._onSearch_memoizeOne(data, adapterFilter);
    var adapterList = tenantData ? (tenantData.data ? tenantData.data : []) : []

    return (
      <Container>
        <PageHeader title="Dashboard" />
        <div
          className={styles.mainContainer}
        >
          <div
            className={styles.leftPanel}
          >
            <Paper>
              <TenantSummary
                data={data ? data : {}}
              />
            </Paper>
            <Paper
              className={styles.paperPadding}
            >
              <AjaxActionIndicator
                state={[adapterSummaryResults]}
              />
              <SearchBox
                value={adapterFilter}
                onDebouncedChange={this._onChangeAdapterSearch}
                filterStatusLabel={` ${numeral(adapterList.length).format("0,000")} ${adapterList.length === 1 ? "Adapter" : "Adapters"}`}
                onClearClick={this._onClearAdapterSearch}
              />
              <DataTable
                columns={this.columns}
                data={adapterList}
              />
            </Paper>
            {/*<StatusIndicatorLegend
              className={styles.paperPadding}
            />*/}

          </div>
          <div
            className={styles.rightPanel}
          >
            <Paper>
              <DelinquentTenantTable
                delinquentTenantResults={delinquentTenantResults}
              />
            </Paper>
          </div>
        </div>
      </Container>
    );
  }
}

export const Dashboard = DashboardService.inject(
  _Dashboard
);
