import {
  React,
} from "$Imports/Imports";

import {
  AuthenticationSettingsInstance
} from "$Utilities/Security";

const defaultClaims: ISecurityContext = {
  Roles: [],
  FirstName: "",
  LastName: "",
  EmailAddress: "",
  IsAdminUser: false,
  IsClientUser: false,
};

interface ISecurityContext  {
  FirstName: string;
  LastName: string;
  EmailAddress: string;
  IsClientUser: boolean;
  IsAdminUser: boolean;
  Roles: string[];
}

const ApplicationSecurityContext = React.createContext(defaultClaims);

const ApplicationSecurityConsumer = ApplicationSecurityContext.Consumer;

interface IApplicationSecurityProviderProps {

}

interface IApplicationSecurityProviderState {
  securityContext: ISecurityContext;
}

class ApplicationSecurityProvider extends React.PureComponent<IApplicationSecurityProviderProps, IApplicationSecurityProviderState> {
  state = {
    securityContext: defaultClaims,
  };

  componentDidMount() {  

    const securityContext: ISecurityContext = {
      Roles: AuthenticationSettingsInstance.getRoles(),
      FirstName: AuthenticationSettingsInstance.getFirstName(),
      LastName: AuthenticationSettingsInstance.getLastName(),
      EmailAddress: AuthenticationSettingsInstance.getEmailAddress(),    
      IsAdminUser: AuthenticationSettingsInstance.isAdminUser(),
      IsClientUser: AuthenticationSettingsInstance.isClientUser(),
    };

    this.setState({
      securityContext
    });
  }

  render() {
    return (
      <ApplicationSecurityContext.Provider
        value={this.state.securityContext}
      >
        {this.props.children}
      </ApplicationSecurityContext.Provider>
    );
  }
}

function useApplicationSecurity(): ISecurityContext {
  const context = React.useContext(ApplicationSecurityContext);

  if (context === undefined) {
    throw new Error("useApplicationSecurity must be used within a ApplicationSecurityProvider");
  }

  return context;
}

/**
 * Checks the given security context for a list of roles
 * @param securityContext Security context to check roles for
 * @param roles A collection of roles that a user needs to have in their role list
 * @returns
 *    true - User has all roles in their security context,
 *    false - User is missing at least one role from the supplied list
 */
function securityContextHasRoles(securityContext: ISecurityContext, roles: string[]): boolean {
  let hasRoles = true;
  for (const role of roles) {
    if (securityContext.Roles.indexOf(role) < 0) {
      hasRoles = false;
      break;
    }
  }

  return hasRoles;
}

function securityContextLacksRole(securityContext: ISecurityContext, role: string): boolean {
  return securityContext.Roles.indexOf(role) < 0;
}

export {
  ApplicationSecurityProvider,
  useApplicationSecurity,
  ISecurityContext,
  ApplicationSecurityConsumer,
  ApplicationSecurityContext,
  securityContextHasRoles,
  securityContextLacksRole
};
