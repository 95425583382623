import {
  React,
  bind,
  _,
} from "$Imports/Imports";

import {
  ISecurityContext
} from "$Providers/.";

import {
  MenuItem,
  ListItemIcon
} from "$Imports/MaterialUIComponents";

import { INavigationItem } from "./../modules/navigation";
import { withStyles } from "$Imports/MaterialUIStyles";
import { ThemeConsumer } from "$Providers/YaharaThemeProvider";

interface ISideNavigationMenuItemProps {
  navigationItem: INavigationItem;
  securityContext: ISecurityContext;
  selected?: boolean;
  onMenuItemClick?: (event: React.MouseEvent<HTMLLIElement, MouseEvent>, navigationItem: INavigationItem) => void;
}


export class SideNavigationMenuItem extends React.PureComponent<ISideNavigationMenuItemProps> {
  static defaultProps: Partial<ISideNavigationMenuItemProps> = {
    selected: false,
  };

  @bind
  private _onClick(event: React.MouseEvent<HTMLLIElement, MouseEvent>) {
    if (this.props.onMenuItemClick) {
      this.props.onMenuItemClick(event, this.props.navigationItem);
    }
  }

  render() {
    const {
      navigationItem,
      selected,
      securityContext
    } = this.props;

    const disabled = !(typeof navigationItem.enabled === "function" ? navigationItem.enabled(navigationItem, securityContext) : navigationItem.enabled);

    const hasAccess = navigationItem.hasAccess !== undefined ?
      typeof navigationItem.hasAccess === "function" ? navigationItem.hasAccess(navigationItem, securityContext) : navigationItem.hasAccess :
      true;


    return hasAccess ? (
      <ThemeConsumer>
        {(context) => 
        {
          const StylesMenuItem = withStyles(context.themeConfig.sideNavigationMenu)(MenuItem);
          const StylesListItemIcon = withStyles(context.themeConfig.sideNavigationMenu)(ListItemIcon);

          return (<StylesMenuItem
            onClick={this._onClick}
            selected={selected}
            disabled={disabled}
            style={{
              minHeight: 48
            }}
          >
            {
              navigationItem.icon ? (
                <StylesListItemIcon>
                  {React.createElement(navigationItem.icon, selected ? {htmlColor: (context.themeConfig.sideNavigationSelectedColor)} : null)} 
                </StylesListItemIcon>
              ) : null
            }
            {navigationItem.label}
          </StylesMenuItem>);
        }
      }
      </ThemeConsumer>
    ) : null;
  }
}
