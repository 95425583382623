import {
  React,
  bind,
  _,
  memoizeOne,
} from "$Imports/Imports";

import {
  ChildJobViewModel
} from "$Generated/api";

import {
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from "$Imports/MaterialUIComponents";

import {
  DateFormatter
} from "$Components/Common";

interface ISubmissionDateSelectionProps {
  data: ChildJobViewModel[];
  selectedValue?: string;
  onChange?: (e: React.ChangeEvent<{ name?: string | undefined; value: unknown; }>, child: React.ReactNode, value: ChildJobViewModel | undefined) => void;
}

export class SubmissionDateSelection extends React.PureComponent<ISubmissionDateSelectionProps> {

  @bind
  private _onChange(e: React.ChangeEvent<{ name?: string | undefined; value: unknown; }>, child: React.ReactNode): void {

    const { onChange, data } = this.props;

    if (onChange) {
      const childValue = this._findSelected(data, e.target.value);
      onChange(e, child, childValue);
    }
  }

  private _findSelected(childJobs: ChildJobViewModel[], searchValue?: string | unknown): ChildJobViewModel | undefined {
    let foundItems = _.find(childJobs, (cj) => {
      if (searchValue === undefined || searchValue === null) {
        return false;
      }

      return cj.jobId === searchValue;
    });

    if (foundItems === undefined &&
      _.isArray(childJobs) &&
      childJobs.length !== 0) {
      foundItems = childJobs[0];
    }

    return foundItems;
  }

  private readonly _findSelected_memoize = memoizeOne(this._findSelected);

  render() {
    const { selectedValue, data } = this.props;
    const selection = this._findSelected_memoize(data, selectedValue);
    return (
      <FormControl>
        <InputLabel htmlFor="submission-dates">Submission Date</InputLabel>
        <Select
          value={selection ? selection.jobId : ""}
          onChange={this._onChange}
        >
          {_.map(data, (cj, cjidx) => (
            <MenuItem key={cjidx} value={cj.jobId}>
              <DateFormatter
                value={cj.startDate}
              />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  }
}