import {
  _,
  managedAjaxUtil,
  IAjaxState,
  FreezerService,
  bind,
} from "$Imports/Imports";

import { 
  ConfigurationApiFactory,
  LytxContacts,
  LytxContactsResponseBase, 
  ResponseBase
} from "$Generated/api";

import { APPCONFIG } from "../../constants/Config";
import { isNullOrUndefined } from "../../modules/helpers";

const InjectedPropName = "tenantContactsService";

interface ITenantContactsState {
  tenantId: string;
  contacts: IAjaxState<LytxContacts>;
  editContacts: string;
  valid: boolean;
  saveResult: IAjaxState<ResponseBase>;
}

class TenantContactsFreezerService extends FreezerService<ITenantContactsState, typeof InjectedPropName>{

  constructor() {
    super({
      tenantId: "",
      contacts: managedAjaxUtil.createInitialState(),
      editContacts: "",
      valid: true,
      saveResult: managedAjaxUtil.createInitialState()
    }, InjectedPropName);
  }

  private async fetchTenantContacts(tenantId: string): Promise<void> {
    await managedAjaxUtil.fetchResults({
      freezer: this.freezer,
      ajaxStateProperty: "contacts",
      onExecute: (apiOptions, params, options) => {
        const configurationApiFactory = ConfigurationApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
        return configurationApiFactory.apiV1ContactsLytxTenantIdGet({ tenantId: params.tenantId });
      },
      onOk: (data: LytxContactsResponseBase) => {
        if (data.success && data.data) {
          this.freezer.get().set({ editContacts: data.data.errorReportEmailRecipients?.join("\n") });
        }
      },
      params: {
        tenantId: tenantId
      }
    });
  }

  @bind
  public async saveContacts() {
    if (this.validateEmails()) {
      return managedAjaxUtil.fetchResults({
        freezer: this.freezer,
        ajaxStateProperty: "saveResult",
        onExecute: (apiOptions, params, options) => {
          const contacts: LytxContacts = {
            errorReportEmailRecipients: this.getEmails()
          };
          const configurationApiFactory = ConfigurationApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
          return configurationApiFactory.apiV1ContactsLytxSavePost({ tenantId: params.tenantId, body: contacts });
        },
        onOk: (data: ResponseBase) => {
          if (data.success) {
            this.resetFreezer();
          }
        },
        params: {
          tenantId: this.freezer.get().tenantId
        }
      });
    } else {
      this.freezer.get().set({ valid: false });
    }
  }

  private validateEmails(): boolean {
    var isValid = true;
    const emails = this.getEmails();
    if (emails.length > 0) {
      const emailRegex = RegExp(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/);
      isValid = emails.every((e) => emailRegex.test(e));
    }
    return isValid;
  }

  public setTenantId(tenantId: string) {
    this.freezer.get().set({ tenantId: tenantId });

    this.fetchTenantContacts(tenantId);
  }

  public getTenantId(): string {
    return this.freezer.get().tenantId;
  }

  public getContacts(): LytxContacts {
    const contacts = this.freezer.get().contacts.toJS();
    return contacts.data ? contacts.data : {};
  }

  public getEmailsAsString(): string {
    return this.freezer.get().editContacts;
  }

  public getEmails(): string[] {
    return this.freezer.get().editContacts.split("\n").map((e) => e.trim());
  }

  public onEditChange(newValue: string) {
    this.freezer.get().set({ editContacts: newValue });
  }

  @bind
  public resetFreezer() {
    this.freezer.get().set({
      tenantId: "",
      contacts: managedAjaxUtil.createInitialState(),
      editContacts: "",
      valid: true,
      saveResult: managedAjaxUtil.createInitialState()
    });
  }
}

export const TenantContactsService = new TenantContactsFreezerService();
export type ITenantContactsServiceInjectedProps = ReturnType<TenantContactsFreezerService["getPropsForInjection"]>;