import {
  React,
  IAjaxState,
  _
} from "$Imports/Imports";

import { LinearProgress } from "$Imports/MaterialUIComponents";

const styles = require("./AjaxActionIndicator.scss") as {
  mainContainer: string;
};

interface IAjaxActionIndicatorProps {
  state: IAjaxState<any> | IAjaxState<any>[];
  showProgress?: boolean;
}

export class AjaxActionIndicator extends React.PureComponent<IAjaxActionIndicatorProps> {

  static defaultProps: Partial<IAjaxActionIndicatorProps> = {
    showProgress: false,
  };

  render() {
    const { state, showProgress } = this.props;
    const checkAjaxState: IAjaxState<any>[] = state instanceof Array ? state as IAjaxState<any>[] : [state];
    let isFetching = false;

    if (state) {
      isFetching = _.some(checkAjaxState, (c) => c.isFetching);
    }

    return (
      <div
        className={styles.mainContainer}
      >
        {isFetching || showProgress ? (<LinearProgress />) : null}
      </div>
    );
  }
}
