import {
  FreezerService,
} from "$Imports/Imports";

import {
  timeZoneType
} from "$Components/Common";

const InjectedPropName = "timeZoneService";

interface ITimeZoneFreezerState {
  timeZone: timeZoneType;
  dialogTimeZone: timeZoneType;
  dialogIsOpen: boolean;
  isMiliaryTime: boolean; 
  dialogIsMilitaryTime: boolean;
}

class TimeZoneFreezerService extends FreezerService<ITimeZoneFreezerState, typeof InjectedPropName> {
  constructor() {
    const savedTZ = ((window.localStorage.getItem("timeZone") as timeZoneType) || "UTC"); // Casting should be safe because we are only setting a timeZoneType here
    const savedIsMilitaryTime = TimeZoneFreezerService.convertSavedIsMilitaryTimeString(window.localStorage.getItem("isMilitaryTime") || "0");

    super({
      timeZone: savedTZ,
      dialogIsOpen: false,
      dialogTimeZone: savedTZ,
      isMiliaryTime: savedIsMilitaryTime,
      dialogIsMilitaryTime: savedIsMilitaryTime
    }, InjectedPropName);
  }

  private static convertSavedIsMilitaryTimeString(input: string): boolean {
    return input === "1";
  }

  public updateTimeZone(timeZone: timeZoneType) {
    this.freezer.get().set({ timeZone });
    window.localStorage.setItem("timeZone", timeZone);
  }

  public updateDialogTimeZone(timeZone: timeZoneType) {
    this.freezer.get().set({ dialogTimeZone: timeZone });
  }

  public updateDialogIsOpen(isOpen: boolean) {
    this.freezer.get().set({ dialogIsOpen: isOpen });
  }

  public updateIsMilitaryTime(isMilitaryTime: boolean) {
    this.freezer.get().set({isMiliaryTime: isMilitaryTime});
    window.localStorage.setItem("isMilitaryTime", isMilitaryTime ? "1" : "0");
  }

  public updateDialogIsMilitaryTime(isMilitaryTime: boolean) {
    this.freezer.get().set({ dialogIsMilitaryTime: isMilitaryTime });
  }

  public getTimeZone(): timeZoneType {
    return this.freezer.get().timeZone;
  }

  public getDialogTimeZone(): timeZoneType {
    return this.freezer.get().dialogTimeZone;
  }

  public getDialogIsOpen(): boolean {
    return this.freezer.get().dialogIsOpen;
  }

  public getisMilitaryTime(): boolean {
    return this.freezer.get().isMiliaryTime;
  }

  public getDialogIsMilitaryTime(): boolean {
    return this.freezer.get().dialogIsMilitaryTime;
  }

}

export const TimeZoneService = new TimeZoneFreezerService();
export type ITimeZoneServiceInjectedProps = ReturnType<TimeZoneFreezerService["getPropsForInjection"]>;
