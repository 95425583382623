import {
  React,
  _,
} from "$Imports/Imports";

import {
  JobViewModel,
  JobStep
} from "$Generated/api";

import {
  DataTable,
  IDataTableColumn
} from "$Components/Common";

import {
  Button
} from "$Imports/MaterialUIComponents";


interface IAdminMessageResubmissionProps {
  data: JobViewModel;
  resubmit: (messageId: string) => void;
}

export class AdminMessageResubmission extends React.PureComponent<IAdminMessageResubmissionProps> {


  resubmitMessage(id: string | undefined) {
    if (id && this.props.resubmit) {
      this.props.resubmit(id);
    }
    else {
      throw new Error("MessageId undefined");
    }
  }

  private readonly columns: IDataTableColumn<JobStep>[] = [
    {
      columnName: "resubmit",
      columnFieldData: (d) => {
        return d.stepNumber !== 1 ?
          (
            <Button
              variant="contained"
              color="primary"
              onClick={() => this.resubmitMessage(d.messageId)}

            >
              Resubmit
            </Button>
          ) : (<div />);
      },
      headerValue: "",
      cellProps: { style: { width: "100px" } }
    },
    {
      columnName: "name",
      columnFieldData: (d) => d.friendlyName ? d.friendlyName : "",
      headerValue: "Name",
      cellProps: { style: { width: "150px", overflowWrap: "break-word" } }
    },
    {
      columnName: "status",
      columnFieldData: (d) => d.resultStatus ? d.resultStatus : "",
      headerValue: "Status",
      cellProps: { style: { maxWidth: "80px", overflowWrap: "break-word" } }
    },
    {
      columnName: "message",
      columnFieldData: (d) => d.message ? d.message : "",
      headerValue: "Message",
      cellProps: { style: { maxWidth: "300px", overflowWrap: "break-word" } }
    },
  ];


  render() {

    const data = this.props.data.steps || [];

    return (
      <DataTable
        columns={this.columns}
        data={data}
      />
    );
  }
}