import {
  React,
  _,
  bind
} from "$Imports/Imports";

import {
  FormControl,
  InputLabel,
  Select,
  SelectProps,
  MenuItem,
} from "$Imports/MaterialUIComponents";

const styles: {
  statusSelector: string;
} = require("./JobStatusSelector.scss");

interface IJobStatusSelectorProps extends SelectProps {
  onStatusChange: (e: React.ChangeEvent<{ name?: string | undefined; value: unknown; }>, child: React.ReactNode, status: string) => void;
}

export class JobStatusSelector extends React.PureComponent<IJobStatusSelectorProps> {
  @bind
  private _onChange(e: React.ChangeEvent<{ name?: string | undefined; value: unknown; }>, child: React.ReactNode): void {
    this.props.onStatusChange(e, child, e.target.value as string);
  }

  render() {
    const { value, onChange, onStatusChange, ...passThroughProps } = this.props;

    return (
      <FormControl className={styles.statusSelector}>
        <InputLabel htmlFor="status">Status</InputLabel>
        <Select
          {...passThroughProps}
          value={value}
          onChange={this._onChange}
        >
          <MenuItem value="">
            <em>Show all</em>
          </MenuItem>
          <MenuItem value="Processing">
            Processing
          </MenuItem>
          <MenuItem value="Complete">
            Complete
          </MenuItem>
          <MenuItem value="Error">
            Error
          </MenuItem>
        </Select>
      </FormControl>
    );
  }
}