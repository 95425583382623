import {
  React,
  moment,
} from "$Imports/Imports";

import {
  StatusIndicator,
  statusType
} from "..";

import {
  JobViewModelJobStatusEnum
} from "$Generated/api";

import { Tooltip } from "$Imports/MaterialUIComponents";

export interface IIntervalOption {
  granularity?: moment.DurationInputArg2,
  interval?: moment.DurationInputArg1,
}

interface IJobStatusIndicatorProps {
  jobStatus: JobViewModelJobStatusEnum | undefined;
  jobStartDate?: Date | moment.Moment | null;
  unknownOption?: IIntervalOption | ((d: Date | moment.Moment | undefined | null) => boolean);
}

export class JobStatusIndicator extends React.PureComponent<IJobStatusIndicatorProps> {

  static defaultProps: Partial<IJobStatusIndicatorProps> = {
    unknownOption: {
      granularity: "day",
      interval: 1,
    }
  }
  render() {
    const { jobStatus, unknownOption, jobStartDate } = this.props;

    let status: statusType = "Unknown";
    switch (jobStatus) {
      case "Complete":
        status = "Good";
        
        break;

      case "Error":
        status = "Error";
        break;

      case "Processing":
        status = "Processing";
        break;

      default:
        status = "Unknown";
        break;
    }

    if (unknownOption && status === "Processing") {
      if (typeof unknownOption === "function" && unknownOption(jobStartDate)) {
        status = "Unknown";
      } else if (typeof unknownOption === "object" &&
        unknownOption.granularity &&
        unknownOption.interval !== null &&
        unknownOption.interval !== undefined &&
        jobStartDate) {
        if (moment(jobStartDate).isBefore(moment().subtract(unknownOption.interval, unknownOption.granularity))) {
          status = "Unknown";
        }
      }
    }

    return (
      <Tooltip 
          title={jobStatus === undefined ? "" : jobStatus}
          placement="right-end">
      <StatusIndicator
        status={status}
      />
      </Tooltip>
    );
  }
}
