import {
  bind,
  FreezerService,
  IAjaxState,
  managedAjaxUtil,
  moment
} from "$Imports/Imports";

import {
  DrivingFaultEventIEnumerableResponseBase,
  TransportationApiFactory,
} from "$Generated/api";

import {
  IPagerState
} from "../PagerPagingState";

import {
  SitePubSubManager
} from "$Utilities/PubSubUtil";

import {
  ErrorService
} from "$State/ErrorFreezerService";

const InjectedPropName = "jobDetailFaultsService";

const defaultPagerState: IPagerState = {
  page: 0,
  rowsPerPage: 50,
};

interface ITenantViewFaultFreezerState {
  jobDetailFaultsResults: IAjaxState<DrivingFaultEventIEnumerableResponseBase>;
  jobDetailFaultsPager: IPagerState;
  jobDetailFaultsSearch: string;
  jobId: string | null;
}

class TenantViewFaultFreezerService extends FreezerService<ITenantViewFaultFreezerState, typeof InjectedPropName> {
  constructor() {
    super({
      jobDetailFaultsResults: managedAjaxUtil.createInitialState(),
      jobDetailFaultsPager: defaultPagerState,
      jobDetailFaultsSearch: "",
      jobId: null
    }, InjectedPropName);

    SitePubSubManager.subscribe("application:login:before", this.clearResults);
    SitePubSubManager.subscribe("job-detail:job-id-change", this.clearResults);
  }

  public setSearch(search: string) {
    const currentPaging = this.freezer.get().jobDetailFaultsPager.toJS();

    this.freezer.get().set({
      jobDetailFaultsSearch: search,
      jobDetailFaultsPager: {
        page: 0,
        rowsPerPage: currentPaging.rowsPerPage
      }
    });
  }

  public setFaultsJobId(jobId: string) {
    this.freezer.get().set({
      jobId
    });
  }

  @bind
  private clearResults() {
    this.freezer.get().set({
      jobDetailFaultsResults: managedAjaxUtil.createInitialState(),
      jobDetailFaultsSearch: "",
      jobDetailFaultsPager: defaultPagerState,
    });
  }

  public setPagerState(pager: IPagerState) {
    this.freezer.get().jobDetailFaultsPager.set(pager);
  }

  public async fetchFaults(forceUpdate: boolean = false) {
    const results = this.freezer.get().jobDetailFaultsResults;
    const jobId = this.freezer.get().jobId;

    if (results.hasFetched && !forceUpdate) {
      return;
    }

    return managedAjaxUtil.fetchResults({
      ajaxStateProperty: "jobDetailFaultsResults",
      freezer: this.freezer,
      onExecute: (apiOptions, params, options) => {
        const transportationFactory = TransportationApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
        return transportationFactory.apiV1DrivingFaultsGet(params);
      },
      params: {
        jobId: jobId ? jobId : undefined,
      },
      onError: () => {
        ErrorService.pushErrorMessage("Failed to retrieve vehicle assignment data from the server.");
      }
    });
  }
}

export const TenantViewFaultsService = new TenantViewFaultFreezerService();
export type ITenantViewFaultsServiceInjectedProps = ReturnType<TenantViewFaultFreezerService["getPropsForInjection"]>;