import {
  React
} from "$Imports/Imports";


import { IThemeConfiguration } from "$Themes/ThemeConfiguration";
import { defaultTheme } from "$Themes/defaultTheme";

interface IYaharaThemeContext {
  themeConfig: IThemeConfiguration,
}

const defaultThemeContext: IYaharaThemeContext = {
  themeConfig: defaultTheme,
};

const YaharaThemeContext = React.createContext(defaultThemeContext);

const YaharaThemeConsumer = YaharaThemeContext.Consumer;

interface IYaharaThemeProviderProps {
  themeConfig: IThemeConfiguration;
}

interface IYaharaThemeProviderState {
  themeContext: IYaharaThemeContext;
}

export class YaharaThemeProvider extends React.Component<IYaharaThemeProviderProps, IYaharaThemeProviderState> {

  constructor(props: IYaharaThemeProviderProps) {
    super(props);
    this.state = { themeContext: { themeConfig: props.themeConfig } };
  }


  render() {
    return (
      <YaharaThemeContext.Provider
        value={this.state.themeContext}
      >
        {this.props.children}
      </YaharaThemeContext.Provider>
    );
  }
}

export {
  YaharaThemeConsumer as ThemeConsumer,
  YaharaThemeContext as ThemeContext
};