import {
  FreezerService,
  IAjaxState,
  managedAjaxUtil,
  _
} from "$Imports/Imports";

import { APPCONFIG } from "../constants/Config";

import {
  TenantApiFactory,
  TenantViewModel,
  TenantSummaryViewModel,
  ResponseBase,
} from "$Generated/api";

const InjectedPropName = "tenantService";
export interface ITenantGridFilters {
  itemsPerPage: number;
  currentPage: number;
  totalPages: number;
  searchFilter: string;
  sortColumn: string;
  sortAscending: boolean;
  isActive: boolean;
}

const defaultFilters: ITenantGridFilters = {
  itemsPerPage: 25,
  currentPage: 1,
  totalPages: 1,
  searchFilter: "",
  sortColumn: "",
  sortAscending: true,
  isActive: true,
};

export interface ICustomAttribute {
  showMessageDialog: boolean;
  messageText: string;
  messageKey: string;
}

const defaultCustomAttribute: ICustomAttribute = {
  showMessageDialog: false,
  messageText: "",
  messageKey: ""
};

interface ITenantState {
  filters: ITenantGridFilters;
  tenantResults: IAjaxState<any>;
  tenantActionResult: IAjaxState<ResponseBase>;
  hasError: boolean;
  selectedTenant?: TenantViewModel;
  paginatorCurrentPage: number;
  editAddDialogMode: string;
  hasTenantIdError: string;
  isTenantIdInvalid: boolean;
  showEditCredentialsDialog: boolean;
  showOpenDialog: boolean;
  showRestartDialog: boolean;
  loadResults: IAjaxState<any>;
  dataArray: string[];
  addNewCustomAttribute: boolean;
  customAttribute?: ICustomAttribute;
  textDialogData: string;
  startJobDialogOpen: boolean;
}

class TenantFreezerService extends FreezerService<ITenantState, typeof InjectedPropName> {
  constructor() {
    super({
      filters: defaultFilters,
      tenantResults: managedAjaxUtil.createInitialState(),
      tenantActionResult: managedAjaxUtil.createInitialState(),
      hasError: false,
      selectedTenant: undefined,
      paginatorCurrentPage: 1,
      editAddDialogMode: "none",
      hasTenantIdError: "",
      isTenantIdInvalid: true,
      showEditCredentialsDialog: false,
      showOpenDialog: false,
      showRestartDialog: false,
      loadResults: managedAjaxUtil.createInitialState(),
      dataArray: [],
      addNewCustomAttribute: false,
      customAttribute: defaultCustomAttribute,
      textDialogData: "",
      startJobDialogOpen: false,
    }, InjectedPropName);
  }

  public async getTenants(): Promise<void | any> {
    const filters: ITenantGridFilters = this.freezer.get().filters.toJS();
    return managedAjaxUtil.fetchResults({
      ajaxStateProperty: "tenantResults",
      freezer: this.freezer,
      onExecute: (apiOptions, params, options) => {
        if (params === undefined) {
          throw new Error("Parameters is undefined");
        }

        const tenantApi = TenantApiFactory(APPCONFIG.fetch, APPCONFIG.baseUrl);
        return tenantApi.apiV1TenantsGet(params);
      },
      params: {
        skip: ((filters.currentPage - 1) * filters.itemsPerPage),
        take: filters.itemsPerPage,
        sortColumn: filters.sortColumn,
        sortAscending: filters.sortAscending,
        search: filters.searchFilter,
        isActive: filters.isActive,
      },
      onOk: (data) => {
        /*if (data !== undefined && data.success && data.data !== undefined) {
          filters.totalPages = data.totalPages !== undefined ? data.totalPages : 0;
          filters.currentPage = data.currentPage !== undefined ? data.currentPage : 0;
          filters.itemsPerPage = data.itemsPerPage !== undefined ? data.itemsPerPage : 25;
          this.freezer.get().filters.set(filters);
          this.freezer.get().set({ hasError: false });
        }

        if (!data.success) {
          this.freezer.get().set({ hasError: true });
        }*/

        return data;
      },
      onError: () => {
        this.freezer.get().set({ hasError: true });
      }
    });
  }

  public async deleteTenant(): Promise<void | ResponseBase> {
    const tenant = this.freezer.get().selectedTenant;
    if (tenant !== undefined) {
      return managedAjaxUtil.fetchResults({
        ajaxStateProperty: "tenantActionResult",
        freezer: this.freezer,
        onExecute: (apiOptions, params, options) => {
          if (params === undefined) {
            throw new Error("Parameters is undefined");
          }

          const tenantApi = TenantApiFactory(APPCONFIG.fetch, APPCONFIG.baseUrl);
          return tenantApi.apiV1TenantDeletePost(params);
        },
        params: {
          body: tenant.toJS()
        },
        onOk: (data) => {
          if (data !== undefined && data.success) {
            this.getTenants();
            this.freezer.get().set({ selectedTenant: undefined, showOpenDialog: false });
          }

          if (!data.success) {
            this.freezer.get().set({ hasError: true });
          }

          return data;
        },
        onError: () => {
          this.freezer.get().set({ hasError: true });
        }
      });
    }
  }

  public async saveTenant(): Promise<void | ResponseBase> {
    const tenant = this.freezer.get().selectedTenant;
    if (tenant !== undefined) {
      return managedAjaxUtil.fetchResults({
        ajaxStateProperty: "tenantActionResult",
        freezer: this.freezer,
        onExecute: (apiOptions, params, options) => {
          if (params === undefined) {
            throw new Error("Parameters is undefined");
          }

          const tenantApi = TenantApiFactory(APPCONFIG.fetch, APPCONFIG.baseUrl);
          return tenantApi.apiV1TenantSavePost(params);
        },
        params: {
          body: tenant.toJS()
        },
        onOk: (data) => {
          if (data !== undefined && data.success) {
            this.freezer.get().set({ editAddDialogMode: "none", hasTenantIdError: "", isTenantIdInvalid: false, selectedTenant: undefined, customAttribute: undefined, addNewCustomAttribute: false });
            this.getTenants();
          }

          if (!data.success) {
            if (data?.error?.message?.search("23505") === 0) {
              const error: string = "Tenant Id already exists. Please enter new Tenant Id.";
              this.freezer.get().set({ hasTenantIdError: error, isTenantIdInvalid: true });
            }
            this.freezer.get().set({ hasError: true });
          }

          return data;
        },
        onError: () => {
          this.freezer.get().set({ hasError: true });
        }
      });
    }
  }

  public async restartExecution(): Promise<void | ResponseBase> {
    const tenant = this.freezer.get().selectedTenant;
    if (tenant !== undefined) {
      return managedAjaxUtil.fetchResults({
        ajaxStateProperty: "tenantResults",
        freezer: this.freezer,
        onExecute: (apiOptions, params, options) => {
          if (params === undefined) {
            throw new Error("Parameters is undefined");
          }

          const tenantApi = TenantApiFactory(APPCONFIG.fetch, APPCONFIG.baseUrl);
          return tenantApi.apiV1TenantRestartPost(params);
        },
        params: {},
        onOk: (data) => {
          if (!data.success) {
            this.freezer.get().set({ hasError: true, selectedTenant: undefined, showRestartDialog: false });
            this.getTenants();
          }

          return data;
        },
        onError: () => {
          this.freezer.get().set({ hasError: true });
        }
      });
    }
  }

  public async startJob(tenant: TenantSummaryViewModel): Promise<void | ResponseBase> {
    if (tenant !== undefined) {
      return managedAjaxUtil.fetchResults({
        ajaxStateProperty: "tenantResults",
        freezer: this.freezer,
        onExecute: (apiOptions, params, options) => {
          if (params === undefined) {
            throw new Error("Parameters is undefined");
          }
          const tenantApi = TenantApiFactory(APPCONFIG.fetch, APPCONFIG.baseUrl);
          return tenantApi.apiV1TenantStartPost(params);
        },
        params: {
          body: tenant
        },
        onOk: (data) => {
          if (!data.success) {
            this.freezer.get().set({ hasError: true });
          }
          this.freezer.get().set({startJobDialogOpen : true});
          return data;
        },
        onError: () => {
          this.freezer.get().set({ hasError: true });
        }
      });
    }
  }

  public getStartJobDialogOpen(): boolean
  {
    return this.freezer.get().startJobDialogOpen;
  }

  public setStartJobDialogOpen(toSet: boolean)
  {
    this.freezer.get().set({startJobDialogOpen : toSet});
  }

}

export const TenantService = new TenantFreezerService();
export type ITenantServiceInjectedProps = ReturnType<TenantFreezerService["getPropsForInjection"]>;
