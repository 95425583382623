import {
    FreezerService,
    IAjaxState,
    managedAjaxUtil,
    _,
    bind
  } from "$Imports/Imports";
  
  import {
    JobDetailApiFactory,
    LytxAdminReportViewListResponseBase,
    LytxAdminReportView
  } from "$Generated/api";
  
  import {
    SitePubSubManager
  } from "$Utilities/PubSubUtil";
  
  import {
    ErrorService
  } from "$State/ErrorFreezerService";

  import {
    IPagerState
  } from "../PagerPagingState";
  
  const InjectedPropName = "LytxAdminErrorReport";
  
  interface ILytxAdminErrorReportFreezerState {
    jobId: string | null;
    reportListResults: IAjaxState<LytxAdminReportViewListResponseBase>;
    selectedReport: LytxAdminReportView | null;
    lytxReportPagedState: IPagerState;
  }

  const defaultPagerState: IPagerState = {
    page: 0,
    rowsPerPage: 25,
  };
  
  class LytxAdminErrorReportFreezerService extends FreezerService<ILytxAdminErrorReportFreezerState, typeof InjectedPropName> {
    constructor() {
      super({
        jobId: null,
        reportListResults: managedAjaxUtil.createInitialState(),
        selectedReport: null,
        lytxReportPagedState: defaultPagerState,
      }, InjectedPropName);
  
      SitePubSubManager.subscribe("application:login:before", this.clearResults);
      SitePubSubManager.subscribe("job-detail:job-id-change", this.clearResults);
    }
  
    @bind
    private clearResults() {
      this.freezer.get().set({
        jobId: null,
        reportListResults: managedAjaxUtil.createInitialState(),
        selectedReport: null,
        lytxReportPagedState: defaultPagerState
       });
    }
  
    public setJobId(jobId: string): void {
      this.freezer.get().set({
        jobId,
      });
    }
  
    public setLytxReportPagerState(pager: IPagerState) {
      this.freezer.get().lytxReportPagedState.set(pager);
    }

    public setSelectedLytxReport(report: LytxAdminReportView | null){
      this.freezer.get().set({
        selectedReport: report,
      })
    }

    public async fetchErrors(forceUpdate: boolean = false) {
      if (this.freezer.get().reportListResults.hasFetched
        && !forceUpdate) {
        return;
      }
  
      const jobId = this.freezer.get().jobId;
  
      return managedAjaxUtil.fetchResults({
        ajaxStateProperty: "reportListResults",
        freezer: this.freezer,
        onExecute: (apiOptions, params, options) => {
          const submissionFactory = JobDetailApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
            return submissionFactory.apiV1JobDetailLytxAdminReportsGet(params);
        },
        params: {
          jobId: jobId ? jobId : undefined
        },
        onOk: (data: LytxAdminReportViewListResponseBase) => {
          const reports = data?.data;

          // Select first report
          if (reports && reports.length > 0){
            const report = reports[0];
            this.freezer.get().set({
              selectedReport: report
            });
          }
        },
        onError: () => {
          ErrorService.pushErrorMessage("Failed to retrieve error data from server");
        }
      })
    }
  }
  
  export const LytxAdminErrorReportService = new LytxAdminErrorReportFreezerService();
  export type ILytxAdminErrorReportFreezerServiceInjectedProps = ReturnType<LytxAdminErrorReportFreezerService["getPropsForInjection"]>;